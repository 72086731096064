import {
  Grid,
  Fade,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  IconButton,
  Stack,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Add, Delete, ExpandMore, Settings } from '@mui/icons-material';
import { WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { MouseEvent } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { PresetStore } from '../Stores/PresetStore';
import { arrayMoveImmutable } from 'array-move';
import { FieldInputTypeName, FieldInputTypes } from '@ekkogmbh/apisdk';
import { FieldInputTypePopover } from './FieldInputTypePopover';

const styles = FormStyles;
const fadeTimeout = 2000;

interface PresetPanelSortingState {
  items: string[];
  popoverAnchor?: HTMLButtonElement;
  editFieldInutTypeValue?: string;
}

interface PresetPanelSortingStores {
  presetStore: PresetStore;
}

interface PresetPanelSortingProps extends WithStyles<typeof styles> {
  loading: boolean;
  readOnly?: boolean;
  title?: string;
  sortableItems: string[];
  onSelectItem?: (selectedItems: string[]) => void;
  selectedItems?: string[];
}

@inject('presetStore')
@observer
class PresetPanelSortingComponent extends React.Component<PresetPanelSortingProps, PresetPanelSortingState> {
  public state: PresetPanelSortingState = {
    items: [],
  };

  get stores(): PresetPanelSortingStores {
    return this.props as PresetPanelSortingProps & PresetPanelSortingStores;
  }

  public componentDidMount(): void {
    const { selectedItems, onSelectItem } = this.props;
    const { presetStore } = this.stores;

    if (onSelectItem && selectedItems) {
      presetStore.selectedKeys = selectedItems;
      onSelectItem(selectedItems);
    }
  }

  public static getDerivedStateFromProps(props: Readonly<PresetPanelSortingProps>, state: PresetPanelSortingState) {
    if (JSON.stringify(props.sortableItems) !== JSON.stringify(state.items)) {
      return {
        items: props.sortableItems,
      };
    }

    return null;
  }

  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const { presetStore } = this.stores;
    const { items } = this.state;

    presetStore.setState({ keys: arrayMoveImmutable(items, oldIndex, newIndex) });
    presetStore.changed = true;
  };

  public onAddKey = (value: string) => {
    const { presetStore } = this.stores;
    const { keys, types } = presetStore.state;

    const newKeys = Array.from(keys);
    newKeys.push(value);
    types[value] = { name: FieldInputTypeName.ANY };
    presetStore.setState({ keys: newKeys, types });
    presetStore.changed = true;
  };

  public onDeleteKey = (event: MouseEvent<HTMLButtonElement>, value: string) => {
    event.stopPropagation();

    const { presetStore } = this.stores;
    const { keys, types } = presetStore.state;

    const newKeys = Array.from(keys);
    delete types[value];
    delete newKeys[keys.indexOf(value)];

    presetStore.setState({ keys: newKeys.filter(String), types });
    presetStore.changed = true;
  };

  private onToggleKey = (value: string) => {
    const { presetStore } = this.stores;
    const { selectedKeys } = presetStore;
    const { onSelectItem } = this.props;

    if (onSelectItem === undefined) {
      return;
    }

    const keyIndex = selectedKeys.indexOf(value);

    if (keyIndex >= 0) {
      selectedKeys.splice(keyIndex, 1);
    } else {
      selectedKeys.push(value);
    }

    // copy so that observers pick up the change
    presetStore.selectedKeys = selectedKeys.slice();

    // trigger callback
    onSelectItem(presetStore.selectedKeys);
  };

  public togglePopover = (anchor?: HTMLButtonElement, value?: string): void => {
    this.setState({ popoverAnchor: anchor, editFieldInutTypeValue: value });
  };

  public render() {
    const { items, popoverAnchor, editFieldInutTypeValue } = this.state;
    const { classes, loading, readOnly, title, onSelectItem } = this.props;
    const { presetStore } = this.stores;
    const { types } = presetStore.state;
    const { stagedKeys, selectedKeys } = presetStore;

    return (
      <Grid container spacing={2} alignItems={'stretch'} style={{ position: 'relative' }}>
        {loading && <LoadingMask width={42} height={42} />}
        {editFieldInutTypeValue && popoverAnchor && (
          <FieldInputTypePopover
            initialConfig={types[editFieldInutTypeValue]}
            onChangeCallback={(conf) =>
              presetStore.setState({
                types: { ...types, [editFieldInutTypeValue]: conf },
              })
            }
            popoverProps={{
              open: true,
              anchorEl: popoverAnchor,
              anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
              onClose: () => this.togglePopover(),
              style: {
                maxWidth: '500px',
              },
            }}
          />
        )}
        <Grid item xs={12}>
          {
            <Fade in={true} timeout={fadeTimeout}>
              <div
                style={{
                  margin: 8,
                  padding: 0,
                  borderStyle: 'solid',
                  borderRadius: 4,
                  borderWidth: 1,
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                }}
              >
                <Accordion
                  style={{
                    padding: 0,
                    minHeight: 54,
                  }}
                  elevation={0}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    style={{ minHeight: 46 }}
                    classes={{
                      content: classes.AccordionSummaryContent,
                      expandIconWrapper: classes.AccordionExpandIcon,
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography color={'secondary'}>{title ?? 'Key-Order'}</Typography>
                    {onSelectItem && (
                      <Typography color={'primary'} style={{ paddingLeft: '20px' }}>
                        click on items to enable or disable them
                      </Typography>
                    )}
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <SortableList
                      lockAxis={'y'}
                      distance={5}
                      items={items}
                      types={types}
                      readOnly={!!readOnly}
                      shouldCancelStart={() => !!readOnly}
                      stagedItems={stagedKeys}
                      onSortEnd={this.onSortEnd}
                      onDelete={this.onDeleteKey as never}
                      onClickItem={this.onToggleKey as never}
                      onClickConfig={this.togglePopover as never}
                      checkedItems={selectedKeys}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            </Fade>
          }
        </Grid>
      </Grid>
    );
  }
}

export const SortableItem = SortableElement(
  ({
    value,
    readOnly,
    sortIndex,
    onDelete,
    onClick,
    onClickConfig,
    checked,
  }: {
    value: string;
    readOnly: boolean;
    sortIndex: number;
    onDelete: (event: MouseEvent, value: string) => void;
    onClickConfig: (anchor: HTMLButtonElement, value: string) => void;
    onClick?: (value: string) => void;
    checked: boolean;
  }) => (
    <ListItem
      style={{
        borderTopStyle: 'inset',
        borderTopColor: 'rgba(0, 0, 0, 0.23)',
        borderTopWidth: 1,
        backgroundColor: 'white',
        userSelect: 'none',
      }}
      onClick={onClick ? () => onClick(value) : undefined}
    >
      <Grid container justifyContent={'space-between'} alignItems={'baseline'}>
        <Grid item xs={8}>
          <Typography color={checked ? 'primary' : 'secondary'}>
            <span>{sortIndex + 1}.&nbsp;</span>
            <span>{value}</span>
          </Typography>
        </Grid>
        {!readOnly && (
          <Grid item xs={4}>
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <IconButton onClick={(e) => onClickConfig(e.currentTarget, value)}>
                <Settings />
              </IconButton>
              <IconButton onClick={(e: MouseEvent) => onDelete(e, value)} size="large">
                <Delete fontSize={'small'} />
              </IconButton>
            </Stack>
          </Grid>
        )}
      </Grid>
    </ListItem>
  ),
);

export const SortableList = SortableContainer(
  ({
    items,
    readOnly,
    stagedItems,
    checkedItems,
    onDelete,
    onClickItem,
    onClickConfig,
  }: {
    items: string[];
    types: FieldInputTypes;
    readOnly: boolean;
    stagedItems: string[];
    checkedItems: string[];
    onDelete: (event: MouseEvent, value: string) => void;
    onClickItem?: (value: string) => void;
    onClickConfig: (anchor: HTMLButtonElement, value: string) => void;
  }) => (
    <List style={{ padding: 4, width: '100%' }}>
      {items.map((value: string, index: number) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          readOnly={readOnly}
          sortIndex={index}
          value={value}
          onDelete={onDelete as never}
          onClick={onClickItem as never}
          checked={checkedItems.includes(value)}
          onClickConfig={onClickConfig as never}
        />
      ))}
      {stagedItems.map((value: string, index: number) => (
        <ListItem
          key={`stagedItem-${index}`}
          style={{
            borderTopStyle: 'inset',
            borderTopColor: 'rgba(0, 0, 0, 0.23)',
            borderTopWidth: 1,
            backgroundColor: 'white',
          }}
        >
          <Add color={'action'} />
          <Typography>
            <span>{value}</span>
          </Typography>
        </ListItem>
      ))}
    </List>
  ),
);

const StyleWrapped = withStyles(styles)(PresetPanelSortingComponent);

export const PresetPanelSorting = StyleWrapped;
