import { Operation, OperationPayloadCompartmentUpdate, OperationType } from '@ekkogmbh/apisdk';
import { Typography } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import {
  OperationGroupDetailsContentPropsWithStores,
  OperationGroupDetailsContentState,
} from './OperationGroupDetailsContent';

const PayloadCompartmentImport = (payload: OperationPayloadCompartmentUpdate): React.ReactNode => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      overflowY: 'hidden',
      overflowX: 'auto',
      flexWrap: 'nowrap',
    }}
  >
    {Object.keys(payload.fields).map((key: string) => (
      <div
        key={key}
        style={{
          display: 'inline-block',
          width: 155,
          whiteSpace: 'normal',
          wordBreak: 'break-all',
        }}
      >
        <div style={{ margin: 8 }}>
          <Typography
            variant={'subtitle2'}
            color={'textSecondary'}
            style={{
              fontWeight: 700,
              wordBreak: 'keep-all',
            }}
          >
            {key}
          </Typography>
        </div>
        <div style={{ margin: 8 }}>
          <span>{payload.fields[key]}</span>
        </div>
      </div>
    ))}
  </div>
);

const OperationIdentifier = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.identifier',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (identifier: Operation['identifier']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{identifier}</div>
    ),
  },
});

const OperationTypeColumn = (): MUIDataTableColumnDef => ({
  label: 'Type',
  name: 'entry.type',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (type: Operation['type']): React.ReactNode => <div>{type}</div>,
  },
});

const OperationPayloadColumn = (): MUIDataTableColumnDef => ({
  label: 'Payload',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Operation): React.ReactNode => {
      switch (value.type) {
        case OperationType.COMPARTMENT_UPDATE:
          return PayloadCompartmentImport(value.payload as OperationPayloadCompartmentUpdate);
        default:
          return <div />;
      }
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  OperationGroupDetailsContentPropsWithStores,
  OperationGroupDetailsContentState,
  null
>> = [OperationIdentifier, OperationTypeColumn, OperationPayloadColumn];

export { PayloadCompartmentImport, OperationIdentifier, OperationTypeColumn, OperationPayloadColumn };
