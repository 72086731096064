import { Label } from '@ekkogmbh/apisdk';
import { Chip, Typography } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { Highlight } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React, { CSSProperties } from 'react';
import Highlighter from 'react-highlight-words';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import {
  LabelManagementContentActionHandlers,
  LabelManagementContentPropsWithStores,
  LabelManagementContentState,
} from './LabelManagementContent';

class LabelDataTableActions extends DataTableActionsComponent<Label> {}

const LabelActions = (
  _: LabelManagementContentState,
  __: LabelManagementContentPropsWithStores,
  actions: LabelManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (label: Label): React.ReactNode => {
      const actionButtons: ActionButton<Label>[] = [
        {
          title: 'Blink',
          onClick: actions.blink,
          icon: Highlight,
        },
      ];

      return <LabelDataTableActions dataset={label} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const LabelBodyRenderHighlighter = (
  propsWithStores: LabelManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (value: string) => {
  const { searchText } = propsWithStores.searchContentStore;
  const unhighlightStyle: CSSProperties = {};
  const highlightStyle: CSSProperties = {
    ...unhighlightStyle,
    backgroundColor: '#BBB',
  };

  return (
    <Highlighter
      searchWords={[searchText.replace(/\\/g, '\\\\')]}
      textToHighlight={value}
      highlightStyle={highlightStyle}
      unhighlightStyle={unhighlightStyle}
    />
  );
};

const LabelId = (
  _: LabelManagementContentState,
  propsWithStores: LabelManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Id',
  name: 'entry.id',
  options: {
    customBodyRender: LabelBodyRenderHighlighter(propsWithStores),
  },
});

const LabelTechnology = (
  _: LabelManagementContentState,
  propsWithStores: LabelManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Technology',
  name: 'entry.technology',
  options: {
    sort: true,
    filter: false,
    customBodyRender: LabelBodyRenderHighlighter(propsWithStores),
  },
});

const renderIdentifier = (value: string, propsWithStores: LabelManagementContentPropsWithStores) => {
  const {
    classes,
    searchContentStore: { searchText },
  } = propsWithStores;

  const unhighlightStyle: CSSProperties = {};
  const highlightStyle: CSSProperties = {
    ...unhighlightStyle,
    backgroundColor: '#BBB',
  };

  return (
    <span style={{ position: 'relative' }}>
      <Typography variant={'overline'} align={'center'} color={'textPrimary'} className={classes.identifier}>
        <Highlighter
          searchWords={[searchText.replace(/\\/g, '\\\\')]}
          textToHighlight={value}
          highlightStyle={highlightStyle}
          unhighlightStyle={unhighlightStyle}
        />
      </Typography>
    </span>
  );
};

const LabelBodyRenderIdentifier = (
  propsWithStores: LabelManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (value: string) => (
  <div
    style={{
      whiteSpace: 'nowrap',
      overflowY: 'hidden',
      overflowX: 'auto',
    }}
  >
    <Chip
      variant={'outlined'}
      label={renderIdentifier(value, propsWithStores)}
      style={{
        position: 'relative',
        margin: 8,
        overflow: 'hidden',
      }}
    />
  </div>
);

const LabelIdentifier = (
  _: LabelManagementContentState,
  propsWithStores: LabelManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.identifierValue',
  options: {
    sort: true,
    filter: false,
    customBodyRender: LabelBodyRenderIdentifier(propsWithStores),
  },
});

const saturation = 400;

const LabelBodyRenderStatus = () =>
  // eslint-disable-next-line react/display-name
  (value: Label['status']) => {
    let backgroundColor: string;

    switch (value) {
      case 'ONLINE':
        backgroundColor = green[saturation];
        break;

      case 'OFFLINE':
      case 'TIMEOUT':
      case 'UNASSIGNED':
        backgroundColor = red[saturation];
        break;

      default:
        backgroundColor = orange[saturation];
        break;
    }

    return (
      <div>
        <Chip
          label={value}
          style={{
            backgroundColor,
            color: 'white',
          }}
        />
      </div>
    );
  };

const LabelStatus = (): MUIDataTableColumnDef => ({
  label: 'Status',
  name: 'entry.status',
  options: {
    sort: true,
    filter: false,
    customBodyRender: LabelBodyRenderStatus(),
  },
});

const LabelBodyRenderBatteryStatus = () =>
  // eslint-disable-next-line react/display-name
  (value: Label['batteryStatus']) => {
    let backgroundColor: string;

    switch (value) {
      case 'GOOD':
        backgroundColor = green[saturation];
        break;

      case 'BAD':
        backgroundColor = red[saturation];
        break;

      default:
        backgroundColor = orange[saturation];
        break;
    }

    return (
      <div>
        <Chip
          label={value}
          style={{
            backgroundColor,
            color: 'white',
          }}
        />
      </div>
    );
  };

const LabelBatteryStatus = (): MUIDataTableColumnDef => ({
  label: 'Battery-Status',
  name: 'entry.batteryStatus',
  options: {
    sort: true,
    filter: false,
    customBodyRender: LabelBodyRenderBatteryStatus(),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  LabelManagementContentPropsWithStores,
  LabelManagementContentState,
  LabelManagementContentActionHandlers,
  null
>> = [LabelId, LabelIdentifier, LabelTechnology, LabelStatus, LabelBatteryStatus, LabelActions];

export { LabelId, LabelIdentifier, LabelTechnology, LabelStatus, LabelBatteryStatus };
