import React from 'react';
import { TitleProps } from 'src/Routes';
import { BlueprintStore } from './Stores/BlueprintStore';
import { Provider } from 'mobx-react';
import { Page } from 'src/Common/Components/Page';
import { BlueprintManagementContent } from './Components/BlueprintManagementContent';

const blueprintStore = new BlueprintStore();

const stores = { blueprintStore };

export const BlueprintManagement = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <BlueprintManagementContent />
    </Page>
  </Provider>
);
