import { Template } from '@ekkogmbh/apisdk';
import {
  Theme,
  IconButton,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Cancel } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { spacing } from '../../Common/Helper/ThemeHelper';
import { LabelLinkStore } from '../Stores/LabelLinkStore';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 350,
  },
  button: {
    margin: spacing(theme),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

type LabelLinkTemplateMapperPropsWithStores = LabelLinkTemplateMapperProps & LabelLinkTemplateMapperStores;

interface LabelLinkTemplateMapperProps extends WithStyles<typeof styles> {
  templateGroupName: string;
  availableTemplates: Template[];
  allowAddingPages: boolean;
}

interface LabelLinkTemplateMapperStores {
  labelLinkStore: LabelLinkStore;
}

interface LabelLinkTemplateMapperState {}

@inject('labelLinkStore')
@observer
class LabelLinkTemplateMapperComponent extends React.Component<
  LabelLinkTemplateMapperProps,
  LabelLinkTemplateMapperState
> {
  public state: LabelLinkTemplateMapperState = {};

  get stores(): LabelLinkTemplateMapperStores {
    return this.props as LabelLinkTemplateMapperPropsWithStores;
  }

  public addPage = (page?: string): void => {
    const { labelLinkStore } = this.stores;
    const { templateGroupName, availableTemplates } = this.props;

    const pages: string[] = labelLinkStore.getPageIndices(templateGroupName);
    const defaultTemplateName = availableTemplates[0].name;

    if (page !== undefined) {
      if (pages.length < 1 || pages.indexOf(page) === -1) {
        labelLinkStore.setPage(templateGroupName, page, defaultTemplateName);
      }
    } else {
      if (pages.length < 1) {
        labelLinkStore.setPage(templateGroupName, '1', defaultTemplateName);
      } else {
        // add highest page number +1
        const pagesAsNumber: number[] = pages.map((page: string) => parseInt(page));
        const highestPageNumber: number = Math.max(...pagesAsNumber);

        labelLinkStore.setPage(templateGroupName, (highestPageNumber + 1).toString(), defaultTemplateName);
      }
    }
  };

  public onChangePage = (fromPage: string, toPage: string) => {
    const { templateGroupName } = this.props;
    const { labelLinkStore } = this.stores;
    const { templateGroups } = labelLinkStore;

    if (parseInt(toPage) > 0) {
      const pages = labelLinkStore.getPageIndices(templateGroupName);
      const templateName = templateGroups[templateGroupName][fromPage];

      const isTargetPageOccupied = pages.indexOf(toPage) !== -1;
      if (isTargetPageOccupied) {
        // swap target template name to source position
        const templateNameOfTarget = templateGroups[templateGroupName][toPage];
        labelLinkStore.setPage(templateGroupName, fromPage, templateNameOfTarget);
      } else {
        labelLinkStore.deletePage(templateGroupName, fromPage);
      }

      labelLinkStore.setPage(templateGroupName, toPage, templateName);
    }
  };

  public renderPage = (page: string, templateOptions: React.JSX.Element[]): React.JSX.Element => {
    const { templateGroupName } = this.props;
    const { labelLinkStore } = this.stores;
    const { templateGroups } = labelLinkStore;

    return (
      <ListItem key={`label-link-template-mapper-${page}-${templateGroupName}`}>
        <ListItemText>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2}>
              <TextField
                variant="standard"
                type={'number'}
                value={page}
                onChange={(e) => {
                  this.onChangePage(page, e.target.value);
                }}
                style={{ height: '100%' }}
              />
            </Grid>
            <Grid item xs={9}>
              <StyledSelectField
                native
                variant="standard"
                value={templateGroups[templateGroupName][page]}
                onChange={(e) => {
                  labelLinkStore.setPage(templateGroupName, page, e.target.value as string);
                }}
              >
                {templateOptions}
              </StyledSelectField>
            </Grid>
          </Grid>
        </ListItemText>
        <ListItemSecondaryAction>
          <Tooltip title={`Remove Page ${page}`} placement={'right'}>
            <IconButton onClick={() => labelLinkStore.deletePage(templateGroupName, page)} size="large">
              <Cancel />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  public render(): React.JSX.Element {
    const { templateGroupName, classes } = this.props;
    const { allowAddingPages, availableTemplates } = this.props;
    const { labelLinkStore } = this.stores;

    const templateOptions = availableTemplates.map((template: Template) => (
      <option key={template.id} value={template.name}>
        {template.name}
      </option>
    ));
    const pages = labelLinkStore.getPageIndices(templateGroupName);

    return (
      <Grid container spacing={0} alignItems={'stretch'}>
        {pages.length > 0 && (
          <Grid item xs={12}>
            <List dense className={classes.root}>
              <ListItem>
                <Grid container spacing={0} alignItems={'stretch'}>
                  <Grid item xs={2}>
                    <Typography>Page</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>Template</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {pages.map((page: string) => this.renderPage(page, templateOptions))}
            </List>
          </Grid>
        )}
        {allowAddingPages && (
          <Grid item xs={12}>
            <Button
              variant={'outlined'}
              color={'secondary'}
              className={classes.button}
              style={{ width: '97%' }}
              onClick={() => {
                this.addPage();
              }}
            >
              add page
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

export const LabelLinkTemplateMapper = withStyles(styles)(LabelLinkTemplateMapperComponent);
