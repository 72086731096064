import { Accordion, AccordionDetails, Paper, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import React, { CSSProperties } from 'react';
import { Component } from 'react';
import { spacing } from '../Helper/ThemeHelper';
import { ApiStore, Permissions } from '../Stores/ApiStore';
import { NavigationStore } from '../Stores/NavigationStore';
import { ContentActionDefinition, ContentActions } from './ContentActions';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden' as const,
    boxShadow: theme.shadows[5],
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen * 4,
    }),
  },
  rootWhenNotExpanded: {
    margin: 0,
    minHeight: 0,
    height: 0,
  },
  rootWhenExpanded: {
    marginBottom: 48,
  },
  expansion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  expansionExpanded: {
    minHeight: spacing(theme) * 7,
    marginTop: '16px !important',
    margin: '16px !important',
  },
});

interface ContentPanelCloseHandler {
  closeHandler: () => void;
}

export interface ContentPanelDefinition<P extends ContentPanelCloseHandler = { closeHandler: () => void }> {
  name: string;
  icon: React.ComponentType<SvgIconProps>;
  isHidden: boolean;
  // @TODO no idea yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  panelComponent: React.ComponentType<any>;
  panelProps: Partial<P>;
  permission: Permissions;
  expandHandler?: (expandCallback: () => void) => void;
  toggleOffCallback?: () => void;
  expansionStyle?: CSSProperties;
}

export const NONE_EXPANDED = 'none';

type ExpandedPanelState = string | typeof NONE_EXPANDED;

const stores = ['api', 'navigationStore'];

export interface ContentPanelsStores {
  api: ApiStore;
  navigationStore: NavigationStore;
}

interface ContentPanelsState {
  expandedPanel: ExpandedPanelState;
}

interface ContentPanelsProps extends WithStyles<typeof styles> {
  panels: ContentPanelDefinition[];
  onDeleteAction?: () => void;
  closePanelsHandler?: (closePanelsCallback: () => void) => void;
}

@inject(...stores)
class ContentPanelsComponent extends Component<ContentPanelsProps, ContentPanelsState> {
  public state: ContentPanelsState = {
    expandedPanel: NONE_EXPANDED,
  };

  get stores(): ContentPanelsStores {
    return this.props as ContentPanelsProps & ContentPanelsStores;
  }

  public renderPanels = () => {
    const { api, navigationStore } = this.stores;
    const { classes, panels, onDeleteAction, closePanelsHandler } = this.props;
    const { expandedPanel } = this.state;

    if (closePanelsHandler !== undefined) {
      closePanelsHandler(() => {
        this.setState({ expandedPanel: NONE_EXPANDED });
      });
    }

    const contentActions: ContentActionDefinition[] = panels.map(
      ({ name, icon, toggleOffCallback, permission, isHidden }: ContentPanelDefinition) => {
        const toggleHandler = () => {
          if (this.state.expandedPanel === name) {
            if (toggleOffCallback !== undefined) {
              toggleOffCallback();
            }
            this.setState({ expandedPanel: NONE_EXPANDED });
          } else {
            this.setState({ expandedPanel: name });
          }
          navigationStore.scrollTop();
        };

        const closeHandler = () => {
          this.setState({ expandedPanel: NONE_EXPANDED });
          navigationStore.scrollTop();
        };

        const hasPermission = api.userHasPermissionOnAnyNode(permission);

        return {
          name,
          icon,
          hasPermission,
          toggleHandler,
          closeHandler,
          isHidden,
        };
      },
    );

    const contentPanels = panels.map((contentPanelDefinition: ContentPanelDefinition, index: number) => {
      const {
        name,
        panelComponent: PanelComponent,
        panelProps,
        expandHandler,
        expansionStyle,
      } = contentPanelDefinition;

      if (expandHandler !== undefined) {
        expandHandler(() => {
          this.setState({ expandedPanel: name });
        });
      }

      const expansionDetailsStyle = { padding: 0, ...expansionStyle };

      return (
        <Accordion
          key={index}
          expanded={expandedPanel === name}
          className={classNames(classes.expansion, expandedPanel === name && classes.expansionExpanded)}
        >
          <AccordionDetails style={expansionDetailsStyle}>
            {expandedPanel === name && (
              <PanelComponent {...panelProps} closeHandler={contentActions[index].closeHandler} />
            )}
          </AccordionDetails>
        </Accordion>
      );
    });

    return {
      actions: <ContentActions contentActions={contentActions} onDelete={onDeleteAction} />,
      panels: contentPanels,
    };
  };

  public render() {
    const { classes } = this.props;
    const { expandedPanel } = this.state;

    const { actions, panels } = this.renderPanels();

    return (
      <React.Fragment>
        {actions}

        <Paper
          className={classNames(
            classes.root,
            expandedPanel !== NONE_EXPANDED && classes.rootWhenExpanded,
            expandedPanel === NONE_EXPANDED && classes.rootWhenNotExpanded,
          )}
        >
          {panels}
        </Paper>
      </React.Fragment>
    );
  }
}

const StyleWrapped = withStyles(styles)(ContentPanelsComponent);

export const ContentPanels = StyleWrapped;
