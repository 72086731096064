import { StartupStrategy, StartupStrategyType, Zone } from '@ekkogmbh/apisdk';
import { Fade, Grid, ListItemText, MenuItem } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { PickerProfileStore } from '../Stores/PickerProfileStore';
import { ZonePicker } from 'src/ZoneManagement/ZonePicker';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { StyledCheckbox } from 'src/Common/Components/Forms/StyledCheckbox';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { NotificationStrategyForm } from 'src/ZoneManagement/Components/NotificationStrategyForm';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';

const styles = FormStyles;

interface PickerProfileConfigurationFormState {}

interface PickerProfileConfigurationFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface PickerProfileConfigurationFormStores {
  api: ApiStore;
  pickerProfileStore: PickerProfileStore;
  notificationStrategyStore: NotificationStrategyStore;
}

type PickerProfileConfigurationFormPropsWithStores = PickerProfileConfigurationFormProps &
  PickerProfileConfigurationFormStores;

@inject('api', 'pickerProfileStore', 'notificationStrategyStore')
@observer
class PickerProfileConfigurationFormComponent extends Component<
  PickerProfileConfigurationFormProps,
  PickerProfileConfigurationFormState
> {
  private fetchPromises: CancelableFetchPromises = {};

  public state: PickerProfileConfigurationFormState = {};

  get stores(): PickerProfileConfigurationFormPropsWithStores {
    return this.props as PickerProfileConfigurationFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { pickerProfileStore, notificationStrategyStore } = this.stores;
    const { startupStrategy } = pickerProfileStore.state;

    // initialize state for pre–selected SelectFields
    if (startupStrategy === undefined) {
      pickerProfileStore.setState({ startupStrategy: { name: StartupStrategyType.SELECT, config: {} } });
    }

    // hmmm
    if (!notificationStrategyStore.changed) {
      const { editablePickerProfile } = pickerProfileStore;
      const initialNotificationStrategy =
        editablePickerProfile?.notificationStrategy ?? editablePickerProfile?.zone.notificationStrategy;
      notificationStrategyStore.resetStore(initialNotificationStrategy);
    }
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private onStrategySelect = (name: StartupStrategy['name']) => {
    const { pickerProfileStore } = this.stores;

    pickerProfileStore.setState({ startupStrategy: { name, config: {} } });
  };

  private renderStrategy(): React.JSX.Element {
    const { pickerProfileStore } = this.stores;
    const { startupStrategy } = pickerProfileStore.state;

    switch (startupStrategy?.name as StartupStrategyType | undefined) {
      case StartupStrategyType.SCAN:
        return <></>;
      case StartupStrategyType.NEXT:
        const config = startupStrategy!.config;
        const amount: number = config.amount ?? 1;
        const onceOnly: boolean = config.onceOnly !== undefined ? config.onceOnly : true;
        const sortBy: string = config.sortBy !== undefined ? config.sortBy : 'created_at';
        const sortDirection: string = config.sortDirection !== undefined ? config.sortDirection : 'descending';

        // FIXME: possibly want this as API enums
        const sortByOptions = ['created_at', 'name'].map((name: string) => (
          <MenuItem key={`sort-by-${name}`} value={name}>
            <ListItemText primary={name} />
          </MenuItem>
        ));
        const sortDirectionOptions = ['ascending', 'descending'].map((direction: string) => (
          <MenuItem key={`sort-dir-${direction}`} value={direction}>
            <ListItemText primary={direction} />
          </MenuItem>
        ));

        return (
          <Grid container item xs={12} spacing={1} alignItems={'flex-start'} alignContent={'stretch'}>
            <Grid item xs={6}>
              <StyledSelectField
                label={'sort-by'}
                value={sortBy}
                onChange={(e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        sortBy: e.target.value as string,
                      },
                    },
                  });
                }}
              >
                {sortByOptions}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledSelectField
                label={'sort-direction'}
                value={sortDirection}
                onChange={(e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        sortDirection: e.target.value as string,
                      },
                    },
                  });
                }}
              >
                {sortDirectionOptions}
              </StyledSelectField>
            </Grid>
            <Grid item xs={6}>
              <StyledCheckbox
                label={'single use blueprints only'}
                value={onceOnly}
                onChange={(_e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        onceOnly: !onceOnly,
                      },
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                label={'amount'}
                type={'number'}
                value={amount}
                onChange={(e) => {
                  pickerProfileStore.setState({
                    startupStrategy: {
                      ...startupStrategy!,
                      config: {
                        ...config,
                        amount: Number(e.target.value) > 0 ? Number(e.target.value) : 1,
                      },
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        );
      case StartupStrategyType.SELECT:
        return <></>;
      case undefined:
        return <></>;
    }
  }

  public render(): React.JSX.Element {
    const { pickerProfileStore } = this.stores;
    const { zone, startupStrategy, name } = pickerProfileStore.state;

    const isDisabled = pickerProfileStore.editablePickerProfile !== undefined;

    return (
      <Fade in={true} timeout={1000}>
        <Grid container alignItems={'flex-start'} spacing={2}>
          <Grid container item xs={6} spacing={1} alignContent={'stretch'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Picker Profile'} />
              <StyledTextField
                disabled={isDisabled}
                type="text"
                label="Name"
                value={name}
                onChange={(e) => pickerProfileStore.setState({ name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <ZonePicker
                disabled={isDisabled}
                selected={zone}
                onChange={(zone: Zone | undefined) => pickerProfileStore.setState({ zone })}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSelectField
                label="Startup Strategy"
                value={startupStrategy?.name ?? ''}
                onChange={(e) => this.onStrategySelect(e.target.value as StartupStrategy['name'])}
              >
                {Object.values(StartupStrategyType).map((name: StartupStrategyType, index: number) => (
                  <MenuItem key={index} value={name}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </StyledSelectField>
            </Grid>
            <Grid item xs={12}>
              {this.renderStrategy()}
            </Grid>
          </Grid>
          <Grid container item xs={6} spacing={1} alignItems={'flex-start'} alignContent={'stretch'}>
            <Grid item xs={12}>
              <StyledFormHeader label={'Notification Strategy (Optional)'} />
              <NotificationStrategyForm />
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickerProfileConfigurationFormComponent);

export const PickerProfileConfigurationForm = StyleWrapped;
