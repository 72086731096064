import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../../Common/Helper/ThemeHelper';
import { ModulesBaseStyles } from '../../Common/Styles/ModulesBaseStyles';

export const UserManagementStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  ...ModulesBaseStyles(theme),
  // module specific css here
  listItem: {
    paddingLeft: spacing(theme) / 2,
  },
});
