import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from 'src/Common/Components/Page';
import { TitleProps } from 'src/Routes';
import { FinderManagementContent } from './Components/FinderManagementContent';
import { FinderStore } from './Stores/FinderStore';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';

const finderStore = new FinderStore();
const notificationStrategyStore = new NotificationStrategyStore();

const stores = { finderStore, notificationStrategyStore };

export const FinderManagement = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <FinderManagementContent />
    </Page>
  </Provider>
);
