import { Blueprint, BlueprintPayload } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export type BlueprintState = BlueprintPayload;

const defaultBlueprintState = (): BlueprintState => ({
  name: '',
  zone: {
    name: '',
    coordinate: '',
  },
  steps: [],
  once: false,
});

export class BlueprintStore {
  @observable
  public editableBlueprint?: Blueprint;

  @observable
  public isSaveable: boolean = false;

  @observable
  public state: BlueprintState = {
    ...defaultBlueprintState(),
  };

  @action
  public setState = (newState: Partial<BlueprintState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.isSaveable = this.state.name !== '' && this.state.zone.name !== '' && this.state.zone.coordinate !== '';
  };

  @action setEditableBlueprint = (blueprint: Blueprint): void => {
    this.editableBlueprint = blueprint;
    this.state = this.convertBlueprintToBlueprintState(blueprint);
    this.isSaveable = true;
  };

  @action
  public resetStore = (): void => {
    this.editableBlueprint = undefined;
    this.setState({
      ...defaultBlueprintState(),
    });
  };

  @action
  public reset = (): void => {
    if (this.editableBlueprint !== undefined) {
      this.setState(this.convertBlueprintToBlueprintState(this.editableBlueprint));
    } else {
      this.resetStore();
    }
  };

  public getSavableBlueprint(): BlueprintPayload {
    return { ...this.state };
  }

  private convertBlueprintToBlueprintState(blueprint: Blueprint): BlueprintState {
    return {
      name: blueprint.name,
      zone: {
        name: blueprint.zone.name,
        coordinate: blueprint.zone.coordinate,
      },
      steps: blueprint.steps,
      once: blueprint.once,
    };
  }
}
