import {
  ApiSdkEvents,
  ApplicableRenderersPayload,
  ApplicableRenderersResult,
  AuthMechanism,
  Blueprint,
  BlueprintPayload,
  CompartmentSavePayload,
  CompartmentSelector,
  CompartmentView,
  Credentials,
  DevicePool,
  Endpoint,
  EventRule,
  EventRuleIdentifier,
  EventRuleSavePayload,
  Filtering,
  Finder,
  HttpApiSdk,
  HttpMethod,
  ImportCompartmentsDiff,
  Label,
  LinkBulkSavePayload,
  LinkProfile,
  LinkSavePayload,
  LinkView,
  Node,
  Operation,
  OperationGroup,
  OperationGroupCreate,
  OperationGroupId,
  Pagination,
  PaginationResponse,
  Permission,
  PickerProfile,
  PickingArea,
  PickingAreaCreatePayload,
  PickingAreaUpdatePayload,
  Preset,
  PresetSavePayload,
  ReceivingArea,
  ReceivingAreaIdentifier,
  RendererPayload,
  RendererResult,
  ReplenishmentPlan,
  ReplenishmentPlanIdentifier,
  Role,
  SaveFinderPayload,
  SavePickerProfilePayload,
  SaveReceivingAreaPayload,
  SaveSupplyProfilePayload,
  SortedSide,
  SortedSideCreatePayload,
  SortedSideUpdatePayload,
  SpatialEntity,
  SupplyProfile,
  SupplyProfileIdentifier,
  SystemStatus,
  TaskCollectionHandler,
  Technology,
  Template,
  TemplateSavePayload,
  TemplateWithData,
  UpdaterProfile,
  User,
  UserCreatePayload,
  UserPermission,
  UserRoleNodeMapping,
  UserRoleNodeMappingPayload,
  UserSettings,
  UserSettingsPayload,
  UserUpdatePayload,
  Zone,
  ZoneIdentifier,
  ZonePayload,
} from '@ekkogmbh/apisdk';
import EventEmitter from 'eventemitter3';
import { ListenerFn } from 'eventemitter3';
import { action, observable } from 'mobx';
import { RouteShortDefinition } from '../../Routes';
import { NavigationSortedMenu } from './NavigationStore';

export enum Permissions {
  AREAS_READ = 'area_management.read',
  AREAS_WRITE = 'area_management.write',
  MAPPINGS_READ = 'mappings.read',
  MAPPINGS_WRITE = 'mappings.write',
  MAPPINGS_WRITE_RESTRICTED = 'mappings.write.restricted',
  OPERATION_GROUPS_READ = 'operation_groups.read',
  OPERATION_GROUPS_WRITE = 'operation_groups.write',
  PERMISSIONS_READ = 'permissions.read',
  PICKING_AREAS_READ = 'picking_areas.read',
  PICKING_AREAS_WRITE = 'picking_areas.write',
  PRESETS_WRITE = 'presets.write',
  ROLES_READ = 'roles.read',
  ROLES_WRITE = 'roles.write',
  SHELFLABELING_READ = 'shelf_labeling.read',
  SHELFLABELING_WRITE = 'shelf_labeling.write',
  SYSTEMSTATUS_READ = 'system_status.read',
  TEMPLATES_READ = 'templates.read',
  TEMPLATES_WRITE = 'templates.write',
  USERS_READ = 'users.read',
  USERS_WRITE = 'users.write',
  LINK_PROFILES_READ = 'link_profile.read',
  LINK_PROFILES_WRITE = 'link_profile.write',
  COMPARTMENT_SELECTORS_READ = 'compartment_selector.read',
  COMPARTMENT_SELECTORS_WRITE = 'compartment_selector.write',
  FINDERS_READ = 'finder.read',
  FINDERS_WRITE = 'finder.write',
  DEVICE_POOLS_READ = 'device_pool.read',
  DEVICE_POOLS_WRITE = 'device_pool.write',
  ZONES_READ = 'picking_zones.read',
  ZONES_WRITE = 'picking_zones.write',
  UPDATER_PROFILE_READ = 'updater_profile.read',
  UPDATER_PROFILE_WRITE = 'updater_profile.write',
  BLUEPRINTS_READ = 'blueprints.read',
  BLUEPRINTS_WRITE = 'blueprints.write',
  PICKER_PROFILES_READ = 'picker_profiles.read',
  PICKER_PROFILES_WRITE = 'picker_profiles.write',
  EKANBAN_READ = 'ekanban.read',
  EKANBAN_WRITE = 'ekanban.write',
  EVENT_RULES_READ = 'event_rules.read',
  EVENT_RULES_WRITE = 'event_rules.write',
}

export class ApiStore {
  @observable
  public isAuthenticated: boolean = false;
  @observable
  public isRestoring: boolean = true;
  @observable
  public anyNodePermissions: string[] = [];
  private storageKey: string = 'ESLMANAGER_AUTH';

  constructor(
    private api: HttpApiSdk & EventEmitter,
    private authMechanism: AuthMechanism,
    private jwt: boolean = false,
  ) {
    if (this.jwt) {
      this.restoreJWTAuthentication();
    } else {
      this.authenticate();
    }

    this.api.on(ApiSdkEvents.AUTH_EXPIRED, () => {
      this.authMechanism.authentication.isAuthenticated = false;
      this.isAuthenticated = false;
    });

    this.api.on(ApiSdkEvents.AUTH_REFRESH_SUCCESS, () => {
      localStorage.setItem(this.storageKey, JSON.stringify(this.authMechanism.authentication));
    });
  }

  @action
  public async checkAuthentication(): Promise<void> {
    await this.api.isAuthenticated();

    await this.setAnyNodePermissions();
  }

  public getAuthenticatedUser(): User | undefined {
    return this.authMechanism.authentication.user;
  }

  public getUserPermissions(): UserPermission[] {
    const user = this.authMechanism.authentication.user;

    if (!user || !user.permissions) {
      return [];
    }

    return user.permissions;
  }

  public userHasPermissionOnAnyNode(permission: string): boolean {
    return this.anyNodePermissions.indexOf(permission) !== -1;
  }

  public getUserReadPermissionRoutePathsFromMenuLink(routes: RouteShortDefinition[]): string[] {
    return routes
      .filter(
        (route: RouteShortDefinition) =>
          route.menu && route.permission && this.userHasPermissionOnAnyNode(route.permission),
      )
      .map((route: RouteShortDefinition) => route.menu!.link);
  }

  public getUserReadPermissionFallbackRoutePath(
    routes: RouteShortDefinition[],
    menuSorting?: NavigationSortedMenu[],
  ): string | null {
    const menus = routes.filter(
      (route: RouteShortDefinition) =>
        route.menu && route.permission && this.userHasPermissionOnAnyNode(route.permission),
    );
    const sortedMenuEntries: RouteShortDefinition[] = [];
    // @TODO optimize this - only the first match is necessary
    if (menuSorting) {
      menuSorting.forEach((menu) => {
        menu.entries.forEach((entry) => {
          const entryIndex = menus.findIndex((menuEntry) => menuEntry.menu?.link === entry);
          if (entryIndex >= 0) {
            sortedMenuEntries.push(menus[entryIndex]);
          }
        });
      });
    }
    const fallbackRoute = sortedMenuEntries.length > 0 ? sortedMenuEntries[0].menu?.link : menus[0].menu?.link;
    return fallbackRoute ? fallbackRoute : null;
  }

  public async setAnyNodePermissions(): Promise<void> {
    const permissions = this.getUserPermissions();

    this.anyNodePermissions = permissions.reduce((acc: string[], curr: UserPermission) => {
      if (acc.indexOf(curr.permissionName) === -1) {
        acc.push(curr.permissionName);
      }

      return acc;
    }, []) as string[];
  }

  public getUserPermissionsByPermissionName(permission: Permissions): UserPermission[] {
    if (!this.userHasPermissionOnAnyNode(permission)) {
      return [];
    }

    const userPermissions = this.getUserPermissions();

    const filterByName = (userPermission: UserPermission) => userPermission.permissionName === permission;

    return userPermissions.filter(filterByName);
  }

  public userHasPermissionForNode(permission: Permissions, nodeValues: string[]): boolean {
    if (!this.userHasPermissionOnAnyNode(permission)) {
      return false;
    }

    const userPermissions = this.getUserPermissionsByPermissionName(permission);

    return (
      userPermissions.filter((userPermission: UserPermission) => {
        const partsAsString = userPermission.nodeDefinition.nodeValuesDefinition.values.join('-');
        const re = new RegExp('^' + partsAsString);

        return nodeValues.join('-').match(re) !== null;
      }).length > 0
    );
  }

  @action
  public restoreJWTAuthentication = async (): Promise<void> => {
    const storedAuthentication = localStorage.getItem(this.storageKey);

    if (storedAuthentication) {
      await this.setRestoring(true);

      this.authMechanism.authentication = JSON.parse(storedAuthentication);

      const authenticated = await this.api.isAuthenticated();

      await this.setAnyNodePermissions();

      this.isAuthenticated = authenticated;
    }

    await this.setRestoring(false);
  };

  @action
  public authCallback = async (): Promise<void> => {
    const authenticated = await this.api.isAuthenticated();

    if (this.jwt) {
      localStorage.setItem(this.storageKey, JSON.stringify(this.authMechanism.authentication));
    }

    await this.setAnyNodePermissions();

    this.isAuthenticated = authenticated;

    await this.setRestoring(false);
  };

  @action
  public async authenticate(credentials?: Credentials): Promise<void> {
    await this.api.authenticate(credentials, this.authCallback);
  }

  @action
  public async logout(): Promise<void> {
    await this.authMechanism.invalidateAuthentication();

    if (this.jwt) {
      localStorage.removeItem(this.storageKey);
    }

    this.isAuthenticated = false;
    await this.setRestoring(false);
  }

  @action
  public async setRestoring(bool: boolean): Promise<void> {
    this.isRestoring = bool;
  }

  // --- Delegates

  public async updateCurrentUserPassword(password: string): Promise<void> {
    return await this.api.updateCurrentUsersPassword(password);
  }

  public async fetchCurrentUser(): Promise<User> {
    return await this.api.getCurrentUser();
  }

  public async getUser(id: number): Promise<User> {
    return await this.api.getUser(id);
  }

  public async updateUserSettings(settings: UserSettingsPayload): Promise<UserSettings> {
    const response = await this.api.updateUserSettings(settings);
    const user = this.authMechanism.authentication.user;
    if (user != undefined) {
      user.settings = response;
      this.authMechanism.authentication.user = user;
    }
    return response;
  }

  public async importCompartments(file: File, taskCollectionHandler?: TaskCollectionHandler): Promise<void> {
    return await this.api.importCompartments(file, taskCollectionHandler);
  }

  public async importLinks(file: File, taskCollectionHandler?: TaskCollectionHandler): Promise<void> {
    return await this.api.importLinks(file, taskCollectionHandler);
  }

  public async exportCompartments(filtering: Filtering, contentType?: string): Promise<Blob> {
    return await this.api.exportCompartments(filtering, contentType);
  }

  public async exportLinks(filtering: Filtering, contentType?: string): Promise<Blob> {
    return await this.api.exportLinks(filtering, contentType);
  }

  public async getCompartments(pagination: Pagination): Promise<PaginationResponse<CompartmentView>> {
    return await this.api.getCompartments(pagination);
  }

  public async getLabels(pagination: Pagination): Promise<PaginationResponse<Label>> {
    return await this.api.getLabels(pagination);
  }

  public async getSystemStatus(): Promise<SystemStatus> {
    return await this.api.getSystemStatus();
  }

  public async saveCompartment(
    item: CompartmentSavePayload,
    taskCollectionHandler?: TaskCollectionHandler,
  ): Promise<CompartmentView> {
    return await this.api.saveCompartment(item, taskCollectionHandler);
  }

  public async saveLink(link: LinkSavePayload, taskCollectionHandler?: TaskCollectionHandler): Promise<LinkView> {
    return await this.api.saveLink(link, taskCollectionHandler);
  }

  public async saveLinks(
    links: LinkBulkSavePayload[],
    taskCollectionHandler?: TaskCollectionHandler,
  ): Promise<LinkView[]> {
    return await this.api.bulkSaveLink(links, taskCollectionHandler);
  }

  public async getNode(id: number): Promise<Node> {
    return await this.api.getNode(id);
  }

  public async getNodes(): Promise<Node[]> {
    return await this.api.getNodes();
  }

  public async getAccessibleCoordinates(query?: string, trailingDelimiter?: boolean | 'both'): Promise<string[]> {
    return await this.api.getAccessibleCoordinates(query, trailingDelimiter);
  }

  public async getNodeChildren(id: number): Promise<Node[]> {
    return await this.api.getNodeChildren(id);
  }

  public async addNodeChild(value: string, parentNode: Node): Promise<Node> {
    return await this.api.addNodeChild(value, parentNode);
  }

  public async annotateNode(
    node: Node,
    annotations: { system?: Record<string, string>; custom?: Record<string, string> },
  ): Promise<Node> {
    return await this.api.annotateNode(node, annotations);
  }

  public async getRole(id: number): Promise<Role> {
    return await this.api.getRole(id);
  }

  public async getRoles(mappable?: boolean): Promise<Role[]> {
    return await this.api.getRoles(mappable);
  }

  public async getCompartmentFields(): Promise<string[]> {
    return await this.api.getCompartmentFields();
  }

  public async importCompartmentsDiff(csv: File): Promise<ImportCompartmentsDiff> {
    return this.api.importCompartmentsDiff(csv);
  }

  public async addRole(role: Role): Promise<Role> {
    return await this.api.addRole(role);
  }

  public async addUser(user: UserCreatePayload, password?: string): Promise<User> {
    return await this.api.addUser(user, password);
  }

  public async updateRole(role: Role): Promise<Role> {
    return await this.api.updateRole(role);
  }

  public async updateUser(user: UserUpdatePayload, password?: string): Promise<User> {
    return await this.api.updateUser(user, password);
  }

  public async deleteRole(role: Role): Promise<void> {
    return await this.api.deleteRole(role);
  }

  public async deleteUser(user: User): Promise<void> {
    return await this.api.deleteUser(user);
  }

  public async deleteNode(node: Node): Promise<void> {
    return await this.api.deleteNode(node);
  }

  public async deletePreset(preset: Preset): Promise<void> {
    return await this.api.deletePreset(preset.name);
  }

  public async deleteTemplate(template: Template): Promise<void> {
    return await this.api.deleteTemplate(template);
  }

  public async getPermissions(): Promise<Permission[]> {
    return await this.api.getPermissions();
  }

  public async getUsers(pagination: Pagination): Promise<PaginationResponse<User>> {
    return await this.api.getUsers(pagination);
  }

  public async getUserMappings(user: User): Promise<UserRoleNodeMapping[]> {
    return await this.api.getUserMappings(user);
  }

  public async getSortedSides(pagination: Pagination): Promise<PaginationResponse<SortedSide>> {
    return await this.api.getSortedSides(pagination);
  }

  public async addSortedSide(sortedSide: SortedSideCreatePayload): Promise<SortedSide> {
    return await this.api.addSortedSide(sortedSide);
  }

  public async addPickingArea(pickingArea: PickingAreaCreatePayload): Promise<PickingArea> {
    return await this.api.addPickingArea(pickingArea);
  }

  public async updatePickingArea(pickingArea: PickingAreaUpdatePayload): Promise<PickingArea> {
    return await this.api.updatePickingArea(pickingArea);
  }

  public async getSpatialEntitiesByNode(
    nodeValue: string,
    key: string,
    templates: number[],
    sortingStrategy?: string,
  ): Promise<SpatialEntity[]> {
    return await this.api.getSpatialEntitiesByNode(nodeValue, key, templates, sortingStrategy);
  }

  public async getSpatialEntitiesBySortedSide(sortedSide: Pick<SortedSide, 'id'>): Promise<SpatialEntity[]> {
    return await this.api.getSpatialEntitiesBySortedSide(sortedSide as SortedSide);
  }

  public async updateSortedSide(sortedSide: SortedSideUpdatePayload): Promise<SortedSide> {
    return await this.api.updateSortedSide(sortedSide);
  }

  public async deleteSortedSide(sortedSide: SortedSide): Promise<void> {
    return await this.api.deleteSortedSide(sortedSide);
  }

  public async deletePickingArea(pickingArea: PickingArea): Promise<void> {
    return await this.api.deletePickingArea(pickingArea);
  }

  public async addUserRoleNodeMapping(
    user: User,
    userRoleNodeMappingPayload: UserRoleNodeMappingPayload,
  ): Promise<UserRoleNodeMapping> {
    return await this.api.addUserRoleNodeMapping(user, userRoleNodeMappingPayload);
  }

  public async updateUserRoleNodeMapping(
    userRoleNodeMapping: Required<Pick<UserRoleNodeMapping, 'user' | 'id' | 'validUntil'>>,
  ): Promise<UserRoleNodeMapping> {
    return await this.api.updateUserRoleNodeMapping(userRoleNodeMapping);
  }

  public async deleteUserRoleNodeMapping(
    userRoleNodeMapping: Required<Pick<UserRoleNodeMapping, 'user' | 'id'>>,
  ): Promise<void> {
    return await this.api.deleteUserRoleNodeMapping(userRoleNodeMapping);
  }

  public async deleteLink(link: LinkView, taskCollectionHandler?: TaskCollectionHandler): Promise<void> {
    return await this.api.deleteLink(link, taskCollectionHandler);
  }

  public async blinkLabel(label: Label, taskCollectionHandler?: TaskCollectionHandler): Promise<void> {
    return await this.api.blinkLabel(label, taskCollectionHandler);
  }

  public async blinkSortedSide(sortedSide: SortedSide, duration: number): Promise<void> {
    return await this.api.blinkSortedSide(sortedSide, duration);
  }

  public async blinkByCoordinate(
    coordinate: CompartmentView['coordinate'],
    color?: string,
    duration?: number,
  ): Promise<LinkView[]> {
    return await this.api.blinkByCoordinate(coordinate, color, duration);
  }

  public async deleteCompartment(
    compartment: CompartmentView,
    taskCollectionHandler?: TaskCollectionHandler,
  ): Promise<void> {
    return await this.api.deleteCompartment(compartment, taskCollectionHandler);
  }

  public async getPickingAreas(pagination: Pagination): Promise<PaginationResponse<PickingArea>> {
    return await this.api.getPickingAreas(pagination);
  }

  public async getPresets(pagination: Pagination, compartmentIdentifier?: string): Promise<PaginationResponse<Preset>> {
    return await this.api.getPresets(pagination, compartmentIdentifier);
  }

  public async getAvailablePresets(coordinate: string): Promise<Preset[]> {
    return this.api.getAvailablePresets(coordinate);
  }

  public async savePreset(preset: PresetSavePayload): Promise<Preset> {
    return await this.api.savePreset(preset);
  }

  public async getTemplates(pagination: Pagination): Promise<PaginationResponse<TemplateWithData>> {
    return await this.api.getTemplates(pagination);
  }

  public async getTemplate(templateId: Required<Pick<Template, 'id'>>): Promise<TemplateWithData> {
    return await this.api.getTemplate(templateId);
  }

  public async getCompartmentTemplates(coordinate: CompartmentView['coordinate']): Promise<TemplateWithData[]> {
    return await this.api.getCompartmentTemplates({ coordinate });
  }

  public async getAvailableTemplates(coordinate: string, linkableOnly: boolean = true): Promise<Template[]> {
    return await this.api.getAvailableTemplates(coordinate, linkableOnly);
  }

  public async getAllTemplates(): Promise<TemplateWithData[]> {
    let templates: TemplateWithData[] = [];

    let page = 1;
    let expectedTemplateCount = Infinity;
    while (templates.length < expectedTemplateCount) {
      const result = await this.api.getTemplates({ page });
      expectedTemplateCount = result.totalItemCount!;
      templates = templates.concat(result.items!);
      page += 1;
    }
    return templates;
  }

  public async saveTemplate(
    coordinate: string,
    name: string,
    template: TemplateSavePayload,
  ): Promise<TemplateWithData> {
    return await this.api.saveTemplate(coordinate, name, template);
  }

  public async getTechnologies(): Promise<Technology[]> {
    return await this.api.getTechnologies();
  }

  public async getOperationGroups(pagination: Pagination): Promise<PaginationResponse<OperationGroup>> {
    return await this.api.getOperationGroups(pagination);
  }

  public async addOperationGroup(operationGroup: OperationGroupCreate, csv?: File): Promise<OperationGroup> {
    return await this.api.addOperationGroup(operationGroup, csv);
  }

  public async deleteOperationGroup(operationGroup: OperationGroup): Promise<void> {
    return await this.api.deleteOperationGroup(operationGroup);
  }

  public async getOperationGroup(id: OperationGroupId): Promise<OperationGroup> {
    return await this.api.getOperationGroup(id);
  }

  public async getCompartmentOperations(
    compartmentIdentifier: string,
    pagination: Pagination,
  ): Promise<PaginationResponse<Operation>> {
    return await this.api.getCompartmentOperations(compartmentIdentifier, pagination);
  }

  public async getOperations(
    operationGroup: OperationGroup,
    pagination: Pagination,
  ): Promise<PaginationResponse<Operation>> {
    return await this.api.getOperations(operationGroup, pagination);
  }

  public async bulkDeleteCompartments(
    coordinates: string[],
    taskCollectionHandler?: TaskCollectionHandler,
  ): Promise<void> {
    return await this.api.bulkDeleteCompartments(coordinates, taskCollectionHandler);
  }

  public async renderTemplate(payload: RendererPayload): Promise<RendererResult> {
    return await this.api.renderTemplate(payload);
  }

  public async getApplicableRenderers(payload: ApplicableRenderersPayload): Promise<ApplicableRenderersResult> {
    return await this.api.getApplicableRenderers(payload);
  }

  public async getLinkProfiles(pagination: Pagination): Promise<PaginationResponse<LinkProfile>> {
    return await this.api.getLinkProfiles(pagination);
  }

  public async getLinkProfile(coordinate: string, name: string): Promise<LinkProfile> {
    return this.api.getLinkProfile(coordinate, name);
  }

  public async deleteLinkProfile(coordinate: string, name: string): Promise<void> {
    return this.api.deleteLinkProfile(coordinate, name);
  }

  public async saveLinkProfile(linkProfile: LinkProfile, overwrite?: boolean): Promise<LinkProfile> {
    return this.api.saveLinkProfile(linkProfile, overwrite);
  }

  public async getAvailableCompartmentSelectors(coordinate: string): Promise<CompartmentSelector[]> {
    return this.api.getAvailableCompartmentSelectors(coordinate);
  }

  public async getCompartmentSelectors(pagination: Pagination): Promise<PaginationResponse<CompartmentSelector>> {
    return this.api.getCompartmentSelectors(pagination);
  }

  public async saveCompartmentSelector(
    compartmentSelector: CompartmentSelector,
    overwrite: boolean = false,
  ): Promise<CompartmentSelector> {
    return this.api.saveCompartmentSelector(compartmentSelector, overwrite);
  }

  public async deleteCompartmentSelector(coordinate: string, name: string): Promise<void> {
    return this.api.deleteCompartmentSelector(coordinate, name);
  }

  public async runCompartmentSelector(coordinate: string, name: string, input: string): Promise<CompartmentView[]> {
    return this.api.runCompartmentSelector(coordinate, name, input);
  }

  public async getFinders(pagination: Pagination): Promise<PaginationResponse<Finder>> {
    return this.api.getFinders(pagination);
  }

  public async saveFinder(
    coordinate: string,
    name: string,
    payload: SaveFinderPayload,
    overwrite: boolean = false,
  ): Promise<Finder> {
    return this.api.saveFinder(coordinate, name, payload, overwrite);
  }

  public async deleteFinder(coordinate: string, name: string): Promise<void> {
    return this.api.deleteFinder(coordinate, name);
  }

  public async getUpdaterProfiles(pagination: Pagination): Promise<PaginationResponse<UpdaterProfile>> {
    return await this.api.getUpdaterProfiles(pagination);
  }

  public async getUpdaterProfile(coordinate: string, name: string): Promise<UpdaterProfile> {
    return this.api.getUpdaterProfile(coordinate, name);
  }

  public async deleteUpdaterProfile(coordinate: string, name: string): Promise<void> {
    return this.api.deleteUpdaterProfile(coordinate, name);
  }

  public async saveUpdaterProfile(updaterProfile: UpdaterProfile, overwrite?: boolean): Promise<UpdaterProfile> {
    return this.api.saveUpdaterProfile(updaterProfile, overwrite);
  }

  /////////////////
  // V2
  /////////////////

  public async getDevicePool(coordinate: string, name: string): Promise<DevicePool> {
    return this.api.getDevicePool(coordinate, name);
  }

  public async getDevicePools(pagination: Pagination): Promise<PaginationResponse<DevicePool>> {
    return this.api.getDevicePools(pagination);
  }

  public async saveDevicePool(devicePool: DevicePool, overwrite: boolean = false): Promise<DevicePool> {
    return this.api.saveDevicePool(devicePool, overwrite);
  }

  public async deleteDevicePool(devicePool: DevicePool): Promise<void> {
    return this.api.deleteDevicePool(devicePool.coordinate, devicePool.name);
  }

  public async getZone(coordinate: string, name: string): Promise<Zone> {
    return this.api.getZone(coordinate, name);
  }

  public async getZones(pagination: Pagination): Promise<PaginationResponse<Zone>> {
    return this.api.getZones(pagination);
  }

  public async saveZone(zone: ZonePayload, overwrite: boolean = false): Promise<Zone> {
    return this.api.saveZone(zone, overwrite);
  }

  public async deleteZone(zone: Zone, force: boolean = false): Promise<void> {
    return this.api.deleteZone(zone.coordinate, zone.name, force);
  }

  public async getPickerProfiles(pagination: Pagination): Promise<PaginationResponse<PickerProfile>> {
    return await this.api.getPickerProfiles(pagination);
  }

  public async getPickerProfile(zone: ZoneIdentifier, name: string): Promise<PickerProfile> {
    return this.api.getPickerProfile(zone, name);
  }

  public async deletePickerProfile(pickerProfile: PickerProfile): Promise<void> {
    return this.api.deletePickerProfile(pickerProfile.zone, pickerProfile.name);
  }

  public async savePickerProfile(
    zone: ZoneIdentifier,
    name: string,
    payload: SavePickerProfilePayload,
    overwrite?: boolean,
  ): Promise<PickerProfile> {
    return this.api.savePickerProfile(zone, name, payload, overwrite);
  }

  public async getBlueprint(coordinate: string, zoneName: string, name: string): Promise<Blueprint> {
    return this.api.getBlueprint(coordinate, zoneName, name);
  }

  public async getBlueprints(coordinate: string, zoneName: string): Promise<Blueprint[]> {
    return this.api.getBlueprints(coordinate, zoneName);
  }

  public async deleteBlueprint(blueprint: Blueprint): Promise<void> {
    return this.api.deleteBlueprint(blueprint);
  }

  public async saveBlueprint(blueprint: BlueprintPayload, overwrite: boolean = false): Promise<Blueprint> {
    return this.api.saveBlueprint(blueprint, overwrite);
  }

  public async getReplenishmentPlans(pagination: Pagination): Promise<PaginationResponse<ReplenishmentPlan>> {
    return this.api.getReplenishmentPlans(pagination);
  }

  public async getReplenishmentPlan(identifier: ReplenishmentPlanIdentifier): Promise<ReplenishmentPlan> {
    return this.api.getReplenishmentPlan(identifier);
  }

  public async saveReplenishmentPlan(
    replenishmentPlan: ReplenishmentPlan,
    overwrite: boolean,
  ): Promise<ReplenishmentPlan> {
    return this.api.saveReplenishmentPlan(replenishmentPlan, overwrite);
  }

  public async getReceivingAreas(pagination: Pagination): Promise<PaginationResponse<ReceivingArea>> {
    return this.api.getReceivingAreas(pagination);
  }

  public async saveReceivingArea(
    identifier: ReceivingAreaIdentifier,
    payload: SaveReceivingAreaPayload,
    overwrite: boolean,
  ): Promise<ReceivingArea> {
    const { coordinate, name } = identifier;
    const { replenishmentPlan } = payload;
    return this.api.saveReceivingArea({ coordinate, name, replenishmentPlan }, overwrite);
  }

  public async deleteReceivingArea(identifier: ReceivingAreaIdentifier): Promise<void> {
    return this.api.deleteReceivingArea(identifier);
  }

  public async getSupplyProfiles(pagination: Pagination): Promise<PaginationResponse<SupplyProfile>> {
    return this.api.getSupplyProfiles(pagination);
  }

  public async getSupplyProfile(identifier: SupplyProfileIdentifier): Promise<SupplyProfile> {
    return this.api.getSupplyProfile(identifier);
  }

  public async saveSupplyProfile(
    identifier: SupplyProfileIdentifier,
    payload: SaveSupplyProfilePayload,
    overwrite: boolean,
  ): Promise<SupplyProfile> {
    return this.api.saveSupplyProfile(identifier, payload, overwrite);
  }

  public async deleteSupplyProfile(supplyProfile: SupplyProfile): Promise<void> {
    return this.api.deleteSupplyProfile(supplyProfile);
  }

  public async deleteReplenishmentPlan(identifier: ReplenishmentPlanIdentifier): Promise<void> {
    return this.api.deleteReplenishmentPlan(identifier);
  }

  public async saveEventRule(
    identifier: EventRuleIdentifier,
    payload: EventRuleSavePayload,
    overwrite: boolean,
  ): Promise<EventRule> {
    return this.api.saveEventRule(identifier, payload, overwrite);
  }

  public async deleteEventRule(identifier: EventRuleIdentifier): Promise<void> {
    return this.api.deleteEventRule(identifier);
  }

  public async getEventRule(identifier: EventRuleIdentifier): Promise<EventRule> {
    return this.api.getEventRule(identifier);
  }

  public async getEventRules(pagination: Pagination): Promise<PaginationResponse<EventRule>> {
    return this.api.getEventRules(pagination);
  }

  // @TODO eventemitter3 interface ¯\_(ツ)_/¯
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public on(event: string, fn: ListenerFn, context?: any): void {
    this.api.on(event, fn, context);
  }

  // @TODO eventemitter3 interface ¯\_(ツ)_/¯
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public once(event: string, fn: ListenerFn, context?: any): void {
    this.api.once(event, fn, context);
  }

  // @TODO eventemitter3 interface ¯\_(ツ)_/¯
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public off(event: string, fn?: ListenerFn, context?: any): void {
    this.api.off(event, fn, context);
  }

  public endpointEventType(sdkEvent: ApiSdkEvents, endpoint: Endpoint): string {
    return this.api.endpointEventType(sdkEvent, endpoint);
  }

  public endpointEventTypeWithMethod(sdkEvent: ApiSdkEvents, endpoint: Endpoint, method: HttpMethod): string {
    return this.api.endpointEventTypeWithMethod(sdkEvent, endpoint, method);
  }
}
