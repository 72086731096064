import { EventEmitter } from 'eventemitter3';
import { action, observable } from 'mobx';

export const ST_PLACEHOLDER = '%s%';
export const DELIMITER_FIELD_VALUE = '::';
export const DELIMITER_FIELDS = ';;';

export interface SearchTextFieldOverride {
  field: string;
  pattern: string;
}

export class SearchContentStore extends EventEmitter {
  @observable
  public searchBarDisabled: boolean = false;

  @observable
  public searchText: string = '';

  @observable
  public searchTextFieldOverride: SearchTextFieldOverride | null = null;

  public start(): void {
    this.setSearchBarDisabled(false);
    this.setSearchText('');
    this.setSearchTextFieldOverride(null);
    this.emit('start');
  }

  public end(): void {
    this.setSearchBarDisabled(false);
    this.setSearchText('');
    this.setSearchTextFieldOverride(null);
    this.emit('end');
  }

  @action
  public setSearchBarDisabled(disabled: boolean = true): void {
    this.searchBarDisabled = disabled;
  }

  @action
  public setSearchText(searchText: string): void {
    this.searchText = searchText;
    this.emit('searchText', searchText);
  }

  @action
  public setSearchTextFieldOverride(searchTextFieldOverride: SearchTextFieldOverride | null = null): void {
    this.searchTextFieldOverride = searchTextFieldOverride;
  }

  public emitRefresh(): void {
    this.emit('refresh');
  }
}
