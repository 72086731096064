import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../../Common/Helper/ThemeHelper';

export const AreaManagementStyles = (theme: Theme): Record<string, CSSProperties> => {
  const spacingUnit2 = spacing(theme) * 2; // remove asap
  return {
    root: {
      width: '100%',
      overflowX: 'auto' as const,
      boxShadow: theme.shadows[5],
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen * 4,
      }),
    },
    dataTablePaper: {
      paddingTop: 15,
    },
    icon: {
      marginRight: spacingUnit2, // workaround for tslint -.-
      // marginRight: spacing(theme) * 2,
    },
    iconSpacer: {
      marginLeft: spacing(theme) * 2,
      marginRight: spacing(theme) * 2,
    },
    link: {
      color: theme.palette.text.primary,
    },
    verticalAlignMiddle: {
      verticalAlign: 'middle',
    },
    expansion: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    expansionExpanded: {
      minHeight: spacing(theme) * 7,
    },
  };
};
