import { CompartmentSelector, CompartmentSelectorConfiguration } from '@ekkogmbh/apisdk';
import { Stack, Typography } from '@mui/material';
import { Delete, Edit, FindReplace } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  CompartmentSelectorManagementContentActionHandlers,
  CompartmentSelectorManagementContentPropsWithStores,
  CompartmentSelectorManagementContentState,
} from './CompartmentSelectorManagementContent';

class CompartmentSelectorDataTableActions extends DataTableActionsComponent<CompartmentSelector> {}

const Actions = (
  _: CompartmentSelectorManagementContentState,
  __: CompartmentSelectorManagementContentPropsWithStores,
  actions: CompartmentSelectorManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (compartmentSelector: CompartmentSelector): React.ReactNode => {
      const actionButtons: ActionButton<CompartmentSelector>[] = [
        {
          title: 'Test',
          onClick: actions.run,
          icon: FindReplace,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <CompartmentSelectorDataTableActions
          dataset={compartmentSelector}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: CompartmentSelector['name']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{name}</div>
    ),
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: CompartmentSelector['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const Type = (): MUIDataTableColumnDef => ({
  label: 'Type',
  name: 'entry.type',
  options: {
    sort: false,
    filter: false,
  },
});

const Configuration = (): MUIDataTableColumnDef => ({
  label: 'Configuration',
  name: 'entry.configuration',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (configuration: CompartmentSelector['configuration']): React.ReactNode => (
      <div>
        {Object.keys(configuration).map((key: string) => (
          <div
            key={key}
            style={{
              display: 'inline-block',
              width: 155,
              whiteSpace: 'normal',
              wordBreak: 'break-all',
            }}
          >
            <div style={{ margin: 8 }}>
              <Typography
                variant={'subtitle2'}
                color={'textSecondary'}
                style={{
                  fontWeight: 700,
                  wordBreak: 'keep-all',
                }}
              >
                {key}
              </Typography>
            </div>
            <div style={{ marginLeft: 8 }}>{renderConfigOption(configuration, key)}</div>
          </div>
        ))}
      </div>
    ),
  },
});

const renderConfigOption = (configuration: CompartmentSelectorConfiguration, key: string) => {
  const subConfig: typeof configuration[keyof CompartmentSelectorConfiguration] = configuration[key];

  if (Array.isArray(subConfig)) {
    return <span>{(subConfig as string[]).join(', ')}</span>;
  }

  switch (typeof subConfig) {
    case 'object':
      const parts: string[] = [];
      Object.keys(subConfig).forEach((subKey) => parts.push(`${subKey}: ${subConfig[subKey]}`));
      return (
        <Stack spacing={0}>
          {parts.map((part, index) => (
            <Typography key={`conf-part-${index}`}>{part}</Typography>
          ))}
        </Stack>
      );
    case 'boolean':
    case 'number':
    case 'string':
      return <span>{subConfig}</span>;
    default:
      return <></>;
  }
};

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  CompartmentSelectorManagementContentPropsWithStores,
  CompartmentSelectorManagementContentState,
  CompartmentSelectorManagementContentActionHandlers
>> = [Name, Coordinate, Type, Configuration, Actions];
