import { Grid } from '@mui/material';
import React from 'react';
import { Page } from '../../Common/Components/Page';
import { TitleProps } from '../../Routes';
import { NoPermissionsContent } from './NoPermissionsContent';

export const NoPermissions = ({ title }: TitleProps): React.JSX.Element => (
  <Page title={title} isSearchable={false}>
    <Grid item xs={12}>
      <NoPermissionsContent />
    </Grid>
  </Page>
);
