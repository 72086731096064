import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React, { ChangeEvent, Component } from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;
const checkboxStyle = { margin: 12 };

interface StyledCheckboxProps extends WithStyles<typeof styles> {
  label: string;
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  tooltip?: string;
}

class StyledCheckboxComponent extends Component<StyledCheckboxProps> {
  public checkbox = (): React.JSX.Element => {
    const { label, value, onChange, disabled } = this.props;
    return (
      <FormControlLabel
        style={checkboxStyle}
        label={label}
        control={<Checkbox checked={value} onChange={onChange} color="secondary" disabled={disabled} />}
      />
    );
  };

  public render() {
    const { tooltip } = this.props;

    return (
      <>
        {tooltip && tooltip !== '' && (
          <Tooltip title={tooltip} arrow={true}>
            {this.checkbox()}
          </Tooltip>
        )}
        {!tooltip && this.checkbox()}
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(StyledCheckboxComponent);

export const StyledCheckbox = StyleWrapped;
