import { Button, Fade, Grid, Radio, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { Component } from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';

const styles = FormStyles;
const fadeTimeout = 2000;

interface ExportPanelState {
  currentTab: number;
  loading: boolean;
  exportFormat: string;
  formatTypes: string[];
}

export interface ExportPanelTabDefinition {
  description: string;
  icon: React.ComponentType<SvgIconProps>;
  exportHandler: (contentType: string) => () => Promise<void>;
}

export interface ExportPanelProps extends WithStyles<typeof styles> {
  tabs: ExportPanelTabDefinition[];
  formatTypes: string[];
  closeHandler: () => void;
  exportHandler: (contentType: string) => () => void;
}

const FormatTypeMap = {
  csv: {
    text: 'CSV',
    contentType: 'text/csv',
  },
  json: {
    text: 'JSON',
    contentType: 'application/json',
  },
};

class ExportPanelComponent extends Component<ExportPanelProps, ExportPanelState> {
  public state: ExportPanelState = {
    loading: false,
    currentTab: 0,
    exportFormat: 'text/csv',
    formatTypes: [],
  };

  public static getDerivedStateFromProps(
    props: Readonly<ExportPanelProps>,
    state: ExportPanelState,
  ): Partial<ExportPanelState> | null {
    if (JSON.stringify(props.formatTypes) !== JSON.stringify(state.formatTypes)) {
      let formatTypes: string[] = props.formatTypes.map((v) => v);

      const reducer = (accFormatTypes: string[], currFormatType: string): string[] => {
        if (FormatTypeMap[currFormatType] !== undefined && accFormatTypes.indexOf(currFormatType) === -1) {
          accFormatTypes.push(currFormatType);
        }
        return accFormatTypes;
      };

      if (formatTypes.length !== 0) {
        formatTypes = formatTypes.reduce(reducer, []);
      }

      const exportFormat = formatTypes.length !== 0 ? FormatTypeMap[formatTypes[0]].contentType : 'text/csv';

      if (formatTypes.length === 0) {
        formatTypes.push('csv');
      }

      return {
        exportFormat,
        formatTypes,
      };
    }

    return null;
  }

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: false });
  }

  public handleTabChange = (_: React.SyntheticEvent, value: number): void => {
    this.setState({ currentTab: value });
  };

  public closeHandler = () => {
    this.setState({ currentTab: 0 });

    if (this.props.closeHandler !== undefined) {
      this.props.closeHandler();
    }
  };

  public handleExportTypeChange = ({ target: { value } }: SelectChangeEvent<unknown>): void => {
    this.setState({ exportFormat: value as string });
  };

  public getFormatTypeOptions = (): React.ReactNode[] => {
    const formatTypes = this.state.formatTypes.map((type) => type);

    const isInvalidType = (type: string) => FormatTypeMap[type] === undefined;

    if (formatTypes.find((type) => isInvalidType(type)) !== undefined) {
      formatTypes.length = 0;
      formatTypes.push('csv');
    }

    return formatTypes.map((formatType: string, key: number) => (
      <option key={key} value={FormatTypeMap[formatType].contentType}>
        {FormatTypeMap[formatType].text}
      </option>
    ));
  };

  public render() {
    const { classes } = this.props;
    const { currentTab, exportFormat } = this.state;
    const { tabs } = this.props;

    const formatOptions = this.getFormatTypeOptions();

    return (
      <React.Fragment>
        <Tabs
          value={currentTab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="secondary"
          classes={{
            flexContainer: classes.exportFlexContainer,
            indicator: classes.exportIndicator,
          }}
        >
          {tabs.map((tabDef: ExportPanelTabDefinition, index: number) => {
            const { description } = tabDef;
            const { currentTab } = this.state;

            return (
              <Tab
                key={index}
                style={{
                  display: 'block',
                  maxWidth: 400,
                }}
                label={
                  <div
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      lineHeight: '48px',
                    }}
                  >
                    <Radio checked={currentTab === index} />
                    <tabDef.icon style={{ margin: 11 }} />
                    {description}
                  </div>
                }
              />
            );
          })}
        </Tabs>

        <Grid
          item
          xs={12}
          style={{
            marginTop: 24,
            display: 'inline-flex',
          }}
        >
          <Fade in={true} timeout={fadeTimeout}>
            <StyledSelectField native value={exportFormat} onChange={this.handleExportTypeChange} label="Type">
              {formatOptions}
            </StyledSelectField>
          </Fade>
        </Grid>

        {spacer(12)}

        <Grid
          item
          lg={4}
          xs={12}
          style={{
            marginTop: 24,
            display: 'inline-flex',
          }}
        >
          <Button variant="contained" color="secondary" className={classes.button} onClick={this.closeHandler}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={tabs[currentTab].exportHandler(exportFormat)}
          >
            Export
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

const StyleWrapped = withStyles(styles)(ExportPanelComponent);

export const ExportPanel = StyleWrapped;
