import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, SupplyProfile } from '@ekkogmbh/apisdk';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { SupplyProfileStore } from '../Stores/SupplyProfileStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject } from 'mobx-react';
import { Component } from 'react';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { WithStyles, withStyles } from '@mui/styles';
import { SupplyProfileManagementStyles } from '../Styles/SupplyProfileManagementStyles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './SupplyProfileDatatableColumnDefinitions';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { Grid, Paper } from '@mui/material';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import classNames from 'classnames';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { SupplyProfilePanel, SupplyProfilePanelProps } from './SupplyProfilePanel';
import { Add } from '@mui/icons-material';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';

interface SupplyProfileManagementContentActions {
  updateSupplyProfile?: (SupplyProfile: SupplyProfile) => void;
}

export interface SupplyProfileManagementContentActionHandlers {
  edit: (supplyProfile: SupplyProfile) => void;
  delete: (SupplyProfile: SupplyProfile) => void;
}

export interface SupplyProfileManagementContentState {
  deletableSupplyProfile?: SupplyProfile;
}

interface SupplyProfileManagementContentStores {
  api: ApiStore;
  supplyProfileStore: SupplyProfileStore;
  navigationStore: NavigationStore;
  searchContentStore: SearchContentStore;
}

interface SupplyProfileManagementContentProps
  extends WithStyles<typeof SupplyProfileManagementStyles>,
    RouteComponentProps {}

export type SupplyProfileManagementContentPropsWithStores = SupplyProfileManagementContentProps &
  SupplyProfileManagementContentStores;

@inject('api', 'supplyProfileStore', 'navigationStore', 'searchContentStore')
class SupplyProfileManagementContentComponent extends Component<
  SupplyProfileManagementContentProps,
  SupplyProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<SupplyProfile> = ['name', 'coordinate'];
  private readonly sortFieldMaps: DataTableSortFieldMap<SupplyProfile> = { name: 'entry.name' };
  private readonly actions: SupplyProfileManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  public state: SupplyProfileManagementContentState = {};

  get stores(): SupplyProfileManagementContentStores {
    return this.props as SupplyProfileManagementContentProps & SupplyProfileManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<SupplyProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<SupplyProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSupplyProfiles(pagination),
      EslManagerPublicRouteV1.SUPPLY_PROFILES,
      HttpMethod.GET,
    );
  };

  public onEdit = async (supplyProfile: SupplyProfile): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateSupplyProfile } = this.actions;

    if (updateSupplyProfile) {
      updateSupplyProfile(supplyProfile);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, supplyProfileStore } = this.stores;
    const { name, coordinate } = supplyProfileStore.state;

    await request<SupplyProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveSupplyProfile(
        { name, coordinate },
        supplyProfileStore.getPayload(),
        supplyProfileStore.editableSupplyProfile !== undefined,
      ),
      EslManagerPublicRouteV1.SUPPLY_PROFILE,
      HttpMethod.PUT,
      { 200: 'Supply Profile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  public onDelete = async (supplyProfile: SupplyProfile): Promise<void> => {
    this.setState({ deletableSupplyProfile: supplyProfile });
  };

  public onDeleteDismiss = async () => {
    this.setState({ deletableSupplyProfile: undefined });
  };

  public onDeleteConfirm = async () => {
    const { deletableSupplyProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableSupplyProfile) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteSupplyProfile(deletableSupplyProfile),
      EslManagerPublicRouteV1.SUPPLY_PROFILE,
      HttpMethod.DELETE,
      { 200: 'Supply Profile deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { supplyProfileStore } = this.stores;

    const addPanelDefinition: ContentPanelDefinition<SupplyProfilePanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: SupplyProfilePanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.EKANBAN_WRITE,
      toggleOffCallback: supplyProfileStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateSupplyProfile } = this.actions;
        if (updateSupplyProfile === undefined) {
          this.actions.updateSupplyProfile = async (supplyProfile: SupplyProfile) => {
            expandCallback();
            supplyProfileStore.resetStore(supplyProfile);
          };
        }
      },
    };

    return [addPanelDefinition];
  };

  public render() {
    const { classes } = this.props;
    const { deletableSupplyProfile } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as SupplyProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />
        {deletableSupplyProfile && (
          <GenericDialog
            title={'Delete Supply Profile'}
            text={deletableSupplyProfile.name}
            type={'confirmation'}
            maxWidth={'sm'}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteConfirm}
            fullWidth
            centered
            open
          />
        )}

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMaps}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<SupplyProfileManagementContentProps, typeof SupplyProfileManagementContentComponent>(
  SupplyProfileManagementContentComponent,
);
const StyleWrapped = withStyles(SupplyProfileManagementStyles)(RouterWrapped);

export const SupplyProfileManagementContent = StyleWrapped;
