import { Compartment } from '@ekkogmbh/apisdk';
import { Checkbox, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { set } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ChangeEvent, Component } from 'react';
import { DataTableData, DataTableStore } from '../Stores/DataTableStore';
import { RowSelectStore } from '../Stores/RowSelectStore';

const styles = (_: Theme) => ({
  root: {},
});

interface RowSelectAllState {}

interface RowSelectAllStores {
  dataTableStore: DataTableStore;
  rowSelectStore: RowSelectStore;
}

interface RowSelectAllProps extends WithStyles<typeof styles> {}

const stores: Array<keyof RowSelectAllStores> = ['rowSelectStore', 'dataTableStore'];

@inject(...stores)
@observer
class RowSelectAllComponent extends Component<RowSelectAllProps, RowSelectAllState> {
  public state = {};

  get stores(): RowSelectAllStores {
    return this.props as RowSelectAllStores & RowSelectAllProps;
  }

  public onChangeSelectAll = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    const { dataTableStore, rowSelectStore } = this.stores;

    const identifiers = (dataTableStore.data as DataTableData<Compartment>).map(
      (compartment: Compartment) => compartment.coordinate,
    );

    const entries = {};

    for (const identifier of identifiers) {
      entries[identifier] = checked;
    }

    set(rowSelectStore.selectedRows, entries);
    rowSelectStore.refreshRowsSelected();
  };

  public render() {
    const { dataTableStore, rowSelectStore } = this.stores;

    const checked = rowSelectStore.selectedCount > 0 && dataTableStore.data.length === rowSelectStore.selectedCount;

    return <Checkbox checked={checked} onChange={this.onChangeSelectAll} value={'all'} />;
  }
}

const StyleWrapped = withStyles(styles)(RowSelectAllComponent);

export const RowSelectAll = StyleWrapped;
