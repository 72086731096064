import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { EventRuleManagementContent } from './Components/EventRuleManagementContent';
import { EventRuleStore } from './Stores/EventRuleStore';

const eventRuleStore = new EventRuleStore();

const stores = { eventRuleStore };

export const EventRuleManagement = (): React.JSX.Element => (
  <Provider {...stores}>
    <Page title="Event Rules" isSearchable={true}>
      <EventRuleManagementContent />
    </Page>
  </Provider>
);
