import { AdapterFormats } from '@mui/x-date-pickers';

const MomentFormats: Partial<AdapterFormats> = {
  year: 'YYYY',
  month: 'MMMM',
  fullDate: 'YYYY MMMM Do',
  fullTime12h: 'hh:mm a',
  fullTime24h: 'HH:mm',
  fullTime: 'HH:mm',
  keyboardDateTime: 'YYYY MMMM Do HH:mm',
  keyboardDateTime12h: 'YYYY MMMM Do hh:mm a',
  keyboardDateTime24h: 'YYYY MMMM Do HH:mm',
  keyboardDate: 'YYYY MMMM Do',
};

export default MomentFormats;
