import { Blueprint } from '@ekkogmbh/apisdk';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  BlueprintManagementContentActionHandlers,
  BlueprintManagementContentPropsWithStores,
  BlueprintManagementContentState,
} from './BlueprintManagementContent';
import { Bookmark, Delete, Edit } from '@mui/icons-material';

class BlueprintDataTableActions extends DataTableActionsComponent<Blueprint> {}

const Actions = (
  _: BlueprintManagementContentState,
  propsWithStores: BlueprintManagementContentPropsWithStores,
  actions: BlueprintManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (pool: Blueprint): React.ReactNode => {
      const { api } = propsWithStores;

      const actionButtons: ActionButton<Blueprint>[] = [
        {
          title: 'Show Steps',
          onClick: actions.showSteps,
          icon: Bookmark,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !api.userHasPermissionOnAnyNode(Permissions.BLUEPRINTS_WRITE),
        },
      ];

      return <BlueprintDataTableActions dataset={pool} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: Blueprint['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Once = (): MUIDataTableColumnDef => ({
  label: 'Once',
  name: 'entry.once',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (once: Blueprint['once']): React.ReactNode => <div>{String(once)}</div>,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  BlueprintManagementContentPropsWithStores,
  BlueprintManagementContentState,
  BlueprintManagementContentActionHandlers
>> = [Name, Once, Actions];
