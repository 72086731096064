import { FieldReplacement } from '@ekkogmbh/apisdk';
import { Button, Divider, Grid, Tab, Tabs } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CompartmentGeneratorStore } from '../Stores/CompartmentGeneratorStore';

const styles = FormStyles;

interface CompartmentGeneratorFormState {
  activeTab: number;
  fieldInput: string;
}

interface CompartmentGeneratorFormProps extends WithStyles<typeof styles> {
  noCoordinate?: boolean;
}

interface CompartmentGeneratorFormStores {
  compartmentGeneratorStore: CompartmentGeneratorStore;
}

type CompartmentGeneratorFormPropsWithStores = CompartmentGeneratorFormProps & CompartmentGeneratorFormStores;

@inject('compartmentGeneratorStore')
@observer
class CompartmentGeneratorFormComponent extends Component<
  CompartmentGeneratorFormProps,
  CompartmentGeneratorFormState
> {
  public state: CompartmentGeneratorFormState = {
    activeTab: 0,
    fieldInput: '',
  };

  get stores(): CompartmentGeneratorFormStores {
    return this.props as CompartmentGeneratorFormPropsWithStores;
  }

  public onAddField(): void {
    const { fieldInput } = this.state;
    const { compartmentGeneratorStore } = this.stores;

    if (fieldInput !== '') {
      compartmentGeneratorStore.addField(fieldInput);
      this.setState({
        fieldInput: '',
        activeTab: compartmentGeneratorStore.fields.length - (this.props.noCoordinate === true ? 1 : 0),
      });
    }
  }

  public onRemoveField(): void {
    const { activeTab } = this.state;
    const { compartmentGeneratorStore } = this.stores;

    const fieldIndex = this.props.noCoordinate !== true ? activeTab - 1 : activeTab;

    this.setState({ activeTab: 0 }, () => compartmentGeneratorStore.removeField(fieldIndex));
  }

  public renderField(): React.JSX.Element {
    const { activeTab } = this.state;
    const { noCoordinate } = this.props;
    const { compartmentGeneratorStore } = this.stores;

    const fieldIndex = noCoordinate === true ? activeTab : activeTab > 0 ? activeTab - 1 : undefined;

    const { pattern, replacement } =
      fieldIndex !== undefined ? compartmentGeneratorStore.fields[fieldIndex] : compartmentGeneratorStore.coordinate;

    return (
      <Grid container spacing={1} alignItems={'stretch'} alignContent={'center'} style={{ paddingRight: 20 }}>
        <Grid item xs={12}>
          <StyledTextField
            type={'text'}
            label={'Pattern'}
            value={pattern}
            onChange={(e) => compartmentGeneratorStore.setPattern(e.target.value, fieldIndex)}
            tooltip={'Regular expression pattern for extracting values'}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            type={'text'}
            label={'Replacement'}
            value={replacement}
            onChange={(e) => compartmentGeneratorStore.setReplacement(e.target.value, fieldIndex)}
            tooltip={'Value generation template'}
          />
        </Grid>
      </Grid>
    );
  }

  public render(): React.JSX.Element {
    const { activeTab, fieldInput } = this.state;
    const { compartmentGeneratorStore } = this.stores;

    const isAddDisabled = fieldInput === '' || compartmentGeneratorStore.getFieldByName(fieldInput) !== undefined;

    return (
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={12}>
          <Tabs
            value={
              this.props.noCoordinate === true && compartmentGeneratorStore.fields.length === 0 ? false : activeTab
            }
            variant={'scrollable'}
            scrollButtons={'auto'}
            onChange={(_, newTab) => this.setState({ activeTab: newTab })}
          >
            {this.props.noCoordinate !== true && (
              <Tab key={`tGroup-coord`} label={'coordinate'} style={{ textTransform: 'none' }} />
            )}
            {compartmentGeneratorStore.fields.map((field: FieldReplacement, index: number) => (
              <Tab key={`tGroup-${index}`} label={field ? field.key : ''} style={{ textTransform: 'none' }} />
            ))}
          </Tabs>
          <Divider />
        </Grid>
        {(this.props.noCoordinate !== true || compartmentGeneratorStore.fields.length > 0) && (
          <Grid item xs={12}>
            {this.renderField()}
            <Divider />
          </Grid>
        )}
        <Grid container item xs={6} spacing={1} alignItems={'center'} alignContent={'stretch'}>
          <Grid container item xs={9} spacing={2} alignItems={'center'} alignContent={'stretch'}>
            <Grid item xs={9}>
              <StyledTextField
                type={'text'}
                label={'Field'}
                value={fieldInput}
                onChange={(e) => this.setState({ fieldInput: e.target.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant={'outlined'} color="secondary" disabled={isAddDisabled} onClick={() => this.onAddField()}>
                add
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'contained'}
              color={'secondary'}
              disabled={
                this.props.noCoordinate !== true ? activeTab === 0 : compartmentGeneratorStore.fields.length === 0
              }
              onClick={() => this.onRemoveField()}
            >
              remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(CompartmentGeneratorFormComponent);

export const CompartmentGeneratorForm = StyleWrapped;
