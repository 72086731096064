import {
  Box,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { enqueueSnackbar } from 'notistack';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { DevicePoolStore } from '../Stores/DevicePoolStore';
import React, { Component } from 'react';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import {
  CompartmentView,
  EslManagerPublicRouteV1,
  HttpMethod,
  Pagination,
  PaginationResponse,
  SortingStrategyType,
} from '@ekkogmbh/apisdk';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { inject, observer } from 'mobx-react';
import { ArrowLeft, ArrowRight, RemoveCircle } from '@mui/icons-material';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { arrayMoveMutable } from 'array-move';

const styles = FormStyles;

interface DevicePoolSortingStores {
  api: ApiStore;
  devicePoolStore: DevicePoolStore;
}

interface DevicePoolSortingProps extends WithStyles<typeof styles> {
  onError?: () => void;
}

interface DevicePoolSortingState {
  loading: boolean;
  failure: boolean;
  pages?: PaginationResponse<CompartmentView>;
}

const SortableItem = SortableElement(
  ({
    value,
    sortIndex,
    onRemove,
  }: {
    value: string;
    sortIndex: number;
    onRemove: (value: string, sortIndex?: number) => void;
  }) => (
    <ListItem
      style={{
        borderTopStyle: 'inset',
        borderTopColor: 'rgba(0, 0, 0, 0.23)',
        borderTopWidth: 1,
        backgroundColor: 'white',
        userSelect: 'none',
      }}
    >
      <ListItemText>
        <Typography variant={'subtitle1'} color={'secondary'}>
          <span style={{ display: 'inline-block', minWidth: 40 }}>{sortIndex + 1}.&nbsp;</span>
          <span style={{ display: 'inline-block', minWidth: 160 }}>{value}</span>
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton onClick={() => onRemove(value, sortIndex)} size="large">
          <RemoveCircle />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ),
);

const SortableList = SortableContainer(
  ({ items, onRemove }: { items: string[]; onRemove: (value: string, sortIndex?: number) => void }) => (
    <List style={{ padding: 4, width: '100%' }}>
      {items.map((value: string, index: number) => (
        <SortableItem
          key={`sortable-item-comp-${index}`}
          index={index}
          sortIndex={index}
          value={value}
          onRemove={onRemove}
        />
      ))}
    </List>
  ),
);

@inject('api', 'devicePoolStore')
@observer
class DevicePoolSortingComponent extends Component<DevicePoolSortingProps, DevicePoolSortingState> {
  public state: DevicePoolSortingState = {
    loading: true,
    failure: false,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): DevicePoolSortingStores {
    return this.props as DevicePoolSortingProps & DevicePoolSortingStores;
  }

  public componentDidMount(): void {
    this.fetchItems(1);
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchItems = async (page: number): Promise<void> => {
    cancelFetchPromises(this.fetchPromises);

    const { api, devicePoolStore } = this.stores;
    const { state } = devicePoolStore;
    const { onError } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true }, onError);
    };

    const coordinate = state.compartmentSelector?.coordinate ?? state.coordinate;

    this.setState({ loading: true }, async () => {
      const pages = await request<PaginationResponse<CompartmentView>>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getCompartments({
          page,
          filter: coordinate,
          limit: 8,
        } as Pagination),
        EslManagerPublicRouteV1.COMPARTMENTS,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );
      this.setState({ loading: false, pages });
    });
  };

  private toggleItem = (coordinate: string, sortIndex?: number): void => {
    const { devicePoolStore } = this.stores;
    const { sortingStrategy } = devicePoolStore.state;
    const sortedItems = (sortingStrategy.metadata.coordinates ?? []) as string[];

    if (sortIndex === undefined) {
      sortIndex = sortedItems.indexOf(coordinate);
    }

    if (sortIndex >= 0) {
      sortedItems.splice(sortIndex, 1);
    } else {
      sortedItems.push(coordinate);
    }

    devicePoolStore.setState({
      sortingStrategy: {
        name: SortingStrategyType.MANUAL,
        metadata: { coordinates: sortedItems },
      },
    });
  };

  private onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const { devicePoolStore } = this.stores;
    const { sortingStrategy } = devicePoolStore.state;

    const sortedItems = sortingStrategy.metadata.coordinates as string[];
    arrayMoveMutable(sortedItems, oldIndex, newIndex);

    devicePoolStore.setState({
      sortingStrategy: {
        name: SortingStrategyType.MANUAL,
        metadata: { coordinates: sortedItems },
      },
    });
  };

  private paginationNavigation = (pages: PaginationResponse<CompartmentView>): React.JSX.Element => (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={2}>
        <Box display={'flex'} justifyContent={'center'}>
          <IconButton
            disabled={pages.currentPage! === 1}
            onClick={() => this.fetchItems(pages.currentPage! - 1)}
            size="large"
          >
            <ArrowLeft />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <StyledFormHeader label={`Coordinates (${pages.currentPage!} / ${pages.pageCount!})`} />
      </Grid>
      <Grid item xs={2}>
        <Box display={'flex'} justifyContent={'center'}>
          <IconButton
            disabled={pages.currentPage! === pages.pageCount!}
            onClick={() => this.fetchItems(pages.currentPage! + 1)}
            size="large"
          >
            <ArrowRight />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );

  private paperWrap = (content: React.JSX.Element): React.JSX.Element => (
    <div
      style={{
        margin: 8,
        padding: 0,
        borderStyle: 'solid',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.23)',
      }}
    >
      {content}
    </div>
  );

  public render() {
    const { loading, failure, pages } = this.state;
    const { devicePoolStore } = this.stores;
    const { sortingStrategy } = devicePoolStore.state;

    const sortedItems = (sortingStrategy.metadata.coordinates ?? []) as string[];
    const fetchedItems = pages && pages.items ? pages.items : [];

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        <Grid item xs={6}>
          {this.paperWrap(
            <>
              <StyledFormHeader label={'Sorted Coordinates'} />
              {sortedItems.length < 1 && (
                <Typography color={'secondary'} variant={'subtitle2'} align={'center'}>
                  select coordinates to add them for sorting
                </Typography>
              )}
              {sortedItems.length > 0 && (
                <SortableList
                  lockAxis={'y'}
                  items={sortedItems}
                  onSortEnd={this.onSortEnd}
                  onRemove={this.toggleItem}
                />
              )}
            </>,
          )}
        </Grid>
        <Grid item xs={6}>
          {this.paperWrap(
            <>
              {loading && (
                <Fade in={true} timeout={2000}>
                  <CheckmarkSpinner complete={false} failure={failure} />
                </Fade>
              )}
              {!loading && fetchedItems.length < 1 && (
                <Typography color={'secondary'} variant={'subtitle2'} align={'center'}>
                  no compartments found
                </Typography>
              )}
              {!loading && pages && fetchedItems.length > 0 && (
                <Grid container>
                  <Grid item xs={12}>
                    {this.paginationNavigation(pages)}
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {fetchedItems.map((compartment: CompartmentView) => (
                        <ListItem
                          style={{
                            borderTopStyle: 'inset',
                            borderTopColor: 'rgba(0, 0, 0, 0.23)',
                            borderTopWidth: 1,
                            backgroundColor: 'white',
                            userSelect: 'none',
                          }}
                          key={compartment.coordinate}
                          onClick={() => this.toggleItem(compartment.coordinate)}
                        >
                          <ListItemText>
                            <Typography
                              variant={'subtitle1'}
                              color={sortedItems.includes(compartment.coordinate) ? 'primary' : 'secondary'}
                            >
                              {compartment.coordinate}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              )}
            </>,
          )}
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(DevicePoolSortingComponent);

export const DevicePoolSorting = StyleWrapped;
