import { List, ListItem, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { RouteShortDefinition } from '../../../Routes';

export interface SortableLinkedListItemProps {
  open: boolean;
  classes: Record<string, string>;
  route: RouteShortDefinition;
}

export interface SortableSubmenuProps {
  classes: Record<string, string>;
  items: RouteShortDefinition[];
}

export const SortableLinkedListItem = SortableElement(({ classes, route }: SortableLinkedListItemProps) => {
  const { title } = route;
  const { text, icon: Icon } = route.menu!;

  return (
    <ListItem
      key={`nav-entry-item-${title}`}
      button
      style={{
        paddingLeft: 24,
        paddingRight: 24,
        zIndex: 20000,
      }}
    >
      <Icon className={classes.navigationLinkIcon} />
      <ListItemText
        disableTypography
        className={classes.link}
        primary={<Typography>{text}</Typography>}
        style={{ marginLeft: 28 }}
      />
    </ListItem>
  );
});

export const SortableSubmenu = SortableContainer(({ classes, items }: SortableSubmenuProps) => (
  <List disablePadding style={{ width: '100%' }}>
    {items.map((route: RouteShortDefinition, index: number) => (
      <SortableLinkedListItem key={index} index={index} open={true} classes={classes} route={route} />
    ))}
  </List>
));
