import { Role, UserRoleNodeMapping, UserRoleNodeMappingPayload } from '@ekkogmbh/apisdk';
import { Fade, FormControl, Grid, Hidden, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DatePicker } from '@mui/x-date-pickers';
import { inject } from 'mobx-react';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { AreaSelect } from '../../Common/Components/AreaSelect';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { ReactSelectMultiValue } from '../../Common/Components/ReactSelectMulti';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api'];

interface UserMappingPanelStores {
  api: ApiStore;
}

interface UserMappingPanelState {
  roleId: string;
  validUntil: string;
  changed: boolean;
  loading: boolean;
  allFilled: boolean;
  nodeSelectValue: ReactSelectMultiValue[];
}

interface UserMappingPanelProps extends WithStyles<typeof styles> {
  allRoles: Role[];
  closeHandler: () => void;
  saveHandler: (mapping: UserRoleNodeMappingPayload) => Promise<UserRoleNodeMapping>;
}

@inject(...stores)
class UserMappingPanelComponent extends React.PureComponent<UserMappingPanelProps, UserMappingPanelState> {
  public state: UserMappingPanelState = {
    roleId: '',
    validUntil: '',
    changed: false,
    loading: false,
    allFilled: false,
    nodeSelectValue: [],
  };

  get stores(): UserMappingPanelStores {
    return this.props as UserMappingPanelProps & UserMappingPanelStores;
  }

  public handleError = (status: number, response: Response, json: Record<string, unknown>): void => {
    if (status > 400 && status <= 500) {
      enqueueSnackbar(response.statusText + ': ' + json.message, { variant: 'error' });
    }
  };

  public handleReset = () => {
    this.setState({
      allFilled: false,
      roleId: '',
      nodeSelectValue: [],
      validUntil: '',
      changed: false,
    });
  };

  public handleChange = (name: string) => (event: SelectChangeEvent<unknown>) => {
    const changed = true;

    const { nodeSelectValue, validUntil } = this.state;
    let { roleId } = this.state;

    // historical reasons
    if (name === 'roleId') {
      roleId = event.target.value as string;
    }

    const allFilled = this.isAllFilled(roleId, validUntil, nodeSelectValue);

    this.setState({
      allFilled,
      roleId,
      validUntil,
      changed,
    });
  };

  public handleChangeValidUntil = (date: Moment | null) => {
    if (date == null) {
      this.setState({
        allFilled: false,
        changed: true,
      });

      return;
    }

    const changed = true;

    const { nodeSelectValue, roleId } = this.state;

    const validUntil = date.format('YYYY-MM-DD');
    const allFilled = this.isAllFilled(roleId, validUntil, nodeSelectValue);

    this.setState({
      allFilled,
      validUntil,
      changed,
    });
  };

  public isAllFilled = (roleId: string, validUntil: string, nodeSelectValue: ReactSelectMultiValue[]) =>
    roleId !== undefined && roleId !== '' && nodeSelectValue.length > 0 && validUntil !== '';

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { allFilled, roleId, nodeSelectValue, validUntil, loading } = this.state;

    if (!allFilled || loading) {
      return;
    }

    this.setState({ loading: true });

    const nodeId = nodeSelectValue[nodeSelectValue.length - 1].value;

    const mapping = {
      role: {
        id: parseInt(roleId, 10),
      },
      node: {
        id: parseInt(nodeId as string, 10),
      },
      validUntil,
    };

    try {
      await saveHandler(mapping);

      this.setState({ loading: false });
      closeHandler();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  public handleChangeArea = async (nodeSelectValue: ReactSelectMultiValue[]) => {
    const changed = true;

    const { roleId, validUntil } = this.state;

    const allFilled = this.isAllFilled(roleId, validUntil, nodeSelectValue);

    this.setState({
      allFilled,
      nodeSelectValue,
      roleId,
      validUntil,
      changed,
    });
  };

  public render() {
    const { allRoles, classes, closeHandler } = this.props;
    const { allFilled, changed, loading, roleId, validUntil, nodeSelectValue } = this.state;

    const allRolesOptions = allRoles.map(({ id, name }: Role, index: number) => (
      <option key={index} value={String(id)}>
        {name}
      </option>
    ));

    allRolesOptions.unshift(<option key={-1} value="" />);

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item lg={5} md={8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <StyledSelectField native label="Role" value={roleId} onChange={this.handleChange('roleId')}>
              {allRolesOptions}
            </StyledSelectField>
          </Fade>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <Grid item lg={5} md={8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <FormControl variant="outlined" className={classes.margin}>
              <AreaSelect handleValueChange={this.handleChangeArea} value={nodeSelectValue} />
            </FormControl>
          </Fade>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <Grid item lg={5} md={8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <DatePicker
              className={classes.margin}
              label="Valid-Until"
              disablePast
              value={validUntil === '' ? moment() : moment(validUntil)}
              onChange={this.handleChangeValidUntil}
            />
          </Fade>
        </Grid>

        <Hidden lgDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <FormPanelButtons
          cancelHandler={closeHandler}
          resetHandler={this.handleReset}
          saveHandler={this.handleSave}
          deleteHandler={undefined}
          isResetDisabled={!changed}
          isSaveDisabled={!changed || !allFilled}
          isDeleteDisabled={true}
          isDeleteHidden={true}
        />
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(UserMappingPanelComponent);

export const UserMappingPanel = StyleWrapped;
