import { Operation, OperationCreate, OperationPayloadSwitchPage, OperationType } from '@ekkogmbh/apisdk';
import { Card, CardContent, CardHeader, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { PureComponent } from 'react';
import { spacing } from 'src/Common/Helper/ThemeHelper';

const OperationTypeTexts: Record<OperationType, string> = {
  'switch-page': 'Switch-Page',
  'compartment-update': 'Compartment-Update',
};

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  },
  content: {
    padding: spacing(theme),
  },
  header: {
    padding: spacing(theme),
  },
  title: {
    color: theme.palette.secondary.light,
    fontWeight: 700,
  },
  text: {
    color: theme.palette.secondary.light,
    fontWeight: 700,
  },
});

interface OperationCardProps extends WithStyles<typeof styles> {
  operation: Operation | OperationCreate;
}

class OperationCardComponent extends PureComponent<OperationCardProps> {
  public render() {
    const {
      classes,
      operation: { type },
    } = this.props;

    return (
      <Card elevation={4} className={classes.root}>
        <CardContent className={classes.content}>
          <CardHeader className={classes.header} title={OperationTypeTexts[type]} />
          {this.renderOperationTypeInfo()}
        </CardContent>
      </Card>
    );
  }

  private renderOperationTypeInfo = () => {
    const { classes, operation } = this.props;
    const { type, payload } = operation;

    switch (type) {
      case OperationType.SWITCH_PAGE:
      default:
        const switchPagePayload = payload as OperationPayloadSwitchPage;

        return (
          <Typography variant={'body2'} component={'h2'} align={'left'} className={classes.text}>
            <ul>
              <li>{'Label-Id: ' + switchPagePayload.labelId}</li>
              <li>{'Technology: ' + switchPagePayload.technology}</li>
              <li>{'Page-Number: ' + switchPagePayload.pageNumber}</li>
            </ul>
          </Typography>
        );
    }
  };
}

const StyleWrapped = withStyles(styles)(OperationCardComponent);

export const OperationCard = StyleWrapped;
