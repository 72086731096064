import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import React from 'react';
import { FormStyles } from '../Styles/FormStyles';

export enum PanelAction {
  CREATE = 'Save',
  EDIT = 'Edit',
}

// @TODO refactor?
interface FormPanelButtonsProps extends WithStyles<typeof FormStyles> {
  cancelHandler: () => void;
  finished?: boolean;
  deleteHandler?: () => void;
  resetHandler?: () => void;
  backHandler?: () => void;
  nextHandler?: () => void;
  saveHandler?: () => void;
  isDeleteDisabled?: boolean;
  isResetDisabled?: boolean;
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  isSaveDisabled?: boolean;
  isDeleteHidden?: boolean;
  isNextHidden?: boolean;
}

class FormPanelButtonsComponent extends React.PureComponent<FormPanelButtonsProps> {
  public render() {
    const {
      classes,
      cancelHandler,
      finished,
      deleteHandler,
      resetHandler,
      backHandler,
      nextHandler,
      saveHandler,
      isDeleteDisabled,
      isResetDisabled,
      isNextDisabled,
      isBackDisabled,
      isSaveDisabled,
      isDeleteHidden,
    } = this.props;

    return (
      <Grid item lg={6} md={8} xs={12} className={classes.formPanelButtonsGrid}>
        <Button variant="contained" color="secondary" className={classes.button} onClick={cancelHandler}>
          {finished ? 'Close' : 'Cancel'}
        </Button>

        {resetHandler !== undefined && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={isResetDisabled}
            onClick={resetHandler}
            style={{ visibility: finished ? 'hidden' : 'visible' }}
          >
            Reset
          </Button>
        )}

        {backHandler !== undefined && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={isBackDisabled}
            onClick={backHandler}
            style={{ visibility: finished ? 'hidden' : 'visible' }}
          >
            Back
          </Button>
        )}

        {nextHandler !== undefined && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={isNextDisabled}
            onClick={nextHandler}
            style={{ visibility: finished ? 'hidden' : 'visible' }}
          >
            Next
          </Button>
        )}

        {saveHandler !== undefined && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={isSaveDisabled}
            onClick={saveHandler}
            style={{ visibility: finished ? 'hidden' : 'visible' }}
          >
            Save
          </Button>
        )}

        <Button
          variant="outlined"
          color="secondary"
          className={classNames(classes.button, classes.formPanelButtonsDelete)}
          disabled={isDeleteDisabled}
          onClick={deleteHandler}
          style={{ visibility: isDeleteHidden || finished ? 'hidden' : 'visible' }}
        >
          Delete
        </Button>
      </Grid>
    );
  }
}

export const FormPanelButtons = withStyles(FormStyles)(FormPanelButtonsComponent);
