import { PickerProfile } from '@ekkogmbh/apisdk';
import { Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  PickerProfileManagementContentActionHandlers,
  PickerProfileManagementContentPropsWithStores,
  PickerProfileManagementContentState,
} from './PickerProfileManagementContent';

class PickerProfileDataTableActions extends DataTableActionsComponent<PickerProfile> {}

const PickerProfileActions = (
  _: PickerProfileManagementContentState,
  __: PickerProfileManagementContentPropsWithStores,
  actions: PickerProfileManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (pickerProfile: PickerProfile): React.ReactNode => {
      const actionButtons: ActionButton<PickerProfile>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <PickerProfileDataTableActions dataset={pickerProfile} isProcessing={false} actionButtons={actionButtons} />
      );
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: string): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (coordinate: string): React.ReactNode => <div style={{ fontWeight: 700 }}>{coordinate}</div>,
  },
});

const ZoneCustomBody = (zone: PickerProfile['zone']) => (
  <Typography>
    {zone.name}@{zone.coordinate}
  </Typography>
);

const ZoneColumn = (): MUIDataTableColumnDef => ({
  label: 'Zone',
  name: 'entry.zone',
  options: {
    sort: false,
    filter: false,
    customBodyRender: ZoneCustomBody,
  },
});

const StrategyNameColumn = (): MUIDataTableColumnDef => ({
  label: 'Strategy',
  name: 'entry.startupStrategy',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (strategy: PickerProfile['startupStrategy']): React.ReactNode => (
      <Typography>{strategy.name}</Typography>
    ),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  PickerProfileManagementContentPropsWithStores,
  PickerProfileManagementContentState,
  PickerProfileManagementContentActionHandlers
>> = [Name, Coordinate, ZoneColumn, StrategyNameColumn, PickerProfileActions];
