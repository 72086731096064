import { DevicePool } from '@ekkogmbh/apisdk';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  DevicePoolManagementContentActionHandlers,
  DevicePoolManagementContentPropsWithStores,
  DevicePoolManagementContentState,
} from './DevicePoolManagementContent';
import { Delete, Edit } from '@mui/icons-material';

class DevicePoolDataTableActions extends DataTableActionsComponent<DevicePool> {}

const Actions = (
  _: DevicePoolManagementContentState,
  __: DevicePoolManagementContentPropsWithStores,
  actions: DevicePoolManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (pool: DevicePool): React.ReactNode => {
      const actionButtons: ActionButton<DevicePool>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return <DevicePoolDataTableActions dataset={pool} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: DevicePool['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: DevicePool['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const CompartmentSelector = (): MUIDataTableColumnDef => ({
  label: 'Compartment Selector',
  name: 'entry.compartmentSelector',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (compartmentSelector: DevicePool['compartmentSelector']): React.ReactNode => (
      <div>{compartmentSelector.name + '@' + compartmentSelector.coordinate}</div>
    ),
  },
});

const TemplateFilter = (): MUIDataTableColumnDef => ({
  label: 'Template Filter',
  name: 'entry.templateFilter',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (templateFilter: DevicePool['templateFilter']): React.ReactNode => (
      <div>{`${templateFilter.type} [${templateFilter.templateNames.join(', ')}]`}</div>
    ),
  },
});

const SortingStrategy = (): MUIDataTableColumnDef => ({
  label: 'Sorting Strategy',
  name: 'entry.sortingStrategy.name',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (name: DevicePool['sortingStrategy']['name']): React.ReactNode => <div>{`${name}`}</div>,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  DevicePoolManagementContentPropsWithStores,
  DevicePoolManagementContentState,
  DevicePoolManagementContentActionHandlers
>> = [Name, Coordinate, CompartmentSelector, TemplateFilter, SortingStrategy, Actions];
