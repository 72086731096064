import { Finder, HttpMethod, EslManagerPublicRouteV1, PaginationResponse } from '@ekkogmbh/apisdk';
import { Refresh } from '@mui/icons-material';
import { Fade, IconButton, ListItemText, MenuItem, SelectChangeEvent, SelectProps, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface FinderPickerStores {
  api: ApiStore;
}

interface FinderPickerProps extends WithStyles<typeof styles> {
  coordinate: string;
  selected?: Pick<Finder, 'name' | 'coordinate'>;
  optional?: boolean;
  onChange: (finder: Finder | undefined) => void;
  onError?: () => void;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
  label?: string;
}

interface FinderPickerState {
  loading: boolean;
  failure: boolean;
  finders: (Finder | undefined)[];
}

@inject('api')
class FinderPickerComponent extends Component<FinderPickerProps, FinderPickerState> {
  public state: FinderPickerState = {
    loading: true,
    failure: false,
    finders: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): FinderPickerStores {
    return this.props as FinderPickerProps & FinderPickerStores;
  }

  public componentDidMount(): void {
    const { optional, coordinate, onError } = this.props;

    if (coordinate === '' && !optional) {
      this.setState({ failure: true }, () => {
        if (onError) {
          onError();
        }
      });
      return;
    }

    this.setState({ loading: true }, this.fetchFinders);
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchFinders = async (): Promise<void> => {
    const { api } = this.stores;
    const { coordinate, onError, onChange, optional, selected } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, finders: [] }, onError);
    };

    let finders: (Finder | undefined)[] = [];
    try {
      const finderPagination = await request<PaginationResponse<Finder>>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getFinders({ page: 1, limit: 999, filter: { coordinate } }),
        EslManagerPublicRouteV1.FINDERS,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );

      if (finderPagination.items !== undefined) {
        finders = finderPagination.items;
      }
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    if (optional) {
      finders.unshift(undefined);
    }

    if (finders.length > 0) {
      this.setState({ loading: false, finders });

      if (!selected && !optional) {
        onChange(finders[0]);
      }
    } else if (!optional) {
      onErrorCallback();
    }
  };

  private getListIndex = (finderIndex?: Pick<Finder, 'name' | 'coordinate'>): number => {
    const { finders } = this.state;

    if (finderIndex === undefined) {
      return 0;
    }

    return finders.findIndex(
      (f: Finder | undefined) => f && f.name === finderIndex.name && f.coordinate === finderIndex.coordinate,
    );
  };

  private onChangeSelector = (event: SelectChangeEvent<unknown>): void => {
    const { onChange } = this.props;
    const { finders } = this.state;

    const value = +(event.target.value as string | number);

    onChange(finders[value]);
  };

  private createMenuItem = (index: number, finder?: Finder): React.JSX.Element => {
    const name = finder ? finder.name : '-';
    const coordinate = finder ? finder.coordinate : '';

    return (
      <MenuItem key={`finder-picker-item-${name}-${coordinate}`} value={index}>
        <ListItemText primary={name} secondary={coordinate} />
      </MenuItem>
    );
  };

  public render() {
    const { coordinate, label, disabled, selected } = this.props;
    const { finders, loading, failure } = this.state;

    const entries = finders.map((finder: Finder | undefined, index: number) => this.createMenuItem(index, finder));

    const selectedIndex = selected ? this.getListIndex(selected) : 0;

    return (
      <>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
          >
            <Fade in={true} timeout={2000}>
              <CheckmarkSpinner complete={false} failure={failure} />
            </Fade>
          </div>
        )}
        {!loading && (
          <StyledSelectField
            value={selectedIndex >= 0 ? selectedIndex : 0}
            label={label ?? 'Finder'}
            onChange={this.onChangeSelector}
            disabled={disabled}
            endAdornment={
              <Tooltip title={'reload finder list'}>
                <span>
                  <IconButton
                    disabled={coordinate === ''}
                    aria-label="refresh"
                    onClick={async () => {
                      await this.fetchFinders();
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </span>
              </Tooltip>
            }
          >
            {entries}
          </StyledSelectField>
        )}
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(FinderPickerComponent);

export const FinderPicker = StyleWrapped;
