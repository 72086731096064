import { ClearingUpdateStrategy, CompartmentSelector, Preset, UpdaterProfile, UpdateStrategy } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface UpdaterProfileState {
  name: string;
  coordinate: string;
  updateStrategy?: UpdateStrategy;
  preset?: Preset;
  compartmentSelector?: CompartmentSelector;
  compartmentInputInstructions?: string | null;
  multipleCompartmentSelection: boolean;
  clearingText: string;
  preserveFields: boolean;
  allFilled: boolean;
}

export class UpdaterProfileStore {
  @observable
  public editableUpdaterProfile?: UpdaterProfile;

  @observable
  public state: UpdaterProfileState = {
    name: '',
    coordinate: '',
    updateStrategy: undefined,
    preset: undefined,
    compartmentSelector: undefined,
    compartmentInputInstructions: undefined,
    multipleCompartmentSelection: false,
    clearingText: '',
    preserveFields: true,
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<UpdaterProfileState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled =
        this.state.name !== '' && this.state.coordinate !== '' && this.state.compartmentSelector !== undefined;

      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState({
      name: '',
      coordinate: '',
      updateStrategy: undefined,
      preset: undefined,
      compartmentSelector: undefined,
      compartmentInputInstructions: undefined,
      multipleCompartmentSelection: false,
      clearingText: '',
      preserveFields: true,
      allFilled: false,
    });

    this.editableUpdaterProfile = undefined;
  };

  @action
  public setEditableUpdaterProfile = (updaterProfile?: UpdaterProfile): void => {
    this.editableUpdaterProfile = updaterProfile;

    if (updaterProfile) {
      const {
        name,
        coordinate,
        compartmentInputInstructions,
        compartmentSelector,
        multipleCompartmentSelection,
        updateStrategy,
        preserveFields,
      } = updaterProfile;

      this.setState({
        name,
        coordinate,
        updateStrategy,
        preset: undefined,
        compartmentInputInstructions,
        compartmentSelector,
        multipleCompartmentSelection,
        clearingText:
          updateStrategy.type === 'clearing' ? (updateStrategy as ClearingUpdateStrategy).params.clearingText : '',
        preserveFields,
        allFilled: true,
      });
    }
  };
}
