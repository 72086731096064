import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { ReceivingAreaStore } from './Stores/ReceivingAreaStore';
import { ReceivingAreaManagementContent } from './Components/ReceivingAreaManagementContent';

const receivingAreaStore = new ReceivingAreaStore();

const stores = { receivingAreaStore };

export const ReceivingAreaManagement = (): React.JSX.Element => (
  <Provider {...stores}>
    <Page title="Receiving Areas" isSearchable={true}>
      <ReceivingAreaManagementContent />
    </Page>
  </Provider>
);
