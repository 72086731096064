import {
  NotificationStrategy,
  PanelConfiguration,
  PickerProfile,
  SavePickerProfilePayload,
  StartupStrategy,
  UiConfiguration,
  Zone,
  ZoneIdentifier,
} from '@ekkogmbh/apisdk';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { PickerProfileStore } from '../Stores/PickerProfileStore';
import { PickerProfileConfigurationForm } from './PickerProfileConfigurationForm';
import { PickerProfileUserInterfaceForm } from './PickerProfileUserInterfaceForm';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';

const styles = FormStyles;

interface PickerProfilePanelStores {
  api: ApiStore;
  pickerProfileStore: PickerProfileStore;
  notificationStrategyStore: NotificationStrategyStore;
}

interface PickerProfilePanelState {
  activeStep: number;
  finished: boolean;
  loading: boolean;
}

export interface PickerProfilePanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (zone: ZoneIdentifier, name: string, payload: SavePickerProfilePayload) => Promise<PickerProfile>;
}

const StepTitles = ['Profile', 'User Interface', 'Overview'] as const;
type StepTitle = typeof StepTitles[number];

@inject('api', 'pickerProfileStore', 'notificationStrategyStore')
@observer
class PickerProfilePanelComponent extends React.Component<PickerProfilePanelProps, PickerProfilePanelState> {
  public state: PickerProfilePanelState = {
    activeStep: 0,
    finished: false,
    loading: false,
  };

  get stores(): PickerProfilePanelStores {
    return this.props as PickerProfilePanelProps & PickerProfilePanelStores;
  }

  public handleReset = async () => {
    this.setState({ activeStep: 0, loading: false });
    const { pickerProfileStore, notificationStrategyStore } = this.stores;
    const { editablePickerProfile } = pickerProfileStore;
    pickerProfileStore.resetStore();
    pickerProfileStore.setEditablePickerProfile(editablePickerProfile);

    // init notification strategy store
    const notificationStrategy =
      editablePickerProfile?.notificationStrategy ?? editablePickerProfile?.zone.notificationStrategy;
    notificationStrategyStore.resetStore(notificationStrategy);
  };

  public componentWillUnmount(): void {
    const { pickerProfileStore, notificationStrategyStore } = this.stores;
    pickerProfileStore.resetStore();
    notificationStrategyStore.resetStore();
  }

  public handleSave = async () => {
    const { saveHandler, closeHandler } = this.props;
    const { pickerProfileStore, notificationStrategyStore } = this.stores;
    const { name, zone, startupStrategy, sourcePanel, destinationPanel } = pickerProfileStore.state;

    const payload: SavePickerProfilePayload = {
      startupStrategy: {
        name: startupStrategy!.name,
        config: Object.assign({}, startupStrategy!.config),
      },
      uiConfiguration: { sourcePanel, destinationPanel } as UiConfiguration,
    };

    if (notificationStrategyStore.changed) {
      const notificationStrategy = notificationStrategyStore.getSavableNotificationStrategy();

      if (notificationStrategy.name === NotificationStrategy.NULL) {
        delete payload.notificationStrategy;
      } else {
        payload.notificationStrategy = notificationStrategy;
      }
    }

    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          await saveHandler(zone!, name, payload);
          closeHandler();
        } catch (e) {
          this.setState({ loading: false });
        }
      },
    );
  };

  public isNextDisabled = (step: DynamicStepperStep & { title: StepTitle }): boolean => {
    const { pickerProfileStore } = this.stores;
    const { name, zone, startupStrategy, sourcePanel, destinationPanel } = pickerProfileStore.state;

    switch (step.title) {
      case 'Profile':
        return name === '' || zone === undefined || startupStrategy === undefined;
      case 'User Interface':
        return sourcePanel === undefined || destinationPanel === undefined;
      case 'Overview':
        return true;
    }
  };

  public handleNext = async () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep + 1 });
  };

  public handleBack = async () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep - 1 });
  };

  public getSteps = (): (DynamicStepperStep & { title: StepTitle })[] => [
    {
      title: 'Profile',
      elementCallback: (): React.JSX.Element => <PickerProfileConfigurationForm errorCallback={this.handleReset} />,
    },
    {
      title: 'User Interface',
      elementCallback: (): React.JSX.Element => <PickerProfileUserInterfaceForm errorCallback={this.handleReset} />,
    },
    {
      title: 'Overview',
      elementCallback: (): React.JSX.Element => {
        const { pickerProfileStore } = this.stores;
        const {
          name,
          zone: _zone,
          startupStrategy: _startupStrategy,
          sourcePanel: _sourcePanel,
          destinationPanel: _destinationPanel,
        } = pickerProfileStore.state;

        const zone = _zone as Zone;
        const startupStrategy = _startupStrategy as StartupStrategy;
        const sourcePanel = _sourcePanel as PanelConfiguration;
        const destinationPanel = _destinationPanel as PanelConfiguration;
        const overlineStyle = { fontWeight: 700 };

        return (
          <Grid container spacing={2} alignItems="stretch" style={{ wordBreak: 'break-word' }}>
            <Grid item xs={6}>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Name
              </Typography>
              <Typography variant="h6" gutterBottom>
                {name}
              </Typography>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Zone
              </Typography>
              <Typography variant="h6" gutterBottom>
                {`${zone.name}@${zone.coordinate}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Type
              </Typography>
              <Typography variant="h6" gutterBottom>
                {startupStrategy.name}
              </Typography>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Source Panel Display Mode
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sourcePanel.displayMode}
              </Typography>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Destination Panel Display Mode
              </Typography>
              <Typography variant="h6" gutterBottom>
                {destinationPanel.displayMode}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  public render() {
    const { closeHandler } = this.props;
    const { activeStep, finished, loading } = this.state;
    const steps = this.getSteps();

    const finalStep = steps.length - 1;
    const isNextDisabled = this.isNextDisabled(steps[activeStep]);

    return (
      <Grid container spacing={2} alignContent="stretch">
        <Grid item xs={12}>
          <div style={{ display: loading ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={finished} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          {!loading && <DynamicStepper activeStep={activeStep} steps={steps} />}
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            cancelHandler={closeHandler}
            finished={finished}
            resetHandler={this.handleReset}
            backHandler={this.handleBack}
            nextHandler={this.handleNext}
            saveHandler={this.handleSave}
            isSaveDisabled={activeStep !== finalStep}
            isBackDisabled={activeStep === 0}
            isNextDisabled={isNextDisabled}
            isDeleteDisabled={true}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickerProfilePanelComponent);

export const PickerProfilePanel = StyleWrapped;
