import { Preset } from '@ekkogmbh/apisdk';
import { Typography } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { Permissions } from 'src/Common/Stores/ApiStore';
import {
  PresetManagementContentActionHandlers,
  PresetManagementContentPropsWithStores,
  PresetManagementContentState,
} from './PresetManagementContent';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import Delete from '@mui/icons-material/Delete';
import { DataTableRecord } from 'src/Common/Stores/DataTableStore';
import { Edit } from '@mui/icons-material';

class PresetManagementDataTableActions extends DataTableActionsComponent<DataTableRecord<Preset>> {}

const PresetNameColumn = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: true,
    customBodyRender: (name: string): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const PresetCoordinateColumn = (): MUIDataTableColumnDef => ({
  label: 'Coordinate-Prefix',
  name: 'entry.coordinatePrefix',
  options: {
    sort: true,
    filter: true,
  },
});

const PresetKeysColumn = (): MUIDataTableColumnDef => ({
  label: 'Fields',
  name: 'entry.keys',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (keys: Preset['keys']): React.ReactNode => {
      const noneComponent = (
        <Typography color={'textSecondary'} style={{ fontWeight: 700 }}>
          ―
        </Typography>
      );

      return keys && keys.length > 0 ? (
        <div>
          <Typography display={'inline'} color={'textPrimary'}>
            {keys.join(', ')}
          </Typography>
        </div>
      ) : (
        noneComponent
      );
    },
  },
});

const PresetActionsColumn = (
  _: PresetManagementContentState,
  propsWithStores: PresetManagementContentPropsWithStores,
  actions: PresetManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (preset: Preset): React.ReactNode => {
      const hasWritePermission = propsWithStores.api.userHasPermissionOnAnyNode(Permissions.PRESETS_WRITE);

      const actionButtons: ActionButton<Preset>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return <PresetManagementDataTableActions dataset={preset} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  PresetManagementContentPropsWithStores,
  PresetManagementContentState,
  PresetManagementContentActionHandlers
>> = [PresetNameColumn, PresetCoordinateColumn, PresetKeysColumn, PresetActionsColumn];
