import { ReceivingAreaProfileMapping, SupplyProfile } from '@ekkogmbh/apisdk';
import { Delete, Edit, Stop } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  SupplyProfileManagementContentActionHandlers,
  SupplyProfileManagementContentPropsWithStores,
  SupplyProfileManagementContentState,
} from './SupplyProfileManagementContent';
import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';

class SupplyProfileDataTableActions extends DataTableActionsComponent<SupplyProfile> {}

const Actions = (
  _: SupplyProfileManagementContentState,
  __: SupplyProfileManagementContentPropsWithStores,
  actions: SupplyProfileManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (supplyProfile: SupplyProfile): React.ReactNode => {
      const actionButtons: ActionButton<SupplyProfile>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <SupplyProfileDataTableActions dataset={supplyProfile} isProcessing={false} actionButtons={actionButtons} />
      );
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: SupplyProfile['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: SupplyProfile['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const renderReceivingAreaChip = (mapping: ReceivingAreaProfileMapping) => (
  <Tooltip
    arrow
    title={
      <Stack direction={'row'} spacing={2}>
        <Stack direction={'column'}>
          <Typography fontWeight={700}>States</Typography>
          {mapping.observedStates.map((state: string) => (
            <Stack key={`state-tooltip-${state}`} direction={'row'}>
              <Stop style={{ color: mapping.stateColors[state] }} />
              <Typography>{state}</Typography>
            </Stack>
          ))}
        </Stack>
        <Stack direction={'column'}>
          <Typography fontWeight={700}>Fields</Typography>
          {mapping.observedFields.map((fieldKey: string) => (
            <Typography key={`field-tooltip-${fieldKey}`}>{fieldKey}</Typography>
          ))}
        </Stack>
      </Stack>
    }
  >
    <Chip
      sx={{ height: 'auto' }}
      variant={'outlined'}
      label={
        <Stack>
          <Typography color={'textPrimary'} style={{ fontSize: 16, fontWeight: 700, userSelect: 'none' }}>
            {mapping.receivingArea.name}
          </Typography>
          <Typography color={'textSecondary'} style={{ fontSize: 12, userSelect: 'none' }}>
            {mapping.receivingArea.coordinate}
          </Typography>
        </Stack>
      }
    />
  </Tooltip>
);

const ReceivingAreas = (): MUIDataTableColumnDef => ({
  label: 'Receiving Areas',
  name: 'entry.receivingAreaMappings',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (receivingAreaMappings: SupplyProfile['receivingAreaMappings']): React.ReactNode => (
      <Stack direction={'row'} spacing={1}>
        {receivingAreaMappings.map((mapping) => (
          <Box key={`ra-table-${mapping.receivingArea.coordinate}`}>{renderReceivingAreaChip(mapping)}</Box>
        ))}
      </Stack>
    ),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  SupplyProfileManagementContentPropsWithStores,
  SupplyProfileManagementContentState,
  SupplyProfileManagementContentActionHandlers
>> = [Name, Coordinate, ReceivingAreas, Actions];
