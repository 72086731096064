import { action, observable, set } from 'mobx';

export class RowSelectStore {
  @observable
  public selectedRows: { [key: string]: boolean } = {};

  @observable
  public selectedCount: number = 0;

  @action
  public set(identifier: string, checked: boolean): void {
    const entry = {};

    entry[identifier] = checked;

    set(this.selectedRows, entry);
    this.refreshRowsSelected();
  }

  @action
  public setMultiple(identifiers: string[], checked: boolean): void {
    const entries = {};

    for (const identifier of identifiers) {
      entries[identifier] = checked;
    }

    set(this.selectedRows, entries);
    this.refreshRowsSelected();
  }

  @action
  public reset(): void {
    const identifiers = Object.keys(this.selectedRows);
    const entries = {};

    for (const identifier of identifiers) {
      entries[identifier] = false;
    }

    set(this.selectedRows, entries);
    this.selectedCount = 0;
  }

  @action
  public clear(): void {
    this.selectedRows = {};
    this.selectedCount = 0;
  }

  @action
  public refreshRowsSelected(): void {
    this.selectedCount = 0;

    const keys = Object.keys(this.selectedRows);

    if (keys.length > 0) {
      for (const selectedRowKey of keys) {
        if (this.selectedRows[selectedRowKey]) {
          this.selectedCount++;
        }
      }
    }
  }
}
