import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { Routes } from '../../Routes';

const styles = (_: Theme) => ({
  root: {},
});

interface NoPermissionsContentStores {
  api: ApiStore;
  navigationStore: NavigationStore;
}

const stores = ['api', 'navigationStore'];

interface NoPermissionsContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

@inject(...stores)
class NoPermissionsContentComponent extends Component<NoPermissionsContentProps> {
  get stores(): NoPermissionsContentStores {
    return this.props as NoPermissionsContentProps & NoPermissionsContentStores;
  }

  public componentDidMount(): void {
    const { api, navigationStore } = this.stores;

    const fallbackRoutePath = api.getUserReadPermissionFallbackRoutePath(Routes, navigationStore.menuSorting);

    if (fallbackRoutePath !== null) {
      this.props.history.push(fallbackRoutePath);
    }
  }

  public render() {
    return <div>{/**/}</div>;
  }
}

const RouterWrapped = withRouter<NoPermissionsContentProps, typeof NoPermissionsContentComponent>(
  NoPermissionsContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const NoPermissionsContent = StyleWrapped;
