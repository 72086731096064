import { ZoneStrategy } from '@ekkogmbh/apisdk';
import { ListItemText, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface StrategyPickerStores {
  api: ApiStore;
}

interface StrategyPickerProps extends WithStyles<typeof styles> {
  label: string;
  strategies: ZoneStrategy[];
  selected?: ZoneStrategy['name'];
  onChange: (strategy: ZoneStrategy) => void;
  onError?: () => void;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
}

interface StrategyPickerState {
  loading: boolean;
  failure: boolean;
}

@inject('api')
class StrategyPickerComponent extends Component<StrategyPickerProps, StrategyPickerState> {
  public state: StrategyPickerState = {
    loading: false,
    failure: false,
  };

  get stores(): StrategyPickerStores {
    return this.props as StrategyPickerProps & StrategyPickerStores;
  }

  private getListIndex = (name?: ZoneStrategy['name']): number => {
    const { strategies } = this.props;

    if (name === undefined) {
      return 0;
    }

    return strategies.findIndex((strat: ZoneStrategy) => strat && strat.name === name);
  };

  private onChangeStrategy = (event: SelectChangeEvent<unknown>): void => {
    const { onChange, strategies } = this.props;

    const value = event.target.value as number;

    onChange(strategies[value]);
  };

  private createMenuItem = (index: number, strategy?: ZoneStrategy): React.JSX.Element => {
    const name = strategy ? strategy.name : '-';

    return (
      <MenuItem key={`strat-picker-item-${name}`} value={index}>
        <ListItemText primary={name} />
      </MenuItem>
    );
  };

  public render() {
    const { strategies, label, disabled, selected } = this.props;
    const { loading, failure } = this.state;

    const entries = strategies.map((strategy: ZoneStrategy, index: number) => this.createMenuItem(index, strategy));

    return (
      <StyledSelectField
        value={selected ? this.getListIndex(selected) : 0}
        label={label}
        onChange={this.onChangeStrategy}
        disabled={failure || loading || disabled}
      >
        {entries}
      </StyledSelectField>
    );
  }
}

const StyleWrapped = withStyles(styles)(StrategyPickerComponent);

export const StrategyPicker = StyleWrapped;
