import { action, observable } from 'mobx';
import { Config } from '../../config';

export class ConfigStore {
  public constructor(config: Config) {
    this.config = config;
  }

  @observable
  public config: Config;

  @action
  public setConfig(config: Config): void {
    this.config = config;
  }
}
