import { action, observable } from 'mobx';

// eslint-disable-next-line @typescript-eslint/ban-types
export type DataTableRecord<T = object> = T;
// eslint-disable-next-line @typescript-eslint/ban-types
export type DataTableData<T = object> = DataTableRecord<T>[];

export class DataTableStore {
  @observable
  public loading: boolean = false;

  @observable
  public data: DataTableData = [];

  @action
  public setLoading(loading: boolean): void {
    this.loading = loading;
  }
}
