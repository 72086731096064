import {
  EslManagerPrivateRoute,
  HttpMethod,
  Pagination,
  PaginationResponse,
  SortedSide,
  SortedSideCreatePayload,
  SortedSideUpdatePayload,
  SpatialEntity,
  Template,
} from '@ekkogmbh/apisdk';
import { Grid, MenuItem, Paper, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from '../../Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { request } from '../../Common/Helper/FetchHandler';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { PickingSideStore } from '../Stores/PickingSideStore';
import { PickingStyles } from '../Styles/PickingStyles';
import { materialDatatableColumnDefinitions } from './PickingSideDatatableColumnDefinitions';
import { SortedSidePanel, SortedSidePanelProps } from './SortedSidePanel';
import React from 'react';

const styles = PickingStyles;

const stores = ['api', 'configStore', 'paginationStore', 'searchContentStore', 'navigationStore', 'pickingSideStore'];

export interface PickingSidesContentActionHandlers {
  edit: (sortedSide: SortedSide) => void;
  delete: (sortedSide: SortedSide) => void;
  blink: (sortedSide: SortedSide) => void;
}

export type PickingSidesContentPropsWithStores = PickingSidesContentProps & PickingSidesContentStores;

export interface PickingSidesContentStores {
  api: ApiStore;
  configStore: ConfigStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  pickingSideStore: PickingSideStore;
}

export interface PickingSidesContentActions {
  updateSortedSide?: (sortedSide: SortedSide) => void;
}

export interface PickingSidesContentState {
  editableSortedSide?: SortedSide;
  deleteDialogOpen: boolean;
  blinkDialogOpen: boolean;
  blinkDurationSelected: string;
}

export interface PickingSidesContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

@inject(...stores)
class PickingSidesContentComponent extends Component<PickingSidesContentProps, PickingSidesContentState> {
  public state: PickingSidesContentState = {
    deleteDialogOpen: false,
    blinkDialogOpen: false,
    blinkDurationSelected: '',
  };
  private readonly actions: PickingSidesContentActions = {};
  private readonly filterFields: DataTableFilterFields<SortedSide> = ['name', 'key', 'templates', 'sortingStrategy'];
  private readonly sortFieldMap: DataTableSortFieldMap<SortedSide> = {
    name: 'S.name',
    key: 'S.key',
    templates: 'S.templates',
    sortingStrategy: 'S.sortingStrategy',
  };
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Picking-Side updated.',
    201: 'Picking-Side created.',
    204: 'Picking-Side deleted.',
  };

  get stores(): PickingSidesContentStores {
    return this.props as PickingSidesContentPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    this.setState({
      deleteDialogOpen: false,
      editableSortedSide: undefined,
    });
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
    const { pickingSideStore } = this.stores;
    pickingSideStore.resetStore();
  }

  public blinkSortedSide = async (sortedSide: SortedSide, duration: number): Promise<void> => {
    const { api } = this.stores;

    return request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.blinkSortedSide(sortedSide, duration),
      EslManagerPrivateRoute.BLINK_SORTED_SIDE,
      HttpMethod.POST,
      { 204: 'Blink request successful.' },
    );
  };

  public addSortedSide = async (sortedSide: SortedSideCreatePayload): Promise<SortedSide> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<SortedSide>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addSortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDES,
      HttpMethod.POST,
      this.successStatusCodes,
      successCallback,
    );
  };

  public updateSortedSide = async (sortedSide: SortedSideUpdatePayload): Promise<SortedSide> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<SortedSide>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.updateSortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDE,
      HttpMethod.PUT,
      this.successStatusCodes,
      successCallback,
    );
  };

  public deleteSortedSide = async (sortedSide: SortedSide): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteSortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDE,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public fetchSortedSides = async (pagination: Pagination): Promise<PaginationResponse<SortedSide>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<SortedSide>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSortedSides(pagination),
      EslManagerPrivateRoute.SORTED_SIDES,
      HttpMethod.GET,
    );
  };

  public fetchSortableItems = async (
    nodeValue: string,
    key: string,
    templates: Array<Pick<Template, 'id'>>,
    sortingStrategy?: string,
  ): Promise<SpatialEntity[]> => {
    const { api } = this.stores;
    const templateIds = templates.map((t) => t.id);

    return await request<SpatialEntity[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSpatialEntitiesByNode(nodeValue, key, templateIds, sortingStrategy),
      EslManagerPrivateRoute.SPATIAL_ENTITIES,
      HttpMethod.GET,
    );
  };

  public fetchSortableItemsBySortedSide = async (
    sortedSide: SortedSide | Pick<SortedSide, 'id'>,
  ): Promise<SpatialEntity[]> => {
    const { api } = this.stores;

    return await request<SpatialEntity[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSpatialEntitiesBySortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDE_SPATIAL_ENTITIES,
      HttpMethod.GET,
    );
  };

  public createContentPanels = (): ContentPanelDefinition[] => {
    const { pickingSideStore } = this.stores;
    const { editableSortedSide } = this.state;
    const { addSortedSide, updateSortedSide, fetchSortableItems, fetchSortableItemsBySortedSide } = this;

    const closeCallback = () => {
      this.setState({ editableSortedSide: undefined });
      pickingSideStore.resetStore();
    };

    const addSortedSidePanelDefinition: ContentPanelDefinition<SortedSidePanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: SortedSidePanel,
      panelProps: {
        saveHandler: addSortedSide,
        updateHandler: updateSortedSide,
        closeCallback,
        fetchSortableItems,
        fetchSortableItemsBySortedSide,
        sortedSide: editableSortedSide,
      },
      permission: Permissions.PICKING_AREAS_WRITE,
      toggleOffCallback: closeCallback,
      expandHandler: (expandCallback: () => void) => {
        const { actions } = this;

        if (actions.updateSortedSide === undefined) {
          actions.updateSortedSide = (sortedSide: SortedSide) => {
            this.setState({ editableSortedSide: sortedSide });
            expandCallback();
          };
        }
      },
    };

    return [addSortedSidePanelDefinition];
  };

  public openEditPanel = async (sortedSide: SortedSide): Promise<void> => {
    const { updateSortedSide } = this.actions;

    if (updateSortedSide) {
      updateSortedSide(sortedSide);
    }
  };

  public openDeleteDialog = async (sortedSide: SortedSide): Promise<void> => {
    this.setState({
      editableSortedSide: sortedSide,
      deleteDialogOpen: true,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      editableSortedSide: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { editableSortedSide } = this.state;

    if (!editableSortedSide || !editableSortedSide.id) {
      return;
    }

    await this.deleteSortedSide(editableSortedSide);

    this.setState({
      editableSortedSide: undefined,
      deleteDialogOpen: false,
    });
  };

  public openBlinkDialog = async (sortedSide: SortedSide): Promise<void> => {
    this.setState({
      editableSortedSide: sortedSide,
      blinkDialogOpen: true,
      blinkDurationSelected: '300',
    });
  };

  public onBlinkDismiss = async () => {
    this.setState({
      editableSortedSide: undefined,
      blinkDialogOpen: false,
    });
  };

  public onBlinkConfirm = async () => {
    const { editableSortedSide, blinkDurationSelected } = this.state;

    if (editableSortedSide) {
      // Do not wait for the request to finish before closing the dialog
      this.blinkSortedSide(editableSortedSide, Number(blinkDurationSelected));

      this.setState({
        editableSortedSide: undefined,
        blinkDialogOpen: false,
      });
    }
  };

  public handleChange = (name: string) => ({ target: { value } }: SelectChangeEvent<unknown>) => {
    if (name === 'blink') {
      this.setState({
        blinkDurationSelected: value as string,
      });
    }
  };

  public render() {
    const { editableSortedSide, deleteDialogOpen, blinkDialogOpen, blinkDurationSelected } = this.state;
    const { classes } = this.props;
    const { fetchSortedSides } = this;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PickingSidesContentPropsWithStores, {
        edit: this.openEditPanel,
        delete: this.openDeleteDialog,
        blink: this.openBlinkDialog,
      }),
    );

    const contentPanels = this.createContentPanels();

    const deleteDialogText =
      deleteDialogOpen && editableSortedSide ? (
        <React.Fragment>
          <div>
            Delete Picking-Side: <span className={classes.boldFont}>{editableSortedSide.name}</span>
          </div>
          <div>
            on Node: <span className={classes.boldFont}>{editableSortedSide.node.parts.join(NodeSeparator)}</span>?
          </div>
        </React.Fragment>
      ) : (
        ''
      );

    return (
      <Grid item xs={12}>
        {deleteDialogOpen && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={deleteDialogOpen}
            title={'Delete Picking-Side'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        {blinkDialogOpen && editableSortedSide && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            minHeight={170}
            centered={true}
            open={blinkDialogOpen}
            title={'Blink ' + editableSortedSide.name}
            text={
              <StyledSelectField value={blinkDurationSelected} onChange={this.handleChange('blink')} label={'Duration'}>
                <MenuItem value={0}>stop</MenuItem>
                <MenuItem value={60}>1 minute</MenuItem>
                <MenuItem value={300}>5 minutes</MenuItem>
                <MenuItem value={600}>10 minutes</MenuItem>
              </StyledSelectField>
            }
            onClose={this.onBlinkDismiss}
            onConfirm={this.onBlinkConfirm}
          />
        )}

        <ContentPanels panels={contentPanels} />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={fetchSortedSides}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<PickingSidesContentProps, typeof PickingSidesContentComponent>(
  PickingSidesContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PickingSidesContent = StyleWrapped;
