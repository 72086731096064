import { Side, ZoneStrategy } from '@ekkogmbh/apisdk';

interface StrategyInfo {
  label: string;
  field: string;
}

type mergeStrategy = 'distribute' | 'multiplex';
export const MergeStrategies: Record<mergeStrategy, StrategyInfo> = {
  distribute: {
    label: 'Distribute by',
    field: 'distributeBy',
  },
  multiplex: {
    label: 'Multiplex by',
    field: 'multiplexBy',
  },
};

export const invalidMergeConfig = (strategy: ZoneStrategy): boolean =>
  Object.keys(MergeStrategies).includes(strategy.name) &&
  !Object.values(Side)
    .map((s) => s.toString())
    .includes(strategy.configuration[MergeStrategies[strategy.name].field] as string);
