import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { RoleManagementContent } from './Components/RoleManagementContent';

export const RoleManagement = (): React.JSX.Element => (
  <Provider>
    <Page title="Roles" isSearchable={false}>
      <RoleManagementContent />
    </Page>
  </Provider>
);
