import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../../Common/Helper/ThemeHelper';
import { ModulesBaseStyles } from '../../Common/Styles/ModulesBaseStyles';

export const CompartmentStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  ...ModulesBaseStyles(theme),
  // module specific css here
  button: {
    flexGrow: 1,
    margin: spacing(theme),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: spacing(theme),
    padding: spacing(theme),
  },
  exportIndicator: {
    display: 'none',
  },
  exportFlexContainer: {
    display: 'block',
  },
  dataTableColumnFields: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowY: 'hidden',
    overflowX: 'auto',
    width: 660,
    flexWrap: 'nowrap',
  },
  dataTableColumnLinks: {
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    overflowX: 'auto',
    width: 360,
  },
  '@media (max-width: 1880px)': {
    dataTableColumnFields: {
      width: 640,
    },
  },
  '@media (max-width: 1780px)': {
    dataTableColumnFields: {
      width: 570,
    },
  },
  '@media (max-width: 1680px)': {
    dataTableColumnFields: {
      width: 520,
    },
  },
  '@media (max-width: 1580px)': {
    dataTableColumnFields: {
      width: 450,
    },
  },
  '@media (max-width: 1480px)': {
    dataTableColumnFields: {
      width: 400,
    },
  },
  '@media (max-width: 1260px)': {
    dataTableColumnFields: {
      width: 350,
    },
    dataTableColumnLinks: {
      width: 200,
    },
  },
});
