import { ReplenishmentPlan } from '@ekkogmbh/apisdk';
import { Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  ReplenishmentPlanManagementContentActionHandlers,
  ReplenishmentPlanManagementContentPropsWithStores,
  ReplenishmentPlanManagementContentState,
} from './ReplenishmentPlanManagementContent';

class ReplenishmentPlanDataTableActions extends DataTableActionsComponent<ReplenishmentPlan> {}

const Actions = (
  _: ReplenishmentPlanManagementContentState,
  __: ReplenishmentPlanManagementContentPropsWithStores,
  actions: ReplenishmentPlanManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (replenishmentPlan: ReplenishmentPlan): React.ReactNode => {
      const actionButtons: ActionButton<ReplenishmentPlan>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <ReplenishmentPlanDataTableActions
          dataset={replenishmentPlan}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: ReplenishmentPlan['name']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{name}</div>
    ),
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: ReplenishmentPlan['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  ReplenishmentPlanManagementContentPropsWithStores,
  ReplenishmentPlanManagementContentState,
  ReplenishmentPlanManagementContentActionHandlers
>> = [Name, Coordinate, Actions];
