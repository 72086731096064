import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { ReceivingAreaStore } from '../Stores/ReceivingAreaStore';
import { WithStyles, withStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { Grid, ListItemText, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CoordinateInput } from 'src/Common/Components/CoordinateInput';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { EslManagerPublicRouteV1, HttpMethod, PaginationResponse, ReplenishmentPlan } from '@ekkogmbh/apisdk';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';

const styles = FormStyles;

interface ReceivingAreaPanelStores {
  api: ApiStore;
  receivingAreaStore: ReceivingAreaStore;
}

interface ReceivingAreaPanelState {
  loading: boolean;
  selectableReplenishmentPlans: ReplenishmentPlan[];
}

export interface ReceivingAreaPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: () => Promise<void>;
}

@inject('api', 'receivingAreaStore')
@observer
class ReceivingAreaPanelComponent extends React.Component<ReceivingAreaPanelProps, ReceivingAreaPanelState> {
  public state: ReceivingAreaPanelState = {
    loading: true,
    selectableReplenishmentPlans: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): ReceivingAreaPanelStores {
    return this.props as ReceivingAreaPanelProps & ReceivingAreaPanelStores;
  }

  public async componentDidMount(): Promise<void> {
    const { receivingAreaStore } = this.stores;
    const { editableReceivingArea } = receivingAreaStore;

    if (editableReceivingArea !== undefined) {
      receivingAreaStore.resetStore(editableReceivingArea);
    }

    await this.fetchReplenishmentPlans();
  }

  public componentWillUnmount(): void {
    const { receivingAreaStore } = this.stores;
    receivingAreaStore.resetStore();
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchReplenishmentPlans = async (): Promise<void> => {
    const { closeHandler } = this.props;
    const { api, receivingAreaStore } = this.stores;

    const replenishmentPlanPagination = await request<PaginationResponse<ReplenishmentPlan>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getReplenishmentPlans({ page: 1, limit: 999 }),
      EslManagerPublicRouteV1.RECEIVING_AREAS,
      HttpMethod.GET,
      undefined,
      undefined,
      closeHandler,
    );

    if (replenishmentPlanPagination.items !== undefined) {
      this.setState({ loading: false, selectableReplenishmentPlans: replenishmentPlanPagination.items }, () => {
        if (receivingAreaStore.editableReceivingArea !== undefined && replenishmentPlanPagination.items?.length === 1) {
          receivingAreaStore.setState({ replenishmentPlan: replenishmentPlanPagination.items[0] });
        }
      });
    }
  };

  public handleReset = async () => {
    const { receivingAreaStore } = this.stores;
    const { editableReceivingArea } = receivingAreaStore;
    receivingAreaStore.resetStore(editableReceivingArea);
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;

    this.setState({ loading: true }, async () => {
      await saveHandler();
      this.handleReset();
      closeHandler();
    });
  };

  public getSelectedReplenishmentPlanIndex = () => {
    const { receivingAreaStore } = this.stores;
    const { replenishmentPlan } = receivingAreaStore.state;
    const { selectableReplenishmentPlans } = this.state;

    if (replenishmentPlan !== undefined) {
      return selectableReplenishmentPlans.findIndex(
        (value) => value.name === replenishmentPlan.name && value.coordinate === replenishmentPlan?.coordinate,
      );
    } else {
      return '';
    }
  };

  public onSelectReplenishmentPlan = (event: SelectChangeEvent<unknown>) => {
    const { receivingAreaStore } = this.stores;
    const { selectableReplenishmentPlans } = this.state;

    if (event?.target?.value != undefined) {
      receivingAreaStore.setState({ replenishmentPlan: selectableReplenishmentPlans[event.target.value as number] });
    }
  };

  public render() {
    const { loading, selectableReplenishmentPlans } = this.state;
    const { receivingAreaStore } = this.stores;
    const { name, coordinate, allFilled } = receivingAreaStore.state;
    const { closeHandler } = this.props;

    const isEditMode = receivingAreaStore.editableReceivingArea !== undefined;
    const hasPlans = selectableReplenishmentPlans.length > 0;
    const selectedPlanIndex = this.getSelectedReplenishmentPlanIndex();

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <div style={{ display: loading ? 'block' : 'none' }}>
          <Grid
            item
            xs={12}
            style={{
              height: 496,
              position: 'relative',
            }}
          >
            <div
              style={{
                top: '50%',
                marginTop: -48,
                position: 'absolute',
                width: '100%',
              }}
            >
              <CheckmarkSpinner complete={false} failure={false} />
            </div>
          </Grid>
        </div>

        {!loading && (
          <>
            <Grid item container mt={0} spacing={1} alignContent={'stretch'}>
              <Grid item xs={6}>
                <Stack spacing={1}>
                  <StyledTextField
                    type={'text'}
                    label={'Name'}
                    value={name}
                    disabled={isEditMode}
                    onChange={(e) => receivingAreaStore.setState({ name: e.target.value })}
                  />
                  <CoordinateInput
                    value={coordinate}
                    disabled={isEditMode}
                    onChange={(coordinate) => receivingAreaStore.setState({ coordinate })}
                    trailingDelimiter={false}
                  />
                  <div>
                    <StyledSelectField
                      disabled={!hasPlans}
                      onChange={this.onSelectReplenishmentPlan}
                      value={selectedPlanIndex}
                      label={'Replenishment Plan'}
                    >
                      {selectableReplenishmentPlans.map((plan: ReplenishmentPlan, index: number) => (
                        <MenuItem key={index} value={index}>
                          <ListItemText primary={plan.coordinate} secondary={plan.name} />
                        </MenuItem>
                      ))}
                    </StyledSelectField>
                  </div>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormPanelButtons
                cancelHandler={closeHandler}
                saveHandler={this.handleSave}
                resetHandler={this.handleReset}
                isDeleteHidden={true}
                isSaveDisabled={!allFilled}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(ReceivingAreaPanelComponent);

export const ReceivingAreaPanel = StyleWrapped;
