import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { CsvDropzoneStore } from '../Common/Stores/CsvDropzoneStore';
import { LabelManagementContent } from './Components/LabelManagementContent';

const csvDropzoneStore = new CsvDropzoneStore();

const stores = {
  csvDropzoneStore,
};

export const LabelManagement = (): React.JSX.Element => (
  <Provider {...stores}>
    <Page title="Labels" isSearchable={true}>
      <LabelManagementContent />
    </Page>
  </Provider>
);
