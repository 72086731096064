import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, UpdaterProfile } from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { CompartmentGeneratorStore } from '../../CompartmentManagement/Stores/CompartmentGeneratorStore';
import { UpdaterProfileStore } from '../Stores/UpdaterProfileStore';
import { UpdaterProfileManagementStyles } from '../Styles/UpdaterProfileManagementStyles';
import { materialDatatableColumnDefinitions } from './UpdaterProfileDatatableColumnDefinitions';
import { UpdaterProfilePanel, UpdaterProfilePanelProps } from './UpdaterProfilePanel';

const styles = UpdaterProfileManagementStyles;

const stores = [
  'api',
  'updaterProfileStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
  'compartmentGeneratorStore',
];

interface UpdaterProfileManagementContentActions {
  updateUpdaterProfile?: (updaterProfile: UpdaterProfile) => void;
}

export interface UpdaterProfileManagementContentActionHandlers {
  edit: (updaterProfile: UpdaterProfile) => void;
  delete: (updaterProfile: UpdaterProfile) => void;
}

export interface UpdaterProfileManagementContentStores {
  api: ApiStore;
  updaterProfileStore: UpdaterProfileStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  compartmentGeneratorStore: CompartmentGeneratorStore;
}

export interface UpdaterProfileManagementContentState {
  deletableUpdaterProfile?: UpdaterProfile;
}

export interface UpdaterProfileManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type UpdaterProfileManagementContentPropsWithStores = UpdaterProfileManagementContentProps &
  UpdaterProfileManagementContentStores;

@inject(...stores)
class UpdaterProfileManagementContentComponent extends Component<
  UpdaterProfileManagementContentProps,
  UpdaterProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<UpdaterProfile> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<UpdaterProfile> = {
    name: 'UP.name',
  };
  private readonly actions: UpdaterProfileManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): UpdaterProfileManagementContentStores {
    return this.props as UpdaterProfileManagementContentPropsWithStores;
  }

  public state: UpdaterProfileManagementContentState = {
    deletableUpdaterProfile: undefined,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchUpdaterProfiles = async (pagination: Pagination): Promise<PaginationResponse<UpdaterProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<UpdaterProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getUpdaterProfiles(pagination),
      EslManagerPublicRouteV1.UPDATER_PROFILES,
      HttpMethod.GET,
    );
  };

  public onDelete = async (updaterProfile: UpdaterProfile): Promise<void> => {
    this.setState({
      deletableUpdaterProfile: updaterProfile,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      deletableUpdaterProfile: undefined,
    });
  };

  public onDeleteOk = async () => {
    const { deletableUpdaterProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableUpdaterProfile) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteUpdaterProfile(deletableUpdaterProfile.coordinate, deletableUpdaterProfile.name),
      EslManagerPublicRouteV1.UPDATER_PROFILE,
      HttpMethod.DELETE,
      { 200: 'UpdaterProfile deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (updaterProfile: UpdaterProfile): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateUpdaterProfile } = this.actions;

    if (updateUpdaterProfile) {
      updateUpdaterProfile(updaterProfile);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (updaterProfilePayload: UpdaterProfile): Promise<UpdaterProfile> => {
    const { api, navigationStore, searchContentStore, updaterProfileStore } = this.stores;
    const { editableUpdaterProfile } = updaterProfileStore;

    const doOverwrite = editableUpdaterProfile !== undefined;

    const updaterProfile = await request<UpdaterProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveUpdaterProfile(updaterProfilePayload, doOverwrite),
      EslManagerPublicRouteV1.UPDATER_PROFILE,
      HttpMethod.PUT,
      { 200: 'UpdaterProfile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return updaterProfile;
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { updaterProfileStore } = this.stores;

    const addUpdaterProfilePanelDefinition: ContentPanelDefinition<UpdaterProfilePanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: UpdaterProfilePanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.UPDATER_PROFILE_WRITE,
      toggleOffCallback: () => updaterProfileStore.resetStore(),
      expandHandler: (expandCallback: () => void) => {
        const { updateUpdaterProfile } = this.actions;
        if (updateUpdaterProfile === undefined) {
          this.actions.updateUpdaterProfile = async (updaterProfile: UpdaterProfile) => {
            expandCallback();
            updaterProfileStore.setEditableUpdaterProfile(updaterProfile);
          };
        }
      },
    };

    return [addUpdaterProfilePanelDefinition];
  };

  public render() {
    const { deletableUpdaterProfile } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as UpdaterProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />

        {deletableUpdaterProfile && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableUpdaterProfile}
            title={'Delete Updater Profile'}
            text={deletableUpdaterProfile.name}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchUpdaterProfiles}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<UpdaterProfileManagementContentProps, typeof UpdaterProfileManagementContentComponent>(
  UpdaterProfileManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const UpdaterProfileManagementContent = StyleWrapped;
