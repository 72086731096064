import {
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  PickerProfile,
  SavePickerProfilePayload,
  ZoneIdentifier,
} from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { PickerProfileStore } from '../Stores/PickerProfileStore';
import { PickerProfileManagementStyles } from '../Styles/PickerProfileManagementStyles';
import { materialDatatableColumnDefinitions } from './PickerProfileDatatableColumnDefinitions';
import { PickerProfilePanel, PickerProfilePanelProps } from './PickerProfilePanel';

const styles = PickerProfileManagementStyles;

const stores = ['api', 'pickerProfileStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

interface PickerProfileManagementContentActions {
  updatePickerProfile?: (pickerProfile: PickerProfile) => void;
}

export interface PickerProfileManagementContentActionHandlers {
  edit: (pickerProfile: PickerProfile) => void;
  delete: (pickerProfile: PickerProfile) => void;
}

export interface PickerProfileManagementContentStores {
  api: ApiStore;
  pickerProfileStore: PickerProfileStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface PickerProfileManagementContentState {
  deletablePickerProfile?: PickerProfile;
}

export interface PickerProfileManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type PickerProfileManagementContentPropsWithStores = PickerProfileManagementContentProps &
  PickerProfileManagementContentStores;

@inject(...stores)
class PickerProfileManagementContentComponent extends Component<
  PickerProfileManagementContentProps,
  PickerProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<PickerProfile> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<PickerProfile> = {
    name: 'PP.name',
  };
  private readonly actions: PickerProfileManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): PickerProfileManagementContentStores {
    return this.props as PickerProfileManagementContentPropsWithStores;
  }

  public state: PickerProfileManagementContentState = {
    deletablePickerProfile: undefined,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchPickerProfiles = async (pagination: Pagination): Promise<PaginationResponse<PickerProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<PickerProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPickerProfiles(pagination),
      EslManagerPublicRouteV2.PICKER_PROFILES,
      HttpMethod.GET,
    );
  };

  public onDelete = async (pickerProfile: PickerProfile): Promise<void> => {
    this.setState({
      deletablePickerProfile: pickerProfile,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      deletablePickerProfile: undefined,
    });
  };

  public onDeleteOk = async () => {
    const { deletablePickerProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletablePickerProfile) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deletePickerProfile(deletablePickerProfile),
      EslManagerPublicRouteV2.PICKER_PROFILE,
      HttpMethod.DELETE,
      { 200: 'PickerProfile deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (pickerProfile: PickerProfile): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updatePickerProfile } = this.actions;

    if (updatePickerProfile) {
      updatePickerProfile(pickerProfile);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (
    zone: ZoneIdentifier,
    name: string,
    payload: SavePickerProfilePayload,
  ): Promise<PickerProfile> => {
    const { api, navigationStore, searchContentStore, pickerProfileStore } = this.stores;
    const { editablePickerProfile } = pickerProfileStore;

    const doOverwrite = editablePickerProfile !== undefined;
    const pickerProfile = await request<PickerProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.savePickerProfile(zone, name, payload, doOverwrite),
      EslManagerPublicRouteV2.PICKER_PROFILE,
      HttpMethod.PUT,
      { 200: 'PickerProfile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return pickerProfile;
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { pickerProfileStore } = this.stores;

    const addPickerProfilePanelDefinition: ContentPanelDefinition<PickerProfilePanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: PickerProfilePanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.PICKER_PROFILES_WRITE,
      toggleOffCallback: () => pickerProfileStore.resetStore(),
      expandHandler: (expandCallback: () => void) => {
        const { updatePickerProfile } = this.actions;
        if (updatePickerProfile === undefined) {
          this.actions.updatePickerProfile = async (pickerProfile: PickerProfile) => {
            expandCallback();
            pickerProfileStore.setEditablePickerProfile(pickerProfile);
          };
        }
      },
    };

    return [addPickerProfilePanelDefinition];
  };

  public render() {
    const { deletablePickerProfile } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PickerProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />

        {deletablePickerProfile && (
          <GenericDialog
            type="confirmation"
            maxWidth="sm"
            fullWidth={true}
            centered={true}
            open={!!deletablePickerProfile}
            title="Delete Picker Profile"
            text={deletablePickerProfile.name}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchPickerProfiles}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<PickerProfileManagementContentProps, typeof PickerProfileManagementContentComponent>(
  PickerProfileManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PickerProfileManagementContent = StyleWrapped;
