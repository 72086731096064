import {
  CompartmentFields,
  CompartmentSavePayload,
  CompartmentView,
  EslManagerPrivateRoute,
  HttpMethod,
  LinkView,
  PaginationResponse,
  Preset,
  RendererPayload,
  RendererResult,
  Template,
} from '@ekkogmbh/apisdk';
import { Delete } from '@mui/icons-material';
import { Fade, Grid, Hidden, IconButton, InputAdornment, SelectChangeEvent } from '@mui/material';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { TemplatePreviewList } from 'src/TemplateManagement/Components/TemplatePreviewList';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { FormPanelButtons, PanelAction } from '../../Common/Components/FormPanelButtons';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { request } from '../../Common/Helper/FetchHandler';
import { onKeyPressCallback } from '../../Common/Helper/FormHelper';
import { CancelableFetchPromises, cancelFetchPromises, noop } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { TaskCollectionProgressCallback } from '../../Common/Stores/TaskCollectionStore';
import { FormStyles } from '../../Common/Styles/FormStyles';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'configStore'];

type SelectablePreset = Pick<Preset, 'id' | 'coordinatePrefix'> & { fields: string[] };

interface CompartmentPanelStores {
  api: ApiStore;
  configStore: ConfigStore;
}

interface CompartmentPanelState {
  action: PanelAction;
  fields: CompartmentFields;
  coordinate: string;
  fieldsPreset: string;
  presets: Record<string, SelectablePreset>;
  changed: boolean;
  changedPreset: boolean;
  loading: boolean;
  deleteDialogOpen: boolean;
  selectedPrefix: string;
  templates: Template[];
}

export interface CompartmentPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (compartment: CompartmentSavePayload) => Promise<void>;
  deleteHandler: (compartment: CompartmentView, progressCallback?: TaskCollectionProgressCallback) => Promise<void>;
  compartment?: CompartmentView;
}

@inject(...stores)
class CompartmentPanelComponent extends Component<CompartmentPanelProps, CompartmentPanelState> {
  public state: CompartmentPanelState = {
    action: PanelAction.CREATE,
    fields: {},
    coordinate: '',
    fieldsPreset: '',
    presets: {},
    changed: false,
    changedPreset: false,
    loading: false,
    deleteDialogOpen: false,
    selectedPrefix: '',
    templates: [],
  };
  private fetchPromises: CancelableFetchPromises = {};
  private tabIndex: number = 1;
  private progressCompleteCallback: () => void;

  constructor(props: CompartmentPanelProps) {
    super(props);

    const { compartment } = this.props;

    if (compartment !== undefined) {
      const action = PanelAction.EDIT;
      const fields = { ...compartment.fields };
      const value = compartment.coordinate;
      const templates: Template[] = [];

      compartment.links.forEach((link: LinkView) => {
        const { template } = link;
        templates.push(template);
      });

      this.state = {
        ...this.state,
        action,
        fields,
        coordinate: value,
        templates,
      };
    } else {
      const action = PanelAction.CREATE;

      this.state = {
        ...this.state,
        action,
      };
    }

    this.progressCompleteCallback = noop;
  }

  get stores(): CompartmentPanelStores {
    return this.props as CompartmentPanelProps & CompartmentPanelStores;
  }

  public static createPresetsObject(presets: Preset[]): Record<string, SelectablePreset> {
    return presets.reduce((acc: Record<string, SelectablePreset>, { id, name, coordinatePrefix, keys }: Preset) => {
      acc[name] = {
        id,
        coordinatePrefix,
        fields: keys ?? [],
      };
      return acc;
    }, {});
  }

  public async componentDidMount(): Promise<void> {
    const { action } = this.state;
    let { fields } = this.state;

    this.setState(
      {
        loading: true,
      },
      async () => {
        const userPresets = (await this.fetchPresets()).items ?? [];
        const presets = CompartmentPanelComponent.createPresetsObject(userPresets);

        fields =
          userPresets.length === 1 && action === PanelAction.CREATE
            ? presets[Object.keys(presets)[0]].fields.reduce(this.presetFieldsReducer(fields), {})
            : fields;

        this.setState({
          presets,
          fieldsPreset: userPresets.length === 1 ? Object.keys(presets)[0] : '',
          fields,
          selectedPrefix: userPresets.length === 1 ? presets[Object.keys(presets)[0]].coordinatePrefix : '',
          loading: false,
        });
      },
    );
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
    this.progressCompleteCallback = noop;
  }

  // from: interface Component<P = {}, S = {}, SS = any> extends ComponentLifecycle<P, S, SS> { }
  public componentDidUpdate(
    prevProps: Readonly<CompartmentPanelProps>,
    _: Readonly<CompartmentPanelState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __: any,
  ): void {
    const newCompartment = this.props.compartment;
    const { compartment } = prevProps;

    // this is just for a visual effect when the compartment to edit has changed
    const c1 = JSON.stringify(compartment);
    const c2 = JSON.stringify(newCompartment);

    if (c1 !== c2) {
      this.setState({ fields: {} }, () => this.updateState(newCompartment));
    }
  }

  public fetchPresets = async (): Promise<PaginationResponse<Preset>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Preset>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPresets({ page: 1, limit: 1000 }),
      EslManagerPrivateRoute.PRESETS,
      HttpMethod.GET,
    );
  };

  public fetchRendererResult = async (payload: RendererPayload): Promise<RendererResult> => {
    const { api } = this.stores;

    return await request<RendererResult>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.renderTemplate(payload),
      EslManagerPrivateRoute.RENDERER,
      HttpMethod.POST,
    );
  };

  public fetchTemplateWithData = async (template: Template): Promise<Template> => {
    const { api } = this.stores;

    return await request<Template>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getTemplate(template),
      EslManagerPrivateRoute.TEMPLATE,
      HttpMethod.GET,
    );
  };

  public resetState = () => {
    const { compartment } = this.props;
    this.updateState(compartment);
  };

  public onChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { fields } = this.state;

    fields[name] = value;

    this.setState({
      fields,
      changed: true,
    });
  };

  public onChangeFieldsPreset = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;

    const stringValue = value as string;

    const { compartment } = this.props;

    const { action, presets } = this.state;

    let { changed, changedPreset } = this.state;

    const fields = action === PanelAction.EDIT && compartment !== undefined ? { ...compartment.fields } : {};

    if (action === PanelAction.EDIT && compartment !== undefined) {
      changed = true;
      changedPreset = true;
    }

    const selectedPrefix = presets[stringValue].coordinatePrefix;

    this.setState({
      changed,
      changedPreset,
      selectedPrefix,
      fieldsPreset: stringValue,
      fields:
        presets[stringValue] !== undefined
          ? presets[stringValue].fields.reduce(this.presetFieldsReducer(fields), {})
          : {},
    });
  };

  public presetFieldsReducer = (currentFields: CompartmentFields) => (
    fieldSet: CompartmentFields,
    fieldKey: string,
  ): CompartmentFields => {
    if (currentFields[fieldKey] !== undefined) {
      fieldSet[fieldKey] = currentFields[fieldKey];
    } else {
      fieldSet[fieldKey] = '';
    }

    return fieldSet;
  };

  public onDeleteField = (event: MouseEvent<HTMLDivElement>) => {
    const { fieldName } = event.currentTarget.dataset;
    const { fields } = this.state;

    if (fieldName !== undefined) {
      delete fields[fieldName];
    }

    this.setState({
      fields,
      changed: true,
    });
  };

  public onSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { action, coordinate, fields, fieldsPreset, presets, selectedPrefix } = this.state;

    let compartment = {
      coordinate,
      fields,
    };

    if (action !== PanelAction.EDIT && fieldsPreset && presets[fieldsPreset]) {
      compartment = {
        coordinate: selectedPrefix + coordinate,
        fields,
      };
    }

    // TODO: associated task collection should be handled (but possibly not here)
    // const progressCallback = (_: string, __: string, progress?: number) => {
    //   if (progress !== undefined && progress >= 100) {
    //     this.progressCompleteCallback();
    //   }
    // };

    this.progressCompleteCallback = () => {
      this.setState({ loading: false });
      closeHandler();
    };

    this.setState({ loading: true });

    try {
      await saveHandler(compartment);
      this.progressCompleteCallback();
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  public onDelete = () => {
    this.setState({ deleteDialogOpen: true });
  };

  public onDeleteDismiss = () => {
    this.setState({ deleteDialogOpen: false });
  };

  public onDeleteOk = () => {
    const { compartment, closeHandler, deleteHandler } = this.props;

    this.setState({
      loading: true,
      deleteDialogOpen: false,
    });

    const progressCallback = (_: string, __: string, progress?: number) => {
      if (progress === 100) {
        // @TODO prevent set state when unmounted
        this.setState({ loading: false });
        closeHandler();
      }
    };

    deleteHandler(compartment!, progressCallback);
  };

  public getTabIndex = (): number => this.tabIndex++;

  public textField(label: string, value: string): React.JSX.Element {
    const { classes } = this.props;

    return (
      <Grid item lg={6} xs={12} key={label}>
        <Fade in={true} timeout={fadeTimeout}>
          <TextField
            className={classes.margin}
            variant="outlined"
            id={`comp-panel-field-${label}`}
            label={label}
            name={label}
            value={value}
            onChange={this.onChangeField}
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focused,
              },
            }}
            InputProps={{
              inputProps: { tabIndex: this.getTabIndex() },
              classes: {
                root: classes.outlinedInput,
                focused: classes.focused,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <Hidden xsUp xlDown>
                  <InputAdornment variant="filled" position="end">
                    {/* // after update the onClick type is not clearly defined */}
                    <IconButton
                      color="secondary"
                      data-field-name={label}
                      onClick={this.onDeleteField as never}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                </Hidden>
              ),
            }}
          />
        </Fade>
      </Grid>
    );
  }

  public render() {
    const { classes } = this.props;
    const {
      action,
      fields,
      fieldsPreset,
      presets,
      coordinate,
      changed,
      loading,
      deleteDialogOpen,
      templates,
    } = this.state;

    const { configStore } = this.stores;
    const { coordinateFieldName, labelIdFieldName } = configStore.config;

    const fieldKeys = Object.keys(fields);

    const deleteDialogText = coordinate ? `Delete Compartment with Coordinate ${coordinate}?` : '';

    const options = Object.keys(presets).map((preset: string, index: number) => (
      <option key={index} value={preset}>
        {preset}
      </option>
    ));

    if (fieldsPreset === '' || fieldsPreset === null || fieldsPreset === undefined) {
      options.unshift(<option key={-1} value="" />);
    }

    const coordinatePrefix = presets[fieldsPreset] !== undefined ? presets[fieldsPreset].coordinatePrefix : '';
    const saveCondition = changed && coordinate !== '';
    const handleKeyPress = onKeyPressCallback(this.onSave, saveCondition, 'Enter');
    const uniqueTemplates = templates.filter(
      (value, index, self) =>
        index === self.findIndex((template) => template.id === value.id && template.name === value.name),
    );

    return (
      <Grid container spacing={2} alignItems={'stretch'} onKeyPress={handleKeyPress}>
        {action === PanelAction.EDIT && deleteDialogOpen && (
          <GenericDialog
            type="confirmation"
            open={deleteDialogOpen}
            title={'Delete Link'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
            timedOkButton={true}
          />
        )}

        {loading && <LoadingMask />}

        <Grid item lg={8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <StyledSelectField
              native
              disabled={Object.keys(presets).length < 2}
              value={fieldsPreset}
              onChange={this.onChangeFieldsPreset}
              label="Preset"
            >
              {options}
            </StyledSelectField>
          </Fade>
        </Grid>

        {spacer(4)}

        {action !== PanelAction.EDIT && (
          <Grid item lg={4} xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <TextField
                variant={'outlined'}
                disabled={true}
                title={'Coordinate-Prefix'}
                label={'Coordinate-Prefix'}
                value={coordinatePrefix}
                className={classes.margin}
              />
            </Fade>
          </Grid>
        )}

        <Grid item lg={action !== PanelAction.EDIT ? 4 : 8} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <CoordinateInput
              disabled={action === PanelAction.EDIT}
              onChange={(coordinate) => this.setState({ coordinate, changed: true })}
              value={coordinate}
              trimPrefix={coordinatePrefix}
              trailingDelimiter="both"
            />
          </Fade>
        </Grid>

        {spacer(action !== PanelAction.EDIT ? 4 : 8)}

        <Grid container item lg={8} xs={12} alignContent={'flex-start'}>
          <Grid item lg={12} xs={12}>
            <Typography className={classes.instructions}>Fields:</Typography>
          </Grid>

          <Hidden lgDown>
            <Grid item lg={4}>
              <Hidden xsUp xlDown>
                <Typography className={classes.instructions}>Add Field:</Typography>
              </Hidden>
            </Grid>
          </Hidden>

          <Grid item lg={12} xs={12}>
            <Grid container spacing={2} alignItems={'stretch'}>
              {fieldKeys.length > 0 && fieldKeys.map((key: string) => this.textField(key, fields[key]))}
              {fieldKeys.length === 0 && <Typography className={classes.instructions}>- fields are empty -</Typography>}
            </Grid>
          </Grid>
        </Grid>

        {action === PanelAction.EDIT && templates.length > 0 && (
          <Grid container item lg={4} xs={12} alignItems={'stretch'} alignContent={'flex-start'}>
            <Grid item lg={12} xs={12}>
              <Typography className={classes.instructions}>Linked Templates:</Typography>
            </Grid>
            <Fade in={true} timeout={fadeTimeout}>
              <Grid item lg={12} xs={12}>
                <TemplatePreviewList
                  templates={uniqueTemplates}
                  fields={fields}
                  coordinate={coordinate}
                  coordinateFieldName={coordinateFieldName}
                  labelIdFieldName={labelIdFieldName}
                  fetchRendererResult={this.fetchRendererResult}
                  fetchTemplateWithData={this.fetchTemplateWithData}
                />
              </Grid>
            </Fade>
          </Grid>
        )}

        {spacer(12)}

        <FormPanelButtons
          cancelHandler={this.onCancel}
          resetHandler={this.resetState}
          saveHandler={this.onSave}
          deleteHandler={this.onDelete}
          isResetDisabled={!changed}
          isSaveDisabled={!saveCondition}
          isDeleteDisabled={action !== PanelAction.EDIT}
        />
      </Grid>
    );
  }

  protected updateState = (compartment?: CompartmentView) => {
    const { presets } = this.state;

    if (compartment !== undefined) {
      const action = PanelAction.EDIT;
      const fields = { ...compartment.fields };
      const value = compartment.coordinate;
      const templates: Template[] = [];

      compartment.links.forEach((link: LinkView) => {
        const { template } = link;
        templates.push(template);
      });

      this.setState({
        templates,
        action,
        fields,
        fieldsPreset: '',
        coordinate: value,
        changed: false,
      });
    } else {
      const action = PanelAction.CREATE;

      const isSinglePreset = Object.keys(presets).length === 1;

      this.setState({
        action,
        coordinate: '',
        fieldsPreset: isSinglePreset ? Object.keys(presets)[0] : '',
        fields: isSinglePreset ? presets[Object.keys(presets)[0]].fields.reduce(this.presetFieldsReducer({}), {}) : {},
        changed: false,
      });
    }
  };
}

export const CompartmentPanel = withStyles(styles)(CompartmentPanelComponent);
