import { BlueprintValue, Zone } from '@ekkogmbh/apisdk';
import Button from '@mui/material/Button';
import { Add, Close } from '@mui/icons-material';
import React from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { StyledTextField } from '../../Common/Components/Forms/StyledTextField';
import { BlueprintStore } from '../Stores/BlueprintStore';
import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { inject, observer } from 'mobx-react';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';

const styles = FormStyles;

const stores = ['api', 'blueprintStore'];

interface BlueprintPanelStores {
  api: ApiStore;
  blueprintStore: BlueprintStore;
}

interface BlueprintPanelState {
  loading: boolean;
  activeStep: number;
  zoneIdentifier: Pick<Zone, 'name' | 'coordinate'>;
}

export interface BlueprintPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: () => Promise<void>;
  zoneIdentifier: Pick<Zone, 'name' | 'coordinate'>;
}

export type BlueprintPanelPropsWithStores = BlueprintPanelProps & BlueprintPanelStores;

@inject(...stores)
@observer
class BlueprintPanelComponent extends React.Component<BlueprintPanelProps, BlueprintPanelState> {
  public state: BlueprintPanelState = {
    loading: false,
    activeStep: 0,
    zoneIdentifier: {
      name: '',
      coordinate: '',
    },
  };

  get stores(): BlueprintPanelStores {
    return this.props as BlueprintPanelPropsWithStores;
  }

  public componentWillUnmount(): void {
    const { blueprintStore } = this.stores;
    blueprintStore.resetStore();
  }

  public static getDerivedStateFromProps(
    props: Readonly<BlueprintPanelProps>,
    state: BlueprintPanelState,
  ): Partial<BlueprintPanelState> | null {
    const zoneIdentifier = {
      name: props.zoneIdentifier.name,
      coordinate: props.zoneIdentifier.coordinate,
    };

    if (
      props.zoneIdentifier.name !== state.zoneIdentifier.name &&
      props.zoneIdentifier.coordinate !== state.zoneIdentifier.coordinate
    ) {
      (props as BlueprintPanelPropsWithStores).blueprintStore.setState({ zone: zoneIdentifier });
      return {
        zoneIdentifier,
      };
    }

    return null;
  }

  private namingStep = (): DynamicStepperStep => {
    const { blueprintStore } = this.stores;
    const { name, once } = blueprintStore.state;

    const toggleOnce = (): void => {
      blueprintStore.setState({ once: !once });
    };

    return {
      title: 'Blueprint',
      elementCallback: () => (
        <Grid container spacing={0} alignContent={'stretch'}>
          <Grid item lg={6}>
            <Grid container spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                <StyledTextField
                  type={'text'}
                  label={'Name'}
                  value={name}
                  onChange={(e) => blueprintStore.setState({ name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ margin: 12 }}
                  label="Once"
                  control={<Checkbox checked={once} onChange={toggleOnce} color="secondary" />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    };
  };

  private stepsStep = (): DynamicStepperStep => {
    const { blueprintStore } = this.stores;
    const { steps } = blueprintStore.state;

    const addSource = (index: number) => (): void => {
      const updatedSteps = steps;
      updatedSteps[index].source.push({ value: '', amount: 1 });
      blueprintStore.setState({ steps: updatedSteps });
    };

    const addDestination = (index: number) => (): void => {
      const updatedSteps = steps;
      updatedSteps[index].destination.push({ value: '', amount: 1 });
      blueprintStore.setState({ steps: updatedSteps });
    };

    const removeSource = (index: number, sourceIndex: number) => (): void => {
      const updatedSteps = steps;
      updatedSteps[index].source.splice(sourceIndex, 1);
      blueprintStore.setState({ steps: updatedSteps });
    };

    const removeDestination = (index: number, destinationIndex: number) => (): void => {
      const updatedSteps = steps;
      updatedSteps[index].destination.splice(destinationIndex, 1);
      blueprintStore.setState({ steps: updatedSteps });
    };

    const removeStep = (index: number) => (): void => {
      const updatedSteps = steps;
      updatedSteps.splice(index, 1);
      blueprintStore.setState({ steps: updatedSteps });
    };

    const stepsForm = steps.map((step, index) => (
      <Grid
        key={index}
        container
        spacing={2}
        alignContent={'stretch'}
        style={{ borderColor: '#eee', borderStyle: 'solid', borderRadius: 8, marginBottom: 12 }}
      >
        <Grid item xs={12}>
          <Typography variant={'overline'} color={'secondary'}>
            Source
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignContent={'stretch'} style={{ paddingRight: 16 }}>
            {step.source.map((source: BlueprintValue, sourceIndex) => (
              <Grid item xs={2} key={sourceIndex}>
                <StyledTextField
                  type={'text'}
                  label={''}
                  value={source.value}
                  onChange={(e) => {
                    const updatedSteps = steps;
                    updatedSteps[index].source[sourceIndex].value = e.target.value;
                    blueprintStore.setState({ steps: updatedSteps });
                  }}
                  startAdornment={
                    <InputAdornment position={'start'}>
                      <TextField
                        variant="standard"
                        style={{ minWidth: '30px', maxWidth: '110px' }}
                        type={'number'}
                        label={''}
                        inputProps={{ min: 1 }}
                        value={source.amount}
                        onChange={(e) => {
                          const updatedSteps = steps;
                          updatedSteps[index].source[sourceIndex].amount = parseInt(e.target.value);
                          blueprintStore.setState({ steps: updatedSteps });
                        }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position={'end'}>
                      <IconButton onClick={removeSource(index, sourceIndex)} size="large">
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="outlined" color="secondary" onClick={addSource(index)} style={{ margin: 8 }}>
                <Add />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'overline'} color={'secondary'}>
            Destination
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignContent={'stretch'} style={{ paddingRight: 16 }}>
            {step.destination.map((destination: BlueprintValue, destinationIndex) => (
              <Grid item xs={2} key={destinationIndex}>
                <StyledTextField
                  type={'text'}
                  label={''}
                  value={destination.value}
                  onChange={(e) => {
                    const updatedSteps = steps;
                    updatedSteps[index].destination[destinationIndex].value = e.target.value;
                    blueprintStore.setState({ steps: updatedSteps });
                  }}
                  startAdornment={
                    <InputAdornment position={'start'}>
                      <TextField
                        variant="standard"
                        style={{ minWidth: '30px', maxWidth: '110px' }}
                        type={'number'}
                        label={''}
                        inputProps={{ min: 1 }}
                        value={destination.amount}
                        onChange={(e) => {
                          const updatedSteps = steps;
                          updatedSteps[index].destination[destinationIndex].amount = parseInt(e.target.value);
                          blueprintStore.setState({ steps: updatedSteps });
                        }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position={'end'}>
                      <IconButton onClick={removeDestination(index, destinationIndex)} size="large">
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="outlined" color="secondary" onClick={addDestination(index)} style={{ margin: 8 }}>
                <Add />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={removeStep(index)} style={{ margin: 8 }}>
            Delete Step
          </Button>
        </Grid>
      </Grid>
    ));

    const addStep = (): void => {
      blueprintStore.setState({ steps: [...steps, { source: [], destination: [] }] });
    };

    return {
      title: 'Steps',
      elementCallback: () => (
        <Grid container spacing={0} alignContent={'stretch'}>
          <Grid item lg={12}>
            <Grid container spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                {stepsForm}
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" color="secondary" onClick={addStep}>
                  Add Step
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    };
  };

  public render() {
    const { closeHandler, saveHandler } = this.props;
    const { activeStep } = this.state;
    const { blueprintStore } = this.stores;
    const { name } = blueprintStore.state;

    const steps = [this.namingStep(), this.stepsStep()];

    const isNextDisabled = (activeStep === 0 && name === '') || activeStep + 1 === steps.length;

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        <Grid item xs={12}>
          <DynamicStepper activeStep={activeStep} steps={steps} />
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            nextHandler={() => this.setState({ activeStep: activeStep + 1 })}
            backHandler={() => this.setState({ activeStep: activeStep - 1 })}
            saveHandler={() => {
              saveHandler();
              closeHandler();
            }}
            isNextDisabled={isNextDisabled}
            isBackDisabled={activeStep === 0}
            isSaveDisabled={!blueprintStore.isSaveable}
            cancelHandler={closeHandler}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(BlueprintPanelComponent);

export const BlueprintPanel = StyleWrapped;
