import { Chip, Grid, IconButton, Stack } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import React from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { StyledTextField } from './StyledTextField';
import { Add } from '@mui/icons-material';

const styles = FormStyles;

interface ListFormProps extends WithStyles<typeof styles> {
  items: string[];
  onChange(items: string[]): void;
}

interface ListFormState {
  stagedItem: string;
  currentItems: string[];
}

class ListFormComponent extends React.Component<ListFormProps, ListFormState> {
  public state: ListFormState = {
    currentItems: [],
    stagedItem: '',
  };

  public static getDerivedStateFromProps(
    props: Readonly<ListFormProps>,
    state: ListFormState,
  ): Partial<ListFormState> | null {
    const { items } = props;
    const { currentItems } = state;

    const isEqual = items.length === currentItems.length && items.every((v, i) => v === currentItems[i]);

    if (!isEqual) {
      return { currentItems: items };
    }

    return null;
  }

  public addItem = (): void => {
    const { onChange } = this.props;
    const { stagedItem, currentItems } = this.state;

    currentItems.push(stagedItem);
    const newItems = [...new Set(currentItems)]; // ensure no duplicate

    this.setState({ currentItems: newItems, stagedItem: '' }, () => onChange(newItems));
  };

  public removeItem = (index: number): void => {
    const { onChange } = this.props;
    const { currentItems } = this.state;

    currentItems.splice(index, 1);

    this.setState({ currentItems: currentItems.slice() }, () => onChange(currentItems));
  };

  public render() {
    const { currentItems, stagedItem } = this.state;

    return (
      <Stack spacing={1} justifyContent={'flex-start'}>
        <StyledTextField
          type={'text'}
          label={'Add Item'}
          value={stagedItem}
          onChange={(e) => this.setState({ stagedItem: e.target.value as string })}
          endAdornment={
            <IconButton disabled={stagedItem === ''} onClick={() => this.addItem()}>
              <Add />
            </IconButton>
          }
        />
        <Grid container spacing={1}>
          {currentItems.map((item: string, index: number) => (
            <Grid item xs={'auto'} key={`list-form-item-${index}`}>
              <Chip label={item} onDelete={() => this.removeItem(index)} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    );
  }
}

const StyleWrapped = withStyles(styles)(ListFormComponent);

export const ListForm = StyleWrapped;
