import { FieldInputTypeName, PresetSavePayload, Template } from '@ekkogmbh/apisdk';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { MouseEvent } from 'react';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { PresetStore } from '../Stores/PresetStore';
import { PresetPanelSorting } from './PresetPanelSorting';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { Add } from '@mui/icons-material';
import { TemplatePickerDialog } from 'src/TemplateManagement/Components/TemplatePickerDialog';

const stores = ['api', 'presetStore'];

interface PresetPanelStores {
  api: ApiStore;
  presetStore: PresetStore;
}

export interface PresetPanelState {
  finished: boolean;
  loading: boolean;
  activeStep: number;
  keyInput: string;
  templatePickerOpen: boolean;
}

export interface PresetPanelProps {
  closeHandler: () => void;
  saveHandler: (preset: PresetSavePayload) => Promise<void>;
  closeCallback?: () => void;
}

@inject(...stores)
@observer
class PresetPanelComponent extends React.Component<PresetPanelProps, PresetPanelState> {
  public state: PresetPanelState = {
    finished: false,
    loading: false,
    activeStep: 0,
    keyInput: '',
    templatePickerOpen: false,
  };
  private closeTimeout?: number;

  get stores(): PresetPanelStores {
    return this.props as PresetPanelProps & PresetPanelStores;
  }

  public async componentDidMount(): Promise<void> {
    const { presetStore } = this.stores;

    presetStore.resetStore(presetStore.editablePreset);
  }

  public componentWillUnmount(): void {
    this.stores.presetStore.resetStore();
  }

  public handleReset = async (): Promise<void> => {
    const { presetStore } = this.stores;
    presetStore.resetStore(presetStore.editablePreset);
    this.setState({ finished: false, keyInput: '', activeStep: 0 });
  };

  public handleSave = async (): Promise<void> => {
    const { saveHandler } = this.props;
    const { presetStore } = this.stores;
    const { name, coordinate, keys, types, allFilled } = presetStore.state;

    if (!allFilled) {
      return;
    }

    saveHandler({ name, coordinate, keys, types } as PresetSavePayload);
    this.closeTimeout = window.setTimeout(this.closeMountAware, 1250);
  };

  public closeMountAware = (): void => {
    const { closeHandler, closeCallback } = this.props;

    const close =
      closeCallback !== undefined
        ? () => {
            closeHandler();
            closeCallback();
          }
        : closeHandler;

    window.clearTimeout(this.closeTimeout);
    close();
  };

  public onAddKey = (_: MouseEvent<HTMLDivElement>): void => {
    const { keyInput } = this.state;
    const { presetStore } = this.stores;
    const { keys } = presetStore.state;

    if (keyInput.length > 0 && !keys.includes(keyInput)) {
      this.setState({ keyInput: '' }, () => {
        const newKeys = Array.from(keys);
        newKeys.push(keyInput);
        presetStore.setState({ keys: newKeys });
        presetStore.changed = true;
      });
    }
  };

  public onAddTemplateKeys = (template: Template): void => {
    const { presetStore } = this.stores;
    const { keys, types } = presetStore.state;

    const addedKeys = template.fields.filter((candidate: string) => !keys.includes(candidate));
    const newKeys = keys.concat(addedKeys);
    newKeys.forEach((key: string) => (types[key] = { name: FieldInputTypeName.ANY }));
    presetStore.setState({ keys: newKeys, types });
    presetStore.changed = true;
    presetStore.stagedKeys = [];
  };

  public getSteps = (): (DynamicStepperStep & { title: string })[] => {
    const { keyInput } = this.state;
    const { presetStore } = this.stores;
    const { name, coordinate, keys, types } = presetStore.state;

    const isEditMode = !!presetStore.editablePreset;
    const overlineStyle = { fontWeight: 700 };

    return [
      {
        title: 'Preset',
        elementCallback: (): React.JSX.Element => (
          <Grid container item xs={9} spacing={2} alignItems={'stretch'}>
            <Grid item xs={12}>
              <StyledTextField
                type={'text'}
                label={'Name'}
                value={name}
                onChange={(e) => presetStore.setState({ name: e.target.value as string })}
                disabled={isEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <CoordinateInput
                value={coordinate}
                onChange={(coordinate) => presetStore.setState({ coordinate })}
                disabled={isEditMode}
                trailingDelimiter={false}
              />
            </Grid>
          </Grid>
        ),
      },
      {
        title: 'Fields',
        elementCallback: (): React.JSX.Element => (
          <Grid container item xs={12} spacing={2} alignItems={'flex-start'}>
            <Grid container item xs={4} spacing={2} alignItems={'flex-start'}>
              <Grid item xs={12}>
                <StyledTextField
                  label={'Field Key'}
                  value={keyInput}
                  type={'text'}
                  onChange={(e) => this.setState({ keyInput: e.target.value as string })}
                  endAdornment={
                    <IconButton onClick={this.onAddKey as never} size="large">
                      <Add />
                    </IconButton>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color={'secondary'}
                  fullWidth
                  variant={'outlined'}
                  style={{ marginLeft: '2' }}
                  onClick={() => this.setState({ templatePickerOpen: true })}
                >
                  {'Import from Template'}
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={7}>
              <Grid item xs={12}>
                <PresetPanelSorting loading={false} sortableItems={keys} />
              </Grid>
            </Grid>
          </Grid>
        ),
      },
      {
        title: 'Overview',
        elementCallback: (): React.JSX.Element => (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'overline'} color={'primary'} style={overlineStyle}>
                {'Name'}
              </Typography>
              <Typography variant={'h6'} gutterBottom>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'overline'} color={'primary'} style={overlineStyle}>
                {'Coordinate'}
              </Typography>
              <Typography variant={'h6'}>{coordinate}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'overline'} color={'primary'} style={overlineStyle}>
                {'Fields'}
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={1} justifyContent={'flex-start'} alignContent={'fext-start'}>
              {keys.map((k, i) => {
                const fieldType = types[k];

                return (
                  <Grid key={`preset-overview-key-${i}`} item xs={1}>
                    <Typography color={'secondary'} variant={'h6'}>
                      {k}
                    </Typography>
                    <br />
                    <Typography color={'primary'} variant={'h6'}>
                      {fieldType ? fieldType.name : ''}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ),
      },
    ];
  };

  public render(): React.JSX.Element {
    const { presetStore } = this.stores;
    const { finished, activeStep, templatePickerOpen } = this.state;
    const { changed } = presetStore;
    const { allFilled, coordinate } = presetStore.state;

    const close = this.closeMountAware;

    const steps = this.getSteps();
    const numSteps = steps.length;

    const isNextDisabled = activeStep === numSteps - 1 || coordinate === '';

    return (
      <>
        {templatePickerOpen && (
          <TemplatePickerDialog
            title={'Import Preset Field Keys From Template'}
            coordinate={coordinate}
            onClose={() => this.setState({ templatePickerOpen: false })}
            onConfirmCallback={(template) =>
              this.setState({ templatePickerOpen: false }, () => this.onAddTemplateKeys(template))
            }
          />
        )}
        <Grid container spacing={2} alignItems={'stretch'}>
          <Grid container item xs={12}>
            {<DynamicStepper activeStep={activeStep} steps={steps} />}
          </Grid>
          <Grid item xs={12}>
            <FormPanelButtons
              cancelHandler={close}
              finished={finished}
              saveHandler={this.handleSave}
              resetHandler={this.handleReset}
              isDeleteDisabled={true}
              isDeleteHidden={true}
              isSaveDisabled={!changed || !allFilled}
              isResetDisabled={!changed}
              nextHandler={() => this.setState({ activeStep: activeStep + 1 })}
              backHandler={() => this.setState({ activeStep: activeStep - 1 })}
              isNextDisabled={isNextDisabled}
              isBackDisabled={activeStep === 0}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export const PresetPanel = PresetPanelComponent;
