import { inject, observer } from 'mobx-react';
import React from 'react';
import { DataTableStore } from '../Stores/DataTableStore';
import { LoadingMask } from './LoadingMask';

interface LoadingProps {
  dataTableStore?: DataTableStore;
  width?: number;
  height?: number;
  topPercent?: number;
  leftPercent?: number;
}

@inject('dataTableStore')
@observer
class DataTableLoadingMaskComponent extends React.Component<LoadingProps> {
  public render(): React.JSX.Element {
    const { dataTableStore } = this.props;
    if (dataTableStore) {
      return <LoadingMask {...this.props} isLoading={dataTableStore.loading} />;
    }

    return <LoadingMask {...this.props} />;
  }
}

export const DataTableLoadingMask = DataTableLoadingMaskComponent;
