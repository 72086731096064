import { RendererResult, Template } from '@ekkogmbh/apisdk';
import { Chip, Grid, Stack, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { spacing } from '../../Common/Helper/ThemeHelper';
import { TemplatePicker } from '../TemplatePicker';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';

const styles = (theme: Theme) => ({
  button: {
    margin: spacing(theme),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

interface TemplatePickerDialogProps extends WithStyles<typeof styles> {
  title?: string;
  coordinate: string;
  onClose: () => void;
  onConfirmCallback: (template: Template) => void;
}

interface TemplatePickerDialogState {
  loading: boolean;
  rendering?: RendererResult;
  template?: Template;
}

class TemplatePickerDialogComponent extends React.Component<TemplatePickerDialogProps, TemplatePickerDialogState> {
  public state: TemplatePickerDialogState = {
    loading: true,
    rendering: undefined,
    template: undefined,
  };

  public componentDidMount() {
    this.setState({ loading: true }, () => {
      // this.onRefresh();
    });
  }

  public render() {
    const { onConfirmCallback, onClose, coordinate, title } = this.props;
    const { template } = this.state;

    return (
      <GenericDialog
        type={'confirmation'}
        title={title ?? 'Select A Template'}
        onClose={onClose}
        onConfirm={() => onConfirmCallback(template!)}
        okButtonDisabled={!template}
        open={true}
        text={
          <Stack spacing={2}>
            <TemplatePicker
              selected={template}
              coordinate={coordinate}
              onChangeCallback={(selection) => this.setState({ template: selection as Template })}
            />
            {template && (
              <>
                <Grid container>
                  {Object.keys(template.fields).length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <StyledFormHeader label={'Field Keys'} />
                      </Grid>
                      {template.fields.map((key: string, idx: number) => (
                        <Grid item xs={'auto'} key={`template-field-list-${idx}`}>
                          <Chip label={key} />
                        </Grid>
                      ))}
                    </>
                  )}
                  {Object.keys(template.annotations).length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <StyledFormHeader label={'Annotation Keys'} />
                      </Grid>
                      {template.annotations.map((key: string, idx: number) => (
                        <Grid item xs={'auto'} key={`template-annotation-list-${idx}`}>
                          <Chip label={key} />
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              </>
            )}
          </Stack>
        }
      />
    );
  }
}

export const TemplatePickerDialog = withStyles(styles)(TemplatePickerDialogComponent);
