import { CompartmentSelectorIndex, Finder, NotificationStrategy, Template } from '@ekkogmbh/apisdk';
import { Checkbox, FormControlLabel, Grid, InputAdornment, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { FinderStore } from '../Stores/FinderStore';
import { NotificationStrategyForm } from 'src/ZoneManagement/Components/NotificationStrategyForm';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';
import { CompartmentSelectorPicker } from 'src/CompartmentSelectorManagement/CompartmentSelectorPicker';
import { TemplatePicker } from 'src/TemplateManagement/TemplatePicker';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';

const styles = FormStyles;
const checkboxStyle = { margin: 12 };

interface FinderPanelStores {
  api: ApiStore;
  finderStore: FinderStore;
  notificationStrategyStore: NotificationStrategyStore;
}

interface FinderPanelState {
  loading: boolean;
  findTemplates: boolean;
  selectCompartmentSelector: boolean;
  showResultsDuration: number | null;
}

export interface FinderPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (finder: Finder) => Promise<Finder>;
}

@inject('api', 'finderStore', 'notificationStrategyStore')
@observer
class FinderPanelComponent extends React.Component<FinderPanelProps, FinderPanelState> {
  public state: FinderPanelState = {
    loading: false,
    findTemplates: false,
    selectCompartmentSelector: false,
    showResultsDuration: null,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): FinderPanelStores {
    return this.props as FinderPanelProps & FinderPanelStores;
  }

  public componentDidMount(): void {
    const { finderStore } = this.stores;
    const { editableFinder } = finderStore;

    if (editableFinder) {
      if (editableFinder.templateNames && editableFinder.templateNames.length > 0) {
        this.toggleFindTemplates();
      }

      if (editableFinder.compartmentSelector) {
        this.toggleSelectCompartmentSelector();
      }
    }
  }

  public componentWillUnmount(): void {
    const { finderStore, notificationStrategyStore } = this.stores;
    finderStore.resetStore();
    notificationStrategyStore.resetStore();
    cancelFetchPromises(this.fetchPromises);
  }

  public handleReset = async () => {
    const { finderStore, notificationStrategyStore } = this.stores;
    const { editableFinder } = finderStore;

    if (editableFinder !== undefined) {
      finderStore.setEditableFinder(editableFinder);
      notificationStrategyStore.resetStore(editableFinder.notificationStrategy);
      this.setState({
        findTemplates: editableFinder.templateNames !== undefined && editableFinder.templateNames.length > 0,
        selectCompartmentSelector: editableFinder.compartmentSelector !== undefined,
      });
    } else {
      this.setState(
        {
          findTemplates: false,
          selectCompartmentSelector: false,
          showResultsDuration: null,
        },
        this.componentWillUnmount,
      );
    }
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { finderStore } = this.stores;

    const payload = {
      ...finderStore.state,
      notificationStrategy: {
        name: NotificationStrategy.NULL,
        configuration: {},
      },
    } as Finder;

    this.setState({ loading: true }, async () => {
      await saveHandler(payload);
      this.handleReset();
      closeHandler();
    });
  };

  public toggleSelectCompartmentSelector = async () => {
    const { selectCompartmentSelector } = this.state;

    this.setState({ selectCompartmentSelector: !selectCompartmentSelector });
  };

  public toggleFindTemplates = async () => {
    const { findTemplates } = this.state;

    this.setState({ findTemplates: !findTemplates });
  };

  public render() {
    const { closeHandler } = this.props;
    const { loading, findTemplates, selectCompartmentSelector } = this.state;
    const { finderStore } = this.stores;
    const { templateNames, compartmentSelector, coordinate } = finderStore.state;
    const { editableFinder } = finderStore;

    const showResultsDuration = finderStore.state.showResultsDuration ?? 1;

    return (
      <Grid container spacing={2} alignContent="stretch">
        {loading && (
          <Grid item xs={12}>
            <div style={{ display: loading ? 'block' : 'none' }}>
              <Grid container spacing={2} alignContent="stretch">
                <Grid
                  item
                  xs={12}
                  style={{
                    height: 496,
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      top: '50%',
                      marginTop: -48,
                      position: 'absolute',
                      width: '100%',
                    }}
                  >
                    <CheckmarkSpinner complete={false} failure={false} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
        {!loading && (
          <Grid container item xs={12} spacing={2} alignContent="stretch" alignItems="flex-start">
            <Grid container item xs={6} spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                <StyledTextField
                  type="text"
                  label="Name"
                  value={finderStore.state.name}
                  disabled={editableFinder !== undefined}
                  onChange={(e) => finderStore.setState({ name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <CoordinateInput
                  value={finderStore.state.coordinate}
                  disabled={editableFinder !== undefined || findTemplates || selectCompartmentSelector}
                  onChange={(coordinate) => finderStore.setState({ coordinate })}
                  trailingDelimiter={false}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  type="text"
                  label="Compartment Input Instructions"
                  tooltip="Instruction text displayed when running the finder."
                  value={finderStore.state.compartmentInputInstructions ?? ''}
                  onChange={(e) => finderStore.setState({ compartmentInputInstructions: e.target.value })}
                />
              </Grid>
              <Grid item container xs={12} spacing={2} alignContent="stretch">
                <Grid item xs={6}>
                  <Tooltip title="Find labels using the selected templates" arrow>
                    <FormControlLabel
                      style={checkboxStyle}
                      label="Find Templates"
                      control={
                        <Checkbox
                          disabled={finderStore.state.coordinate === ''}
                          checked={findTemplates}
                          onChange={this.toggleFindTemplates}
                          color="secondary"
                        />
                      }
                    />
                  </Tooltip>
                </Grid>
                {findTemplates && (
                  <Grid item xs={6}>
                    <TemplatePicker
                      multiple
                      coordinate={coordinate}
                      selected={templateNames}
                      onChangeCallback={(selection) => {
                        finderStore.setState({ templateNames: (selection as Template[]).map((tmp) => tmp.name) });
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12} spacing={2} alignContent="stretch">
                <Grid item xs={6}>
                  <Tooltip title="Compartment Selector for narrowing the range of the finder" arrow>
                    <FormControlLabel
                      style={checkboxStyle}
                      label="Compartment Selector"
                      control={
                        <Checkbox
                          disabled={finderStore.state.coordinate === ''}
                          checked={selectCompartmentSelector}
                          onChange={this.toggleSelectCompartmentSelector}
                          color="secondary"
                        />
                      }
                    />
                  </Tooltip>
                </Grid>
                {selectCompartmentSelector && (
                  <Grid item xs={6}>
                    <CompartmentSelectorPicker
                      coordinate={coordinate}
                      selected={compartmentSelector as CompartmentSelectorIndex}
                      onChange={(selector) => finderStore.setState({ compartmentSelector: selector })}
                      onError={() => this.setState({ selectCompartmentSelector: false })}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Tooltip title="Show coordinates of found compartments" arrow>
                    <FormControlLabel
                      style={checkboxStyle}
                      label="Show Coordinates"
                      control={
                        <Checkbox
                          checked={finderStore.state.showResultsDuration !== null}
                          onChange={(e) => finderStore.setState({ showResultsDuration: e.target.checked ? 1 : null })}
                          color="secondary"
                        />
                      }
                    />
                  </Tooltip>
                </Grid>
                {finderStore.state.showResultsDuration !== null && (
                  <Grid item xs={6}>
                    <StyledTextField
                      type="number"
                      label={`Show Results Duration`}
                      value={showResultsDuration}
                      onChange={(e) =>
                        finderStore.setState({ showResultsDuration: Math.max(1, Math.min(+e.target.value, 2147483)) })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          {'second' + (showResultsDuration === 1 ? '' : 's')}
                        </InputAdornment>
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <StyledFormHeader label={'Notification Strategies'} />
                <NotificationStrategyForm />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormPanelButtons
            cancelHandler={closeHandler}
            saveHandler={this.handleSave}
            resetHandler={this.handleReset}
            isDeleteHidden={true}
            isSaveDisabled={!finderStore.state.allFilled}
          />
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(FinderPanelComponent);

export const FinderPanel = StyleWrapped;
