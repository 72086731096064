import { Template, TemplateType, TemplateWithData } from '@ekkogmbh/apisdk';
import { Tooltip, Typography } from '@mui/material';
import { Delete, Edit, GetApp, Image } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import { Permissions } from 'src/Common/Stores/ApiStore';
import { DataTableRecord } from 'src/Common/Stores/DataTableStore';
import {
  TemplateManagementContentActionHandlers,
  TemplateManagementContentPropsWithStores,
  TemplateManagementContentState,
} from './TemplateManagementContent';

class TemplateManagementDataTableActions extends DataTableActionsComponent<DataTableRecord<TemplateWithData>> {}

const TemplateNameColumn = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (name: string): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const TemplateCoordinateColumn = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (coordinate: string): React.ReactNode => <div style={{ fontWeight: 700 }}>{coordinate}</div>,
  },
});

const TemplateTypeColumn = (): MUIDataTableColumnDef => ({
  label: 'Type',
  name: 'entry.type',
  options: {
    sort: false,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (type: TemplateType): React.ReactNode => <div>{type}</div>,
  },
});

const TemplateKeysColumn = (): MUIDataTableColumnDef => ({
  label: 'Keys',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Template): React.ReactNode => {
      const noneComponent = (
        <Typography color={'textSecondary'} style={{ fontWeight: 700 }}>
          ―
        </Typography>
      );

      switch (value.type) {
        case TemplateType.APACHE_FOP:
        case TemplateType.SVG_TERA:
          return (
            <div>
              <div>
                <Tooltip title={value.fields.join(', ')}>
                  <div>
                    <Typography display={'inline'} color={'textSecondary'} style={{ fontWeight: 700 }}>
                      fields:{' '}
                    </Typography>
                    <Typography display={'inline'} color={'textPrimary'}>
                      {value.fields.length}{' '}
                    </Typography>
                  </div>
                </Tooltip>
                <Tooltip title={value.annotations.join(', ')}>
                  <div>
                    <Typography display={'inline'} color={'textSecondary'} style={{ fontWeight: 700 }}>
                      annotations:{' '}
                    </Typography>
                    <Typography display={'inline'} color={'textPrimary'}>
                      {value.annotations.length}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        default:
          return noneComponent;
      }
    },
  },
});

const TemplateMetadataColumn = (): MUIDataTableColumnDef => ({
  label: 'Metadata',
  name: 'entry.metadata',
  options: {
    sort: false,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Record<string, unknown>): React.ReactNode => {
      function renderMetadataDimension(metadata: Record<string, string>): React.JSX.Element {
        if (metadata.width === undefined || metadata.height === undefined) {
          return <div></div>;
        }

        return (
          <div>
            <Typography display={'inline'} color={'textSecondary'} style={{ fontWeight: 700 }}>
              width:{' '}
            </Typography>
            <Typography display={'inline'} color={'textPrimary'}>
              {metadata.width}{' '}
            </Typography>
            <Typography display={'inline'} color={'textSecondary'} style={{ fontWeight: 700 }}>
              height:{' '}
            </Typography>
            <Typography display={'inline'} color={'textPrimary'}>
              {metadata.height}
            </Typography>
          </div>
        );
      }

      switch (value.type) {
        case TemplateType.APACHE_FOP:
        case TemplateType.SVG_TERA:
        default:
          return renderMetadataDimension(value as Record<string, string>);
      }
    },
  },
});

const TemplateActionsColumn = (
  _: TemplateManagementContentState,
  propsWithStores: TemplateManagementContentPropsWithStores,
  actions: TemplateManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (template: TemplateWithData): React.ReactNode => {
      const nodeValues = template.coordinate.split(/[._—/]+/);
      const hasWritePermission = propsWithStores.api.userHasPermissionForNode(Permissions.TEMPLATES_WRITE, nodeValues);

      const actionButtons: ActionButton<TemplateWithData>[] = [
        {
          title: 'Preview',
          onClick: actions.preview,
          icon: Image,
          disabled: false,
        },
        {
          title: 'Download',
          onClick: actions.download,
          icon: GetApp,
          disabled: false,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <TemplateManagementDataTableActions dataset={template} isProcessing={false} actionButtons={actionButtons} />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  TemplateManagementContentPropsWithStores,
  TemplateManagementContentState,
  TemplateManagementContentActionHandlers
>> = [
  TemplateNameColumn,
  TemplateCoordinateColumn,
  TemplateTypeColumn,
  TemplateKeysColumn,
  TemplateMetadataColumn,
  TemplateActionsColumn,
];
