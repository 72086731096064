import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import SaveAlt from '@mui/icons-material/SaveAlt';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Dropzone from 'react-dropzone';
import { CsvDropzoneStore } from '../Stores/CsvDropzoneStore';
import { CSVDropzoneStyles } from '../Styles/CSVDropzoneStyles';

const styles = CSVDropzoneStyles;

interface DropzoneProps extends WithStyles<typeof styles> {
  csvDropzoneStore?: CsvDropzoneStore;
  onDrop: () => void;
}

@inject('csvDropzoneStore')
@observer
class CSVDropzoneComponent extends React.Component<DropzoneProps> {
  public onDrop = (files: File[]) => {
    const { csvDropzoneStore, onDrop } = this.props;
    csvDropzoneStore!.setFile(files.shift());
    onDrop();
  };

  public onCancel = () => {
    const { csvDropzoneStore } = this.props;
    csvDropzoneStore!.removeFile();
  };

  public render() {
    const { classes } = this.props;
    return (
      <section style={{ width: '100%' }}>
        <Dropzone
          accept="text/csv, text/plain, .csv, application/json, .json"
          onDrop={this.onDrop}
          onFileDialogCancel={this.onCancel}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classNames(classes.dropZone)}>
              <input {...getInputProps()} />
              <div>
                <SaveAlt className={classNames(classes.uploadIcon)} />

                <Typography className={classNames(classes.text)}>
                  Drop the import file here, or click to select the import file
                </Typography>
              </div>
            </div>
          )}
        </Dropzone>
      </section>
    );
  }
}

export const CSVDropzone = withStyles(styles)(CSVDropzoneComponent);
