import { ApiSdkEvents, HttpApiSdk, TaskCollection, TaskState } from '@ekkogmbh/apisdk';
import EventEmitter from 'eventemitter3';
import { action, observable } from 'mobx';

export type TaskCollectionProgressCallback = (taskCollectionId: string, state: string, progress?: number) => void;

interface TaskCollectionPollInterval {
  [key: string]: number;
}

export class TaskCollectionStore {
  @observable
  public taskCollections: TaskCollection[] = [];

  private intervals: TaskCollectionPollInterval = {};
  private readonly pollRate: number = 1;

  constructor(private readonly api: HttpApiSdk & EventEmitter) {}

  @action
  public async processTaskCollection(
    taskCollectionHeaderValue: string | null,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> {
    if (taskCollectionHeaderValue === null) {
      if (progressCallback) {
        progressCallback('', 'no task-collection created', 100);
      }

      return;
    }

    const parts = taskCollectionHeaderValue.split('/');
    const taskCollectionId = parseInt(parts[parts.length - 1], 10);

    if (!taskCollectionId) {
      if (progressCallback) {
        progressCallback('', 'not found', 100);
      }

      return;
    }

    const taskCollection: TaskCollection = await this.api.getTaskCollectionProgress(taskCollectionId);

    if (!taskCollection) {
      if (progressCallback) {
        progressCallback('', 'not found', 100);
      }

      return;
    }

    this.handleTaskCollection(taskCollection, progressCallback);
  }

  @action
  public async handleTaskCollection(
    taskCollection: TaskCollection,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> {
    switch (taskCollection.state) {
      case TaskState.CREATED:
        await this.handleTaskCollectionCreated(taskCollection, progressCallback);
        break;

      case TaskState.RUNNING:
        await this.handleTaskCollectionRunning(taskCollection, progressCallback);
        break;

      case TaskState.FINISHED:
      case TaskState.FAILED:
        await this.handleTaskCollectionFinished(taskCollection);
        break;
    }

    if (progressCallback) {
      progressCallback(taskCollection.id, taskCollection.state, taskCollection.progress);
    }
  }

  protected async handleTaskCollectionCreated(
    taskCollection: TaskCollection,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> {
    await this.addTaskCollection(taskCollection);
    await this.createPseudoInterval(taskCollection, progressCallback);

    this.api.emit(ApiSdkEvents.TASK_COLLECTION_CREATED, taskCollection);
  }

  protected async createPseudoInterval(
    taskCollection: TaskCollection,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> {
    await this.removePseudoInterval(taskCollection);

    this.intervals[taskCollection.id] = window.setTimeout(async () => {
      try {
        const taskCollectionResponse: TaskCollection = await this.api.getTaskCollectionProgress(
          parseInt(taskCollection.id, 10),
        );

        this.handleTaskCollection(taskCollectionResponse, progressCallback);
      } catch (e) {
        this.api.emit(ApiSdkEvents.TASK_COLLECTION_ERROR, taskCollection);
      }
    }, this.pollRate * 1000);
  }

  protected async removePseudoInterval(taskCollection: TaskCollection): Promise<void> {
    if (this.intervals.hasOwnProperty(taskCollection.id)) {
      clearTimeout(this.intervals[taskCollection.id]);
      delete this.intervals[taskCollection.id];
    }
  }

  protected async handleTaskCollectionRunning(
    taskCollection: TaskCollection,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> {
    await this.updateTaskCollection(taskCollection, progressCallback);
    this.api.emit(ApiSdkEvents.TASK_COLLECTION_PROGRESS, taskCollection);
  }

  protected async handleTaskCollectionFinished(taskCollection: TaskCollection): Promise<void> {
    await this.removeTaskCollection(taskCollection);
    await this.removePseudoInterval(taskCollection);

    this.api.emit(
      taskCollection.state === TaskState.FINISHED
        ? ApiSdkEvents.TASK_COLLECTION_FINISHED
        : ApiSdkEvents.TASK_COLLECTION_FAILED,
      taskCollection,
    );
  }

  protected async addTaskCollection(taskCollection: TaskCollection): Promise<void> {
    const index = this.taskCollections.indexOf(taskCollection);
    if (index < 0) {
      this.taskCollections.push(taskCollection);
    }
  }

  protected async removeTaskCollection(taskCollection: TaskCollection): Promise<void> {
    const index = this.taskCollections.indexOf(taskCollection);
    if (index >= 0) {
      await this.removePseudoInterval(taskCollection);
      this.taskCollections.splice(index, 1);
    }
  }

  protected async updateTaskCollection(
    taskCollection: TaskCollection,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> {
    const index = this.taskCollections.indexOf(taskCollection);
    if (index >= 0) {
      this.taskCollections[index] = taskCollection;
    } else {
      await this.addTaskCollection(taskCollection);
      await this.createPseudoInterval(taskCollection, progressCallback);
    }
  }
}
