import { Checkbox, FormControl, MenuItem, SelectChangeEvent, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import ListItemText from '@mui/material/ListItemText';
import { SelectProps } from '@mui/material/Select';
import { CSSProperties } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { Template } from '@ekkogmbh/apisdk';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { spacing } from '../../Common/Helper/ThemeHelper';

const styles = (theme: Theme) => ({
  formControl: {
    width: 320,
  },
  menuItemUpdated: {
    width: spacing(theme) * 2,
    textAlign: 'center',
  } as CSSProperties,
  dialogTitleSpan: {
    fontWeight: 100,
    fontStyle: 'italic',
  },
});

interface SortedSideTemplatesSelectState {
  selectedTemplateIds: number[];
}

interface SortedSideTemplatesSelectProps extends WithStyles<typeof styles> {
  value: number[];
  allTemplates: Template[];
  changeHandler: (event: SelectChangeEvent<number[]>) => void;
  noDialog?: boolean;
  changeIndicator?: boolean;
}

class SortedSideTemplatesSelectComponent extends React.PureComponent<
  SortedSideTemplatesSelectProps,
  SortedSideTemplatesSelectState
> {
  public state: SortedSideTemplatesSelectState;

  constructor(props: SortedSideTemplatesSelectProps) {
    super(props);

    this.state = {
      selectedTemplateIds: [],
    };
  }

  // @TODO get derived state from props
  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: Readonly<SortedSideTemplatesSelectProps>): void {
    const { value } = nextProps;

    this.setState({
      selectedTemplateIds: [...value],
    });
  }

  public handleChange = (event: SelectChangeEvent<unknown>) => {
    const templateIds = event.target.value as number[];
    this.setState({ selectedTemplateIds: templateIds });
    // Bubble up the whole template struct
    // Unfortunately the typing here is broken as hell, due to the fact, that material-ui
    // does not provide the right typing in the first place.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event.target.value = this.props.allTemplates.filter((t) => templateIds.includes(t.id)) as any;
    this.props.changeHandler(event as SelectChangeEvent<number[]>);
  };

  public didTemplateChange = (template: Pick<Template, 'id'>): boolean => {
    if (!this.props.changeIndicator) {
      return false;
    }

    const previousTemplates = this.props.value;
    const currentTemplates = this.state.selectedTemplateIds;

    return (
      (previousTemplates.indexOf(template.id) === -1 && currentTemplates.indexOf(template.id) > -1) ||
      (previousTemplates.indexOf(template.id) > -1 && currentTemplates.indexOf(template.id) === -1)
    );
  };

  public render() {
    const { classes } = this.props;

    const { selectedTemplateIds } = this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        },
      },
    };

    const getTemplateName = (templateId: number): string => {
      const fullTemplate = this.props.allTemplates.find((candidate) => candidate.id === templateId);
      if (fullTemplate === undefined) {
        throw new Error(`Could not locate template with id ${templateId} within list of all templates`);
      }
      return fullTemplate.name;
    };

    const selectedRenderValue = (value: SelectProps['value']) => {
      const selectedIds = value as number[];
      return selectedIds.map((id) => getTemplateName(id)).join(', ');
    };

    const mapper = (template: Template) => (
      <MenuItem key={template.id} value={template.id}>
        <span className={classes.menuItemUpdated}>{this.didTemplateChange(template) && '*'}</span>
        <Checkbox checked={selectedTemplateIds.indexOf(template.id) > -1} />
        <ListItemText primary={template.name} />
      </MenuItem>
    );

    return (
      <React.Fragment>
        <FormControl className={classes.formControl} variant="outlined">
          <StyledSelectField
            multiple
            style={{ marginTop: 0 }}
            value={selectedTemplateIds}
            onChange={this.handleChange}
            label="Template"
            renderValue={selectedRenderValue}
            MenuProps={MenuProps}
          >
            {this.props.allTemplates.map(mapper)}
          </StyledSelectField>
        </FormControl>
      </React.Fragment>
    );
  }
}

const StyleWrapped = withStyles(styles)(SortedSideTemplatesSelectComponent);

export const SortedSideTemplatesSelect = StyleWrapped;
