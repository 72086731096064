import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../Helper/ThemeHelper';

export const FormStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  root: {
    alignItems: 'stretch',
  },
  button: {
    flexGrow: 1,
    margin: spacing(theme),
  },
  buttonGrid: {
    position: 'absolute',
    right: 24,
    marginTop: -36,
  },
  margin: {
    margin: spacing(theme),
    width: '100%',
  },
  label: {
    '&.Mui-focused': {
      color: theme.palette.secondary.main,
    },
  },
  focused: {},
  outlinedInput: {
    width: '100%',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
  },
  outlinedInputError: {
    width: '100%',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },
  notchedOutline: {},
  instructions: {
    margin: spacing(theme),
  },
  disabled: {
    color: theme.palette.grey['500'],
  },
  formPanelButtonsGrid: {
    marginTop: 24,
    display: 'inline-flex',
  },
  formPanelButtonsDelete: {
    marginLeft: 160,
    marginRight: -8,
  },
  formControlSelectAutoWidth: {
    margin: spacing(theme),
    width: '100%',
  },
  AccordionSummaryContent: {
    margin: '16px 0 8px 0 !important',
  },
  AccordionExpandIcon: {
    marginTop: 4,
  },
  adornedStart: {
    whiteSpace: 'nowrap',
  },
});
