import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { SupplyProfileManagementContent } from './Components/SupplyProfileManagementContent';
import { SupplyProfileStore } from './Stores/SupplyProfileStore';

const supplyProfileStore = new SupplyProfileStore();

const stores = { supplyProfileStore };

export const SupplyProfileManagement = (): React.JSX.Element => (
  <Provider {...stores}>
    <Page title="Supply Profiles" isSearchable={true}>
      <SupplyProfileManagementContent />
    </Page>
  </Provider>
);
