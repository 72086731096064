import { KeyboardEvent } from 'react';

export const onKeyPressCallback = (callback: () => void, condition: boolean, key: string) => async ({
  key: pressedKey,
}: KeyboardEvent<HTMLElement>): Promise<void> => {
  if (key === pressedKey && condition) {
    callback();
  }
};

export const isArrayValue = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  value?: Array<string | number | boolean | object> | string | number | boolean | object,
): value is string[] => (value as string[]).length !== undefined;

// maybe change to structuredClone() ???
// const copy = <T extends object>(obj: T): DeepCopy<T> => structuredClone(obj);
type DeepCopy<T> = T extends object ? { [K in keyof T]: DeepCopy<T[K]> } : T;

export const copy = <T extends object>(obj: T): DeepCopy<T> => JSON.parse(JSON.stringify(obj));
