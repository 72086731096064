import { Endpoint, EslManagerPrivateRoute, HttpMethod, PaginationResponse, Permission, Role } from '@ekkogmbh/apisdk';
import { Accordion, AccordionDetails, Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataTable, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { createRequestWrapper } from 'src/Common/Helper/FetchHandler';
import { SuccessHandlerStatusMessages } from 'src/Common/Helper/ResponseHandler';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { ContentActions } from '../../Common/Components/ContentActions';
import { injectFakePagination } from '../../Common/Helper/Pagination';
import { CancelableFetchPromises, cancelFetchPromises, makePromiseCancelable } from '../../Common/Helper/PromiseHelper';
import { TaskCollectionProgressCallback } from '../../Common/Stores/TaskCollectionStore';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { Routes, RouteShortDefinition } from '../../Routes';
import { RoleManagementStyles } from '../Styles/RoleManagementStyles';
import { materialDatatableColumnDefinitions } from './RoleDatatableColumnDefinitions';
import { RolePanel } from './RolePanel';

enum ExpandedPanel {
  ADD = 'add',
  NONE = '',
}

interface RoleManagementContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore'];

export interface RoleManagementContentActionHandlers {
  users: (role: Role) => void;
  edit: (role: Role) => void;
  delete: (role: Role) => void;
}

export interface RoleManagementContentHelpers {
  updateRolePermissions: (role: Role, permissions: Permission[]) => Promise<void>;
}

export interface RoleManagementContentState {
  allPermissions: Permission[];
  expandedPanel: string;
  editableRole?: Role;
  deleteDialogOpen: boolean;
}

export interface RoleManagementContentProps extends WithStyles<typeof RoleManagementStyles>, RouteComponentProps {}

export type RoleManagementContentPropsWithStores = RoleManagementContentProps & RoleManagementContentStores;

@inject(...stores)
class RoleManagementContentComponent extends Component<RoleManagementContentProps, RoleManagementContentState> {
  public state: RoleManagementContentState;
  private fetchPromises: CancelableFetchPromises = {};
  private readonly filterFields: string[];
  private readonly sortFieldMap: DataTableSortFieldMap<Role>;
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Role updated.',
    201: 'Role created.',
    204: 'Role deleted.',
  };

  constructor(props: RoleManagementContentProps) {
    super(props);

    this.state = {
      allPermissions: [],
      expandedPanel: ExpandedPanel.NONE,
      deleteDialogOpen: false,
    };

    this.filterFields = ['name'];
    this.sortFieldMap = { name: 'name' };
  }

  get stores(): RoleManagementContentStores {
    return this.props as RoleManagementContentProps & RoleManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public onAddItemClick = () => {
    const { expandedPanel } = this.state;

    if (expandedPanel === ExpandedPanel.ADD) {
      this.onCloseAddItem();
    } else {
      this.setState({
        expandedPanel: ExpandedPanel.ADD,
      });
    }
  };

  public onCloseAddItem = () => {
    this.setState({
      expandedPanel: ExpandedPanel.NONE,
      editableRole: undefined,
    });
  };

  public fetchPermissions = async (): Promise<Permission[]> => {
    if (
      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS] &&
      !this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].isResolved()
    ) {
      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].cancel();
    }

    const { api } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.PERMISSIONS };

    const requestWrapper = createRequestWrapper<Permission[]>(api, api.getPermissions(), enqueueSnackbar);

    return await requestWrapper(endpoint, HttpMethod.GET);
  };

  public fetchRoles = async (): Promise<PaginationResponse<Role>> => {
    const { api } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.ROLES };

    const requestWrapper = createRequestWrapper<Role[]>(api, api.getRoles(), enqueueSnackbar);

    const data = await requestWrapper(endpoint, HttpMethod.GET);

    return injectFakePagination<Role>(data);
  };

  public fetchItems = async (): Promise<PaginationResponse<Role>> => {
    cancelFetchPromises(this.fetchPromises);

    if (this.state.allPermissions.length === 0) {
      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS] = makePromiseCancelable(this.fetchPermissions());

      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].promise.catch((reason) => {
        if (reason.isCanceled) {
          return;
        }

        throw reason;
      });

      const allPermissions = await this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].promise;

      this.setState({ allPermissions });
    }

    this.fetchPromises[EslManagerPrivateRoute.ROLES] = makePromiseCancelable(this.fetchRoles());

    this.fetchPromises[EslManagerPrivateRoute.ROLES].promise.catch((reason) => {
      if (reason.isCanceled) {
        return;
      }

      throw reason;
    });

    return await this.fetchPromises[EslManagerPrivateRoute.ROLES].promise;
  };

  public updateRolePermissions = async (role: Role, permissions: Permission[]): Promise<void> => {
    role.permissions = permissions;
    await this.handleSaveRole(role);
  };

  public handleSaveRole = async (
    role: Partial<Role>,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> => {
    if (role.id === undefined) {
      await this.handleAddRole(role);
    } else {
      await this.handleUpdateRole(role);
    }

    if (progressCallback) {
      // @TODO workaround
      progressCallback('', '');
    }
  };

  public handleAddRole = async (role: Partial<Role>): Promise<Role> => {
    const { api, searchContentStore } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.ROLES };

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    const requestWrapper = createRequestWrapper<Role>(api, api.addRole(role as Role), enqueueSnackbar, successCallback);

    return await requestWrapper(endpoint, HttpMethod.POST, this.successStatusCodes);
  };

  public handleUpdateRole = async (role: Partial<Role>): Promise<Role> => {
    const { api, searchContentStore, navigationStore } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.ROLE,
      params: { id: role.id },
    };

    const successCallback = async () => {
      searchContentStore.emitRefresh();
      await api.checkAuthentication();

      const routeFilter = (route: RouteShortDefinition) =>
        route.menu !== undefined &&
        (route.permission === undefined || api.anyNodePermissions.indexOf(route.permission) !== -1);

      const menuEntries = Routes.filter(routeFilter);

      navigationStore.initMenuSorting();
      navigationStore.applyPermissionsToMenuSorting(menuEntries.map((entry) => entry.menu?.link as string));
    };

    const requestWrapper = createRequestWrapper<Role>(
      api,
      api.updateRole(role as Role),
      enqueueSnackbar,
      successCallback,
    );

    return await requestWrapper(endpoint, HttpMethod.PUT, this.successStatusCodes);
  };

  public handleDeleteRole = async (role: Role): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.ROLE,
      params: { id: role.id },
    };

    const successCallback = () => {
      searchContentStore.emitRefresh();
      api.checkAuthentication();
    };

    const requestWrapper = createRequestWrapper<void>(api, api.deleteRole(role), enqueueSnackbar, successCallback);

    return await requestWrapper(endpoint, HttpMethod.DELETE, this.successStatusCodes);
  };

  public actionHandlerUsers = async (role: Role) => {
    const { history } = this.props;

    history.push('/roles/' + role.id + '/users/');
  };

  public actionHandlerEdit = async (role: Role) => {
    const { navigationStore } = this.stores;
    const expandedPanel = ExpandedPanel.ADD;

    this.setState({
      expandedPanel,
      editableRole: role,
    });
    navigationStore.scrollTop();
  };

  public actionHandlerDeleteDialog = async (role: Role) => {
    this.setState({
      deleteDialogOpen: true,
      editableRole: role,
    });
  };

  public onDeleteOk = async () => {
    const { editableRole } = this.state;

    if (editableRole && editableRole.id !== undefined) {
      await this.handleDeleteRole(editableRole);
    }

    this.setState({
      editableRole: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      editableRole: undefined,
      deleteDialogOpen: false,
    });
  };

  public render() {
    const { api } = this.stores;
    const { classes } = this.props;
    const { allPermissions, deleteDialogOpen, editableRole, expandedPanel } = this.state;

    const hasWritePermission = api.userHasPermissionOnAnyNode(Permissions.ROLES_WRITE);

    const expansionPaperStyle =
      expandedPanel === ExpandedPanel.NONE
        ? {
            margin: 0,
            minHeight: 0,
            height: 0,
          }
        : {
            marginBottom: 48,
          };

    const deleteDialogText = editableRole ? `Delete Role ${editableRole.name}?` : '';

    const columnDefinitions: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(
        this.state,
        this.props as RoleManagementContentPropsWithStores,
        {
          users: this.actionHandlerUsers,
          edit: this.actionHandlerEdit,
          delete: this.actionHandlerDeleteDialog,
        },
        {
          updateRolePermissions: this.updateRolePermissions,
        },
      ),
    );

    return (
      <Grid item xs={12}>
        {deleteDialogOpen && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={deleteDialogOpen}
            title={'Delete Role'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        {hasWritePermission && <ContentActions onClick={this.onAddItemClick} />}

        <Paper className={classes.root} style={expansionPaperStyle}>
          <Accordion
            expanded={expandedPanel === ExpandedPanel.ADD}
            className={classNames(classes.expansion, expandedPanel === ExpandedPanel.ADD && classes.expansionExpanded)}
          >
            <AccordionDetails>
              {expandedPanel === ExpandedPanel.ADD && (
                <RolePanel
                  role={editableRole}
                  allPermissions={allPermissions}
                  closeHandler={this.onCloseAddItem}
                  saveHandler={this.handleSaveRole}
                  deleteHandler={this.onDeleteOk}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={this.fetchItems}
            columns={columnDefinitions}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            disableFooter={true}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<RoleManagementContentProps, typeof RoleManagementContentComponent>(
  RoleManagementContentComponent,
);
const StyleWrapped = withStyles(RoleManagementStyles)(RouterWrapped);

export const RoleManagementContent = StyleWrapped;
