import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography/Typography';
import { ExpandMore, HelpOutline } from '@mui/icons-material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Routes, RouteShortDefinition } from '../../Routes';
import ekkoLogo from '../ekko.png';
import { ApiStore } from '../Stores/ApiStore';
import { ConfigStore } from '../Stores/ConfigStore';
import { NavigationStore, SubMenuItem } from '../Stores/NavigationStore';
import { NavigationStyles } from '../Styles/NavigationStyles';
import { SortableSubmenu } from './Navigation/SortableSubmenu';

interface NavigationDrawerStores {
  api: ApiStore;
  configStore: ConfigStore;
  navigationStore: NavigationStore;
}

interface NavigationDrawerState {
  isSorting: boolean;
  sortingEnabled: boolean;
}

const stores = ['api', 'configStore', 'navigationStore'];

interface NavigationDrawerProps extends WithStyles<typeof NavigationStyles> {}

@inject(...stores)
@observer
class Navigation extends React.Component<NavigationDrawerProps, NavigationDrawerState> {
  public state: NavigationDrawerState = {
    isSorting: false,
    sortingEnabled: false,
  };

  get stores(): NavigationDrawerStores {
    return this.props as NavigationDrawerProps & NavigationDrawerStores;
  }

  public componentDidMount(): void {
    const { api, navigationStore } = this.stores;

    // Register routes configured with Menu and with permission.
    const routeFilter = (route: RouteShortDefinition) =>
      route.menu !== undefined &&
      (route.permission === undefined || api.anyNodePermissions.indexOf(route.permission) !== -1);

    const menuEntries = Routes.filter(routeFilter);

    navigationStore.applyPermissionsToMenuSorting(menuEntries.map((entry) => entry.menu?.link as string));
    navigationStore.applySortingToMenuEntries(menuEntries);

    // Gather routes belonging to a sub menu
    const subMenuMap = menuEntries.reduce(
      (acc: Record<string, RouteShortDefinition[]>, route: RouteShortDefinition) => {
        if (route.menu && route.menu.subMenu) {
          const subMenuName = route.menu.subMenu;

          if (Object.keys(acc).includes(subMenuName)) {
            acc[subMenuName].push(route);
          } else {
            acc[subMenuName] = [route];
          }
        }

        return acc;
      },
      {},
    );

    const subMenus = Object.keys(subMenuMap).map(
      (name: string): SubMenuItem => ({
        title: name,
        entries: subMenuMap[name],
      }),
    );

    navigationStore.applySortingToSubMenus(subMenus);
  }

  public toggleDrawer = () => {
    const { navigationStore } = this.stores;
    this.setState({ sortingEnabled: false }, () => {
      if (navigationStore.open) {
        navigationStore.drawerClose();
      } else {
        navigationStore.drawerOpen();
      }
    });
  };

  public toggleMenuSorting = () => {
    const { sortingEnabled } = this.state;
    this.setState({ sortingEnabled: !sortingEnabled });
  };

  public getDrawerOpenState = () => {
    const { navigationStore } = this.stores;
    return navigationStore.open;
  };

  public renderFAQListItem = (faqUrl: string) => {
    const { classes } = this.props;

    const listItem = (
      <ListItem
        button
        style={{
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <HelpOutline className={classes.navigationLinkIcon} />
        <ListItemText
          disableTypography
          className={classes.link}
          primary={<Typography>{'FAQ'}</Typography>}
          style={{ marginLeft: 28 }}
        />
      </ListItem>
    );

    return (
      <a href={faqUrl} className={classes.link} target={'_blank'} rel={'noopener noreferrer'}>
        {this.getDrawerOpenState() && listItem}
        {!this.getDrawerOpenState() && (
          <Tooltip
            title={<span className={classes.drawerTooltipTitle}>FAQ</span>}
            aria-label={'FAQ'}
            placement={'right'}
            enterDelay={150}
            leaveDelay={100}
          >
            {listItem}
          </Tooltip>
        )}
      </a>
    );
  };

  private createSubMenu(menuItem: SubMenuItem): React.JSX.Element {
    const { navigationStore } = this.stores;
    const { openSubMenues } = navigationStore;
    const { classes } = this.props;
    const { title, entries } = menuItem;
    const { sortingEnabled } = this.state;

    const isExpanded = openSubMenues.includes(title);
    const sortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      this.setState({ isSorting: true }, () => {
        navigationStore.moveSubMenuEntry(title, oldIndex, newIndex);
        this.setState({ isSorting: false });
      });
    };

    return (
      <ListItem
        key={`nav-sub-menu-item-${title}`}
        component={'div'}
        disableGutters
        style={{
          width: '100%',
          margin: 0,
          padding: 0,
        }}
      >
        <Accordion
          square
          elevation={0}
          expanded={isExpanded}
          onChange={() => navigationStore.toggleSubMenu(title)}
          style={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.subMenuHeader}
            style={{
              marginLeft: 0,
              width: '100%',
            }}
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            {sortingEnabled && <SortableSubmenu classes={classes} items={entries} onSortEnd={sortEnd} lockAxis={'y'} />}
            {!sortingEnabled && (
              <List disablePadding style={{ width: '100%' }}>
                {menuItem.entries.map((entry) => this.createLinkedListItem(entry))}
              </List>
            )}
          </AccordionDetails>
          <Divider />
        </Accordion>
      </ListItem>
    );
  }

  private createLinkedListItem(route: RouteShortDefinition): React.JSX.Element {
    const { open } = this.stores.navigationStore;
    const { classes } = this.props;
    const { title } = route;
    const { link, text, icon: Icon } = route.menu!;

    const listItem = (
      <ListItem
        key={`nav-entry-item-${title}`}
        button
        style={{
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Icon className={classes.navigationLinkIcon} />
        <ListItemText
          disableTypography
          className={classes.link}
          primary={<Typography>{text}</Typography>}
          style={{ marginLeft: 28 }}
        />
      </ListItem>
    );

    return (
      <Link className={classes.link} to={link} key={`${text}-${route.path}`}>
        {open && listItem}
        {!open && (
          <Tooltip
            title={<span className={classes.drawerTooltipTitle}>{text}</span>}
            aria-label={title}
            placement={'right'}
            enterDelay={150}
            leaveDelay={100}
          >
            {listItem}
          </Tooltip>
        )}
      </Link>
    );
  }

  public render() {
    const { configStore, navigationStore } = this.stores;
    const { classes } = this.props;
    const { sortedMenuEntries, sortedSubMenus } = navigationStore;
    const { isSorting, sortingEnabled } = this.state;

    const isOpen = this.getDrawerOpenState();

    const listItems = isSorting
      ? []
      : isOpen
      ? sortedSubMenus.map((subMenu: SubMenuItem) => this.createSubMenu(subMenu))
      : sortedMenuEntries.map((route: RouteShortDefinition) => this.createLinkedListItem(route));

    return (
      <Drawer
        variant="permanent"
        anchor="left"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: classNames(classes.drawerPaper, !this.getDrawerOpenState() && classes.drawerPaperClose),
        }}
        open={isOpen}
      >
        <div className={classes.toolbarIconWrapper}>
          <img
            src={ekkoLogo}
            className={classNames(classes.toolbarIconLogo, !this.getDrawerOpenState() && classes.toolbarIconLogoHidden)}
            alt="ekko"
          />
          {isOpen && (
            <Tooltip
              title={'Sort Menu Items'}
              aria-label={'Sort Menu Items'}
              placement={'bottom'}
              enterDelay={150}
              leaveDelay={100}
            >
              <IconButton
                onClick={this.toggleMenuSorting}
                style={{
                  marginLeft: 4,
                  marginRight: 4,
                  backgroundColor: sortingEnabled ? green[400] : undefined,
                  color: sortingEnabled ? 'white' : undefined,
                }}
                size="large"
              >
                <SwapVertIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            onClick={this.toggleDrawer}
            style={{
              marginLeft: 4,
              marginRight: 4,
            }}
            size="large"
          >
            {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {listItems}
          <Divider />
          {configStore.config?.faqUrl !== undefined && this.renderFAQListItem(configStore.config?.faqUrl)}
        </List>
      </Drawer>
    );
  }
}

export const NavigationDrawer = withStyles(NavigationStyles)(Navigation);
