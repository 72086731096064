import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { emphasize } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../../Helper/ThemeHelper';

const MenuMaxHeight = 160;

export const ReactSelectStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    minHeight: 56,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    margin: spacing(theme),
    paddingLeft: 6,
  },
  valueContainerFullValue: {
    padding: 0,
    fontSize: 12,
    color: grey[400],
    width: '100%',
    transition: theme.transitions.create(['height', 'padding'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
  },
  valueContainerFullValueExpanded: {
    padding: spacing(theme),
    height: 20,
    overflow: 'auto',
    position: 'initial',
  },
  chip: {
    margin: `${spacing(theme) / 2}px ${spacing(theme) / 2}px`,
    borderRadius: spacing(theme),
    '& svg': {
      width: 0,
      overflow: 'hidden',
      margin: '0 4px 0 -4px',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '&:hover svg': {
      width: 24,
    },
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${spacing(theme)}px ${spacing(theme) * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 14,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 9000,
    padding: 0,
    marginTop: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    animationDuration: '500ms',
    animationTimingFunction: 'ease',
    animationName: '$menuPaper',
    animationIterationCount: 'initial',
    borderBottomRightRadius: spacing(theme),
    borderBottomLeftRadius: spacing(theme),
  },
  menuList: {
    maxHeight: MenuMaxHeight,
    overflowY: 'auto',
    paddingBottom: 0,
    paddingTop: 0,
    boxSizing: 'border-box',
  },
  divider: {
    height: spacing(theme) * 2,
  },
  '@keyframes menuPaper': {
    '0%': {
      maxHeight: 0,
    },
    '100%': {
      maxHeight: MenuMaxHeight,
    },
  },
});
