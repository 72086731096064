import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { TemplateStore } from '../Stores/TemplateStore';
import { TemplatePreview } from './TemplatePreview';

const styles = FormStyles;

interface TemplateCreateOverviewProps extends WithStyles<typeof styles> {
  templateStore?: TemplateStore;
}

@inject('templateStore')
@observer
class TemplateCreateOverviewComponent extends React.Component<TemplateCreateOverviewProps> {
  public render() {
    const { templateStore } = this.props;
    const {
      state: { name, coordinate, type },
      templateFile,
      rendererResult,
    } = templateStore!;

    const overlineStyle = { fontWeight: 700 };

    const parsedFields = rendererResult!.fields;
    const parsedAnnos = rendererResult!.annotations;
    const parsedFieldKeys = Object.keys(parsedFields);
    const parsedAnnoKeys = Object.keys(parsedAnnos);

    return (
      <Grid container spacing={2} alignItems={'stretch'} style={{ wordBreak: 'break-word' }}>
        <Grid item xs={6}>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Name
          </Typography>
          <Typography variant="h6" gutterBottom>
            {name}
          </Typography>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Coordinate
          </Typography>
          <Typography variant="h6" gutterBottom>
            {coordinate}
          </Typography>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Type
          </Typography>
          <Typography variant="h6" gutterBottom>
            {type}
          </Typography>
          {parsedFieldKeys.length > 0 && (
            <>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Field Keys
              </Typography>
              <Grid container item>
                {parsedFieldKeys.map((key: string) => (
                  <Grid item xs={12} key={`template-field-${key}`}>
                    <Typography variant="h6" display={'inline'} gutterBottom>
                      {key}
                    </Typography>
                    <Typography color={'primary'} display={'inline'} style={{ margin: '4px' }}>
                      ({parsedFields[key]})
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {parsedAnnoKeys.length > 0 && (
            <>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Annotation Keys
              </Typography>
              <Grid container item>
                {parsedAnnoKeys.map((key: string) => (
                  <Grid item xs={12} key={`template-anno-${key}`}>
                    <Typography variant="h6" display={'inline'} gutterBottom>
                      {key}
                    </Typography>
                    <Typography color={'primary'} display={'inline'} style={{ margin: '4px' }}>
                      ({parsedAnnos[key]})
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
        <Grid container item xs={6}>
          {templateFile && (
            <Grid item xs={12}>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Filename
              </Typography>
              <Typography variant="h6" gutterBottom>
                {templateFile!.name}
              </Typography>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Filesize
              </Typography>
              <Typography variant="h6" gutterBottom>
                {Number(templateFile!.size / 1024).toFixed(2)} kB
              </Typography>
            </Grid>
          )}
          <Grid item xs={'auto'} zeroMinWidth>
            <Typography variant="overline" color={'primary'} style={overlineStyle}>
              Render Preview
            </Typography>
            <TemplatePreview rendererResult={rendererResult!} />
          </Grid>
        </Grid>

        {spacer(3)}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(TemplateCreateOverviewComponent);

export const TemplateCreateOverview = StyleWrapped;
