import { LinkProfile } from '@ekkogmbh/apisdk';
import { Chip, Typography } from '@mui/material';
import { Check, Clear, Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  LinkProfileManagementContentActionHandlers,
  LinkProfileManagementContentPropsWithStores,
  LinkProfileManagementContentState,
} from './LinkProfileManagementContent';

class LinkProfileDataTableActions extends DataTableActionsComponent<LinkProfile> {}

const LinkProfileActions = (
  _: LinkProfileManagementContentState,
  __: LinkProfileManagementContentPropsWithStores,
  actions: LinkProfileManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (linkProfile: LinkProfile): React.ReactNode => {
      const actionButtons: ActionButton<LinkProfile>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return <LinkProfileDataTableActions dataset={linkProfile} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: LinkProfile['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (coordinate: LinkProfile['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const Technology = (): MUIDataTableColumnDef => ({
  label: 'Technology',
  name: 'entry.technology',
  options: {
    sort: false,
    filter: false,
  },
});

// eslint-disable-next-line react/display-name
const TemplateGroupsCustomBody = (value: LinkProfile) => {
  const templateGroups = Object.keys(value.templateGroups);

  return (
    <div>
      {templateGroups.map((name: string) => {
        const isAutoGroup = value['autoTemplateGroup'] === name;
        return (
          <Chip
            style={{ margin: 8 }}
            key={`group-field-${name}`}
            variant={isAutoGroup ? 'outlined' : undefined}
            label={
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {name}
              </Typography>
            }
          />
        );
      })}
    </div>
  );
};

const TemplateGroups = (
  _: LinkProfileManagementContentState,
  __: LinkProfileManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Template Groups',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: TemplateGroupsCustomBody,
  },
});

// @FIXME: this is the save type because LinkProfile does not yet use the CompartmentSelectorEntity
const CompartmentSelectorCustomBody = (compartmentSelector: LinkProfile['compartmentSelector']) => (
  <Typography>{compartmentSelector.type}</Typography>
);

const CompartmentSelectorColumn = (): MUIDataTableColumnDef => ({
  label: 'Compartment Selector',
  name: 'entry.compartmentSelector',
  options: {
    sort: false,
    filter: false,
    customBodyRender: CompartmentSelectorCustomBody,
  },
});

const LabelInstructionsColumn = (): MUIDataTableColumnDef => ({
  label: 'Label Input Instructions',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: LinkProfile): React.ReactNode => (
      <Typography>{value.labelInputInstructions ?? '-'}</Typography>
    ),
  },
});

const CompartmentInstructionsColumn = (): MUIDataTableColumnDef => ({
  label: 'Compartment Input Instructions',
  name: 'entry.compartmentInputInstructions',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: LinkProfile['compartmentInputInstructions']): React.ReactNode => (
      <Typography>{value ?? '-'}</Typography>
    ),
  },
});

const CompartmentCreationColumn = (): MUIDataTableColumnDef => ({
  label: 'Compartment Creation',
  name: 'entry.noMatchStrategy',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: LinkProfile['noMatchStrategy']): React.ReactNode =>
      value && value.name !== 'null' ? <Check /> : <Clear />,
  },
});

const RemoveObsoleteCompartmentsColumn = (): MUIDataTableColumnDef => ({
  label: 'Compartment Removal',
  name: 'entry.removeObsoleteCompartments',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: LinkProfile['removeObsoleteCompartments']): React.ReactNode =>
      !!value ? <Check /> : <Clear />,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  LinkProfileManagementContentPropsWithStores,
  LinkProfileManagementContentState,
  LinkProfileManagementContentActionHandlers
>> = [
  Name,
  Coordinate,
  Technology,
  TemplateGroups,
  CompartmentSelectorColumn,
  LabelInstructionsColumn,
  CompartmentInstructionsColumn,
  CompartmentCreationColumn,
  RemoveObsoleteCompartmentsColumn,
  LinkProfileActions,
];
