import { CompartmentView, LinkView } from '@ekkogmbh/apisdk';
import { TableCell, Typography } from '@mui/material';
import { Delete, Edit, Highlight, Link as LinkIcon } from '@mui/icons-material';
import Assignment from '@mui/icons-material/Assignment';
import { MUIDataTableColumnDef, MUIDataTableColumnOptions } from 'mui-datatables';
import React, { CSSProperties, MouseEvent } from 'react';
import Highlighter from 'react-highlight-words';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { RowSelect } from '../../Common/Components/RowSelect';
import { RowSelectAll } from '../../Common/Components/RowSelectAll';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  CompartmentManagementContentActionHandlers,
  CompartmentManagementContentHelpers,
  CompartmentManagementContentPropsWithStores,
  CompartmentManagementContentState,
  ProcessingType,
} from './CompartmentManagementContent';
import { LinkChip } from './LinkChip';

class CompartmentDataTableActions extends DataTableActionsComponent<CompartmentView> {}

const CompartmentActions = (
  _: CompartmentManagementContentState,
  propsWithStores: CompartmentManagementContentPropsWithStores,
  actions: CompartmentManagementContentActionHandlers,
  helpers?: CompartmentManagementContentHelpers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (compartment: CompartmentView): React.ReactNode => {
      // TODO: utilize materialized_path once available?
      const nodeValues = compartment.coordinate.split(/[._—/]+/);

      const hasWritePermission = propsWithStores.api.userHasPermissionForNode(
        Permissions.SHELFLABELING_WRITE,
        nodeValues,
      );

      const actionButtons: ActionButton<CompartmentView>[] = [
        {
          title: 'Details',
          onClick: actions.details,
          icon: Assignment,
          disabled: compartment.operationCount < 1,
        },
        {
          title: 'Add Link',
          onClick: actions.link,
          icon: LinkIcon,
          disabled: !hasWritePermission,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Blink',
          onClick: actions.blink,
          icon: Highlight,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <CompartmentDataTableActions
          dataset={compartment}
          isProcessing={helpers!.isProcessing({
            id: compartment.coordinate,
            type: ProcessingType.COMPARTMENT,
          })}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const RowSelectCheckboxColumn = (coordinate: string) => <RowSelect identifier={coordinate} />;

const RowSelectHeadCell = ({ index, width }: Record<string, number>) => (
  <TableCell
    key={index}
    scope={'col'}
    style={{
      width,
      padding: '4px 8px',
    }}
  >
    <RowSelectAll />
  </TableCell>
);

// eslint-disable-next-line react/display-name
const CompartmentBodyRenderIdentifier = (propsWithStores: CompartmentManagementContentPropsWithStores) => (
  coordinate: string,
) => {
  const { searchText } = propsWithStores.searchContentStore;
  const unhighlightStyle: CSSProperties = {
    fontWeight: 700,
    fontSize: 14,
  };
  const highlightStyle: CSSProperties = {
    ...unhighlightStyle,
    backgroundColor: '#BBB',
  };

  return (
    <Highlighter
      searchWords={[searchText.replace(/\\/g, '\\\\')]}
      textToHighlight={coordinate}
      highlightStyle={highlightStyle}
      unhighlightStyle={unhighlightStyle}
    />
  );
};

const CompartmentIdentifier = (
  _: CompartmentManagementContentState,
  propsWithStores: CompartmentManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: CompartmentBodyRenderIdentifier(propsWithStores),
  },
});

const CompartmentBodyRenderFields = (
  propsWithStores: CompartmentManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (fields: CompartmentView['fields']) => {
  const { classes } = propsWithStores;

  return (
    <div className={classes.dataTableColumnFields}>
      {Object.keys(fields).map((key: string) => (
        <div
          key={key}
          style={{
            display: 'inline-block',
            width: 155,
            whiteSpace: 'normal',
            wordBreak: 'break-all',
          }}
        >
          <div style={{ margin: 8 }}>
            <Typography
              variant={'subtitle2'}
              color={'textSecondary'}
              style={{
                fontWeight: 700,
                wordBreak: 'keep-all',
              }}
            >
              {key}
            </Typography>
          </div>
          <div style={{ margin: 8 }}>
            <span>{fields[key]}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

const CompartmentFields = (
  _: CompartmentManagementContentState,
  propsWithStores: CompartmentManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Fields',
  name: 'entry.fields',
  options: {
    sort: false,
    filter: false,
    customBodyRender: CompartmentBodyRenderFields(propsWithStores),
  },
});

const CompartmentBodyRenderLinks = (
  propsWithStores: CompartmentManagementContentPropsWithStores,
  _: CompartmentManagementContentActionHandlers,
  helpers: CompartmentManagementContentHelpers,
  // eslint-disable-next-line react/display-name
) => (value: CompartmentView) => {
  if (value.links.length < 1) {
    return <div />;
  }

  const { classes } = propsWithStores;
  const { searchText } = propsWithStores.searchContentStore;
  const unhighlightStyle: CSSProperties = {};
  const highlightStyle: CSSProperties = {
    ...unhighlightStyle,
    backgroundColor: '#BBB',
  };

  const clickHandler = (event: MouseEvent<HTMLOrSVGElement>) => {
    const index = event.currentTarget.dataset.index;
    helpers.updateLink(value, index !== undefined ? parseInt(index, 10) : undefined);
  };

  const deleteHandlerWrapper = (link: LinkView) => async () => {
    await helpers.deleteLinkDialog(link);
  };

  return (
    <div className={classes.dataTableColumnLinks}>
      {value.links.map((link: LinkView, index: number) => (
        <LinkChip
          key={link.id}
          link={link}
          isProcessing={helpers.isProcessing({
            id: link.id,
            type: ProcessingType.LINK,
          })}
          dataIndex={index}
          clickHandler={clickHandler}
          deleteHandlerWrapper={deleteHandlerWrapper}
          highlighterOptions={{
            searchText,
            highlightStyle,
            unhighlightStyle,
          }}
        />
      ))}
    </div>
  );
};

const CompartmentLinks = (
  _: CompartmentManagementContentState,
  propsWithStores: CompartmentManagementContentPropsWithStores,
  actions: CompartmentManagementContentActionHandlers,
  helpers?: CompartmentManagementContentHelpers,
): MUIDataTableColumnDef => ({
  label: 'Links',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: CompartmentBodyRenderLinks(propsWithStores, actions, helpers!),
  },
});

const CompartmentSelectBox = (): MUIDataTableColumnDef => ({
  label: '',
  name: 'entry.coordinate',
  options: {
    sort: false,
    filter: false,
    // @TODO update datatable library - no, better remove it and use another
    customHeadRender: (RowSelectHeadCell as unknown) as MUIDataTableColumnOptions['customHeadRender'],
    customBodyRender: RowSelectCheckboxColumn,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  CompartmentManagementContentPropsWithStores,
  CompartmentManagementContentState,
  CompartmentManagementContentActionHandlers,
  CompartmentManagementContentHelpers
>> = [CompartmentSelectBox, CompartmentIdentifier, CompartmentFields, CompartmentLinks, CompartmentActions];

export { CompartmentSelectBox, CompartmentIdentifier, CompartmentFields, CompartmentLinks };
