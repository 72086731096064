import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing, withHint } from '../Helper/ThemeHelper';

export const CSVImportStepperStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  root: {
    width: '100%',
    padding: spacing(theme) * 2,
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  wrapper: {
    minHeight: 200,
  },
  instructions: {
    marginTop: spacing(theme),
    marginBottom: spacing(theme),
  },
  preview: {
    margin: '0 auto',
    width: '50%',
    padding: 4,
    boxSizing: 'border-box',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  previewInner: {
    textAlign: 'center',
  },
  dropZone: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'dashed 0.5rem',
    borderColor: withHint(theme.palette.text).hint,
    cursor: 'pointer',
    boxSizing: 'border-box',
    outline: 'none',
    overflow: 'none',
    transition: theme.transitions.create(['border', 'background-color', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },
  dropZoneLoaded: {
    border: 'dashed 0',
    cursor: 'default',
    borderColor: theme.palette.grey['50'],
    padding: '0.5rem',
    backgroundColor: theme.palette.background.paper,
  },
  uploadIcon: {
    fontSize: '8em',
    color: withHint(theme.palette.text).hint,
    display: 'block',
    margin: '0 auto',
    padding: spacing(theme) * 2,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },
  uploadIconTasksComplete: {
    color: '#92a2bd',
  },
  text: {
    textAlign: 'center',
    fontSize: '1.2em',
    marginBottom: spacing(theme) * 2,
  },
  hide: {
    display: 'none',
  },
  fileIcon: {
    color: theme.palette.primary.main,
    fontSize: '5em',
  },
  button: {
    marginRight: spacing(theme) * 2,
  },
});
