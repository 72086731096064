import { User, UserRoleNodeMapping } from '@ekkogmbh/apisdk';
import { IconButton, Input, InputAdornment, List, ListItem } from '@mui/material';
import { orange, red } from '@mui/material/colors';
import { Delete, Edit, SupervisedUserCircle, Update } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import moment from 'moment';
import React, { Fragment } from 'react';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { TableBodyFiller } from '../../Common/Components/TableBodyFiller';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  UserManagementContentActionHandlers,
  UserManagementContentHelpers,
  UserManagementContentPropsWithStores,
  UserManagementContentState,
} from './UserManagementContent';

class UserDataTableActions extends DataTableActionsComponent<User> {}

const UserActions = (
  _: UserManagementContentState,
  propsWithStores: UserManagementContentPropsWithStores,
  actions: UserManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (user: User): React.ReactNode => {
      const { api } = propsWithStores;

      const hasMappingsWritePermission = api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_READ);

      const actionButtons: ActionButton<User>[] = [
        {
          title: 'Roles',
          onClick: actions.roles,
          icon: SupervisedUserCircle,
          disabled: !hasMappingsWritePermission,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return <UserDataTableActions dataset={user} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const UserBodyRenderUsername = (
  propsWithStores: UserManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (name: User['username']) => {
  const { classes } = propsWithStores;

  return (
    <Fragment>
      <span className={classes.boldFont}>{name}</span>
    </Fragment>
  );
};

const UserUsername = (
  _: UserManagementContentState,
  propsWithStores: UserManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Username',
  name: 'entry.username',
  options: {
    sort: true,
    filter: false,
    customBodyRender: UserBodyRenderUsername(propsWithStores),
  },
});

const UserEmail = (): MUIDataTableColumnDef => ({
  label: 'E-Mail',
  name: 'entry.email',
  options: {
    sort: true,
    filter: false,
  },
});

const UserBodyRenderValidUntil = (
  helpers: UserManagementContentHelpers,
  // eslint-disable-next-line react/display-name
) => (user: User) => (
  <Input
    type={'text'}
    inputProps={{ style: { textAlign: 'center' } }}
    value={new Date(user.validUntil).toLocaleDateString()}
    readOnly={true}
    onClick={() => helpers.onClickValidUntil(user)}
    endAdornment={
      <InputAdornment position="end">
        <IconButton aria-label="Edit" onClick={() => helpers.onClickValidUntil(user)} size="large">
          <Update fontSize={'small'} />
        </IconButton>
      </InputAdornment>
    }
  />
);

const UserValidUntil = (
  _: UserManagementContentState,
  __: UserManagementContentPropsWithStores,
  ___: UserManagementContentActionHandlers,
  helpers?: UserManagementContentHelpers,
): MUIDataTableColumnDef => ({
  label: 'Valid-Until',
  name: 'entry',
  options: {
    sort: true,
    filter: false,
    customBodyRender: UserBodyRenderValidUntil(helpers!),
  },
});

const UserBodyRenderExpiring = () =>
  // eslint-disable-next-line react/display-name
  (validUntil: User['validUntil']) => {
    const momentDate = moment(validUntil);

    const hoursDiff = momentDate.diff(moment(), 'hours');

    let style = {};

    const saturation = 'A400';

    switch (true) {
      case hoursDiff <= 0:
        style = {
          color: red[saturation],
          fontWeight: 700,
        };
        break;

      case hoursDiff < 48:
        style = {
          color: orange[saturation],
          fontWeight: 700,
        };
        break;
    }

    return (
      <Fragment>
        <span style={style}>
          {hoursDiff <= 0 && 'expired '}
          {momentDate.fromNow()}
        </span>
      </Fragment>
    );
  };

const UserExpiring = (): MUIDataTableColumnDef => ({
  label: 'Expiring',
  name: 'entry.validUntil',
  options: {
    sort: false,
    filter: false,
    customBodyRender: UserBodyRenderExpiring(),
  },
});

const UserBodyRenderMappings = (
  propsWithStores: UserManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (mappings: User['userRoleNodeMappings']) => {
  if (mappings === undefined) {
    return;
  }

  const { classes } = propsWithStores;

  return (
    <List dense disablePadding>
      {mappings.map((userRoleNodeMapping: UserRoleNodeMapping, index: number) => (
        <ListItem dense key={index} className={classNames(classes.boldFont, classes.listItem)}>
          {userRoleNodeMapping.role.name}
        </ListItem>
      ))}
    </List>
  );
};

const UserMappings = (
  state: UserManagementContentState,
  propsWithStores: UserManagementContentPropsWithStores,
): MUIDataTableColumnDef => {
  if (state.nodeId !== undefined) {
    return {
      label: 'Roles',
      name: 'entry.userRoleNodeMappings',
      options: {
        sort: false,
        filter: false,
        customBodyRender: UserBodyRenderMappings(propsWithStores),
      },
    };
  }

  return TableBodyFiller;
};

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  UserManagementContentPropsWithStores,
  UserManagementContentState,
  UserManagementContentActionHandlers,
  UserManagementContentHelpers
>> = [UserUsername, UserEmail, UserValidUntil, UserExpiring, UserMappings, UserActions];

export { UserUsername, UserEmail, UserValidUntil, UserExpiring, UserMappings };
