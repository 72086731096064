import { Operation } from '@ekkogmbh/apisdk';
import { Description } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { DataTableRecord } from '../../Common/Stores/DataTableStore';

import {
  OperationIdentifier,
  OperationPayloadColumn,
  OperationTypeColumn,
} from '../../OperationGroups/Components/OperationDatatableColumnDefinitions';
import {
  CompartmentDetailsContentActionHandlers,
  CompartmentDetailsContentPropsWithStores,
  CompartmentDetailsContentState,
} from './CompartmentDetailsContent';

class CompartmentOperationDataTableActions extends DataTableActionsComponent<DataTableRecord<Operation>> {}

const CompartmentOperationActions = (
  __: CompartmentDetailsContentState,
  _: CompartmentDetailsContentPropsWithStores,
  actions: CompartmentDetailsContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: 'Trigger',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Operation): React.ReactNode => {
      const actionButtons: ActionButton<Operation>[] = [
        {
          title: 'Details',
          onClick: actions.details,
          icon: Description,
        },
      ];
      return (
        <CompartmentOperationDataTableActions dataset={value} isProcessing={false} actionButtons={actionButtons} />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  CompartmentDetailsContentPropsWithStores,
  CompartmentDetailsContentState,
  CompartmentDetailsContentActionHandlers
>> = [OperationIdentifier, OperationTypeColumn, OperationPayloadColumn, CompartmentOperationActions];
