import { RendererPayload, RendererResult, TemplateWithData } from '@ekkogmbh/apisdk';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { spacing } from '../../Common/Helper/ThemeHelper';
import { TemplateFieldForm } from './TemplateFieldForm';
import { TemplatePreview } from './TemplatePreview';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';

const styles = (theme: Theme) => ({
  button: {
    margin: spacing(theme),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

interface TemplatePreviewDialogProps extends WithStyles<typeof styles> {
  template: TemplateWithData;
  onClose: () => void;
  fetchRendererResult: (payload: RendererPayload) => Promise<RendererResult>;
  dialogProps: DialogProps;
}

interface TemplatePreviewDialogState {
  loading: boolean;
  rendering?: RendererResult;
  fields: Record<string, string>;
  annotations: Record<string, string>;
  valuesChanged: boolean;
}

class TemplatePreviewDialogComponent extends React.Component<TemplatePreviewDialogProps, TemplatePreviewDialogState> {
  public state: TemplatePreviewDialogState = {
    loading: true,
    rendering: undefined,
    fields: {},
    annotations: {},
    valuesChanged: true,
  };

  private renderTimeout?: number;

  public renderTemplateTimeout = () => {
    this.renderTimeout = window.setTimeout(this.onRefresh, 500);
  };

  public componentDidMount() {
    this.setState({ loading: true }, () => {
      this.onRefresh();
    });
  }

  public componentWillUnmount() {
    window.clearTimeout(this.renderTimeout);
  }

  public onFieldChange = (fields: Record<string, string>) => {
    window.clearTimeout(this.renderTimeout);

    this.setState({ fields, valuesChanged: true }, () => {
      this.renderTemplateTimeout();
    });
  };

  public onAnnotationChange = (annotations: Record<string, string>) => {
    window.clearTimeout(this.renderTimeout);

    this.setState({ annotations, valuesChanged: true }, () => {
      this.renderTemplateTimeout();
    });
  };

  public onRefresh = async () => {
    const { fetchRendererResult, template } = this.props;
    const { data, type } = template;
    const { fields, annotations, valuesChanged } = this.state;

    if (!valuesChanged) {
      return;
    }

    const rendering = await fetchRendererResult({
      data,
      type,
      fields,
      annotations,
      autoFill: true,
      render: true,
    });

    this.setState({ rendering, loading: false, valuesChanged: false });
  };

  public render() {
    const { template, onClose, dialogProps } = this.props;
    const { rendering, loading } = this.state;

    return (
      <Dialog onClose={onClose} {...dialogProps}>
        <DialogTitle id="template-preview-title">{template.name}</DialogTitle>
        <DialogContent>
          {loading && <LoadingMask />}
          {rendering && (
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={'auto'} zeroMinWidth>
                <TemplatePreview rendererResult={rendering} />
              </Grid>
              {Object.keys(rendering.fields).length > 0 && (
                <>
                  <Grid item xs={12}>
                    <StyledFormHeader label={'Fields'} />
                  </Grid>
                  <Grid item xs={12}>
                    <TemplateFieldForm fields={rendering.fields} fieldChangeCallback={this.onFieldChange} />
                  </Grid>
                </>
              )}
              {Object.keys(rendering.annotations).length > 0 && (
                <>
                  <Grid item xs={12}>
                    <StyledFormHeader label={'Annotations'} />
                  </Grid>
                  <Grid item xs={12}>
                    <TemplateFieldForm fields={rendering.annotations} fieldChangeCallback={this.onAnnotationChange} />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant={'contained'} color={'primary'} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const TemplatePreviewDialog = withStyles(styles)(TemplatePreviewDialogComponent);
