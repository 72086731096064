import { Button, Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { StyledTextField } from './StyledTextField';
import { StyledFormHeader } from './StyledFormHeader';

const styles = FormStyles;

interface ObjectListFormState<T> {
  currentItem: number | undefined;
  items: T[];
}

/**
 * Originally made for Notification Strategies, where we want to edit multiple objects within a list.
 * Not suitable for simple "objects" such as strings, since each objects contents are displayed individually.
 */
interface ObjectListFormProps<T> extends WithStyles<typeof styles> {
  title: string;
  defaultItem: T;
  items: T[] | undefined;
  onChange(items: T[]): void;
}

class ObjectListFormComponent<T extends Record<string, string | number>> extends React.Component<
  ObjectListFormProps<T>,
  ObjectListFormState<T>
> {
  public state: ObjectListFormState<T> = {
    currentItem: undefined,
    items: [],
  };

  public async componentDidMount(): Promise<void> {
    const { items } = this.props;

    if (items && items.length > 0) {
      this.setState({ items, currentItem: 0 });
    }
  }

  private addItem(): void {
    const { onChange, defaultItem } = this.props;
    const { items } = this.state;

    items.push({ ...defaultItem });

    this.setState({ items, currentItem: items.length - 1 }, () => onChange(items));
  }

  private removeItem(): void {
    const { onChange } = this.props;
    const { items, currentItem } = this.state;

    items.splice(currentItem!, 1);

    this.setState({ items, currentItem: items.length === 0 ? undefined : 0 }, () => onChange(items));
  }

  public render() {
    const { currentItem, items } = this.state;
    const { defaultItem, onChange, title } = this.props;

    const fields = Object.keys(defaultItem);

    return (
      <Grid container spacing={0} alignContent={'stretch'}>
        <Grid container item spacing={0} xs={12}>
          <Grid item xs={12}>
            <StyledFormHeader label={title} />
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => this.setState({ currentItem: currentItem! - 1 })} disabled={!currentItem}>
              {'<'}
            </Button>
            <Button
              onClick={() => this.setState({ currentItem: currentItem! + 1 })}
              disabled={currentItem === undefined || items.length === 0 || currentItem === items.length - 1}
            >
              {'>'}
            </Button>
            <Typography style={{ display: 'inline-block' }}>
              {currentItem !== undefined && items.length > 0
                ? (currentItem + 1).toString() + '/' + items.length.toString()
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => this.addItem()}>{'ADD'}</Button>
            <Button onClick={() => this.removeItem()}>{'DEL'}</Button>
          </Grid>
        </Grid>
        {currentItem !== undefined && (
          <Grid item container xs={12} spacing={1}>
            {fields.map((fieldName: string) => {
              const value = items[currentItem][fieldName];
              const isNumber = String(value) !== '' && !isNaN(Number(value));

              return (
                <Grid item xs={12} key={`generic-object-list-field-${fieldName}`}>
                  <StyledTextField
                    type={isNumber ? 'number' : 'text'}
                    value={items[currentItem][fieldName]}
                    label={fieldName.toLocaleUpperCase()}
                    onChange={(e) => {
                      let newValue: number | string;

                      if (isNumber) {
                        newValue = Number(e.target.value);
                      } else {
                        newValue = String(e.target.value);
                      }

                      items[currentItem] = {
                        ...items[currentItem],
                        [fieldName]: newValue,
                      };

                      this.setState({ items }, () => onChange(items));
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(ObjectListFormComponent);

export const ObjectListForm = StyleWrapped;
