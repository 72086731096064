import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { CsvDropzoneStore } from '../Common/Stores/CsvDropzoneStore';
import { CompartmentManagementContent } from './Components/CompartmentManagementContent';
import { LinkStore } from './Stores/LinkStore';

const csvDropzoneStore = new CsvDropzoneStore();
const linkStore = new LinkStore();

const stores = {
  csvDropzoneStore,
  linkStore,
};

export const CompartmentManagement = (): React.JSX.Element => (
  <Provider {...stores}>
    <Page title="Compartments" isSearchable={true}>
      <CompartmentManagementContent />
    </Page>
  </Provider>
);
