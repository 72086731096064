import { Zone } from '@ekkogmbh/apisdk';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  ZoneManagementContentActionHandlers,
  ZoneManagementContentPropsWithStores,
  ZoneManagementContentState,
} from './ZoneManagementContent';
import { Check, Clear, Delete, Edit, Receipt } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

class ZoneDataTableActions extends DataTableActionsComponent<Zone> {}

const Actions = (
  _: ZoneManagementContentState,
  __: ZoneManagementContentPropsWithStores,
  actions: ZoneManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (pool: Zone): React.ReactNode => {
      const actionButtons: ActionButton<Zone>[] = [
        {
          title: 'Blueprints',
          onClick: actions.blueprints,
          icon: Receipt,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return <ZoneDataTableActions dataset={pool} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: Zone['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: Zone['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const SourcePool = (): MUIDataTableColumnDef => ({
  label: 'Source Pool',
  name: 'entry.sourcePool',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (sourcePool: Zone['sourcePool']): React.ReactNode => (
      <div>{sourcePool ? sourcePool.name : '-'}</div>
    ),
  },
});

const DestinationPool = (): MUIDataTableColumnDef => ({
  label: 'Destination Pool',
  name: 'entry.destinationPool',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (destinationPool: Zone['destinationPool']): React.ReactNode => (
      <div>{destinationPool ? destinationPool.name : '-'}</div>
    ),
  },
});

const SourceConfirmationPattern = (): MUIDataTableColumnDef => ({
  label: 'Source Confirmation Pattern',
  name: 'entry.sourceConfirmationPattern',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Zone['sourceConfirmationPattern']): React.ReactNode => <div>{value}</div>,
  },
});

const DestinationConfirmationPattern = (): MUIDataTableColumnDef => ({
  label: 'Destination Confirmation Pattern',
  name: 'entry.destinationConfirmationPattern',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Zone['destinationConfirmationPattern']): React.ReactNode => <div>{value}</div>,
  },
});

const SortSteps = (): MUIDataTableColumnDef => ({
  label: 'Sort Steps',
  name: 'entry.sortSteps',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Zone['sortSteps']): React.ReactNode => <div>{value}</div>,
  },
});

const MaxParallelOpenOrders = (): MUIDataTableColumnDef => ({
  label: 'Max Parallel Open Orders',
  name: 'entry.maxParallelOpenOrders',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Zone['maxParallelOpenOrders']): React.ReactNode => <div>{value}</div>,
  },
});

const ConfirmationStrategy = (): MUIDataTableColumnDef => ({
  label: 'Confirmation Strategy',
  name: 'entry.confirmationStrategy.name',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (name: Zone['confirmationStrategy']['name']): React.ReactNode => <div>{name}</div>,
  },
});

const NotificationStrategy = (): MUIDataTableColumnDef => ({
  label: 'Notification Strategy',
  name: 'entry.notificationStrategy.name',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (name: Zone['notificationStrategy']['name']): React.ReactNode => <div>{name}</div>,
  },
});

const MergeStrategy = (): MUIDataTableColumnDef => ({
  label: 'Merge Strategy',
  name: 'entry.mergeStrategy.name',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (name: Zone['mergeStrategy']['name']): React.ReactNode => <div>{name}</div>,
  },
});

const AutoConclude = (): MUIDataTableColumnDef => ({
  label: 'Automatic-Conclude',
  name: 'entry.autoConclude',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Zone['autoConclude']): React.ReactNode => (value ? <Check /> : <Clear />),
  },
});

const AutoDelete = (): MUIDataTableColumnDef => ({
  label: 'Automatic-Delete',
  name: 'entry.autoDelete',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Zone['autoDelete']): React.ReactNode => (value ? <Check /> : <Clear />),
  },
});

const ButtonConfirmEventTopic = (): MUIDataTableColumnDef => ({
  label: 'Confirm-With-Button',
  name: 'entry.buttonConfirmationEventTopic',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Zone['buttonConfirmationEventTopic']): React.ReactNode => {
      if (!value) {
        return (
          <div>
            <Clear />
          </div>
        );
      }

      return (
        <div>
          <Tooltip
            title={
              <span>
                topic: <b>{value}</b>
              </span>
            }
            placement="top"
            enterDelay={500}
            leaveDelay={200}
          >
            <Check />
          </Tooltip>
        </div>
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  ZoneManagementContentPropsWithStores,
  ZoneManagementContentState,
  ZoneManagementContentActionHandlers
>> = [
  Name,
  Coordinate,
  SourcePool,
  DestinationPool,
  SourceConfirmationPattern,
  DestinationConfirmationPattern,
  SortSteps,
  MaxParallelOpenOrders,
  ConfirmationStrategy,
  NotificationStrategy,
  MergeStrategy,
  AutoConclude,
  AutoDelete,
  ButtonConfirmEventTopic,
  Actions,
];
