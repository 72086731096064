import { BlinkColor, NotificationStrategy, NotificationStrategyPayload } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export class NotificationStrategyStore {
  @observable
  public strategies: NotificationStrategyPayload[] = [];

  @observable
  public changed: boolean = false;

  @action
  public addStrategy = (strategy: NotificationStrategy = NotificationStrategy.LIGHT): void => {
    let configuration: Record<string, string | number | boolean>;

    // select default config based on type
    switch (strategy) {
      case NotificationStrategy.LIGHT:
        configuration = {
          color: BlinkColor.RED,
          duration: 30,
          bypassQueue: false,
        };
        break;
      case NotificationStrategy.MQTT:
      case NotificationStrategy.COMBINED:
      case NotificationStrategy.NULL:
      default:
        configuration = {};
    }

    const strats = [...this.strategies];
    strats.push({
      name: NotificationStrategy.LIGHT,
      configuration,
    });
    this.strategies = strats;
    this.changed = true;
  };

  @action
  public removeStrategy = (index: number): void => {
    const strats = [...this.strategies];
    strats.splice(index, 1);
    this.strategies = strats;
    this.changed = true;
  };

  @action
  public setStrategy = (index: number, strategy: NotificationStrategyPayload): void => {
    const strats = [...this.strategies];
    strats[index] = strategy;
    this.strategies = strats;
    this.changed = true;
  };

  @action
  public resetStore = (initialState?: NotificationStrategyPayload): void => {
    if (initialState) {
      switch (initialState.name) {
        case NotificationStrategy.COMBINED:
          this.strategies = initialState.configuration.strategies as Array<NotificationStrategyPayload>;
          break;
        case NotificationStrategy.NULL:
          this.strategies = [];
          break;
        default:
          this.strategies = [initialState];
      }
    } else {
      this.strategies = [];
    }

    this.changed = false;
  };

  public getMetaStrategy = (): NotificationStrategy => {
    switch (this.strategies.length) {
      case 0:
        return NotificationStrategy.NULL;
      case 1:
        return this.strategies[0] ? this.strategies[0].name : NotificationStrategy.NULL;
      default:
        return NotificationStrategy.COMBINED;
    }
  };

  public getSavableNotificationStrategy = (): NotificationStrategyPayload => {
    switch (this.strategies.length) {
      case 0:
        return {
          name: NotificationStrategy.NULL,
          configuration: {},
        };
      case 1:
        return {
          name: this.strategies[0].name,
          configuration: this.strategies[0].configuration,
        };
      default:
        return {
          name: NotificationStrategy.COMBINED,
          configuration: {
            strategies: this.strategies,
          },
        };
    }
  };
}
