import { PaginationResponse } from '@ekkogmbh/apisdk';

export const injectFakePagination = <T>(data: T[]): PaginationResponse<T> => {
  const count = data.length;

  return {
    currentPage: 1,
    items: data,
    pageCount: 1,
    itemsPerPage: count,
    totalItemCount: count,
  };
};
