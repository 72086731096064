import {
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  Zone,
  ZoneIdentifier,
} from '@ekkogmbh/apisdk';
import { Fade, ListItemText, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface ZonePickerStores {
  api: ApiStore;
}

interface ZonePickerProps extends WithStyles<typeof styles> {
  selected?: ZoneIdentifier;
  optional?: boolean;
  onChange: (zone: Zone | undefined) => void;
  onError?: () => void;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
  label?: string;
}

interface ZonePickerState {
  loading: boolean;
  failure: boolean;
  zones: (Zone | undefined)[];
}

@inject('api')
class ZonePickerComponent extends Component<ZonePickerProps, ZonePickerState> {
  public state: ZonePickerState = {
    loading: true,
    failure: false,
    zones: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): ZonePickerStores {
    return this.props as ZonePickerProps & ZonePickerStores;
  }

  public componentDidMount(): void {
    this.setState({ loading: true }, this.fetchZones);
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchZones = async (): Promise<void> => {
    const { api } = this.stores;
    const { onError, onChange, optional, selected } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, zones: [] }, onError);
    };

    let zones: (Zone | undefined)[] = [];
    try {
      const pagedZones = await request<PaginationResponse<Zone>>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getZones({ page: 1, limit: 10 } as Pagination),
        EslManagerPublicRouteV2.ZONES,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );

      if (pagedZones.items) {
        zones = pagedZones.items;
      }
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    if (optional) {
      zones.unshift(undefined);
    }

    if (zones.length > 0) {
      this.setState({ loading: false, zones });

      if (!selected && !optional) {
        onChange(zones[0]);
      }
    } else {
      onErrorCallback();
    }
  };

  private getListIndex = (zone?: ZoneIdentifier): number => {
    const { zones } = this.state;

    if (zone === undefined) {
      return 0;
    }

    return zones.findIndex((z: Zone | undefined) => z && z.name === zone.name && z.coordinate === zone.coordinate);
  };

  private onChangeZone = (event: SelectChangeEvent<unknown>): void => {
    const { onChange } = this.props;
    const { zones } = this.state;

    const value = event.target.value as number;

    onChange(zones[value]);
  };

  private createMenuItem = (index: number, zone?: Zone): React.JSX.Element => {
    const name = zone ? zone.name : '-';
    const coordinate = zone ? zone.coordinate : '';

    return (
      <MenuItem key={`zone-picker-item-${name}-${coordinate}`} value={index}>
        <ListItemText primary={name} secondary={coordinate} />
      </MenuItem>
    );
  };

  public render() {
    const { label, disabled, selected } = this.props;
    const { zones, loading, failure } = this.state;

    const entries = zones.map((zone: Zone | undefined, index: number) => this.createMenuItem(index, zone));

    return (
      <>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
          >
            <Fade in={true} timeout={2000}>
              <CheckmarkSpinner complete={false} failure={failure} />
            </Fade>
          </div>
        )}
        {!loading && (
          <StyledSelectField
            value={selected ? this.getListIndex(selected) : 0}
            label={label ?? 'Zone'}
            onChange={this.onChangeZone}
            disabled={disabled}
          >
            {entries}
          </StyledSelectField>
        )}
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(ZonePickerComponent);

export const ZonePicker = StyleWrapped;
