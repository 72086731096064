import { EslManagerPublicRouteV1, HttpMethod, LinkProfile, Pagination, PaginationResponse } from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { LinkProfileStore } from '../Stores/LinkProfileStore';
import { LinkProfileManagementStyles } from '../Styles/LinkProfileManagementStyles';
import { materialDatatableColumnDefinitions } from './LinkProfileDatatableColumnDefinitions';
import { LinkProfilePanel, LinkProfilePanelProps } from './LinkProfilePanel';

const styles = LinkProfileManagementStyles;

const stores = ['api', 'linkProfileStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

interface LinkProfileManagementContentActions {
  updateLinkProfile?: (linkProfile: LinkProfile) => void;
}

export interface LinkProfileManagementContentActionHandlers {
  edit: (linkProfile: LinkProfile) => void;
  delete: (linkProfile: LinkProfile) => void;
}

export interface LinkProfileManagementContentStores {
  api: ApiStore;
  linkProfileStore: LinkProfileStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface LinkProfileManagementContentState {
  deletableLinkProfile?: LinkProfile;
}

export interface LinkProfileManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type LinkProfileManagementContentPropsWithStores = LinkProfileManagementContentProps &
  LinkProfileManagementContentStores;

@inject(...stores)
class LinkProfileManagementContentComponent extends Component<
  LinkProfileManagementContentProps,
  LinkProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<LinkProfile> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<LinkProfile> = {
    name: 'LP.name',
  };
  private readonly actions: LinkProfileManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): LinkProfileManagementContentStores {
    return this.props as LinkProfileManagementContentPropsWithStores;
  }

  public state: LinkProfileManagementContentState = {
    deletableLinkProfile: undefined,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchLinkProfiles = async (pagination: Pagination): Promise<PaginationResponse<LinkProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<LinkProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getLinkProfiles(pagination),
      EslManagerPublicRouteV1.LINK_PROFILES,
      HttpMethod.GET,
    );
  };

  public onDelete = async (linkProfile: LinkProfile): Promise<void> => {
    this.setState({
      deletableLinkProfile: linkProfile,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      deletableLinkProfile: undefined,
    });
  };

  public onDeleteOk = async () => {
    const { deletableLinkProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableLinkProfile) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteLinkProfile(deletableLinkProfile.coordinate, deletableLinkProfile.name),
      EslManagerPublicRouteV1.LINK_PROFILE,
      HttpMethod.DELETE,
      { 200: 'LinkProfile deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (linkProfile: LinkProfile): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateLinkProfile } = this.actions;

    if (updateLinkProfile) {
      updateLinkProfile(linkProfile);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (linkProfilePayload: LinkProfile): Promise<LinkProfile> => {
    const { api, navigationStore, searchContentStore, linkProfileStore } = this.stores;
    const { editableLinkProfile } = linkProfileStore;

    const doOverwrite = editableLinkProfile !== undefined;

    const linkProfile = await request<LinkProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveLinkProfile(linkProfilePayload, doOverwrite),
      EslManagerPublicRouteV1.LINK_PROFILE,
      HttpMethod.PUT,
      { 200: 'Link Profile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return linkProfile;
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { linkProfileStore } = this.stores;

    const addLinkProfilePanelDefinition: ContentPanelDefinition<LinkProfilePanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: LinkProfilePanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.LINK_PROFILES_WRITE,
      toggleOffCallback: () => linkProfileStore.resetStore(),
      expandHandler: (expandCallback: () => void) => {
        const { updateLinkProfile } = this.actions;
        if (updateLinkProfile === undefined) {
          this.actions.updateLinkProfile = async (linkProfile: LinkProfile) => {
            expandCallback();
            linkProfileStore.setEditableLinkProfile(linkProfile);
          };
        }
      },
    };

    return [addLinkProfilePanelDefinition];
  };

  public render() {
    const { deletableLinkProfile } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as LinkProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />

        {deletableLinkProfile && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableLinkProfile}
            title={'Delete Link Profile'}
            text={deletableLinkProfile.name}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchLinkProfiles}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<LinkProfileManagementContentProps, typeof LinkProfileManagementContentComponent>(
  LinkProfileManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const LinkProfileManagementContent = StyleWrapped;
