import { ReceivingArea, ReceivingAreaProfileMapping, SaveSupplyProfilePayload, SupplyProfile } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export type SupplyProfileState = Omit<SupplyProfile, 'receivingAreaMappings'> & {
  allFilled: boolean;
  areaMappingByCoordinate: Record<string, ReceivingAreaProfileMapping>; // indexing by coordinate to avoid having to search
};

export class SupplyProfileStore {
  @observable
  public editableSupplyProfile?: SupplyProfile;

  @observable
  public state: SupplyProfileState = {
    name: '',
    coordinate: '',
    areaMappingByCoordinate: {},
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<SupplyProfileState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.state.allFilled = this.state.coordinate !== '' && this.state.name !== '';
  };

  public static mapMappingsByCoordinate = (
    receivingAreaMappings: ReceivingAreaProfileMapping[],
  ): Record<string, ReceivingAreaProfileMapping> =>
    receivingAreaMappings.reduce((carry, mapping) => {
      carry[mapping.receivingArea.coordinate] = mapping;
      return carry;
    }, {});

  @action
  public resetStore = (supplyProfile?: SupplyProfile): void => {
    if (supplyProfile !== undefined) {
      const { name, coordinate, receivingAreaMappings } = supplyProfile;
      this.setState({
        name,
        coordinate,
        areaMappingByCoordinate: SupplyProfileStore.mapMappingsByCoordinate(receivingAreaMappings),
      });
    } else {
      this.setState({
        name: '',
        coordinate: '',
        areaMappingByCoordinate: {},
      });
    }

    this.editableSupplyProfile = supplyProfile;
  };

  @action
  public setReceivingAreas = (areas: ReceivingArea[]): void => {
    const currentAreaCoordinates = Object.keys(this.state.areaMappingByCoordinate);
    const createMapping = (receivingArea: ReceivingArea): ReceivingAreaProfileMapping => {
      if (currentAreaCoordinates.includes(receivingArea.coordinate)) {
        return this.state.areaMappingByCoordinate[receivingArea.coordinate];
      } else {
        const states = receivingArea.replenishmentPlan.linearStateMachineDefinition.states;
        return {
          receivingArea,
          observedStates: states,
          observedFields: [],
          stateColors: states.reduce((carry, state) => {
            carry[state] =
              '#' +
              Math.random()
                .toString(16)
                .slice(-6);
            return carry;
          }, {}),
        };
      }
    };

    const areasByCoordinate = areas.reduce((carry, area) => {
      carry[area.coordinate] = createMapping(area);
      return carry;
    }, {});

    this.setState({ areaMappingByCoordinate: areasByCoordinate });
  };

  public getPayload = (): SaveSupplyProfilePayload => ({
    receivingAreas: Object.values(this.state.areaMappingByCoordinate).map((mapping: ReceivingAreaProfileMapping) => {
      const { observedStates, observedFields, stateColors, receivingArea } = mapping;
      const { name, coordinate } = receivingArea;

      // remove colors for states that are not observed
      Object.keys(stateColors).forEach((state: string) => {
        if (!observedStates.includes(state)) {
          delete stateColors[state];
        }
      });

      return { name, coordinate, observedStates, observedFields, stateColors };
    }),
  });
}
