import { TaskCollection } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export class CsvDropzoneStore {
  @observable
  public taskCollection: TaskCollection | undefined;

  @observable
  public file: File | undefined;

  @action
  public getFile(): File | undefined {
    return this.file;
  }

  @action
  public setFile(file: File | undefined): void {
    this.file = file;
  }

  @action
  public removeFile(): void {
    this.file = undefined;
  }

  @action
  public reset(): void {
    this.file = undefined;
    this.taskCollection = undefined;
  }

  @action
  public onSuccessfulImport = (taskCollectionId: string, state: string, progress?: number): void => {
    this.taskCollection = { id: taskCollectionId, state, progress } as TaskCollection;
  };
}
