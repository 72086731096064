import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography/Typography';
import { injectFakePagination } from '../../Common/Helper/Pagination';
import { BlueprintManagementStyles } from '../Styles/BlueprintManagementStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { inject } from 'mobx-react';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { Add } from '@mui/icons-material';
import { BlueprintPanel, BlueprintPanelProps } from './BlueprintPanel';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import {
  Blueprint,
  BlueprintValue,
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  Zone,
} from '@ekkogmbh/apisdk';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './BlueprintColumnDefinitions';
import { request } from 'src/Common/Helper/FetchHandler';
import { BlueprintStore } from '../Stores/BlueprintStore';
import { GenericDialog } from 'src/Common/Components/GenericDialog';

const styles = BlueprintManagementStyles;

const stores = ['api', 'searchContentStore', 'navigationStore', 'blueprintStore'];

interface BlueprintManagementContentActions {
  updateBlueprint?: (blueprint: Blueprint) => void;
}

export interface BlueprintManagementContentActionHandlers {
  delete: (blueprint: Blueprint) => void;
  showSteps: (blueprint: Blueprint) => void;
  edit: (blueprint: Blueprint) => void;
}

export interface BlueprintManagementContentStores {
  api: ApiStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  blueprintStore: BlueprintStore;
}

export interface BlueprintManagementContentState {
  currentZoneIdentifier: Pick<Zone, 'name' | 'coordinate'>;
  deletableBlueprint?: Blueprint;
  stepsDialogOpen: boolean;
  stepsDialogBlueprint?: Blueprint;
}

export interface BlueprintManagementContentParams {
  zoneName: string;
  coordinate: string;
}

export interface BlueprintManagementContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<BlueprintManagementContentParams> {}

export type BlueprintManagementContentPropsWithStores = BlueprintManagementContentProps &
  BlueprintManagementContentStores;

@inject(...stores)
class BlueprintManagementContentComponent extends Component<
  BlueprintManagementContentProps,
  BlueprintManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<Blueprint> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<Blueprint> = { name: 'B.name' };
  private readonly actions: BlueprintManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): BlueprintManagementContentStores {
    return this.props as BlueprintManagementContentPropsWithStores;
  }

  public state: BlueprintManagementContentState = {
    currentZoneIdentifier: {
      name: '',
      coordinate: '',
    },
    stepsDialogOpen: false,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public static getDerivedStateFromProps(
    props: Readonly<BlueprintManagementContentProps>,
    state: BlueprintManagementContentState,
  ): Partial<BlueprintManagementContentState> | null {
    const currentZoneIdentifier = {
      name: props.match.params.zoneName,
      coordinate: props.match.params.coordinate,
    };

    if (currentZoneIdentifier !== state.currentZoneIdentifier) {
      return {
        currentZoneIdentifier,
      };
    }

    return null;
  }

  private fetchBlueprints = async (_: Pagination): Promise<PaginationResponse<Blueprint>> => {
    const { api } = this.stores;
    const {
      currentZoneIdentifier: { name, coordinate },
    } = this.state;

    if (!name || !coordinate) {
      return { currentPage: 0, items: [], pageCount: 0, itemsPerPage: 0, totalItemCount: 0 };
    }

    const blueprints = await request<Blueprint[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getBlueprints(coordinate, name),
      EslManagerPublicRouteV2.BLUEPRINTS,
      HttpMethod.GET,
    );

    return injectFakePagination<Blueprint>(blueprints);
  };

  private onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, blueprintStore } = this.stores;
    const { editableBlueprint } = blueprintStore;

    await request<Blueprint>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveBlueprint(blueprintStore.getSavableBlueprint(), editableBlueprint !== undefined),
      EslManagerPublicRouteV2.BLUEPRINT,
      HttpMethod.PUT,
      { 200: 'Blueprint saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  private onEdit = async (blueprint: Blueprint): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateBlueprint } = this.actions;

    if (updateBlueprint) {
      updateBlueprint(blueprint);
      navigationStore!.scrollTop();
    }
  };

  private onDeleteConfirm = async () => {
    const { deletableBlueprint } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableBlueprint) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteBlueprint(deletableBlueprint),
      EslManagerPublicRouteV2.BLUEPRINT,
      HttpMethod.DELETE,
      { 200: 'Blueprint deleted.' },
    );

    this.setState({ deletableBlueprint: undefined }, () => searchContentStore.emitRefresh());
  };

  private createPanel = (): ContentPanelDefinition<BlueprintPanelProps> => {
    const { blueprintStore } = this.stores;
    return {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: BlueprintPanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
        zoneIdentifier: this.state.currentZoneIdentifier,
      },
      permission: Permissions.ZONES_WRITE,
      toggleOffCallback: blueprintStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateBlueprint } = this.actions;
        if (updateBlueprint === undefined) {
          this.actions.updateBlueprint = async (blueprint: Blueprint) => {
            expandCallback();
            blueprintStore.setEditableBlueprint(blueprint);
          };
        }
      },
    };
  };

  private showSteps = (blueprint: Blueprint) => {
    this.setState({ stepsDialogOpen: true, stepsDialogBlueprint: blueprint });
  };

  private closeSteps = () => {
    this.setState({ stepsDialogOpen: false, stepsDialogBlueprint: undefined });
  };

  public render() {
    const { deletableBlueprint, stepsDialogBlueprint, stepsDialogOpen } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as BlueprintManagementContentPropsWithStores, {
        showSteps: this.showSteps,
        delete: (blueprint) => this.setState({ deletableBlueprint: blueprint }),
        edit: (blueprint) => this.onEdit(blueprint),
      }),
    );

    const stepsTextFieldStyle = {
      color: '#555',
      borderColor: '#555',
    };

    return (
      <Grid item xs={12}>
        <ContentPanels panels={[this.createPanel()]} />

        {stepsDialogBlueprint && stepsDialogOpen && (
          <Dialog open={stepsDialogOpen} onClose={this.closeSteps}>
            <DialogTitle>Blueprint Steps - {stepsDialogBlueprint.name}</DialogTitle>
            <DialogContent>
              <Grid container spacing={0}>
                {stepsDialogBlueprint.steps.map((step, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    style={{ borderColor: '#eee', borderStyle: 'solid', borderRadius: 8, margin: 12, padding: 12 }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography variant={'h6'}>Source</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {step.source.map((source: BlueprintValue, sourceIndex) => (
                            <Grid item xs={4} key={sourceIndex}>
                              <TextField
                                inputProps={{ style: stepsTextFieldStyle }}
                                label={''}
                                value={source.value}
                                name={''}
                                disabled={true}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position={'start'}>
                                      <TextField
                                        variant="standard"
                                        style={{ maxWidth: '70px' }}
                                        type={'text'}
                                        label={''}
                                        value={source.amount}
                                        disabled={true}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={'h6'}>Destination</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {step.destination.map((destination: BlueprintValue, destinationIndex) => (
                            <Grid item xs={4} key={destinationIndex}>
                              <TextField
                                inputProps={{ style: stepsTextFieldStyle }}
                                label={''}
                                value={destination.value}
                                name={''}
                                disabled={true}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position={'start'}>
                                      <TextField
                                        variant="standard"
                                        style={{ maxWidth: '70px' }}
                                        type={'text'}
                                        label={''}
                                        value={destination.amount}
                                        disabled={true}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeSteps} color="primary" variant={'contained'}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {deletableBlueprint && (
          <GenericDialog
            type={'confirmation'}
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableBlueprint}
            title={'Delete Blueprint'}
            text={deletableBlueprint.name}
            onClose={() => this.setState({ deletableBlueprint: undefined })}
            onConfirm={this.onDeleteConfirm}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchBlueprints}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<BlueprintManagementContentProps, typeof BlueprintManagementContentComponent>(
  BlueprintManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const BlueprintManagementContent = StyleWrapped;
