import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, EventRule } from '@ekkogmbh/apisdk';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { EventRuleStore } from '../Stores/EventRuleStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject } from 'mobx-react';
import { Component } from 'react';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { WithStyles, withStyles } from '@mui/styles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './EventRuleDatatableColumnDefinitions';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { Grid, Paper } from '@mui/material';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import classNames from 'classnames';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { Add } from '@mui/icons-material';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { EventRuleManagementStyles } from '../Styles/EventRuleManagementStyles';
import { EventRulePanel, EventRulePanelProps } from './EventRulePanel';

interface EventRuleManagementContentActions {
  updateEventRule?: (EventRule: EventRule) => void;
}

export interface EventRuleManagementContentActionHandlers {
  edit: (eventRule: EventRule) => void;
  delete: (eventRule: EventRule) => void;
}

export interface EventRuleManagementContentState {
  deletableEventRule?: EventRule;
}

interface EventRuleManagementContentStores {
  api: ApiStore;
  eventRuleStore: EventRuleStore;
  navigationStore: NavigationStore;
  searchContentStore: SearchContentStore;
}

interface EventRuleManagementContentProps extends WithStyles<typeof EventRuleManagementStyles>, RouteComponentProps {}

export type EventRuleManagementContentPropsWithStores = EventRuleManagementContentProps &
  EventRuleManagementContentStores;

@inject('api', 'eventRuleStore', 'navigationStore', 'searchContentStore')
class EventRuleManagementContentComponent extends Component<
  EventRuleManagementContentProps,
  EventRuleManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<EventRule> = ['name', 'coordinate'];
  private readonly sortFieldMaps: DataTableSortFieldMap<EventRule> = { name: 'entry.name' };
  private readonly actions: EventRuleManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  public state: EventRuleManagementContentState = {};

  get stores(): EventRuleManagementContentStores {
    return this.props as EventRuleManagementContentProps & EventRuleManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<EventRule>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<EventRule>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getEventRules(pagination),
      EslManagerPublicRouteV1.EVENT_RULES,
      HttpMethod.GET,
    );
  };

  public onEdit = async (eventRule: EventRule): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateEventRule } = this.actions;

    if (updateEventRule) {
      updateEventRule(eventRule);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, eventRuleStore } = this.stores;
    const { name, coordinate, trigger, action } = eventRuleStore.state;

    // prune unset trigger properties
    Object.keys(trigger!.properties).forEach((propKey) => {
      if (trigger!.properties[propKey] === '') {
        delete trigger!.properties[propKey];
      }
    });

    await request<EventRule>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveEventRule(
        { name: name!, coordinate: coordinate! },
        { trigger: trigger!, action: action! },
        eventRuleStore.editableEventRule !== undefined,
      ),
      EslManagerPublicRouteV1.EVENT_RULE,
      HttpMethod.PUT,
      { 200: 'Event Rule saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  public onDelete = async (EventRule: EventRule): Promise<void> => {
    this.setState({ deletableEventRule: EventRule });
  };

  public onDeleteDismiss = async () => {
    this.setState({ deletableEventRule: undefined });
  };

  public onDeleteConfirm = async () => {
    const { deletableEventRule } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableEventRule) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteEventRule(deletableEventRule),
      EslManagerPublicRouteV1.EVENT_RULE,
      HttpMethod.DELETE,
      { 204: 'Event Rule deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { eventRuleStore } = this.stores;

    const addPanelDefinition: ContentPanelDefinition<EventRulePanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: EventRulePanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.EKANBAN_WRITE,
      toggleOffCallback: eventRuleStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateEventRule } = this.actions;
        if (updateEventRule === undefined) {
          this.actions.updateEventRule = async (eventRule: EventRule) => {
            eventRuleStore.resetStore(eventRule);
            expandCallback();
          };
        }
      },
    };

    return [addPanelDefinition];
  };

  public render() {
    const { classes } = this.props;
    const { deletableEventRule } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as EventRuleManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />
        {deletableEventRule && (
          <GenericDialog
            title={'Delete Supply Profile'}
            text={deletableEventRule.name}
            type={'confirmation'}
            maxWidth={'sm'}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteConfirm}
            fullWidth
            centered
            open
          />
        )}

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMaps}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<EventRuleManagementContentProps, typeof EventRuleManagementContentComponent>(
  EventRuleManagementContentComponent,
);
const StyleWrapped = withStyles(EventRuleManagementStyles)(RouterWrapped);

export const EventRuleManagementContent = StyleWrapped;
