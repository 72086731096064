import {
  CompartmentSelectorIndex,
  EslManagerPublicRouteV1,
  Finder,
  HttpMethod,
  Pagination,
  PaginationResponse,
  SaveFinderPayload,
} from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { Add } from '@mui/icons-material';
import { withStyles, WithStyles } from '@mui/styles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { FinderStore } from '../Stores/FinderStore';
import { FinderManagementStyles } from '../Styles/FinderStyles';
import { materialDatatableColumnDefinitions } from './FinderDatatableColumnDefinitions';
import { FinderPanel, FinderPanelProps } from './FinderPanel';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';

const styles = FinderManagementStyles;

const stores = [
  'api',
  'finderStore',
  'notificationStrategyStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
];

interface FinderManagementContentActions {
  updateFinder?: (finder: Finder) => void;
}

export interface FinderManagementContentActionHandlers {
  edit: (finder: Finder) => void;
  delete: (finder: Finder) => void;
}

export interface FinderManagementContentStores {
  api: ApiStore;
  finderStore: FinderStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  notificationStrategyStore: NotificationStrategyStore;
}

export interface FinderManagementContentState {
  deletableFinder?: Finder;
}

export interface FinderManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type FinderManagementContentPropsWithStores = FinderManagementContentProps & FinderManagementContentStores;

@inject(...stores)
class FinderManagementContentComponent extends Component<FinderManagementContentProps, FinderManagementContentState> {
  private readonly filterFields: DataTableFilterFields<Finder> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<Finder> = { name: 'F.name' };
  private readonly actions: FinderManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): FinderManagementContentStores {
    return this.props as FinderManagementContentPropsWithStores;
  }

  public state: FinderManagementContentState = {};

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchFinders = async (pagination: Pagination): Promise<PaginationResponse<Finder>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Finder>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getFinders(pagination),
      EslManagerPublicRouteV1.FINDERS,
      HttpMethod.GET,
    );
  };

  public onDelete = async (finder: Finder): Promise<void> => {
    this.setState({ deletableFinder: finder });
  };

  public onDeleteDismiss = async () => {
    this.setState({ deletableFinder: undefined });
  };

  public onDeleteConfirm = async () => {
    const { deletableFinder } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableFinder) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteFinder(deletableFinder.coordinate, deletableFinder.name),
      EslManagerPublicRouteV1.FINDER,
      HttpMethod.DELETE,
      { 200: 'Finder deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (finder: Finder): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateFinder } = this.actions;

    if (updateFinder) {
      updateFinder(finder);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (finder: Finder): Promise<Finder> => {
    const { api, navigationStore, searchContentStore, finderStore, notificationStrategyStore } = this.stores;
    const { editableFinder } = finderStore;

    const doOverwrite = editableFinder !== undefined;

    const payload: SaveFinderPayload = {
      notificationStrategy: notificationStrategyStore.getSavableNotificationStrategy(),
      templateNames: finder.templateNames ?? [],
      compartmentInputInstructions:
        finder.compartmentInputInstructions !== '' ? finder.compartmentInputInstructions : null,
      showResultsDuration: finder.showResultsDuration,
    };

    if (finder.compartmentSelector) {
      const { name, coordinate } = finder.compartmentSelector;
      payload.compartmentSelector = { name, coordinate } as CompartmentSelectorIndex;
    }

    const responseFinder = await request<Finder>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveFinder(finder.coordinate, finder.name, payload, doOverwrite),
      EslManagerPublicRouteV1.FINDER,
      HttpMethod.PUT,
      { 200: 'Finder saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return responseFinder;
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { finderStore, notificationStrategyStore } = this.stores;

    const addPanelDefinition: ContentPanelDefinition<FinderPanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: FinderPanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.FINDERS_WRITE,
      toggleOffCallback: finderStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateFinder } = this.actions;
        if (updateFinder === undefined) {
          this.actions.updateFinder = async (finder: Finder) => {
            expandCallback();
            finderStore.setEditableFinder(finder);
            notificationStrategyStore.resetStore(finder.notificationStrategy);
          };
        }
      },
    };

    return [addPanelDefinition];
  };

  public render() {
    const { deletableFinder } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as FinderManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />

        {deletableFinder && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableFinder}
            title={'Delete Finder'}
            text={deletableFinder.name}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteConfirm}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchFinders}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<FinderManagementContentProps, typeof FinderManagementContentComponent>(
  FinderManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const FinderManagementContent = StyleWrapped;
