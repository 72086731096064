import { SortedSide, Template } from '@ekkogmbh/apisdk';
import { Delete, Edit, Highlight } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  PickingSidesContentActionHandlers,
  PickingSidesContentPropsWithStores,
  PickingSidesContentState,
} from './PickingSidesContent';

class PickingAreaDataTableActions extends DataTableActionsComponent<SortedSide> {}

const SortedSideName = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (name: string): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const SortedSideKey = (): MUIDataTableColumnDef => ({
  label: 'Key',
  name: 'entry.key',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (key: string): React.ReactNode => key,
  },
});

const SortedSideTemplates = (): MUIDataTableColumnDef => ({
  label: 'Templates',
  name: 'entry.templates',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (templates: SortedSide['templates']): React.ReactNode => {
      const mapper = (template: Template, index: number): React.ReactNode => <li key={index}>{template.name}</li>;

      return <ul style={{ paddingInlineStart: 16 }}>{templates && templates.map(mapper)}</ul>;
    },
  },
});

const SortedSideNode = (): MUIDataTableColumnDef => ({
  label: 'Area',
  name: 'entry.node',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (node: SortedSide['node']): React.ReactNode =>
      node ? node.parts.join(' ' + NodeSeparator + ' ') : null,
  },
});

const SortedSideSortingStrategy = (): MUIDataTableColumnDef => ({
  label: 'Sorting-Strategy',
  name: 'entry.sortingStrategy',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (strategy: SortedSide['sortingStrategy']): React.ReactNode => strategy,
  },
});

const SortedSideActions = (
  _: PickingSidesContentState,
  propsWithStores: PickingSidesContentPropsWithStores,
  actions: PickingSidesContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (sortedSide: SortedSide): React.ReactNode => {
      const hasWritePermission = propsWithStores.api.userHasPermissionOnAnyNode(Permissions.PICKING_AREAS_WRITE);

      const actionButtons: ActionButton<SortedSide>[] = [
        {
          title: 'Blink',
          onClick: actions.blink,
          icon: Highlight,
          disabled: !hasWritePermission,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return <PickingAreaDataTableActions dataset={sortedSide} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  PickingSidesContentPropsWithStores,
  PickingSidesContentState,
  PickingSidesContentActionHandlers
>> = [SortedSideName, SortedSideKey, SortedSideTemplates, SortedSideNode, SortedSideSortingStrategy, SortedSideActions];
