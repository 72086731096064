import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { SystemStatusContent } from './Components/SystemStatusContent';

export const SystemStatus = (): React.JSX.Element => (
  <Provider>
    <Page title="System-Status" isSearchable={false}>
      <SystemStatusContent />
    </Page>
  </Provider>
);
