import {
  EslManagerPrivateRoute,
  HttpMethod,
  Pagination,
  PaginationResponse,
  Preset,
  PresetSavePayload,
} from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { SuccessHandlerStatusMessages } from 'src/Common/Helper/ResponseHandler';
import { ContentPanelDefinition, ContentPanels } from '../../Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { PresetStore } from '../Stores/PresetStore';
import { PresetManagementStyles } from '../Styles/PresetManagementStyles';
import { materialDatatableColumnDefinitions } from './PresetManagementDatatableColumnDefinitions';
import { PresetPanel, PresetPanelProps } from './PresetPanel';

const styles = PresetManagementStyles;

const stores = ['api', 'presetStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

interface PresetManagementContentActions {
  updatePreset?: (preset: Preset) => void;
}

export interface PresetManagementContentActionHandlers {
  edit: (preset: Preset) => void;
  delete: (preset: Preset) => void;
}

export interface PresetManagementContentStores {
  api: ApiStore;
  presetStore: PresetStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface PresetManagementContentState {
  deleteDialogOpen: boolean;
  deletablePreset?: Preset;
}

export interface PresetManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type PresetManagementContentPropsWithStores = PresetManagementContentProps & PresetManagementContentStores;

@inject(...stores)
class PresetManagementContentComponent extends Component<PresetManagementContentProps, PresetManagementContentState> {
  private readonly filterFields: DataTableFilterFields<Preset> = ['name', 'coordinatePrefix'];

  private readonly sortFieldMap: DataTableSortFieldMap<Preset> = {
    name: 'P.name',
  };

  private readonly actions: PresetManagementContentActions = {};

  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    201: 'Preset created.',
    204: 'Preset deleted.',
  };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): PresetManagementContentStores {
    return this.props as PresetManagementContentPropsWithStores;
  }

  public state: PresetManagementContentState = {
    deleteDialogOpen: false,
    deletablePreset: undefined,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchPresets = async (pagination: Pagination): Promise<PaginationResponse<Preset>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Preset>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPresets(pagination),
      EslManagerPrivateRoute.PRESETS,
      HttpMethod.GET,
    );
  };

  public onEdit = async (preset: Preset): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updatePreset } = this.actions;

    if (updatePreset) {
      updatePreset(preset);
      navigationStore!.scrollTop();
    }
  };

  public openDeleteDialog = async (preset: Preset): Promise<void> => {
    this.setState({
      deletablePreset: preset,
      deleteDialogOpen: true,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      deletablePreset: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { deletablePreset } = this.state;

    if (!deletablePreset || !deletablePreset.id) {
      return;
    }

    this.deletePreset(deletablePreset);
    this.onDeleteDismiss();
  };

  public deletePreset = async (preset: Preset): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deletePreset(preset),
      EslManagerPrivateRoute.PRESET,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public savePreset = async (preset: PresetSavePayload): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    await request<Preset>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.savePreset(preset),
      EslManagerPrivateRoute.PRESET,
      HttpMethod.PUT,
      this.successStatusCodes,
    );

    searchContentStore!.emitRefresh();
  };

  public createContentPanels = (): ContentPanelDefinition[] => {
    const { presetStore } = this.stores;
    const { savePreset } = this;

    const closeCallback = () => {
      presetStore.resetStore();
    };

    const addPresetPanelDefinition: ContentPanelDefinition<PresetPanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: PresetPanel,
      panelProps: {
        saveHandler: savePreset,
        closeCallback,
      },
      permission: Permissions.PRESETS_WRITE,
      toggleOffCallback: closeCallback,
      expandHandler: (expandCallback: () => void) => {
        const { updatePreset } = this.actions;
        if (updatePreset === undefined) {
          this.actions.updatePreset = (preset: Preset) => {
            expandCallback();
            presetStore.setEditablePreset(preset);
          };
        }
      },
    };

    return [addPresetPanelDefinition];
  };

  public render() {
    const { fetchPresets } = this;
    const { classes } = this.props;
    const { deleteDialogOpen, deletablePreset } = this.state;

    const contentPanels = this.createContentPanels();

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PresetManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.openDeleteDialog,
      }),
    );

    const deleteDialogText =
      deleteDialogOpen && deletablePreset ? (
        <React.Fragment>
          <div>
            <span className={classes.boldFont}>{deletablePreset.name}</span>
          </div>
        </React.Fragment>
      ) : (
        ''
      );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={contentPanels} />

        {deleteDialogOpen && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={deleteDialogOpen}
            title={'Delete Preset'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        <Paper>
          <DataTable
            fetchItems={fetchPresets}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<PresetManagementContentProps, typeof PresetManagementContentComponent>(
  PresetManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PresetManagementContent = StyleWrapped;
