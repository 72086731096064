import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from 'src/Common/Helper/ThemeHelper';

export const fadeTimeout = 2000;
export const countUpTime = 1500;
export const saturation = 400;
export const chartHeight = 82;
export const chartWidth = 82;
export const chartInnerRadius = 30;
export const chartOuterRadius = 41;

const titleFontSize = 24;
const boldFontWeight = 700;

export const SystemStatusStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto' as const,
    boxShadow: theme.shadows[5],
  },
  title: {
    fontSize: titleFontSize,
    fontWeight: boldFontWeight,
  },
  connectionStatus: {
    fontSize: 18,
    float: 'right',
    color: theme.palette.common.white,
  },
  card: {
    boxShadow: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.text.secondary,
    borderRadius: 8,
  },
  cardContent: {
    padding: spacing(theme),
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: boldFontWeight,
    textAlign: 'center',
    marginBottom: 26,
  },
  cardText: {
    fontSize: titleFontSize,
    fontWeight: boldFontWeight,
    textAlign: 'center',
    marginTop: spacing(theme) * 3,
    marginBottom: spacing(theme) / 2,
  },
  cardCaption: {
    fontSize: 12,
    textAlign: 'center',
  },
  chart: {
    margin: '0 auto',
    transform: 'rotate(-90deg)',
  },
});
