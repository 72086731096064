import { EslManagerPrivateRoute, HttpMethod, Pagination, UserSettings } from '@ekkogmbh/apisdk';
import { EventEmitter } from 'eventemitter3';
import { MUIDataTableOptions } from 'mui-datatables';
import { action, observable } from 'mobx';
import { StoredPagination } from '@ekkogmbh/apisdk/src/Sdk/ApiTypes';
import { enqueueSnackbar } from 'notistack';
import { request } from '../Helper/FetchHandler';
import { CancelableFetchPromises } from '../Helper/PromiseHelper';
import { ApiStore } from './ApiStore';

export interface PaginationControls {
  page: number | null;
  rowCount: number;
  rowsPerPage: number;
  changeRowsPerPage?: (value: number) => void;
  changePage?: (page: number) => void;
  options?: MUIDataTableOptions;
}

const defaultPagination: Omit<StoredPagination, 'path'> = {
  rowsPerPage: 100,
};

export class PaginationStore extends EventEmitter {
  private rootStorageKey: string = 'ESLMANAGER_PAGINATION';
  private tablePath: string = '';
  private apiStore?: ApiStore;
  private fetchPromises: CancelableFetchPromises = {};
  private currentPaginationSettings?: StoredPagination;

  @observable
  public pagination: Pagination = {
    page: 0,
  };

  private paginationControls: PaginationControls = {
    page: null,
    rowCount: 0,
    ...defaultPagination,
  };

  @action
  public setPagination(pagination: Pagination): void {
    this.pagination = pagination;
  }

  public getPaginationControls(): PaginationControls {
    return this.paginationControls;
  }

  public setPaginationControls(paginationControls: PaginationControls): void {
    this.storeChanges(paginationControls);
    this.paginationControls = paginationControls;
    this.emit('pagination-controls');
  }

  public pageReceived(): void {
    this.emit('page-received');
  }

  /**
   * Call this whenever you want the pagination settings to be stored locally and synced with the backend remotely
   *
   * @param {string} path unique identifier for paginated data respectively table view
   * @param apiStore API store passed by initializing component
   */
  public storeAndSyncWithBackend(path: string, apiStore: ApiStore): void {
    if (this.tablePath === path || path.trim().length < 1) {
      return;
    }

    this.tablePath = path;
    this.apiStore = apiStore;

    const remotelyStoredPagination = apiStore.getAuthenticatedUser()?.settings?.pagination ?? [];
    if (remotelyStoredPagination.length > 0) {
      this.storeLocally(remotelyStoredPagination);
    }
  }

  private getStoredPagination(): StoredPagination[] {
    return JSON.parse(localStorage.getItem(this.rootStorageKey) ?? '[]');
  }

  private storeLocally(payload: StoredPagination[]): void {
    const notInPayload = (sp: StoredPagination) => !payload.map((p) => p.path).includes(sp.path);
    const updatedStoredPagination = [...this.getStoredPagination().filter(notInPayload), ...payload];

    this.currentPaginationSettings = updatedStoredPagination.find((p) => p.path === this.tablePath);
    localStorage.setItem(this.rootStorageKey, JSON.stringify(updatedStoredPagination));

    if (this.currentPaginationSettings != undefined) {
      this.paginationControls = { ...this.paginationControls, ...this.currentPaginationSettings };
    }
  }

  private storeChanges(paginationControls: PaginationControls): void {
    if (this.tablePath.length < 1) {
      console.error('attempted to store pagination settings without initialized tablePath!');
    } else if (this.isDifferentToStoredControls(paginationControls)) {
      this.storeLocally([{ path: this.tablePath, rowsPerPage: paginationControls.rowsPerPage }]);
      this.storeRemotely().finally();
    }
  }

  private isDifferentToStoredControls(paginationControls: PaginationControls): boolean {
    return this.paginationControls.rowsPerPage !== paginationControls.rowsPerPage;
  }

  // don't store remotely before storing locally since `this.currentPaginationSettings` needs to be up-to-date
  private async storeRemotely() {
    if (this.apiStore == undefined) {
      console.error('attempted to remotely store pagination settings without initialized apiStore!');
      return;
    }

    try {
      await request<UserSettings>(
        this.apiStore,
        enqueueSnackbar,
        this.fetchPromises,
        this.apiStore.updateUserSettings({
          pagination: !!this.currentPaginationSettings ? [this.currentPaginationSettings] : [],
        }),
        EslManagerPrivateRoute.USER_SETTINGS,
        HttpMethod.PATCH,
      );
    } catch (_) {
      enqueueSnackbar('Unable to store Pagination setting in user settings with the backend!', { variant: 'error' });
    }
  }
}
