import { OperationCreate, TriggerCreate } from '@ekkogmbh/apisdk';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { CsvDropzoneStore } from '../../Common/Stores/CsvDropzoneStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { OperationGroupStore } from '../Stores/OperationGroupStore';
import { OperationCard } from './OperationCard';
import { TriggerCard } from './TriggerCard';

const styles = FormStyles;

const stores = ['api', 'operationGroupStore', 'csvDropzoneStore'];

interface OperationGroupCreateOverviewStores {
  api: ApiStore;
  operationGroupStore: OperationGroupStore;
  csvDropzoneStore: CsvDropzoneStore;
}

interface OperationGroupCreateOverviewState {
  loading: boolean;
}

interface OperationGroupCreateOverviewProps extends WithStyles<typeof styles> {
  hasAreasReadPermission?: boolean;
  updateSortableItems?: () => Promise<void>;
}

@inject(...stores)
@observer
class OperationGroupCreateOverviewComponent extends React.Component<
  OperationGroupCreateOverviewProps,
  OperationGroupCreateOverviewState
> {
  public state: OperationGroupCreateOverviewState = {
    loading: false,
  };

  get stores(): OperationGroupCreateOverviewStores {
    return this.props as OperationGroupCreateOverviewProps & OperationGroupCreateOverviewStores;
  }

  public render() {
    const {
      csvDropzoneStore: { file },
      operationGroupStore: {
        state: { allFilled, allFilledTriggers, description, name, nodeSelectValue, nodeValue, triggers, operations },
      },
    } = this.stores;

    const overlineStyle = { fontWeight: 700 };

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {allFilled && (
          <Grid item xs={6}>
            <Typography variant="overline" color={'primary'} style={overlineStyle}>
              Name
            </Typography>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
            <Typography variant="overline" color={'primary'} style={overlineStyle}>
              Description
            </Typography>
            <Typography variant="h6" gutterBottom>
              {description}
            </Typography>
            <Typography variant="overline" color={'primary'} style={overlineStyle}>
              Area
            </Typography>
            <Typography variant="h6">
              {nodeValue !== ''
                ? nodeValue
                : (nodeSelectValue[nodeSelectValue.length - 1].fullValue as string[]).join(NodeSeparator)}
            </Typography>
          </Grid>
        )}

        <Grid container item xs={6} md={3} spacing={2}>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Triggers
          </Typography>
          <Grid container spacing={2} alignItems={'stretch'}>
            {allFilledTriggers &&
              triggers.map((trigger: TriggerCreate, index: number) => (
                <Grid item xs={12} key={index}>
                  <TriggerCard trigger={trigger} />
                </Grid>
              ))}
          </Grid>

          {operations.length > 0 && (
            <Grid container item>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Operations
              </Typography>
              <Grid container spacing={2} style={{ maxHeight: 600, overflowY: 'scroll' }}>
                {operations.map((operation: OperationCreate, index: number) => (
                  <Grid item xs={6} key={index}>
                    <OperationCard operation={operation} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>

        {spacer(3)}

        {file !== undefined && (
          <Grid item xs={12}>
            <Typography variant="overline" color={'primary'} style={overlineStyle}>
              Filename
            </Typography>
            <Typography variant="h6" gutterBottom>
              {(file as File).name}
            </Typography>
            <Typography variant="overline" color={'primary'} style={overlineStyle}>
              Filesize
            </Typography>
            <Typography variant="h6" gutterBottom>
              {Number((file as File).size / 1024).toFixed(2)} kB
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(OperationGroupCreateOverviewComponent);

export const OperationGroupCreateOverview = StyleWrapped;
