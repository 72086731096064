import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from 'src/Common/Components/Page';
import { TitleProps } from 'src/Routes';
import { PickerProfileManagementContent } from './Components/PickerProfileManagementContent';
import { PickerProfileStore } from './Stores/PickerProfileStore';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';

const stores = {
  pickerProfileStore: new PickerProfileStore(),
  notificationStrategyStore: new NotificationStrategyStore(),
};

export const PickerProfileManagement = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <PickerProfileManagementContent />
    </Page>
  </Provider>
);
