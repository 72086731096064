import { SortedSideSortingStrategy } from '@ekkogmbh/apisdk';
import { Accordion, AccordionDetails, AccordionSummary, Fade, Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ExpandMore } from '@mui/icons-material';
import { inject } from 'mobx-react';
import React from 'react';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { PickingSideStore, SortableSpatialEntity } from '../Stores/PickingSideStore';
import { SortableList } from './Sortables';
import { arrayMoveImmutable } from 'array-move';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['pickingSideStore'];

interface SortedSidePanelSortingStores {
  pickingSideStore: PickingSideStore;
}

interface SortedSidePanelSortingState {
  items: SortableSpatialEntity[];
}

interface SortedSidePanelSortingProps extends WithStyles<typeof styles> {
  sortableItems: SortableSpatialEntity[];
  loading: boolean;
}

@inject(...stores)
class SortedSidePanelSortingComponent extends React.PureComponent<
  SortedSidePanelSortingProps,
  SortedSidePanelSortingState
> {
  public state: SortedSidePanelSortingState = {
    items: [],
  };

  get stores(): SortedSidePanelSortingStores {
    return this.props as SortedSidePanelSortingProps & SortedSidePanelSortingStores;
  }

  public static getDerivedStateFromProps(
    props: Readonly<SortedSidePanelSortingProps>,
    state: SortedSidePanelSortingState,
  ) {
    if (JSON.stringify(props.sortableItems) !== JSON.stringify(state.items)) {
      return {
        items: props.sortableItems,
      };
    }

    return null;
  }

  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const { pickingSideStore } = this.stores;
    const { items } = this.state;
    const { sortingStrategy } = pickingSideStore;

    pickingSideStore.sortableItems = arrayMoveImmutable(items, oldIndex, newIndex);
    pickingSideStore.sortingStrategy =
      sortingStrategy !== SortedSideSortingStrategy.MANUALLY ? SortedSideSortingStrategy.MANUALLY : sortingStrategy;
    pickingSideStore.changed = true;
  };

  public render() {
    const { items } = this.state;
    const { classes, loading } = this.props;

    return (
      <Grid container spacing={2} alignItems={'stretch'} style={{ position: 'relative' }}>
        {loading && <LoadingMask width={42} height={42} />}
        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <div
              style={{
                margin: 8,
                padding: 0,
                borderStyle: 'solid',
                borderRadius: 4,
                borderWidth: 1,
                borderColor: 'rgba(0, 0, 0, 0.23)',
              }}
            >
              {items.length < 1 && (
                <Typography color={'secondary'} variant={'subtitle2'} style={{ margin: 8 }}>
                  No compartments match the selected parameters.
                </Typography>
              )}
              {items.length > 0 && (
                <Accordion
                  style={{
                    padding: 0,
                    minHeight: 54,
                  }}
                  elevation={0}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    style={{ minHeight: 46 }}
                    classes={{
                      content: classes.AccordionSummaryContent,
                      expandIconWrapper: classes.AccordionExpandIcon,
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography color={'secondary'}>Compartment-Order</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <SortableList lockAxis={'y'} items={items} onSortEnd={this.onSortEnd} />
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          </Fade>
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(SortedSidePanelSortingComponent);

export const SortedSidePanelSorting = StyleWrapped;
