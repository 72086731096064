import { action, observable } from 'mobx';
import React from 'react';

export class ContentTitleStore {
  @observable
  public contentTitle?: string | React.JSX.Element;

  @action
  public setContentTitle(contentTitle: string | React.JSX.Element | undefined): void {
    this.contentTitle = contentTitle;
  }
}
