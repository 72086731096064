import { Trigger } from '@ekkogmbh/apisdk';
import { Grid, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { spacing } from '../../Common/Helper/ThemeHelper';
import { TriggerCard } from '../../OperationGroups/Components/TriggerCard';

const styles = (theme: Theme) => ({
  button: {
    margin: spacing(theme),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

interface ItemOperationTriggerDialogProps extends WithStyles<typeof styles>, Omit<DialogProps, 'classes' | 'title'> {
  onClose: () => void;
  open: boolean;
  triggers: Trigger[];
}

class ItemOperationTriggerDialogComponent extends React.Component<ItemOperationTriggerDialogProps> {
  public state = {
    open: true,
  };

  public render() {
    const {
      triggers,
      // workaround to NOT have 'classes' in 'rest'
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      classes,
      onClose,
      ...rest
    } = this.props;

    return (
      <Dialog onClose={onClose} fullWidth={true} maxWidth={'lg'} {...rest}>
        <DialogTitle id="alert-dialog-title">Triggers</DialogTitle>
        <DialogContent>
          <div
            style={{
              width: '100%',
              padding: 16,
              marginBottom: 16,
            }}
          >
            <Grid container spacing={2} alignItems={'stretch'}>
              {triggers.map((trigger: Trigger, index: number) => (
                <Grid item lg={3} md={6} xs={12} key={index}>
                  <TriggerCard trigger={trigger} />
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant={'contained'} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const CompartmentOperationTriggerDialog = withStyles(styles)(ItemOperationTriggerDialogComponent);
