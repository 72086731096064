import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { SupplyProfileStore } from '../Stores/SupplyProfileStore';
import { WithStyles, withStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Popover,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CoordinateInput } from 'src/Common/Components/CoordinateInput';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import {
  EslManagerPublicRouteV1,
  HttpMethod,
  PaginationResponse,
  ReceivingArea,
  ReceivingAreaProfileMapping,
} from '@ekkogmbh/apisdk';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { Add, Refresh, Stop } from '@mui/icons-material';
import { HexColorInput, HexColorPicker } from 'react-colorful';

const styles = FormStyles;

interface SupplyProfilePanelStores {
  api: ApiStore;
  supplyProfileStore: SupplyProfileStore;
}

interface SupplyProfilePanelState {
  loading: boolean;
  selectableReceivingAreas: ReceivingArea[];
  colorPicker?: {
    anchor: HTMLButtonElement;
    mappingCoordinate: string;
    state: string;
    color?: string;
  };
  fieldAdder?: {
    anchor: HTMLButtonElement;
    mappingCoordinate: string;
    fieldKey?: string;
  };
}

export interface SupplyProfilePanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: () => Promise<void>;
}

@inject('api', 'supplyProfileStore')
@observer
class SupplyProfilePanelComponent extends React.Component<SupplyProfilePanelProps, SupplyProfilePanelState> {
  public state: SupplyProfilePanelState = {
    loading: false,
    selectableReceivingAreas: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): SupplyProfilePanelStores {
    return this.props as SupplyProfilePanelProps & SupplyProfilePanelStores;
  }

  public componentDidMount(): void {
    const { supplyProfileStore } = this.stores;
    const { editableSupplyProfile } = supplyProfileStore;

    if (editableSupplyProfile !== undefined) {
      supplyProfileStore.resetStore(editableSupplyProfile);

      this.fetchReceivingAreas();
    }
  }

  public componentWillUnmount(): void {
    const { supplyProfileStore } = this.stores;
    supplyProfileStore.resetStore();
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchReceivingAreas = async (): Promise<void> => {
    const { closeHandler } = this.props;
    const { api, supplyProfileStore } = this.stores;
    const { coordinate } = supplyProfileStore.state;

    const receivingAreaPagination = await request<PaginationResponse<ReceivingArea>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getReceivingAreas({ page: 1, limit: 999, filter: { coordinate } }),
      EslManagerPublicRouteV1.RECEIVING_AREAS,
      HttpMethod.GET,
      undefined,
      undefined,
      closeHandler,
    );

    if (receivingAreaPagination.items !== undefined) {
      this.setState({ selectableReceivingAreas: receivingAreaPagination.items });
    }
  };

  public handleReset = async () => {
    const { supplyProfileStore } = this.stores;
    const { editableSupplyProfile } = supplyProfileStore;
    supplyProfileStore.resetStore(editableSupplyProfile);
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;

    this.setState({ loading: true }, async () => {
      await saveHandler();
      this.handleReset();
      closeHandler();
    });
  };

  public getSelectedReceivingAreaIndices = () => {
    const { supplyProfileStore } = this.stores;
    const { areaMappingByCoordinate } = supplyProfileStore.state;
    const { selectableReceivingAreas } = this.state;

    return Object.keys(areaMappingByCoordinate).map((coord: string) =>
      selectableReceivingAreas.findIndex((value) => value.coordinate === coord),
    );
  };

  public onSelectReceivingArea = (event: SelectChangeEvent<unknown>) => {
    const { supplyProfileStore } = this.stores;
    const { selectableReceivingAreas } = this.state;

    if (event?.target?.value != undefined) {
      supplyProfileStore.setReceivingAreas(
        (event.target.value as number[]).map((idx: number) => selectableReceivingAreas[idx]),
      );
    }
  };

  public toggleFieldAdderPopover = (adderState?: {
    anchor: HTMLButtonElement;
    mappingCoordinate: string;
    fieldKey: string;
  }) => {
    if (adderState === undefined) {
      this.setState({ fieldAdder: undefined });
    } else {
      this.setState({ fieldAdder: adderState });
    }
  };

  public confirmAddField = () => {
    const { fieldAdder } = this.state;
    const { supplyProfileStore } = this.stores;
    const { areaMappingByCoordinate } = supplyProfileStore.state;

    if (fieldAdder !== undefined && fieldAdder.fieldKey !== undefined && fieldAdder.fieldKey !== '') {
      const coordinate = fieldAdder.mappingCoordinate;
      const mapping = areaMappingByCoordinate[coordinate];

      if (mapping.observedFields.includes(fieldAdder.fieldKey)) {
        return;
      }
      mapping.observedFields.push(fieldAdder.fieldKey);

      supplyProfileStore.setState({
        ...areaMappingByCoordinate,
        [coordinate]: {
          ...mapping,
          observedFields: mapping.observedFields.slice(),
        },
      });
    }

    this.toggleFieldAdderPopover();
  };

  public removeField = (coordinate: string, field: string) => {
    const { supplyProfileStore } = this.stores;
    const { areaMappingByCoordinate } = supplyProfileStore.state;

    const mapping = areaMappingByCoordinate[coordinate];
    const fieldIndex = mapping.observedFields.indexOf(field);

    if (fieldIndex >= 0) {
      supplyProfileStore.setState({
        ...areaMappingByCoordinate,
        [coordinate]: {
          ...mapping,
          observedFields: mapping.observedFields.splice(fieldIndex, 1),
        },
      });
    }
  };

  public toggleColorPickerPopover = (mappingStateIndex?: {
    event: React.MouseEvent<HTMLButtonElement>;
    mappingCoordinate: string;
    state: string;
    color?: string;
  }) => {
    if (mappingStateIndex === undefined) {
      this.setState({ colorPicker: undefined });
    } else {
      const { mappingCoordinate, state, event, color } = mappingStateIndex;
      this.setState({ colorPicker: { anchor: event.currentTarget, mappingCoordinate, state, color } });
    }
  };

  public confirmColorPick = () => {
    const { supplyProfileStore } = this.stores;
    const { colorPicker } = this.state;
    const { areaMappingByCoordinate } = supplyProfileStore.state;

    const coordinate = colorPicker!.mappingCoordinate;
    const mapping = areaMappingByCoordinate[coordinate];

    supplyProfileStore.setState({
      areaMappingByCoordinate: {
        ...areaMappingByCoordinate,
        [coordinate]: {
          ...mapping,
          stateColors: {
            ...mapping.stateColors,
            [colorPicker!.state]: colorPicker!.color ?? '',
          },
        },
      },
    });
  };

  public toggleObservedState = (mapping: ReceivingAreaProfileMapping, state: string): void => {
    const { supplyProfileStore } = this.stores;
    const { areaMappingByCoordinate } = supplyProfileStore.state;

    const stateIndex = mapping.observedStates.indexOf(state);
    if (stateIndex < 0) {
      mapping.observedStates.push(state);
    } else {
      mapping.observedStates.splice(stateIndex, 1);
    }

    supplyProfileStore.setState({
      areaMappingByCoordinate: {
        ...areaMappingByCoordinate,
        [mapping.receivingArea.coordinate]: {
          ...mapping,
          observedStates: mapping.observedStates,
        },
      },
    });
  };

  public renderMappingCard = (mapping: ReceivingAreaProfileMapping): React.JSX.Element => (
    <Card variant={'outlined'}>
      <CardHeader
        title={
          <Stack>
            <Typography color={'scondary'}>{mapping.receivingArea.name}</Typography>
            <Typography color={'primary'}>{mapping.receivingArea.coordinate}</Typography>
          </Stack>
        }
      />
      <CardContent>
        <Stack spacing={1}>
          <Tooltip arrow title={'select states to appear in the dashboard and in what colour'} placement={'left'}>
            <Typography variant={'overline'}>Monitored States</Typography>
          </Tooltip>
          <Stack spacing={1}>
            {mapping.receivingArea.replenishmentPlan.linearStateMachineDefinition.states.map((state) => {
              const isObserved = mapping.observedStates.includes(state);
              const color = isObserved ? mapping.stateColors[state] : undefined;
              const colorIcon = (
                <IconButton
                  disabled={!isObserved}
                  onClick={(event) =>
                    this.toggleColorPickerPopover({
                      event,
                      mappingCoordinate: mapping.receivingArea.coordinate,
                      state,
                      color,
                    })
                  }
                >
                  <Stop style={{ color }} />
                </IconButton>
              );
              return (
                <Box key={`${state}-state-select-key`} sx={{ border: 1, borderRadius: 1 }}>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Checkbox
                      checked={mapping.observedStates.includes(state)}
                      onClick={() => this.toggleObservedState(mapping, state)}
                    />
                    <Typography>{state}</Typography>
                    {colorIcon}
                  </Stack>
                </Box>
              );
            })}
          </Stack>
          <Tooltip arrow title={'compartment fields for the table component of the dashboard'} placement={'left'}>
            <Typography variant={'overline'}>Displayed Compartment Fields</Typography>
          </Tooltip>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <IconButton
              onClick={(event) =>
                this.setState({
                  fieldAdder: {
                    anchor: event.currentTarget,
                    mappingCoordinate: mapping.receivingArea.coordinate,
                    fieldKey: '',
                  },
                })
              }
            >
              <Add />
            </IconButton>
            {mapping.observedFields.map((field: string, idx: number) => (
              <Chip
                key={idx}
                variant={'outlined'}
                label={
                  <Typography alignItems={'center'} justifyContent={'center'}>
                    {field}
                  </Typography>
                }
                onDelete={() => this.removeField(mapping.receivingArea.coordinate, field)}
              />
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

  public render() {
    const { loading, selectableReceivingAreas, colorPicker, fieldAdder } = this.state;
    const { supplyProfileStore } = this.stores;
    const { name, coordinate, areaMappingByCoordinate, allFilled } = supplyProfileStore.state;
    const { closeHandler } = this.props;

    const isEditMode = supplyProfileStore.editableSupplyProfile !== undefined;
    const hasAreas = selectableReceivingAreas.length > 0;
    const selectedAreaIndices = this.getSelectedReceivingAreaIndices();

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <div style={{ display: loading ? 'block' : 'none' }}>
          <Grid
            item
            xs={12}
            style={{
              height: 496,
              position: 'relative',
            }}
          >
            <div
              style={{
                top: '50%',
                marginTop: -48,
                position: 'absolute',
                width: '100%',
              }}
            >
              <CheckmarkSpinner complete={false} failure={false} />
            </div>
          </Grid>
        </div>

        {!loading && (
          <>
            <Grid item container mt={0} spacing={1} alignContent={'stretch'}>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <StyledFormHeader label={'Identifier'} />
                  <StyledTextField
                    type={'text'}
                    label={'Name'}
                    value={name}
                    disabled={isEditMode}
                    onChange={(e) => supplyProfileStore.setState({ name: e.target.value })}
                  />
                  <CoordinateInput
                    value={coordinate}
                    disabled={isEditMode || selectedAreaIndices.length > 0}
                    onChange={(coordinate) => supplyProfileStore.setState({ coordinate })}
                    trailingDelimiter={false}
                  />
                  <div>
                    <StyledSelectField
                      multiple
                      disabled={!hasAreas}
                      onChange={this.onSelectReceivingArea}
                      value={selectedAreaIndices}
                      label={'Receiving Areas'}
                      renderValue={() => <ListItemText primary={`${selectedAreaIndices.length} selected`} />}
                      endAdornment={
                        <Tooltip title={'reload area list'}>
                          <span>
                            <IconButton
                              disabled={coordinate === ''}
                              aria-label="refresh"
                              onClick={async () => {
                                await this.fetchReceivingAreas();
                              }}
                            >
                              <Refresh />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    >
                      {selectableReceivingAreas.map((area: ReceivingArea, index: number) => (
                        <MenuItem key={index} value={index}>
                          <Checkbox checked={selectedAreaIndices.includes(index)} />
                          <ListItemText primary={area.coordinate} secondary={area.name} />
                        </MenuItem>
                      ))}
                    </StyledSelectField>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Popover
                  open={fieldAdder !== undefined}
                  anchorEl={fieldAdder?.anchor}
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                  onClose={() => this.toggleFieldAdderPopover()}
                >
                  <Stack border={1} borderRadius={1}>
                    <Input
                      type={'text'}
                      onChange={(e) =>
                        this.setState({ fieldAdder: { ...fieldAdder!, fieldKey: e.target.value as string } })
                      }
                    />
                    <Button onClick={() => this.confirmAddField()}>{'confirm'}</Button>
                  </Stack>
                </Popover>
                <Popover
                  open={colorPicker !== undefined}
                  anchorEl={colorPicker?.anchor}
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                  onClose={() => this.toggleColorPickerPopover()}
                >
                  <Stack>
                    <HexColorPicker
                      color={colorPicker?.color}
                      onChange={(color) => {
                        if (colorPicker !== undefined) {
                          this.setState({ colorPicker: { ...colorPicker, color } });
                        }
                      }}
                    />
                    <HexColorInput
                      color={colorPicker?.color}
                      onChange={(color) => {
                        if (colorPicker !== undefined) {
                          this.setState({ colorPicker: { ...colorPicker, color } });
                        }
                      }}
                    />
                    <Button
                      onClick={() => {
                        this.confirmColorPick();
                        this.toggleColorPickerPopover();
                      }}
                    >
                      {'confirm'}
                    </Button>
                  </Stack>
                </Popover>
                {selectedAreaIndices.length > 0 && (
                  <Stack spacing={1}>
                    <StyledFormHeader label={'Configuration'} />
                    <Grid container spacing={1}>
                      {Object.keys(areaMappingByCoordinate).map((coord: string) => (
                        <Grid item key={coord} xs={6}>
                          {this.renderMappingCard(areaMappingByCoordinate[coord])}
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormPanelButtons
                cancelHandler={closeHandler}
                saveHandler={this.handleSave}
                resetHandler={this.handleReset}
                isDeleteHidden={true}
                isSaveDisabled={!allFilled}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(SupplyProfilePanelComponent);

export const SupplyProfilePanel = StyleWrapped;
