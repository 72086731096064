import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import { CSSProperties } from '@mui/styles';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import classNames from 'classnames';
import React from 'react';
import { spacing } from '../Helper/ThemeHelper';

const styles = (theme: Theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: spacing(theme),
  } as CSSProperties,
  rootSticky: {
    marginLeft: 0,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
});

// TODO: types were just fine in MUIv4
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface TablePaginationActionsPropsOverride extends WithStyles<typeof styles>, TablePaginationActionsProps {
  sticky: boolean;
}

class TablePaginationActionsComponent extends React.Component<TablePaginationActionsPropsOverride> {
  public handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onPageChange, page } = this.props;

    onPageChange(event, page - 1);
  };

  public handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onPageChange, page } = this.props;

    onPageChange(event, page + 1);
  };

  public render() {
    const { backIconButtonProps, classes, count, nextIconButtonProps, page, rowsPerPage, sticky } = this.props;

    return (
      <div className={classNames(classes.root, sticky && classes.rootSticky)}>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          color="inherit"
          className={classes.button}
          {...backIconButtonProps}
          size="large"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          color="inherit"
          className={classes.button}
          {...nextIconButtonProps}
          size="large"
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    );
  }
}

export const TablePaginationActions = withStyles(styles)(TablePaginationActionsComponent);
