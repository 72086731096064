import { UserRoleNodeMapping } from '@ekkogmbh/apisdk';
import { IconButton, Input, InputAdornment } from '@mui/material';
import { orange, red } from '@mui/material/colors';
import { Delete, Update } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import moment from 'moment';
import React, { Fragment } from 'react';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  UserMappingsContentActionHandlers,
  UserMappingsContentHelpers,
  UserMappingsContentPropsWithStores,
  UserMappingsContentState,
} from './UserMappingsContent';

class UserMappingDataTableActions extends DataTableActionsComponent<UserRoleNodeMapping> {}

const UserMappingActions = (
  _: UserMappingsContentState,
  propsWithStores: UserMappingsContentPropsWithStores,
  actions: UserMappingsContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (mapping: UserRoleNodeMapping): React.ReactNode => {
      const { api } = propsWithStores;

      const hasWritePermission =
        api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_WRITE) ||
        api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_WRITE_RESTRICTED);

      const actionButtons: ActionButton<UserRoleNodeMapping>[] = [
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return <UserMappingDataTableActions dataset={mapping} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const UserMappingBodyRenderRole = (
  propsWithStores: UserMappingsContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (name: UserRoleNodeMapping['role']['name']) => {
  const { classes } = propsWithStores;

  return (
    <Fragment>
      <span className={classes.boldFont}>{name}</span>
    </Fragment>
  );
};

const UserMappingRole = (
  _: UserMappingsContentState,
  propsWithStores: UserMappingsContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Role',
  name: 'entry.role.name',
  options: {
    sort: false,
    filter: false,
    customBodyRender: UserMappingBodyRenderRole(propsWithStores),
  },
});

const UserMappingBodyRenderArea = (
  propsWithStores: UserMappingsContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (identifier: UserRoleNodeMapping['node']['identifier']) => {
  const { classes } = propsWithStores;

  return (
    <Fragment>
      <span className={classes.boldFont}>{identifier.join(' ' + NodeSeparator + ' ')}</span>
    </Fragment>
  );
};

const UserMappingArea = (
  _: UserMappingsContentState,
  propsWithStores: UserMappingsContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Area',
  name: 'entry.node.identifier',
  options: {
    sort: false,
    filter: false,
    customBodyRender: UserMappingBodyRenderArea(propsWithStores),
  },
});

const UserMappingBodyRenderValidUntil = (
  helpers: UserMappingsContentHelpers,
  // eslint-disable-next-line react/display-name
) => (userRoleNodeMapping: UserRoleNodeMapping) => (
  <Input
    type={'text'}
    inputProps={{ style: { textAlign: 'center' } }}
    value={new Date(userRoleNodeMapping.validUntil).toLocaleDateString()}
    readOnly={true}
    onClick={() => helpers.onClickValidUntil(userRoleNodeMapping)}
    endAdornment={
      <InputAdornment position="end">
        <IconButton aria-label="Edit" onClick={() => helpers.onClickValidUntil(userRoleNodeMapping)} size="large">
          <Update fontSize={'small'} />
        </IconButton>
      </InputAdornment>
    }
  />
);

const UserMappingValidUntil = (
  _: UserMappingsContentState,
  __: UserMappingsContentPropsWithStores,
  ___: UserMappingsContentActionHandlers,
  helpers?: UserMappingsContentHelpers,
): MUIDataTableColumnDef => ({
  label: 'Valid-Until',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: UserMappingBodyRenderValidUntil(helpers!),
  },
});

const UserMappingBodyRenderExpiring = () =>
  // eslint-disable-next-line react/display-name
  (validUntil: UserRoleNodeMapping['validUntil']) => {
    const momentDate = moment(validUntil);

    const hoursDiff = momentDate.diff(moment(), 'hours');

    let style = {};

    const saturation = 'A400';

    switch (true) {
      case hoursDiff <= 0:
        style = {
          color: red[saturation],
          fontWeight: 700,
        };
        break;

      case hoursDiff < 48:
        style = {
          color: orange[saturation],
          fontWeight: 700,
        };
        break;
    }

    return (
      <Fragment>
        <span style={style}>
          {hoursDiff <= 0 && 'expired '}
          {momentDate.fromNow()}
        </span>
      </Fragment>
    );
  };

const UserMappingExpiring = (): MUIDataTableColumnDef => ({
  label: 'Expiring',
  name: 'entry.validUntil',
  options: {
    sort: false,
    filter: false,
    customBodyRender: UserMappingBodyRenderExpiring(),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  UserMappingsContentPropsWithStores,
  UserMappingsContentState,
  UserMappingsContentActionHandlers,
  UserMappingsContentHelpers
>> = [UserMappingRole, UserMappingArea, UserMappingValidUntil, UserMappingExpiring, UserMappingActions];

export { UserMappingRole, UserMappingArea, UserMappingValidUntil, UserMappingExpiring };
