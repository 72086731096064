import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { CSSProperties } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { spacing } from '../Helper/ThemeHelper';

const styles = (theme: Theme): Record<string, CSSProperties> => {
  const brandSuccess = theme.palette.secondary.main;
  const loaderSize = spacing(theme) * 12;
  const checkHeight = loaderSize / 2.25;
  const checkWidth = checkHeight / 2;
  const checkLeft = loaderSize / 7 + loaderSize / 13;
  const checkThickness = 6;
  const checkColor = brandSuccess;

  const circleLoader = {
    margin: '0 auto 50px',
    border: checkThickness + 'px solid rgba(0, 0, 0, 0)',
    borderLeftColor: checkColor,
    animation: '$loaderSpin 1.2s infinite linear',
    position: 'relative',
    display: 'block',
    verticalAlign: 'top',
    borderRadius: '50%',
    width: loaderSize,
    height: loaderSize,
  } as CSSProperties;

  const loadComplete = {
    animation: 'none',
    borderColor: checkColor,
    transition: 'border 500ms ease-out',
  } as CSSProperties;

  const checkmark = {
    display: 'none',
  } as CSSProperties;

  const checkmarkAfter = {
    '&::after': {
      opacity: 1,
      height: checkHeight,
      width: checkWidth,
      transformOrigin: 'left top',
      borderRight: checkThickness + 'px solid ' + checkColor,
      borderTop: checkThickness + 'px solid ' + checkColor,
      content: "''",
      left: checkLeft,
      top: checkHeight + spacing(theme) / 2,
      position: 'absolute',
    },
  } as CSSProperties;

  const cross = {
    '&::after': {
      opacity: 1,
      height: checkThickness,
      width: checkHeight,
      transformOrigin: 'center',
      content: "''",
      left: checkLeft,
      top: checkHeight - checkThickness / 2,
      position: 'absolute',
      backgroundColor: checkColor,
    },
    '&::before': {
      opacity: 1,
      height: checkThickness,
      width: checkHeight,
      transformOrigin: 'center',
      content: "''",
      left: checkLeft,
      top: checkHeight - checkThickness / 2,
      position: 'absolute',
      backgroundColor: checkColor,
    },
  } as CSSProperties;

  return {
    circleLoader,
    loadComplete,
    checkmark,
    checkmarkAfter,
    cross,
    draw: {
      display: 'block',
    },
    drawAfter: {
      '&::after': {
        animationDuration: String(2000),
        animationTimingFunction: 'ease',
        animationName: '$checkmark',
        transform: 'scaleX(-1) rotate(135deg)',
        content: "''",
      },
    },
    drawCross: {
      '&::after': {
        animationDuration: String(2000),
        animationTimingFunction: 'ease',
        animationName: '$checkmark',
        transform: 'rotate(-45deg)',
        content: "''",
      },
      '&::before': {
        animationDuration: String(2000),
        animationTimingFunction: 'ease',
        animationName: '$checkmark',
        transform: 'rotate(45deg)',
        content: "''",
      },
    },
    '@keyframes loaderSpin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    '@keyframes checkmark': {
      '0%': {
        height: 0,
        width: 0,
        opacity: 1,
      },
      '20%': {
        height: 0,
        width: checkWidth,
        opacity: 1,
      },
      '40%': {
        height: checkHeight,
        width: checkWidth,
        opacity: 1,
      },
      '100%': {
        height: checkHeight,
        width: checkWidth,
        opacity: 1,
      },
    },
  };
};

interface CheckmarkSpinnerProps extends WithStyles<typeof styles> {
  complete: boolean;
  failure: boolean;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}

class CheckmarkSpinnerComponent extends React.Component<CheckmarkSpinnerProps> {
  public render() {
    const { classes, complete, failure, forwardedRef } = this.props;
    return (
      <div className={classNames(classes.circleLoader, complete && classes.loadComplete)} ref={forwardedRef}>
        {failure ? (
          <div
            className={classNames(
              classes.cross,
              classes.checkmark,
              complete && classes.drawCross,
              complete && classes.draw,
            )}
          />
        ) : (
          <div
            className={classNames(
              classes.checkmarkAfter,
              classes.checkmark,
              complete && classes.drawAfter,
              complete && classes.draw,
            )}
          />
        )}
      </div>
    );
  }
}

const StyledComponent = withStyles(styles)(CheckmarkSpinnerComponent);

// eslint-disable-next-line react/display-name
export const CheckmarkSpinner = React.forwardRef<HTMLDivElement, Omit<CheckmarkSpinnerProps, 'classes'>>(
  (props, ref) => <StyledComponent {...props} forwardedRef={ref} />,
);
