import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, ReceivingArea } from '@ekkogmbh/apisdk';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject } from 'mobx-react';
import { Component } from 'react';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { WithStyles, withStyles } from '@mui/styles';
import { ReceivingAreaManagementStyles } from '../Styles/ReceivingAreaManagementStyles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './ReceivingAreaDatatableColumnDefinitions';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { Grid, Paper } from '@mui/material';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import classNames from 'classnames';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { ReceivingAreaPanel, ReceivingAreaPanelProps } from './ReceivingAreaPanel';
import { Add } from '@mui/icons-material';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { ReceivingAreaStore } from '../Stores/ReceivingAreaStore';

interface ReceivingAreaManagementContentActions {
  updateReceivingArea?: (receivingArea: ReceivingArea) => void;
}

export interface ReceivingAreaManagementContentActionHandlers {
  edit: (receivingArea: ReceivingArea) => void;
  delete: (receivingArea: ReceivingArea) => void;
}

export interface ReceivingAreaManagementContentState {
  deletableReceivingArea?: ReceivingArea;
}

interface ReceivingAreaManagementContentStores {
  api: ApiStore;
  receivingAreaStore: ReceivingAreaStore;
  navigationStore: NavigationStore;
  searchContentStore: SearchContentStore;
}

interface ReceivingAreaManagementContentProps
  extends WithStyles<typeof ReceivingAreaManagementStyles>,
    RouteComponentProps {}

export type ReceivingAreaManagementContentPropsWithStores = ReceivingAreaManagementContentProps &
  ReceivingAreaManagementContentStores;

@inject('api', 'receivingAreaStore', 'navigationStore', 'searchContentStore')
class ReceivingAreaManagementContentComponent extends Component<
  ReceivingAreaManagementContentProps,
  ReceivingAreaManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<ReceivingArea> = ['name', 'coordinate'];
  private readonly sortFieldMaps: DataTableSortFieldMap<ReceivingArea> = { name: 'entry.name' };
  private readonly actions: ReceivingAreaManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  public state: ReceivingAreaManagementContentState = {};

  get stores(): ReceivingAreaManagementContentStores {
    return this.props as ReceivingAreaManagementContentProps & ReceivingAreaManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<ReceivingArea>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<ReceivingArea>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getReceivingAreas(pagination),
      EslManagerPublicRouteV1.RECEIVING_AREAS,
      HttpMethod.GET,
    );
  };

  public onEdit = async (ReceivingArea: ReceivingArea): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateReceivingArea } = this.actions;

    if (updateReceivingArea) {
      updateReceivingArea(ReceivingArea);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, receivingAreaStore } = this.stores;
    const { name, coordinate, allFilled } = receivingAreaStore.state;

    if (!allFilled) {
      return;
    }

    await request<ReceivingArea>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveReceivingArea(
        { name: name!, coordinate: coordinate! },
        receivingAreaStore.getPayload(),
        receivingAreaStore.editableReceivingArea !== undefined,
      ),
      EslManagerPublicRouteV1.RECEIVING_AREA,
      HttpMethod.PUT,
      { 200: 'Receiving Area saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  public onDelete = async (receivingArea: ReceivingArea): Promise<void> => {
    this.setState({ deletableReceivingArea: receivingArea });
  };

  public onDeleteDismiss = async () => {
    this.setState({ deletableReceivingArea: undefined });
  };

  public onDeleteConfirm = async () => {
    const { deletableReceivingArea } = this.state;
    const { api, searchContentStore } = this.stores;

    if (deletableReceivingArea === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteReceivingArea(deletableReceivingArea),
      EslManagerPublicRouteV1.RECEIVING_AREA,
      HttpMethod.DELETE,
      { 200: 'Receiving Area deleted.' },
      undefined,
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { receivingAreaStore } = this.stores;

    const addPanelDefinition: ContentPanelDefinition<ReceivingAreaPanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: ReceivingAreaPanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.EKANBAN_WRITE,
      toggleOffCallback: receivingAreaStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateReceivingArea } = this.actions;
        if (updateReceivingArea === undefined) {
          this.actions.updateReceivingArea = async (receivingArea: ReceivingArea) => {
            expandCallback();
            receivingAreaStore.resetStore(receivingArea);
          };
        }
      },
    };

    return [addPanelDefinition];
  };

  public render() {
    const { classes } = this.props;
    const { deletableReceivingArea } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as ReceivingAreaManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />
        {deletableReceivingArea && (
          <GenericDialog
            title={'Delete Receiving Area'}
            text={deletableReceivingArea.name}
            type={'confirmation'}
            maxWidth={'sm'}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteConfirm}
            fullWidth
            centered
            open
          />
        )}

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMaps}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<ReceivingAreaManagementContentProps, typeof ReceivingAreaManagementContentComponent>(
  ReceivingAreaManagementContentComponent,
);
const StyleWrapped = withStyles(ReceivingAreaManagementStyles)(RouterWrapped);

export const ReceivingAreaManagementContent = StyleWrapped;
