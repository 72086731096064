import { PickingArea, PickingColor, PickingType, SortedSide } from '@ekkogmbh/apisdk';
import {
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { StyledTextField } from '../../Common/Components/Forms/StyledTextField';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { ReactSelectAsyncOption } from '../../Common/Components/ReactSelectAsync';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { PickingColors } from '../Helper/PickingColors';
import { PickingAreaStore } from '../Stores/PickingAreaStore';
import { SortedSideSelect } from './SortedSideSelect';

const styles = FormStyles;
const checkboxStyle = { margin: 12 };
const selectStyle = {
  margin: 8,
  width: '100%',
};
const fadeTimeout = 2000;

const stores = ['api', 'configStore', 'pickingAreaStore'];

interface PickingAreaPanelFormStores {
  api: ApiStore;
  configStore: ConfigStore;
  pickingAreaStore: PickingAreaStore;
}

interface PickingAreaPanelFormState {
  loading: boolean;
}

interface PickingAreaPanelFormProps extends WithStyles<typeof styles> {}

@inject(...stores)
@observer
class PickingAreaPanelFormComponent extends React.Component<PickingAreaPanelFormProps, PickingAreaPanelFormState> {
  public state: PickingAreaPanelFormState = {
    loading: false,
  };

  get stores(): PickingAreaPanelFormStores {
    return this.props as PickingAreaPanelFormProps & PickingAreaPanelFormStores;
  }

  public static isAllFilled = (
    externalIdentifier: string,
    colors: string[],
    requiredSideSelected: ReactSelectAsyncOption<SortedSide> | null,
    defaultBlinkDuration: number,
    simultaneousBlinkCount: number,
    simultaneousJobCount: number,
    editablePickingArea?: PickingArea,
  ) => {
    if (editablePickingArea) {
      return colors.length > 0 && defaultBlinkDuration > 0 && simultaneousBlinkCount > 0;
    } else {
      return (
        externalIdentifier !== '' &&
        requiredSideSelected !== null &&
        requiredSideSelected.value !== undefined &&
        colors.length > 0 &&
        defaultBlinkDuration > 0 &&
        simultaneousBlinkCount > 0 &&
        simultaneousJobCount > 0
      );
    }
  };

  public handleChange = (name: string) => ({ target }: SelectChangeEvent<unknown>) => {
    const { pickingAreaStore } = this.stores;
    const { editablePickingArea } = pickingAreaStore;
    const { requiredSideSelected } = pickingAreaStore.state;
    let {
      colors,
      externalIdentifier,
      type,
      requiredSideAck,
      optionalSideAck,
      symmetricalAck,
      defaultBlinkDuration,
      simultaneousBlinkCount,
      simultaneousJobCount,
      ackByButtonTopic,
      automaticJobFinish,
    } = pickingAreaStore.state;

    const { value } = target;

    const toNumber = (numberInput: unknown, minimum: number): number => {
      const numberValue = Number(numberInput);
      return numberValue > minimum ? numberValue : minimum;
    };

    switch (name) {
      case 'externalIdentifier':
        externalIdentifier = value as string;
        break;

      case 'pickingType':
        type = value as PickingType;
        break;

      case 'colors':
        // cant find a "HTMLMultipleSelectElement" type when multiple is true
        colors = (target as HTMLSelectElement & { value: string[] }).value as PickingColor[];
        break;

      case 'requiredSideAck':
        requiredSideAck = (target as HTMLInputElement).checked;
        if (!requiredSideAck) {
          symmetricalAck = false;
        }
        break;

      case 'optionalSideAck':
        optionalSideAck = (target as HTMLInputElement).checked;
        if (!optionalSideAck) {
          symmetricalAck = false;
        }
        break;

      case 'symmetricalAck':
        symmetricalAck = (target as HTMLInputElement).checked;
        if (symmetricalAck) {
          optionalSideAck = true;
          requiredSideAck = true;
        }
        break;

      case 'simultaneousJobCount':
        simultaneousJobCount = toNumber(value, 1);
        break;

      case 'simultaneousBlinkCount':
        simultaneousBlinkCount = toNumber(value, 0);
        break;

      case 'defaultBlinkDuration':
        defaultBlinkDuration = toNumber(value, 0);
        break;

      case 'ackByButtonTopic':
        const stringValue = value as string;
        ackByButtonTopic = stringValue !== '' ? stringValue : null;
        break;

      case 'automaticJobFinish':
        automaticJobFinish = (target as HTMLInputElement).checked;
        break;
    }

    const allFilled = PickingAreaPanelFormComponent.isAllFilled(
      externalIdentifier,
      colors,
      requiredSideSelected,
      defaultBlinkDuration,
      simultaneousBlinkCount,
      simultaneousJobCount,
      editablePickingArea,
    );
    pickingAreaStore.changed = true;
    pickingAreaStore.setState({
      allFilled,
      externalIdentifier,
      type,
      colors,
      requiredSideAck,
      optionalSideAck,
      symmetricalAck,
      simultaneousBlinkCount,
      simultaneousJobCount,
      defaultBlinkDuration,
      ackByButtonTopic,
      automaticJobFinish,
    });
  };

  public handleRequiredSideSelected = (requiredSideSelected: ReactSelectAsyncOption<SortedSide> | null) => {
    const { pickingAreaStore } = this.stores;
    const {
      externalIdentifier,
      colors,
      defaultBlinkDuration,
      simultaneousBlinkCount,
      simultaneousJobCount,
    } = pickingAreaStore.state;

    const allFilled = PickingAreaPanelFormComponent.isAllFilled(
      externalIdentifier,
      colors,
      requiredSideSelected,
      defaultBlinkDuration,
      simultaneousBlinkCount,
      simultaneousJobCount,
    );
    pickingAreaStore.changed = true;
    pickingAreaStore.setState({
      allFilled,
      requiredSideSelected,
    });
  };

  public handleOptionalSideSelected = (optionalSideSelected: ReactSelectAsyncOption<SortedSide> | null) => {
    const { pickingAreaStore } = this.stores;
    let { optionalSideAck } = pickingAreaStore.state;

    if (optionalSideSelected === null) {
      optionalSideAck = null;
    }

    pickingAreaStore.setState({
      optionalSideAck,
      optionalSideSelected,
    });
    pickingAreaStore.changed = true;
  };

  public render() {
    const { pickingAreaStore, configStore } = this.stores;
    const { editablePickingArea } = pickingAreaStore;
    const { loading } = this.state;
    const {
      externalIdentifier,
      type,
      colors,
      requiredSideAck,
      optionalSideAck,
      symmetricalAck,
      requiredSideSelected,
      optionalSideSelected,
      defaultBlinkDuration,
      simultaneousBlinkCount,
      simultaneousJobCount,
      ackByButtonTopic,
      automaticJobFinish,
    } = pickingAreaStore.state;

    const ackByButtonTopicMenuItems = configStore.config.ackByButtonTopics.map((topic) => (
      <MenuItem key={topic} value={topic}>
        {topic}
      </MenuItem>
    ));
    ackByButtonTopicMenuItems.unshift(
      <MenuItem key="disabled" value="">
        disable
      </MenuItem>,
    );

    const colorMenuItems = PickingColors.map((color: PickingColor, index: number) => (
      <MenuItem key={index} value={color}>
        {color}
      </MenuItem>
    ));

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item xs={4}>
          <Fade in={true} timeout={fadeTimeout}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid item xs={12}>
                <StyledTextField
                  type={'text'}
                  label={'External-Identifier'}
                  value={externalIdentifier}
                  onChange={this.handleChange('externalIdentifier')}
                  disabled={editablePickingArea !== undefined}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledSelectField
                  label={'Type'}
                  value={type}
                  onChange={this.handleChange('pickingType')}
                  disabled={editablePickingArea !== undefined}
                >
                  <MenuItem value={'pick-by'}>PickBy</MenuItem>
                  <MenuItem value={'pick-to'}>PickTo</MenuItem>
                  <MenuItem value={'pick-combination'}>PickCombination</MenuItem>
                </StyledSelectField>
              </Grid>

              <Grid item xs={12}>
                <StyledSelectField multiple label={'Colors'} value={[...colors]} onChange={this.handleChange('colors')}>
                  {colorMenuItems}
                </StyledSelectField>
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  type={'number'}
                  label={'Blink-Count'}
                  value={simultaneousBlinkCount}
                  onChange={this.handleChange('simultaneousBlinkCount')}
                  tooltip={'Maximum number of blinking labels'}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledTextField
                  type={'number'}
                  label={'Blink-Duration'}
                  value={defaultBlinkDuration}
                  onChange={this.handleChange('defaultBlinkDuration')}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledTextField
                  type={'number'}
                  label={'Concurrent-Jobs'}
                  value={simultaneousJobCount}
                  onChange={this.handleChange('simultaneousJobCount')}
                  tooltip={'Maximum number of running picking orders'}
                />
              </Grid>
            </Grid>
          </Fade>
        </Grid>

        <Grid item xs={4}>
          <Fade in={true} timeout={fadeTimeout}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid item xs={12}>
                <FormControl variant="outlined" style={selectStyle}>
                  <SortedSideSelect
                    label={'Required Picking-Side'}
                    value={requiredSideSelected}
                    handleValueChange={this.handleRequiredSideSelected}
                    isDisabled={editablePickingArea !== undefined}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" style={selectStyle}>
                  <SortedSideSelect
                    label={'Optional Picking-Side'}
                    value={optionalSideSelected}
                    handleValueChange={this.handleOptionalSideSelected}
                    isDisabled={editablePickingArea !== undefined}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <StyledSelectField
                  label={'Button-Acknowledgement-Topic'}
                  value={ackByButtonTopic === null ? '' : ackByButtonTopic}
                  onChange={this.handleChange('ackByButtonTopic')}
                  disabled={ackByButtonTopicMenuItems.length < 2}
                >
                  {ackByButtonTopicMenuItems}
                </StyledSelectField>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} alignItems={'stretch'}>
                  <Grid item xs={6}>
                    <Tooltip title={'Acknowledge required side once per pick'} arrow={true}>
                      <FormControlLabel
                        style={checkboxStyle}
                        label="Required Side Ack"
                        control={
                          <Checkbox
                            checked={requiredSideAck}
                            onChange={this.handleChange('requiredSideAck')}
                            color="secondary"
                          />
                        }
                        disabled={editablePickingArea !== undefined}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6}>
                    <Tooltip title={'Acknowledge optional side once per order'} arrow={true}>
                      <FormControlLabel
                        style={checkboxStyle}
                        label="Optional Side Ack"
                        control={
                          <Checkbox
                            checked={optionalSideAck !== undefined && optionalSideAck !== null && optionalSideAck}
                            onChange={this.handleChange('optionalSideAck')}
                            color="secondary"
                            disabled={!optionalSideSelected || optionalSideSelected.value === undefined}
                          />
                        }
                        disabled={editablePickingArea !== undefined}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6}>
                    <Tooltip title={'Acknowledge both sides for every pick'} arrow={true}>
                      <FormControlLabel
                        style={checkboxStyle}
                        label="Symmetrical Ack"
                        control={
                          <Checkbox
                            checked={symmetricalAck !== undefined && symmetricalAck !== null && symmetricalAck}
                            onChange={this.handleChange('symmetricalAck')}
                            color="secondary"
                            disabled={!optionalSideSelected || optionalSideSelected.value === undefined}
                          />
                        }
                        disabled={editablePickingArea !== undefined}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6}>
                    <Tooltip
                      title={'Automatically set picking order jobs to finished when all values are acknowledged'}
                      arrow={true}
                    >
                      <FormControlLabel
                        style={checkboxStyle}
                        label="Automatically Finish Jobs"
                        control={
                          <Checkbox
                            checked={automaticJobFinish}
                            onChange={this.handleChange('automaticJobFinish')}
                            color="secondary"
                          />
                        }
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickingAreaPanelFormComponent);

export const PickingAreaPanelForm = StyleWrapped;
