import { Chip, MenuItem, MenuList, Paper, TextField, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import classNames from 'classnames';
import React, { RefObject } from 'react';
import { SelectComponentsProps } from 'react-select/src/Select';

export class ReactSelectComponents {
  public static NoOptionsMessage(props: SelectComponentsProps): React.JSX.Element {
    return (
      <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  public static inputComponent({
    inputRef,
    ...props
  }: { inputRef: RefObject<HTMLInputElement> } & SelectComponentsProps): React.JSX.Element {
    return <div ref={inputRef} {...props} />;
  }

  public static Control(props: SelectComponentsProps): React.JSX.Element {
    return (
      <TextField
        fullWidth
        variant={'outlined'}
        InputProps={{
          inputComponent: ReactSelectComponents.inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }

  public static Option(props: SelectComponentsProps): React.JSX.Element {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  public static Placeholder(props: SelectComponentsProps): React.JSX.Element {
    return (
      <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  public static SingleValue(props: SelectComponentsProps): React.JSX.Element {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  public static LoadingMessage(props: SelectComponentsProps): React.JSX.Element {
    return (
      <Typography color={'primary'} align={'center'} variant={'subtitle1'} {...props.innerProps}>
        Loading ...
      </Typography>
    );
  }

  public static MultiValue(props: SelectComponentsProps): React.JSX.Element {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<Cancel {...props.removeProps} />}
      />
    );
  }

  public static Menu(props: SelectComponentsProps): React.JSX.Element {
    return (
      <Paper elevation={2} square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }

  public static MenuList(props: SelectComponentsProps): React.JSX.Element {
    return (
      <MenuList className={props.selectProps.classes.menuList} {...props.innerProps}>
        {props.children}
      </MenuList>
    );
  }

  public static ValueContainer(props: SelectComponentsProps): React.JSX.Element {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }
}
