import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  Input,
  InputLabel,
  Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogProps } from '@mui/material/Dialog';
import React from 'react';
import { spacing } from '../Helper/ThemeHelper';
import { CheckmarkSpinner } from './CheckmarkSpinner';

const defaultTimeoutSeconds = 2;

const styles = (theme: Theme) => ({
  button: {
    margin: spacing(theme),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

interface GenericDialogState {
  okButtonText: string;
  okButtonTimeoutSecondsLeft: number;
  okButtonTimeout: boolean;
  okButtonTimeoutDone: boolean;
  cancelButtonText: string;
  autoConfirm: boolean;
  autoConfirmDone: boolean;
  autoConfirmSecondsLeft: number;
  inputText: string;
}

type GenericDialogType = 'confirmation' | 'input';

interface GenericDialogProps extends WithStyles<typeof styles>, Omit<DialogProps, 'classes' | 'title' | 'onSubmit'> {
  type: GenericDialogType;
  title: string | React.ReactNode;
  text: React.ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
  onSubmit?: (value: string) => void;
  open: boolean;
  okButtonDisabled?: boolean;
  centered?: boolean;
  timedOkButton?: boolean;
  checkmarkSpinner?: boolean;
  checkmarkSpinnerDone?: boolean;
  minHeight?: number;
  minWidth?: number;
  autoConfirm?: boolean;
  autoConfirmTime?: number;
  autoConfirmTextFn?: (autoConfirmSecondsLeft: number) => React.ReactNode;
  buttonText?: { cancel: string; ok: string };
}

class GenericDialogComponent extends React.Component<GenericDialogProps, GenericDialogState> {
  public state: GenericDialogState = {
    okButtonText: 'OK',
    okButtonTimeoutSecondsLeft: defaultTimeoutSeconds,
    okButtonTimeout: false,
    okButtonTimeoutDone: false,
    cancelButtonText: 'Cancel',
    autoConfirm: false,
    autoConfirmDone: false,
    autoConfirmSecondsLeft: 0,
    inputText: '',
  };

  private okButtonTimeout?: number;
  private autoConfirmTimeout?: number;

  public static getDerivedStateFromProps(props: GenericDialogProps, state: GenericDialogState) {
    const newState: Partial<GenericDialogState> = {};

    if (props.buttonText) {
      newState.okButtonText = props.buttonText.ok;
      newState.cancelButtonText = props.buttonText.cancel;
    }

    if (props.timedOkButton && !state.okButtonTimeout && !state.okButtonTimeoutDone) {
      newState.okButtonTimeout = true;
      newState.okButtonTimeoutSecondsLeft = defaultTimeoutSeconds;
      newState.okButtonText = String(defaultTimeoutSeconds);
    }

    if (props.autoConfirm && !state.autoConfirm && !state.autoConfirmDone) {
      newState.autoConfirm = true;
      newState.autoConfirmSecondsLeft = props.autoConfirmTime;
    }

    return newState;
  }

  public componentWillUnmount() {
    window.clearTimeout(this.okButtonTimeout);
    window.clearTimeout(this.autoConfirmTimeout);
  }

  public timeoutOkButton = () => {
    const { okButtonTimeoutSecondsLeft } = this.state;

    this.okButtonTimeout = window.setTimeout(
      () => {
        if (okButtonTimeoutSecondsLeft > 0) {
          this.setState({
            okButtonText: String(okButtonTimeoutSecondsLeft),
            okButtonTimeoutSecondsLeft: okButtonTimeoutSecondsLeft - 1,
          });
        } else {
          this.setState({
            okButtonText: 'OK',
            okButtonTimeout: false,
            okButtonTimeoutDone: true,
          });
        }
      },
      defaultTimeoutSeconds - okButtonTimeoutSecondsLeft > 0 ? 1000 : 0,
    );
  };

  public autoConfirm = () => {
    const { autoConfirmDone, autoConfirmSecondsLeft } = this.state;
    const { autoConfirm, autoConfirmTime, onConfirm, onClose } = this.props;

    if (!autoConfirm || !autoConfirmTime || autoConfirmDone) {
      return;
    }

    this.autoConfirmTimeout = window.setTimeout(
      () => {
        if (autoConfirmSecondsLeft > 0) {
          this.setState({
            autoConfirmSecondsLeft: autoConfirmSecondsLeft - 1,
          });
        } else {
          this.setState({
            autoConfirmDone: true,
          });

          if (onConfirm && typeof onConfirm === typeof Function) {
            onConfirm();
          }
          onClose();
        }
      },
      autoConfirmTime - autoConfirmSecondsLeft > 0 ? 1000 : 0,
    );
  };

  public render() {
    const {
      classes,
      type,
      title,
      text,
      onClose,
      onConfirm,
      centered,
      checkmarkSpinner,
      checkmarkSpinnerDone,
      minHeight,
      minWidth,
      autoConfirmTextFn,
      okButtonDisabled,
      onSubmit,
      ...rest
    } = this.props;

    // props that don't have to be set for <Dialog/>
    delete rest.timedOkButton;
    delete rest.autoConfirm;
    delete rest.autoConfirmTime;
    delete rest.buttonText;

    const {
      okButtonText,
      okButtonTimeout,
      autoConfirm,
      autoConfirmSecondsLeft,
      cancelButtonText,
      inputText,
    } = this.state;

    if (okButtonTimeout) {
      this.timeoutOkButton();
    }

    this.autoConfirm();

    const okMethod = () => {
      if (type === 'input' && onSubmit && typeof onSubmit === typeof Function) {
        onSubmit(inputText);
      } else if (onConfirm && typeof onConfirm === typeof Function) {
        onConfirm();
      }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (onSubmit && typeof onSubmit === typeof Function) {
        onSubmit(inputText);
      }
    };

    return (
      <Dialog onClose={onClose} {...rest}>
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent
          style={{
            minWidth,
            minHeight,
          }}
        >
          <DialogContentText
            align={centered ? 'center' : 'left'}
            id="confirmation-dialog-description"
            component={'div'}
          >
            {checkmarkSpinner && (
              <Fade in={true} timeout={2000}>
                <CheckmarkSpinner complete={!!checkmarkSpinnerDone} failure={false} />
              </Fade>
            )}
            {!checkmarkSpinner && text}
            {!checkmarkSpinner && type === 'input' && (
              <form onSubmit={handleSubmit}>
                <FormControl variant="standard" margin="normal" required fullWidth>
                  <InputLabel htmlFor="compartment-search">Search for Compartments</InputLabel>
                  <Input
                    name="compartment-search"
                    type="text"
                    id="compartment-search"
                    value={this.state.inputText}
                    onChange={(e) => this.setState({ inputText: e.target.value })}
                  />
                </FormControl>
              </form>
            )}
            <div
              style={{
                paddingTop: 18,
                textAlign: 'center',
              }}
            >
              {autoConfirm && autoConfirmTextFn && autoConfirmTextFn(autoConfirmSecondsLeft)}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={onClose}
            disabled={checkmarkSpinner}
          >
            {cancelButtonText}
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            disabled={okButtonDisabled || checkmarkSpinner || okButtonTimeout}
            className={classes.button}
            onClick={okMethod}
          >
            {okButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const GenericDialog = withStyles(styles)(GenericDialogComponent);
