import { Role } from '@ekkogmbh/apisdk';
import { Delete, Edit, Group } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { enqueueSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { customDivNullRender } from '../../Common/Components/ReactHelpers';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  RoleManagementContentActionHandlers,
  RoleManagementContentHelpers,
  RoleManagementContentPropsWithStores,
  RoleManagementContentState,
} from './RoleManagementContent';
import { RolePermissionSelect } from './RolePermissionSelect';

class RoleDataTableActions extends DataTableActionsComponent<Role> {}

const RoleActions = (
  _: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
  actions: RoleManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (role: Role): React.ReactNode => {
      const { api } = propsWithStores;

      const hasWritePermission = api.userHasPermissionOnAnyNode(Permissions.ROLES_WRITE);
      const hasUsersReadPermission = api.userHasPermissionOnAnyNode(Permissions.USERS_READ);

      const actionButtons: ActionButton<Role>[] = [
        {
          title: 'Users',
          onClick: actions.users,
          icon: Group,
          disabled: !hasUsersReadPermission,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return <RoleDataTableActions dataset={role} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const RoleBodyRenderName = (
  propsWithStores: RoleManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (name: Role['name']) => {
  const { classes } = propsWithStores;

  return (
    <Fragment>
      <span className={classes.boldFont}>{name}</span>
    </Fragment>
  );
};

const RoleBodyRenderPermissions = (
  state: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
  _: RoleManagementContentActionHandlers,
  helpers: RoleManagementContentHelpers,
  // eslint-disable-next-line react/display-name
) => (role: Role) => {
  if (!role) {
    return;
  }

  const { api, searchContentStore } = propsWithStores;
  const { allPermissions } = state;

  const hasWritePermission = api.userHasPermissionOnAnyNode(Permissions.ROLES_WRITE);

  const handleNoPermission = () => {
    enqueueSnackbar('Missing write permission.', { variant: 'error' });
    searchContentStore.emitRefresh();
  };

  return (
    <RolePermissionSelect
      role={role}
      allPermissions={allPermissions}
      saveHandler={hasWritePermission ? helpers.updateRolePermissions : handleNoPermission}
      noDialog={!hasWritePermission}
    />
  );
};

const RoleName = (
  _: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Role',
  name: 'entry.name',
  options: {
    sort: false,
    filter: false,
    customBodyRender: RoleBodyRenderName(propsWithStores),
  },
});

const RolePermissions = (
  state: RoleManagementContentState,
  propsWithStores: RoleManagementContentPropsWithStores,
  actions: RoleManagementContentActionHandlers,
  helpers?: RoleManagementContentHelpers,
): MUIDataTableColumnDef => ({
  label: 'Permissions',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: RoleBodyRenderPermissions(state, propsWithStores, actions, helpers!),
  },
});

const RoleTableSpacer = (): MUIDataTableColumnDef => ({
  label: ' ',
  name: '',
  options: {
    sort: false,
    filter: false,
    customBodyRender: customDivNullRender,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  RoleManagementContentPropsWithStores,
  RoleManagementContentState,
  RoleManagementContentActionHandlers,
  RoleManagementContentHelpers
>> = [RoleName, RolePermissions, RoleTableSpacer, RoleActions];

export { RoleName, RolePermissions, RoleTableSpacer };
