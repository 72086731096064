import { Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ClipboardEvent, Component } from 'react';
import AsyncSelect from 'react-select/async';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select/src/types';
import { CancelablePromise, makePromiseCancelable } from '../Helper/PromiseHelper';
import { ReactSelectComponents } from './ReactSelect/Components';
import { ReactSelectStyles } from './ReactSelect/Styles';

const styles = ReactSelectStyles;

export interface ReactSelectAsyncOption<T> {
  value: T;
  label: string;
}

type SuggestionFetcher<T> = (value: string) => Promise<Array<ReactSelectAsyncOption<T>>>;

interface ReactSelectAsyncProps<T> extends WithStyles<typeof styles> {
  handleChange: (value: ValueType<OptionTypeBase>, actionMeta: ActionMeta<OptionTypeBase>) => void;
  value: ReactSelectAsyncOption<T> | null;
  placeholder: string;
  label?: string;
  onPaste?: (event: ClipboardEvent<HTMLSelectElement>) => void;
  fullValueLabel?: string;
  isDisabled?: boolean;
  fetchSuggestions: SuggestionFetcher<T>;
}

class ReactSelectAsyncComponent<T> extends Component<ReactSelectAsyncProps<T>> {
  private fetchTimeout?: number;
  private wrappedFetcher?: CancelablePromise;

  // @TODO
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static NoOptionsMessage(props: any) {
    return (
      <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
        {props.children}
        {props.selectProps.inputValue.trim().length < 2 && '. Please type at least 2 characters.'}
      </Typography>
    );
  }

  public fetchWrapper = (fetcher: SuggestionFetcher<T>): SuggestionFetcher<T> => (
    value: string,
  ): Promise<Array<ReactSelectAsyncOption<T>>> => {
    if (this.wrappedFetcher && !this.wrappedFetcher.isResolved()) {
      this.wrappedFetcher.cancel();
    }

    if (value.trim().length < 2) {
      return new Promise<Array<ReactSelectAsyncOption<T>>>((resolve) => {
        resolve([]);
      });
    }

    const promise = new Promise<Array<ReactSelectAsyncOption<T>>>((resolve) => {
      window.clearTimeout(this.fetchTimeout);
      this.fetchTimeout = window.setTimeout(async () => {
        const result = await fetcher(value);

        resolve(result);
      }, 500);
    });

    this.wrappedFetcher = makePromiseCancelable<Array<ReactSelectAsyncOption<T>>>(promise);

    return this.wrappedFetcher.promise;
  };

  public render() {
    const { classes, label, handleChange, value, placeholder, onPaste, isDisabled, fetchSuggestions } = this.props;

    // https://react-select.com/components
    const components = {
      Control: ReactSelectComponents.Control,
      LoadingMessage: ReactSelectComponents.LoadingMessage,
      Menu: ReactSelectComponents.Menu,
      MenuList: ReactSelectComponents.MenuList,
      NoOptionsMessage: ReactSelectAsyncComponent.NoOptionsMessage,
      Option: ReactSelectComponents.Option,
      Placeholder: ReactSelectComponents.Placeholder,
      ValueContainer: ReactSelectComponents.ValueContainer,
    };

    return (
      <AsyncSelect
        cacheOptions
        classes={classes}
        textFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
          },
          onPaste,
        }}
        loadOptions={this.fetchWrapper(fetchSuggestions)}
        components={components}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isClearable
      />
    );
  }
}

const StyleWrapped = withStyles(styles)(ReactSelectAsyncComponent);

export const ReactSelectAsync = StyleWrapped;
