import {
  Pagination,
  PaginationResponse,
  PickingArea,
  PickingAreaCreatePayload,
  PickingAreaSortedSide,
  PickingAreaUpdatePayload,
  SortedSide,
} from '@ekkogmbh/apisdk';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { PickingAreaStore } from '../Stores/PickingAreaStore';
import { PickingAreaPanelForm } from './PickingAreaPanelForm';

const stores = ['api', 'pickingAreaStore'];

interface PickingAreaPanelStores {
  api: ApiStore;
  pickingAreaStore: PickingAreaStore;
}

const styles = FormStyles;

export interface PickingAreaPanelState {
  loading: boolean;
}

export interface PickingAreaPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  updateHandler: (pickingArea: PickingAreaUpdatePayload) => Promise<PickingArea>;
  saveHandler: (pickingArea: PickingAreaCreatePayload) => Promise<PickingArea>;
  fetchSortedSides: (pagination: Pagination) => Promise<PaginationResponse<SortedSide>>;
  closeCallback?: () => void;
  pickingArea?: PickingArea;
}

@inject(...stores)
@observer
class PickingAreaPanelComponent extends React.Component<PickingAreaPanelProps, PickingAreaPanelState> {
  public state: PickingAreaPanelState = {
    loading: false,
  };

  get stores(): PickingAreaPanelStores {
    return this.props as PickingAreaPanelProps & PickingAreaPanelStores;
  }

  public async componentDidMount(): Promise<void> {
    await this.refreshStores();
  }

  public async componentDidUpdate(prevProps: Readonly<PickingAreaPanelProps>): Promise<void> {
    await this.refreshStores(prevProps);
  }

  public refreshStores = async (prevProps?: Readonly<PickingAreaPanelProps>): Promise<void> => {
    const { pickingAreaStore } = this.stores;

    const isInitialPickingArea: boolean = this.props.pickingArea !== undefined && prevProps === undefined;

    const isPickingAreaChanged: boolean =
      prevProps !== undefined &&
      prevProps.pickingArea !== undefined &&
      this.props.pickingArea !== undefined &&
      prevProps.pickingArea !== this.props.pickingArea;

    const isNewPickingArea: boolean =
      prevProps !== undefined && prevProps.pickingArea === undefined && this.props.pickingArea !== undefined;

    const isPickingAreaGone: boolean =
      prevProps !== undefined && prevProps.pickingArea !== undefined && this.props.pickingArea === undefined;

    if (isInitialPickingArea || isPickingAreaChanged || isNewPickingArea) {
      pickingAreaStore.editablePickingArea = this.props.pickingArea as PickingArea;
      pickingAreaStore.resetState();
    } else if (isPickingAreaGone) {
      pickingAreaStore.resetStore();
    }
  };

  public handleReset = async () => {
    const { pickingAreaStore } = this.stores;
    pickingAreaStore.resetState();
  };

  public handleSave = async () => {
    const { loading } = this.state;
    const { pickingAreaStore } = this.stores;
    const { closeHandler, saveHandler, updateHandler, closeCallback } = this.props;
    const {
      externalIdentifier,
      type,
      requiredSideAck,
      optionalSideAck,
      symmetricalAck,
      colors,
      allFilled,
      requiredSideSelected,
      optionalSideSelected,
      defaultBlinkDuration,
      simultaneousBlinkCount,
      simultaneousJobCount,
      ackByButtonTopic,
      automaticJobFinish,
    } = pickingAreaStore.state;
    const { editablePickingArea } = pickingAreaStore;

    if (loading) {
      return;
    }

    this.setState({ loading: true });

    if (editablePickingArea === undefined) {
      if (!allFilled || !requiredSideSelected || requiredSideSelected.value === undefined) {
        enqueueSnackbar('All Form-Fields need to be filled correctly.', { variant: 'warning' });
        return;
      }

      const requiredSide: PickingAreaSortedSide = {
        id: requiredSideSelected.value.id,
        name: requiredSideSelected.value.name,
      };

      const optionalSide =
        !optionalSideSelected || optionalSideSelected.value === undefined
          ? null
          : {
              id: optionalSideSelected.value.id,
              name: optionalSideSelected.value.name,
            };

      const creatablePickingArea: PickingAreaCreatePayload = {
        id: externalIdentifier,
        type,
        requiredSide,
        requiredSideAck,
        optionalSide,
        optionalSideAck: optionalSideSelected ? !!optionalSideAck : null,
        symmetricalAck: optionalSideSelected ? !!symmetricalAck : undefined,
        colors,
        defaultBlinkDuration: defaultBlinkDuration,
        simultaneousBlinkCount: simultaneousBlinkCount,
        maximumSimultaneouslyRunningJobs: simultaneousJobCount,
        ackByButtonTopic: ackByButtonTopic !== null ? ackByButtonTopic : undefined,
        automaticJobFinish: automaticJobFinish,
      };

      await saveHandler(creatablePickingArea);
    } else {
      if (!allFilled) {
        enqueueSnackbar('All Form-Fields need to be filled correctly.', { variant: 'warning' });
        return;
      }

      const updatePickingArea: PickingAreaUpdatePayload = editablePickingArea;
      updatePickingArea.colors = colors;
      updatePickingArea.simultaneousBlinkCount = simultaneousBlinkCount;
      updatePickingArea.defaultBlinkDuration = defaultBlinkDuration;
      updatePickingArea.maximumSimultaneouslyRunningJobs = simultaneousJobCount;
      updatePickingArea.ackByButtonTopic = ackByButtonTopic !== null ? ackByButtonTopic : undefined;
      updatePickingArea.automaticJobFinish = automaticJobFinish;

      await updateHandler(updatePickingArea);
    }

    this.setState({ loading: false });

    closeHandler();
    if (closeCallback) {
      closeCallback();
    }
  };

  public render() {
    const { pickingAreaStore } = this.stores;
    const { changed } = pickingAreaStore;
    const { allFilled } = pickingAreaStore.state;

    const { closeHandler, closeCallback } = this.props;

    const close =
      closeCallback !== undefined
        ? () => {
            closeHandler();
            closeCallback();
          }
        : closeHandler;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12}>
          <PickingAreaPanelForm />
        </Grid>

        <FormPanelButtons
          cancelHandler={close}
          resetHandler={this.handleReset}
          saveHandler={this.handleSave}
          deleteHandler={undefined}
          isResetDisabled={!changed}
          isSaveDisabled={!changed || !allFilled}
          isDeleteDisabled={true}
          isDeleteHidden={true}
        />
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickingAreaPanelComponent);

export const PickingAreaPanel = StyleWrapped;
