import { CompartmentView, LinkSavePayload, LinkView } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export class LinkStore {
  @observable
  public targetCompartment?: CompartmentView | undefined = undefined;

  @observable
  public editableLink?: LinkView | undefined = undefined;

  @observable
  public isAllFilled: boolean = false;

  @observable
  public state = {
    coordinate: '',
    technology: '',
    labelId: '',
    pageNumber: 1,
    templateName: '',
  } as LinkSavePayload;

  @action
  public setState = (newState: Partial<LinkSavePayload>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.isAllFilled =
      !!this.state.coordinate && !!this.state.technology && !!this.state.labelId && !!this.state.templateName;
  };

  public resetStore = (): void => {
    this.editableLink = undefined;
    this.targetCompartment = undefined;

    this.setState({
      id: undefined,
      coordinate: '',
      technology: '',
      labelId: '',
      pageNumber: 1,
      templateName: undefined,
    });
  };

  public setTargetCompartment = (compartment: CompartmentView): void => {
    this.editableLink = undefined;
    this.setState({
      coordinate: compartment.coordinate,
      id: undefined,
      technology: '',
      labelId: '',
      pageNumber: 1,
      templateName: '',
    });
  };

  public setEditableLink = (link?: LinkView): void => {
    this.editableLink = link;

    if (link) {
      this.setState({
        id: link.id,
        coordinate: link.coordinate,
        technology: link.technology,
        labelId: link.labelId,
        pageNumber: link.pageNumber,
        templateName: link.template.name,
      });
    } else {
      this.setState({
        id: undefined,
        technology: '',
        labelId: '',
        pageNumber: 1,
        templateName: '',
      });
    }
  };

  public getSavableLink = (): LinkSavePayload => this.state as LinkSavePayload;
}
