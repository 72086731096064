import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { UserMappingsContent } from './Components/UserMappingsContent';

export const UserMappings = ({ title }: TitleProps): React.JSX.Element => (
  <Page title={title} isSearchable={false}>
    <UserMappingsContent />
  </Page>
);
