import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from 'src/Common/Components/Page';
import { CompartmentGeneratorStore } from 'src/CompartmentManagement/Stores/CompartmentGeneratorStore';
import { LabelLinkStore } from 'src/LabelManagement/Stores/LabelLinkStore';
import { TitleProps } from 'src/Routes';
import { LinkProfileManagementContent } from './Components/LinkProfileManagementContent';
import { LinkProfileStore } from './Stores/LinkProfileStore';

const linkProfileStore = new LinkProfileStore();
const labelLinkStore = new LabelLinkStore();
const compartmentGeneratorStore = new CompartmentGeneratorStore();

const stores = {
  linkProfileStore,
  labelLinkStore,
  compartmentGeneratorStore,
};

export const LinkProfileManagement = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <LinkProfileManagementContent />
    </Page>
  </Provider>
);
