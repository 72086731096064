import {
  ClearingUpdateStrategy,
  CompartmentSelector,
  EditMode,
  ManualUpdateStrategy,
  UpdaterProfile,
  UpdateStrategy,
} from '@ekkogmbh/apisdk';
import { RegexScanUpdateStrategy } from '@ekkogmbh/apisdk/src/Sdk/ApiTypes';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { CompartmentGeneratorStore } from '../../CompartmentManagement/Stores/CompartmentGeneratorStore';
import { UpdaterProfileStore } from '../Stores/UpdaterProfileStore';
import { resolveStrategyName } from '../UpdaterProfileManagement';
import { UpdaterProfileCompartmentSelectionForm } from './UpdaterProfileCompartmentSelectionForm';
import { UpdaterProfileConfigForm } from './UpdaterProfileConfigForm';
import { PresetStore } from 'src/PresetManagement/Stores/PresetStore';

const styles = FormStyles;

interface UpdaterProfilePanelStores {
  api: ApiStore;
  compartmentGeneratorStore: CompartmentGeneratorStore;
  updaterProfileStore: UpdaterProfileStore;
  presetStore: PresetStore;
}

interface UpdaterProfilePanelState {
  activeStep: number;
  finished: boolean;
  loading: boolean;
}

export interface UpdaterProfilePanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (updaterProfile: UpdaterProfile) => Promise<UpdaterProfile>;
}

const StepTitles = ['Profile', 'Compartment Selection', 'Configuration', 'Overview'] as const;
type StepTitle = typeof StepTitles[number];

@inject('api', 'compartmentGeneratorStore', 'updaterProfileStore', 'presetStore')
@observer
class UpdaterProfilePanelComponent extends React.Component<UpdaterProfilePanelProps, UpdaterProfilePanelState> {
  public state: UpdaterProfilePanelState = {
    activeStep: 0,
    finished: false,
    loading: false,
  };

  get stores(): UpdaterProfilePanelStores {
    return this.props as UpdaterProfilePanelProps & UpdaterProfilePanelStores;
  }

  public handleReset = async () => {
    this.setState({ activeStep: 0, loading: false });
    const { compartmentGeneratorStore, updaterProfileStore, presetStore } = this.stores;
    compartmentGeneratorStore.resetStore();
    updaterProfileStore.resetStore();
    presetStore.resetStore();
  };

  public componentWillUnmount(): void {
    const { compartmentGeneratorStore, updaterProfileStore, presetStore } = this.stores;
    compartmentGeneratorStore.resetStore();
    updaterProfileStore.resetStore();
    presetStore.resetStore();
  }

  public handleSave = async () => {
    const { saveHandler, closeHandler } = this.props;
    const { compartmentGeneratorStore, updaterProfileStore } = this.stores;
    const {
      clearingText,
      compartmentSelector,
      updateStrategy,
      name,
      coordinate,
      multipleCompartmentSelection,
      preserveFields,
    } = updaterProfileStore.state;

    const compartmentInputInstructions =
      updaterProfileStore.state.compartmentInputInstructions !== ''
        ? updaterProfileStore.state.compartmentInputInstructions
        : null;

    if ((updateStrategy as UpdateStrategy).type === 'regex-scan') {
      (updateStrategy as RegexScanUpdateStrategy).params.fields = compartmentGeneratorStore.fields.slice();
    } else if ((updateStrategy as UpdateStrategy).type === 'clearing') {
      (updateStrategy as ClearingUpdateStrategy).params.clearingText = clearingText;
    }

    const payload: UpdaterProfile = {
      name,
      coordinate,
      compartmentInputInstructions,
      compartmentSelector: compartmentSelector as CompartmentSelector,
      multipleCompartmentSelection,
      updateStrategy: updateStrategy as UpdateStrategy,
      preserveFields,
    };

    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          await saveHandler(payload);
          closeHandler();
        } catch (e) {
          this.setState({ loading: false });
        }
      },
    );
  };

  public isNextDisabled = (step: DynamicStepperStep & { title: StepTitle }): boolean => {
    const { compartmentGeneratorStore, updaterProfileStore } = this.stores;

    switch (step.title) {
      case 'Profile':
        const { name, coordinate } = updaterProfileStore.state;
        return name === '' || coordinate === '';
      case 'Configuration':
        const { updateStrategy, preset } = updaterProfileStore.state;
        return (
          !updateStrategy ||
          ((updateStrategy.type === 'manual' || updateStrategy.type === 'clearing') && !preset) ||
          (updateStrategy.type === 'regex-scan' && compartmentGeneratorStore.fields.length < 1)
        );
      case 'Overview':
        return true;
    }

    return false;
  };

  public handleNext = async () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep + 1 });
  };

  public handleBack = async () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep - 1 });
  };

  public getSteps = (): (DynamicStepperStep & { title: StepTitle })[] => [
    {
      title: 'Profile',
      elementCallback: (): React.JSX.Element => {
        const { updaterProfileStore } = this.stores;
        const { name, coordinate } = updaterProfileStore.state;

        const isDisabled = updaterProfileStore.editableUpdaterProfile !== undefined;

        return (
          <Grid container item xs={12} spacing={2} alignContent="stretch">
            <Grid item xs={6}>
              <StyledTextField
                disabled={isDisabled}
                type="text"
                label="Name"
                value={name}
                onChange={(e) => updaterProfileStore.setState({ name: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <CoordinateInput
                disabled={isDisabled}
                value={coordinate}
                onChange={(coordinate) => updaterProfileStore.setState({ coordinate })}
                trailingDelimiter={false}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Compartment Selection',
      elementCallback: (): React.JSX.Element => (
        <UpdaterProfileCompartmentSelectionForm errorCallback={this.handleReset} />
      ),
    },
    {
      title: 'Configuration',
      elementCallback: (): React.JSX.Element => <UpdaterProfileConfigForm errorCallback={this.handleReset} />,
    },
    {
      title: 'Overview',
      elementCallback: (): React.JSX.Element => {
        const { compartmentGeneratorStore, updaterProfileStore } = this.stores;
        const {
          name,
          coordinate,
          updateStrategy,
          compartmentSelector,
          multipleCompartmentSelection,
          clearingText,
          preserveFields,
        } = updaterProfileStore.state;

        const overlineStyle = { fontWeight: 700 };

        return (
          <Grid container spacing={2} alignItems="stretch" style={{ wordBreak: 'break-word' }}>
            <Grid item xs={6}>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Name
              </Typography>
              <Typography variant="h6" gutterBottom>
                {name}
              </Typography>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Coordinate
              </Typography>
              <Typography variant="h6" gutterBottom>
                {coordinate}
              </Typography>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Compartment Selector
              </Typography>
              <Typography variant="h6" gutterBottom>
                {`${compartmentSelector!.name}@${compartmentSelector!.coordinate} [${compartmentSelector!.type}]`}
              </Typography>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Multiple Compartment Selection
              </Typography>
              <Typography variant="h6" gutterBottom>
                {`${multipleCompartmentSelection ? 'Yes' : 'No'}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Type
              </Typography>
              <Typography variant="h6" gutterBottom>
                {resolveStrategyName((updateStrategy as UpdateStrategy).type)}
              </Typography>
              {(updateStrategy as UpdateStrategy).type === 'manual' && (
                <>
                  <Typography variant="overline" color="primary" style={overlineStyle}>
                    Preset
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {(updateStrategy as ManualUpdateStrategy).params.preset}
                  </Typography>
                  <Typography variant="overline" color="primary" style={overlineStyle}>
                    Mode
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {(updateStrategy as ManualUpdateStrategy).params.editMode ?? EditMode.BULK}
                  </Typography>
                  {(updateStrategy as ManualUpdateStrategy).params.disabledFields &&
                    (updateStrategy as ManualUpdateStrategy).params.disabledFields!.length > 0 && (
                      <>
                        <Typography variant="overline" color="primary" style={overlineStyle}>
                          Disabled Fields
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                          {(updateStrategy as ManualUpdateStrategy).params.disabledFields!.join(', ')}
                        </Typography>
                      </>
                    )}
                </>
              )}
              {(updateStrategy as UpdateStrategy).type === 'regex-scan' && (
                <>
                  <Typography variant="overline" color="primary" style={overlineStyle}>
                    Regex Fields
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {compartmentGeneratorStore.getFieldNames().join(', ')}
                  </Typography>
                </>
              )}
              {(updateStrategy as UpdateStrategy).type === 'clearing' && (
                <>
                  <Typography variant="overline" color="primary" style={overlineStyle}>
                    Preset
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {(updateStrategy as ClearingUpdateStrategy).params.preset}
                  </Typography>
                  {(updateStrategy as ClearingUpdateStrategy).params.disabledFields?.length > 0 && (
                    <>
                      <Typography variant="overline" color="primary" style={overlineStyle}>
                        Disabled Fields
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {(updateStrategy as ClearingUpdateStrategy).params.disabledFields!.join(', ')}
                      </Typography>
                    </>
                  )}
                  <Typography variant="overline" color="primary" style={overlineStyle}>
                    Clearing Text
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {clearingText}
                  </Typography>
                </>
              )}
              <Typography variant="overline" color="primary" style={overlineStyle}>
                Preserve Fields
              </Typography>
              <Typography variant="h6" gutterBottom>
                {preserveFields ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  public render() {
    const { closeHandler } = this.props;
    const { activeStep, finished, loading } = this.state;
    const steps = this.getSteps();

    const finalStep = steps.length - 1;
    const isNextDisabled = this.isNextDisabled(steps[activeStep]);

    return (
      <Grid container spacing={2} alignContent="stretch">
        <Grid item xs={12}>
          <div style={{ display: loading ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={finished} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          {!loading && <DynamicStepper activeStep={activeStep} steps={steps} />}
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            cancelHandler={closeHandler}
            finished={finished}
            resetHandler={this.handleReset}
            backHandler={this.handleBack}
            nextHandler={this.handleNext}
            saveHandler={this.handleSave}
            isSaveDisabled={activeStep !== finalStep}
            isBackDisabled={activeStep === 0}
            isNextDisabled={isNextDisabled}
            isDeleteDisabled={true}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(UpdaterProfilePanelComponent);

export const UpdaterProfilePanel = StyleWrapped;
