import { FieldReplacement, NoMatchStrategy, RegexNoMatchStrategyData, ReplacementTemplate } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export class CompartmentGeneratorStore {
  @observable
  public isEnabled: boolean = false;

  @observable
  public isAllFilled: boolean = false;

  @observable
  public coordinate: ReplacementTemplate = this.getInitialTemplate();

  @observable
  public fields: Array<FieldReplacement> = [];

  @action
  public toggleEnabled(): void {
    this.isEnabled = !this.isEnabled;
  }

  @action
  public resetStore(initialState?: NoMatchStrategy): void {
    if (initialState && initialState.name !== 'null') {
      this.coordinate = initialState.data.coordinate;
      this.fields = initialState.data.fields ?? [];
      this.isAllFilled = true;
      this.isEnabled = true;
    } else {
      this.coordinate = this.getInitialTemplate();
      this.fields = [];
      this.isAllFilled = false;
      this.isEnabled = false;
    }
  }

  @action
  public setAllFilled(): void {
    const unfilledFields = this.fields.filter((field: FieldReplacement) => !this.isTemplateFilled(field));

    this.isAllFilled = unfilledFields.length === 0 && this.isTemplateFilled(this.coordinate);
  }

  @action
  public setField(template: ReplacementTemplate, index?: number): void {
    if (index !== undefined) {
      const newFields = this.fields.slice();
      const { key } = this.fields[index];
      const { pattern, replacement } = template;

      const fieldTemplate: FieldReplacement = {
        key,
        pattern,
        replacement,
      };

      newFields[index] = fieldTemplate;
      this.fields = newFields;
    } else {
      this.coordinate = template;
    }

    this.setAllFilled();
  }

  @action
  public addField(key: string): void {
    const newFields = this.fields.slice();
    const fieldTemplate: FieldReplacement = { key, pattern: '', replacement: '' };

    newFields.push(fieldTemplate);
    this.fields = newFields;
    this.isAllFilled = false;
  }

  @action
  public removeField(index: number): void {
    const newFields = this.fields.slice();
    delete newFields[index];
    this.fields = newFields.filter((f) => f);
    this.setAllFilled();
  }

  public getFieldByName(field: string): ReplacementTemplate | undefined {
    const index = this.fields.findIndex((tmp: FieldReplacement) => tmp.key === field);

    if (index < 0) {
      return undefined;
    }

    return this.fields[index];
  }

  public getFieldNames(): string[] {
    return this.fields.map((field: FieldReplacement) => field.key);
  }

  public setPattern(pattern: string, index?: number): void {
    const { replacement } = index !== undefined ? this.fields[index] : this.coordinate;
    this.setField({ pattern, replacement }, index);
  }

  public setReplacement(replacement: string, index?: number): void {
    const { pattern } = index !== undefined ? this.fields[index] : this.coordinate;
    this.setField({ pattern, replacement }, index);
  }

  public generatePayload(): NoMatchStrategy {
    return {
      name: 'regex',
      data: {
        coordinate: this.coordinate,
        fields: this.fields,
      } as RegexNoMatchStrategyData,
    };
  }

  private isTemplateFilled(template: ReplacementTemplate | FieldReplacement): boolean {
    const { replacement, pattern } = template;

    return replacement !== '' && pattern !== '';
  }

  private getInitialTemplate(): ReplacementTemplate {
    return {
      pattern: '',
      replacement: '',
    };
  }
}
