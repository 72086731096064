import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../Helper/ThemeHelper';

export const AppBarHeight = 220;

export const AppBarStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  appBar: {
    zIndex: 0,
    width: `100%`,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: AppBarHeight,
    animationTimingFunction: 'ease',
    animationName: '$headerNonSticky',
  },
  appBarShift: {},
  appBarButton: {
    marginLeft: spacing(theme),
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  appBarSticky: {
    position: 'fixed',
    top: 0,
    right: spacing(theme) * 3,
    zIndex: 1299,
    width: 'auto',
    height: 'auto',
    animationDuration: '500ms',
    animationTimingFunction: 'ease',
    animationName: '$headerSticky',
  },
  appBarWrapper: {
    position: 'relative',
    height: AppBarHeight,
    marginLeft: -24,
    marginTop: -24,
    width: `calc(100% + ${spacing(theme) * 6}px)`,
    backgroundColor: theme.palette.secondary.main,
  },
  appBarButtonSticky: {
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  appBarNotifications: {
    marginTop: spacing(theme),
    zIndex: 5000,
  },
  appBarAnimationDuration: {
    animationDuration: '250ms',
  },
  '@keyframes headerSticky': {
    '0%': {
      top: -100,
    },
    '100%': {
      top: 0,
    },
  },
  '@keyframes headerNonSticky': {
    '0%': {
      top: 170,
      zIndex: 9999,
    },
    '100%': {
      top: 0,
      zIndex: 0,
    },
  },
});
