import { SystemStatusAppliance } from '@ekkogmbh/apisdk';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';
import { green, orange, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { Component } from 'react';
import { saturation, SystemStatusStyles } from '../Styles/SystemStatusStyles';
import { SystemStatusBoxCard } from './SystemStatusBoxCard';

interface SystemStatusBoxProps extends WithStyles<typeof SystemStatusStyles> {
  appliance: SystemStatusAppliance;
}

class SystemStatusBoxComponent extends Component<SystemStatusBoxProps> {
  public render() {
    const { classes, appliance } = this.props;

    const { name, baseUrl } = appliance.technology;

    const { factoryId } = appliance.tags;

    let applianceData = null;

    if (appliance.information && appliance.information.summaryOfLabels && appliance.information.summaryOfAccessPoints) {
      const { summaryOfLabels, summaryOfAccessPoints } = appliance.information;

      const { batteryStatus, signalStrength, status } = summaryOfLabels;

      const labelData = [
        {
          name: 'successful',
          value: status.successfulTagCount,
          color: green,
        },
        {
          name: 'processing',
          value: status.processingTagCount,
          color: orange,
        },
        {
          name: 'timeout',
          value: status.timeoutTagCount,
          color: red,
        },
      ];

      const batteryData = [
        {
          name: 'good',
          value: batteryStatus.goodBatteryTagCount,
          color: green,
        },
        {
          name: 'bad',
          value: batteryStatus.badBatteryTagCount,
          color: red,
        },
      ];

      const signalData = [
        {
          name: 'excellent',
          value: signalStrength.excellentSignalTagCount,
          color: green,
        },
        {
          name: 'good',
          value: signalStrength.goodSignalTagCount,
          color: orange,
        },
        {
          name: 'bad',
          value: signalStrength.badSignalTagCount,
          color: red,
        },
      ];

      const disconnectedAccessPoints =
        summaryOfAccessPoints.totalAccessPointCount - summaryOfAccessPoints.connectedAccessPointCount;

      const accessPointData = [
        {
          name: 'connected',
          value: summaryOfAccessPoints.connectedAccessPointCount,
          color: green,
        },
        {
          name: 'disconnected',
          value: disconnectedAccessPoints,
          color: red,
        },
      ];

      applianceData = [
        {
          title: 'Labels',
          data: labelData,
        },
        {
          title: 'Battery',
          data: batteryData,
        },
        {
          title: 'Signal-Strength',
          data: signalData,
        },
        {
          title: 'AccessPoints',
          data: accessPointData,
        },
      ];
    }

    return (
      <Grid container spacing={2} alignContent={'space-between'} alignItems={'stretch'}>
        <Grid item xs={9}>
          <Typography className={classes.title} color="textPrimary">
            {name} {factoryId}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Chip
            label={appliance.status}
            className={classNames(classes.title, classes.connectionStatus)}
            style={{
              backgroundColor: appliance.status.toUpperCase() === 'ONLINE' ? green[saturation] : red[saturation],
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 32 }}>
          <Typography component={'p'} color="textSecondary">
            Base Url: {baseUrl}
          </Typography>
        </Grid>

        {applianceData &&
          applianceData.map(({ data, title }, index) => (
            <Grid key={index} item lg={3} xs={6}>
              <SystemStatusBoxCard title={title} data={data} />
            </Grid>
          ))}
      </Grid>
    );
  }
}

export const SystemStatusBox = withStyles(SystemStatusStyles)(SystemStatusBoxComponent);
