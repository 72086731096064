import {
  ButtonPressedTriggerPayload,
  TimeBasedTriggerPayload,
  Trigger,
  TriggerCreate,
  TriggerType,
} from '@ekkogmbh/apisdk';
import { Avatar, Card, CardContent, CardHeader, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { AvTimer, SettingsRemote } from '@mui/icons-material';
import moment from 'moment';
import { PureComponent } from 'react';
import { spacing } from 'src/Common/Helper/ThemeHelper';

const TriggerTypeTexts: Record<TriggerType, string> = {
  'time-based': 'Time-Based',
  'button-pressed': 'Button-Pressed',
};

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  },
  content: {
    padding: spacing(theme),
  },
  header: {
    padding: spacing(theme),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  avatarIcon: {
    fontSize: 32,
    color: theme.palette.primary.light,
  },
  title: {
    color: theme.palette.secondary.light,
    fontWeight: 700,
  },
  text: {
    color: theme.palette.secondary.light,
    fontWeight: 700,
  },
});

interface TriggerCardProps extends WithStyles<typeof styles> {
  trigger: Trigger | TriggerCreate;
}

class TriggerCardComponent extends PureComponent<TriggerCardProps> {
  public render() {
    const {
      classes,
      trigger: { type },
    } = this.props;

    return (
      <Card elevation={4} className={classes.root}>
        <CardContent className={classes.content}>
          <CardHeader
            disableTypography={true}
            className={classes.header}
            avatar={<Avatar className={classes.avatar}>{this.renderTriggerTypeIcon()}</Avatar>}
            title={
              <Typography variant={'subtitle1'} className={classes.title}>
                {TriggerTypeTexts[type]}
              </Typography>
            }
          />
          {this.renderTriggerTypeInfo()}
        </CardContent>
      </Card>
    );
  }

  private renderTriggerTypeIcon = () => {
    const {
      classes,
      trigger: { type },
    } = this.props;

    switch (type) {
      case TriggerType.BUTTON_PRESSED:
        return <SettingsRemote className={classes.avatarIcon} />;
      case TriggerType.TIME_BASED:
      default:
        return <AvTimer className={classes.avatarIcon} />;
    }
  };

  private renderTriggerTypeInfo = () => {
    const {
      classes,
      trigger: { type, payload },
    } = this.props;

    switch (type) {
      case TriggerType.BUTTON_PRESSED:
        const buttonPressedPayload = payload as ButtonPressedTriggerPayload;

        return (
          <Typography variant="body2" component="h2" align={'left'} className={classes.text}>
            <ul>
              <li>{'Label-Id: ' + buttonPressedPayload.labelId}</li>
              <li>{'Technology: ' + buttonPressedPayload.technology}</li>
              <li>{'Button-Index: ' + buttonPressedPayload.buttonIndex}</li>
              {buttonPressedPayload.validFrom !== null && (
                <li>
                  {'Valid-From: ' +
                    moment(buttonPressedPayload.validFrom)
                      .toDate()
                      .toLocaleString()}
                </li>
              )}
              {buttonPressedPayload.validUntil !== null && (
                <li>
                  {'Valid-Until: ' +
                    moment(buttonPressedPayload.validUntil)
                      .toDate()
                      .toLocaleString()}
                </li>
              )}
            </ul>
          </Typography>
        );

      case TriggerType.TIME_BASED:
      default:
        const timeBasedPayload = payload as TimeBasedTriggerPayload;
        return (
          <Typography variant="h5" component="h2" align={'center'} className={classes.text}>
            {moment(timeBasedPayload.datetime)
              .toDate()
              .toLocaleString()}
          </Typography>
        );
    }
  };
}

const StyleWrapped = withStyles(styles)(TriggerCardComponent);

export const TriggerCard = StyleWrapped;
