import { Theme, TypeText } from '@mui/material';

/**
 * Removes the `px` suffix from `theme.spacing()`, which is exactly what MUIv4 used to return.
 */
export const spacing = (theme: Theme): number => {
  const withUnit = theme.spacing();

  if (!withUnit.endsWith('px')) {
    throw new Error(`assertion spacing "${withUnit}" ends on "px" failed`);
  }

  const value = withUnit.substring(0, withUnit.length - 2);
  if (Number.isNaN(value)) {
    throw new Error(`assertion spacing "${value} is a number failed"`);
  }

  return +value;
};

/**
 * Adds `hint` property to `TypeText`, which was dropped with MUIv5 but is still being used in our theme.
 */
export const withHint = (textPalette: TypeText): TypeTextWithHint => {
  if (!Object.keys(textPalette).includes('hint')) {
    throw new Error('text palette is missing hint property');
  }

  return textPalette as TypeTextWithHint;
};

export type TypeTextWithHint = TypeText & { hint: string };
