import { PanelConfiguration, PanelConfigurationDisplayMode, UiConfiguration } from '@ekkogmbh/apisdk';
import { Fade, Grid, ListItemText, MenuItem, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { StyledTextField } from '../../Common/Components/Forms/StyledTextField';
import { PickerProfileStore } from '../Stores/PickerProfileStore';

const styles = FormStyles;

interface PickerProfileUserInterfaceFormState {}

interface PickerProfileUserInterfaceFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface PickerProfileUserInterfaceFormStores {
  api: ApiStore;
  pickerProfileStore: PickerProfileStore;
}

type PickerProfileUserInterfaceFormPropsWithStores = PickerProfileUserInterfaceFormProps &
  PickerProfileUserInterfaceFormStores;

@inject('api', 'pickerProfileStore')
@observer
class PickerProfileUserInterfaceFormComponent extends Component<
  PickerProfileUserInterfaceFormProps,
  PickerProfileUserInterfaceFormState
> {
  public state: PickerProfileUserInterfaceFormState = {};

  get stores(): PickerProfileUserInterfaceFormPropsWithStores {
    return this.props as PickerProfileUserInterfaceFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { sourcePanel, destinationPanel } = this.stores.pickerProfileStore.state;

    // initialize state for pre–selected SelectFields
    if (sourcePanel === undefined) {
      this.stores.pickerProfileStore.setState({
        sourcePanel: {
          displayMode: PanelConfigurationDisplayMode.DEFAULT,
          fieldConfig: { primary: '', secondary: '' },
        },
      });
    }
    if (destinationPanel === undefined) {
      this.stores.pickerProfileStore.setState({
        destinationPanel: {
          displayMode: PanelConfigurationDisplayMode.DEFAULT,
          fieldConfig: { primary: '', secondary: '' },
        },
      });
    }
  }

  private setPanel(key: keyof UiConfiguration, panel: PanelConfiguration): void {
    const { sourcePanel, destinationPanel } = this.stores.pickerProfileStore.state;

    const uiConfiguration = {
      ...{ sourcePanel, destinationPanel },
      [key]: panel,
    };

    this.stores.pickerProfileStore.setState({ ...uiConfiguration });
  }

  public render(): React.JSX.Element {
    const { sourcePanel, destinationPanel } = this.stores.pickerProfileStore.state;

    return (
      <Fade in={true} timeout={1000}>
        <Grid container alignItems="stretch">
          <Grid container item xs={12} spacing={4} alignItems="stretch" alignContent="stretch">
            <Grid item xs={6}>
              <Typography
                variant="overline"
                color="primary"
                style={{ display: 'inline-block', fontWeight: 700, textAlign: 'center', width: '100%' }}
              >
                Source Panel
              </Typography>
              <StyledSelectField
                label="Display Mode"
                value={sourcePanel?.displayMode ?? ''}
                onChange={(e) =>
                  this.setPanel('sourcePanel', {
                    displayMode: e.target.value as PanelConfiguration['displayMode'],
                    fieldConfig: sourcePanel?.fieldConfig ?? { primary: '', secondary: '' },
                  })
                }
              >
                {Object.values(PanelConfigurationDisplayMode).map(
                  (mode: PanelConfigurationDisplayMode, index: number) => (
                    <MenuItem key={index} value={mode}>
                      <ListItemText primary={mode} />
                    </MenuItem>
                  ),
                )}
              </StyledSelectField>
              {!!sourcePanel?.displayMode && (
                <>
                  <StyledTextField
                    type="text"
                    label="Primary Field"
                    value={sourcePanel.fieldConfig?.primary ?? ''}
                    onChange={(e) =>
                      this.setPanel('sourcePanel', {
                        displayMode: (sourcePanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: e.target.value,
                          secondary: sourcePanel.fieldConfig?.secondary ?? '',
                        },
                      })
                    }
                  />
                  <StyledTextField
                    type="text"
                    label="Secondary Field"
                    value={sourcePanel.fieldConfig?.secondary ?? ''}
                    onChange={(e) =>
                      this.setPanel('sourcePanel', {
                        displayMode: (sourcePanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: sourcePanel.fieldConfig?.primary ?? '',
                          secondary: e.target.value,
                        },
                      })
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="overline"
                color="primary"
                style={{ display: 'inline-block', fontWeight: 700, textAlign: 'center', width: '100%' }}
              >
                Destination Panel
              </Typography>
              <StyledSelectField
                label="Display Mode"
                value={destinationPanel?.displayMode ?? ''}
                onChange={(e) =>
                  this.setPanel('destinationPanel', {
                    displayMode: e.target.value as PanelConfiguration['displayMode'],
                    fieldConfig: destinationPanel?.fieldConfig ?? { primary: '', secondary: '' },
                  })
                }
              >
                {Object.values(PanelConfigurationDisplayMode).map(
                  (mode: PanelConfigurationDisplayMode, index: number) => (
                    <MenuItem key={index} value={mode}>
                      <ListItemText primary={mode} />
                    </MenuItem>
                  ),
                )}
              </StyledSelectField>
              {!!destinationPanel?.displayMode && (
                <>
                  <StyledTextField
                    type="text"
                    label="Primary Field"
                    value={destinationPanel.fieldConfig?.primary ?? ''}
                    onChange={(e) =>
                      this.setPanel('destinationPanel', {
                        displayMode: (destinationPanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: e.target.value,
                          secondary: destinationPanel.fieldConfig?.secondary ?? '',
                        },
                      })
                    }
                  />
                  <StyledTextField
                    type="text"
                    label="Secondary Field"
                    value={destinationPanel.fieldConfig?.secondary ?? ''}
                    onChange={(e) =>
                      this.setPanel('destinationPanel', {
                        displayMode: (destinationPanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: destinationPanel.fieldConfig?.primary ?? '',
                          secondary: e.target.value,
                        },
                      })
                    }
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickerProfileUserInterfaceFormComponent);

export const PickerProfileUserInterfaceForm = StyleWrapped;
