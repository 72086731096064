import { OperationGroup, OperationGroupCreate, OperationType, UserPermission } from '@ekkogmbh/apisdk';
import { Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CheckmarkSpinner } from '../../Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { DynamicStepper } from '../../Common/Components/Stepper/DynamicStepper';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { CsvDropzoneStore } from '../../Common/Stores/CsvDropzoneStore';
import { OperationGroupStore } from '../Stores/OperationGroupStore';
import { OperationForm } from './OperationForm';
import { OperationGroupCreateOverview } from './OperationGroupCreateOverview';
import { OperationGroupForm } from './OperationGroupForm';
import { TriggerForm } from './TriggerForm';

export const CREATION_TYPE_COMPARTMENT_UPDATE = 'compartment-update';
export const CREATION_TYPE_SWITCH_PAGE = 'switch-page';
export const CREATION_TYPE_CUSTOM = 'custom';

export type OperationGroupCreationType =
  | typeof CREATION_TYPE_COMPARTMENT_UPDATE
  | typeof CREATION_TYPE_SWITCH_PAGE
  | typeof CREATION_TYPE_CUSTOM;

const stores = ['api', 'operationGroupStore', 'csvDropzoneStore'];

interface OperationGroupPanelStores {
  api: ApiStore;
  operationGroupStore: OperationGroupStore;
  csvDropzoneStore: CsvDropzoneStore;
}

export interface OperationGroupPanelState {
  loading: boolean;
  activeStep: number;
  finished: boolean;
}

export interface OperationGroupPanelProps {
  closeHandler: () => void;
  saveHandler: (operationGroup: OperationGroupCreate, csv?: File) => Promise<OperationGroup>;
  closeCallback?: () => void;
}

@inject(...stores)
@observer
class OperationGroupPanelComponent extends React.Component<OperationGroupPanelProps, OperationGroupPanelState> {
  public state: OperationGroupPanelState = {
    loading: false,
    activeStep: 0,
    finished: false,
  };
  private closeTimeout?: number;

  get stores(): OperationGroupPanelStores {
    return this.props as OperationGroupPanelProps & OperationGroupPanelStores;
  }

  public componentDidMount(): void {
    const { operationGroupStore } = this.stores;
    operationGroupStore.resetState();
  }

  public componentWillUnmount(): void {
    const { operationGroupStore } = this.stores;
    operationGroupStore.resetState();
  }

  public handleReset = async (): Promise<void> => {
    const { operationGroupStore, csvDropzoneStore } = this.stores;
    operationGroupStore.resetState();
    csvDropzoneStore.reset();

    this.setState({ activeStep: 0 });
  };

  public handleSave = async (): Promise<void> => {
    const { csvDropzoneStore, operationGroupStore } = this.stores;
    const { saveHandler } = this.props;
    const {
      allFilled,
      nodeSelectValue,
      nodeValue,
      description,
      name,
      triggers,
      operations,
    } = operationGroupStore.state;

    const steps = this.getSteps();

    const { editableOperationGroup } = operationGroupStore;

    if (!allFilled) {
      return;
    }

    this.setState(
      {
        activeStep: steps.length,
      },
      async () => {
        if (editableOperationGroup === undefined) {
          const node: string =
            nodeValue !== ''
              ? nodeValue
              : (nodeSelectValue[nodeSelectValue.length - 1].fullValue as string[]).join(NodeSeparator);

          const operationGroup: OperationGroupCreate = {
            node,
            description,
            name,
            triggers,
            operations,
          };

          try {
            await saveHandler(operationGroup, csvDropzoneStore.file);
          } catch (e) {
            this.setState({ activeStep: steps.length - 1 });
            return;
          }

          this.setState({ finished: true });

          this.closeTimeout = window.setTimeout(this.closeMountAware, 1250);
        }
      },
    );
  };

  public closeMountAware = (): void => {
    const { csvDropzoneStore } = this.stores;
    const { closeHandler, closeCallback } = this.props;

    const close =
      closeCallback !== undefined
        ? () => {
            closeHandler();
            closeCallback();
          }
        : closeHandler;

    window.clearTimeout(this.closeTimeout);

    csvDropzoneStore.reset();
    close();
  };

  public handleBack = async (): Promise<void> => {
    const { activeStep } = this.state;

    const lastStep = activeStep - 1 < 0 ? 0 : activeStep - 1;

    this.setState({
      activeStep: lastStep,
    });
  };

  public handleNext = async (): Promise<void> => {
    const { activeStep } = this.state;

    const steps = this.getSteps();
    const nextStep = activeStep + 1 > steps.length ? steps.length : activeStep + 1;

    this.setState({
      activeStep: nextStep,
    });
  };

  public stepOperationGroupForm = (): React.JSX.Element => {
    const { api } = this.stores;

    const operationGroupsReadPermissions = api.getUserPermissionsByPermissionName(Permissions.OPERATION_GROUPS_READ);

    const hasAllMatching = operationGroupsReadPermissions.reduce(
      (granted: boolean | null, currPermission: UserPermission) => {
        if (granted === false) {
          return false;
        }

        return api.userHasPermissionForNode(
          Permissions.AREAS_READ,
          currPermission.nodeDefinition.nodeValuesDefinition.values,
        );
      },
      null,
    );

    const hasAreasReadPermission = hasAllMatching === true;

    return <OperationGroupForm hasAreasReadPermission={hasAreasReadPermission} />;
  };

  public stepTriggerForm = (): React.JSX.Element => <TriggerForm />;

  public stepOperationForm = (): React.JSX.Element => <OperationForm />;

  public stepOperationGroupCreate = (): React.JSX.Element => <OperationGroupCreateOverview />;

  public getSteps = (): { title: string; elementCallback: () => React.JSX.Element }[] => [
    {
      title: 'Operation-Group',
      elementCallback: this.stepOperationGroupForm,
    },
    {
      title: 'Triggers',
      elementCallback: this.stepTriggerForm,
    },
    {
      title: 'Operations',
      elementCallback: this.stepOperationForm,
    },
    {
      title: 'Create',
      elementCallback: this.stepOperationGroupCreate,
    },
  ];

  public render(): React.JSX.Element {
    const { operationGroupStore, csvDropzoneStore } = this.stores;
    const {
      changed,
      // loading,
    } = operationGroupStore;
    const { allFilled, allFilledTriggers, allFilledOperations, type } = operationGroupStore.state;
    const { activeStep, finished } = this.state;

    const close = this.closeMountAware;

    const steps = this.getSteps();

    const backHandler = this.handleBack;
    const nextHandler = activeStep === steps.length - 1 ? undefined : this.handleNext;
    const saveHandler = activeStep !== steps.length - 1 ? undefined : this.handleSave;
    const isBackDisabled = activeStep === 0 || activeStep === steps.length;
    const isNextDisabled =
      (activeStep === 0 && !allFilled) ||
      (activeStep === 1 && !allFilledTriggers) ||
      (activeStep === 2 && csvDropzoneStore.file === undefined && type === OperationType.COMPARTMENT_UPDATE) ||
      (activeStep === 2 && !allFilledOperations && type === OperationType.SWITCH_PAGE) ||
      activeStep === steps.length;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12}>
          <div style={{ display: activeStep === steps.length ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={finished} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          <DynamicStepper activeStep={activeStep} steps={steps} />
        </Grid>

        <FormPanelButtons
          cancelHandler={close}
          finished={finished}
          resetHandler={this.handleReset}
          backHandler={backHandler}
          nextHandler={nextHandler}
          saveHandler={saveHandler}
          deleteHandler={undefined}
          isResetDisabled={!changed || activeStep === steps.length}
          isNextDisabled={isNextDisabled}
          isBackDisabled={isBackDisabled}
          isSaveDisabled={!changed || !allFilled || activeStep === steps.length}
          isDeleteDisabled={true}
          isDeleteHidden={true}
        />
      </Grid>
    );
  }
}

export const OperationGroupPanel = OperationGroupPanelComponent;
