import {
  EslManagerPrivateRoute,
  HttpMethod,
  OperationGroup,
  OperationGroupCreate,
  Pagination,
  PaginationResponse,
} from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from '../../Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { OperationGroupStore } from '../Stores/OperationGroupStore';
import { OperationsStyles } from '../Styles/OperationsStyles';
import { materialDatatableColumnDefinitions } from './OperationGroupDatatableColumnDefinitions';
import { OperationGroupPanel, OperationGroupPanelProps } from './OperationGroupPanel';
import React from 'react';

const styles = OperationsStyles;

const stores = ['api', 'operationGroupStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

export interface OperationGroupsContentActionHandlers {
  delete: (operationGroup: OperationGroup) => void;
  details: (operationGroup: OperationGroup) => void;
}

export type OperationGroupsContentPropsWithStores = OperationGroupsContentProps & OperationGroupsContentStores;

export interface OperationGroupsContentStores {
  api: ApiStore;
  operationGroupStore: OperationGroupStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface OperationGroupsContentState {
  creatableOperationGroup?: OperationGroup;
  deletableOperationGroup?: OperationGroup;
  deleteDialogOpen: boolean;
}

export interface OperationGroupsContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

@inject(...stores)
class OperationGroupsContentComponent extends Component<OperationGroupsContentProps, OperationGroupsContentState> {
  public state: OperationGroupsContentState = {
    deleteDialogOpen: false,
  };
  private readonly filterFields: DataTableFilterFields<OperationGroup> = ['description', 'name'];
  private readonly sortFieldMap: DataTableSortFieldMap<OperationGroup> = {
    description: 'OG.description',
    name: 'OG.name',
    createdAt: 'OG.createdAt',
  };
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'OperationGroup updated.',
    201: 'OperationGroup created.',
    204: 'OperationGroup deleted.',
  };

  get stores(): OperationGroupsContentStores {
    return this.props as OperationGroupsContentPropsWithStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchOperationGroups = async (pagination: Pagination): Promise<PaginationResponse<OperationGroup>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<OperationGroup>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getOperationGroups(pagination),
      EslManagerPrivateRoute.OPERATION_GROUPS,
      HttpMethod.GET,
    );
  };

  public addOperationGroup = async (operationGroup: OperationGroupCreate, csv?: File): Promise<OperationGroup> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<OperationGroup>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addOperationGroup(operationGroup, csv),
      EslManagerPrivateRoute.OPERATION_GROUPS,
      HttpMethod.POST,
      this.successStatusCodes,
      successCallback,
    );
  };

  public deleteOperationGroup = async (operationGroup: OperationGroup): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteOperationGroup(operationGroup),
      EslManagerPrivateRoute.OPERATION_GROUP,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public createContentPanels = (): ContentPanelDefinition[] => {
    const { operationGroupStore } = this.stores;
    const { addOperationGroup } = this;

    const closeCallback = () => {
      operationGroupStore.resetStore();
    };

    const addOperationGroupPanelDefinition: ContentPanelDefinition<OperationGroupPanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: OperationGroupPanel,
      panelProps: {
        saveHandler: addOperationGroup,
        closeCallback,
      },
      permission: Permissions.OPERATION_GROUPS_WRITE,
      toggleOffCallback: closeCallback,
    };

    return [addOperationGroupPanelDefinition];
  };

  public openDetails = async (operationGroup: OperationGroup): Promise<void> => {
    this.props.history.push('/operationgroups/' + operationGroup.id);
  };

  public openDeleteDialog = async (operationGroup: OperationGroup): Promise<void> => {
    this.setState({
      deletableOperationGroup: operationGroup,
      deleteDialogOpen: true,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      deletableOperationGroup: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { deletableOperationGroup } = this.state;

    if (!deletableOperationGroup || !deletableOperationGroup.id) {
      return;
    }

    await this.deleteOperationGroup(deletableOperationGroup);

    this.setState({
      deletableOperationGroup: undefined,
      deleteDialogOpen: false,
    });
  };

  public render() {
    const { classes } = this.props;
    const { deletableOperationGroup, deleteDialogOpen } = this.state;
    const { fetchOperationGroups } = this;

    const contentPanels = this.createContentPanels();

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as OperationGroupsContentPropsWithStores, {
        delete: this.openDeleteDialog,
        details: this.openDetails,
      }),
    );

    const deleteDialogText =
      deleteDialogOpen && deletableOperationGroup ? (
        <React.Fragment>
          <div>
            Delete Operation-Group: <span className={classes.boldFont}>{deletableOperationGroup.name}</span>
          </div>
        </React.Fragment>
      ) : (
        ''
      );

    return (
      <Grid item xs={12}>
        {deleteDialogOpen && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={deleteDialogOpen}
            title={'Delete Operation-Group'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        <ContentPanels panels={contentPanels} />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={fetchOperationGroups}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[4].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<OperationGroupsContentProps, typeof OperationGroupsContentComponent>(
  OperationGroupsContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const OperationGroupsContent = StyleWrapped;
