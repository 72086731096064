import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { EventRuleStore } from '../Stores/EventRuleStore';
import { WithStyles, withStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { Grid, Stack } from '@mui/material';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CoordinateInput } from 'src/Common/Components/CoordinateInput';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import {
  CompartmentSelector,
  CompartmentSelectorIndex,
  EventRuleActionType,
  EventRuleTriggerType,
  Finder,
} from '@ekkogmbh/apisdk';
import { FinderPicker } from 'src/FinderManagement/FinderPicker';
import { enqueueSnackbar } from 'notistack';
import { CompartmentSelectorPicker } from 'src/CompartmentSelectorManagement/CompartmentSelectorPicker';

const styles = FormStyles;

interface EventRulePanelStores {
  api: ApiStore;
  eventRuleStore: EventRuleStore;
}

interface EventRulePanelState {
  loading: boolean;
}

export interface EventRulePanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: () => Promise<void>;
}

@inject('api', 'eventRuleStore')
@observer
class EventRulePanelComponent extends React.Component<EventRulePanelProps, EventRulePanelState> {
  public state: EventRulePanelState = {
    loading: false,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): EventRulePanelStores {
    return this.props as EventRulePanelProps & EventRulePanelStores;
  }

  public componentWillUnmount(): void {
    const { eventRuleStore } = this.stores;
    eventRuleStore.resetStore();
    cancelFetchPromises(this.fetchPromises);
  }

  public handleReset = async () => {
    const { eventRuleStore } = this.stores;
    const { editableEventRule } = eventRuleStore;
    eventRuleStore.resetStore(editableEventRule);
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;

    this.setState({ loading: true }, async () => {
      await saveHandler();
      this.handleReset();
      closeHandler();
    });
  };

  public triggerForm = (): JSX.Element => {
    const { eventRuleStore } = this.stores;
    const { trigger, coordinate } = eventRuleStore.state;

    // FIXME: add trigger properties to apisdk?
    switch (trigger?.type) {
      case EventRuleTriggerType.EKANBAN_STATE_TRANSITION:
        return (
          <>
            <StyledTextField
              label={'From State'}
              value={trigger.properties.fromState}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('fromState', e.target.value as string);
              }}
            />
            <StyledTextField
              label={'To State'}
              value={trigger.properties.toState}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('toState', e.target.value as string);
              }}
            />
            <StyledTextField
              label={'Receiving Area Name'}
              value={trigger.properties.receivingAreaName}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('receivingAreaName', e.target.value as string);
              }}
            />
            <StyledTextField
              label={'Replenishment Plan Name'}
              value={trigger.properties.replenishmentPlanName}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('replenishmentPlanName', e.target.value as string);
              }}
            />
          </>
        );
      case EventRuleTriggerType.PICKING_ORDER_CONCLUDED:
        return (
          <>
            <StyledTextField
              label={'External Order ID'}
              value={trigger.properties.externalId}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('externalId', e.target.value as string);
              }}
            />
            <StyledTextField
              label={'Zone Name'}
              value={trigger.properties.zoneName}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('zoneName', e.target.value as string);
              }}
            />
          </>
        );
      case EventRuleTriggerType.DEVICE_TRIGGER:
        return (
          <>
            <div>
              <StyledSelectField
                native
                label={'Device Trigger Type'}
                value={trigger.properties.deviceTriggerType}
                onChange={(e) => {
                  eventRuleStore.setTriggerProperty('deviceTriggerType', e.target.value as string);
                }}
              >
                <option value={'button-pressed'}>{'Button Pressed'}</option>
              </StyledSelectField>
            </div>
            <StyledTextField
              label={'Device ID'}
              value={trigger.properties.deviceId}
              helperText={'<id>::<technology>'}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('deviceId', e.target.value as string);
              }}
            />
            <StyledTextField
              label={'Device Trigger Index'}
              value={trigger.properties.deviceTriggerIndex}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('deviceTriggerIndex', e.target.value as string);
              }}
            />
          </>
        );
      case EventRuleTriggerType.COMPARTMENT_FOUND:
        return (
          <>
            <div>
              <FinderPicker
                optional
                coordinate={coordinate ?? ''}
                selected={{
                  name: trigger.properties.finderName,
                  coordinate: trigger.properties.finderCoordinate,
                }}
                onChange={(finder: Finder | undefined) => {
                  if (finder) {
                    eventRuleStore.setTriggerProperty('finderName', finder.name);
                    eventRuleStore.setTriggerProperty('finderCoordinate', finder.coordinate);
                  } else {
                    // remove properties
                    eventRuleStore.setTriggerProperty('finderName', '');
                    eventRuleStore.setTriggerProperty('finderCoordinate', '');
                  }
                }}
                onError={() => {
                  enqueueSnackbar<'error'>('Could not load finders.');
                  this.handleReset();
                }}
              />
            </div>
            <StyledTextField
              label={'Input'}
              value={trigger.properties.input}
              onChange={(e) => {
                eventRuleStore.setTriggerProperty('input', e.target.value as string);
              }}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  public actionForm = (): JSX.Element => {
    const { eventRuleStore } = this.stores;
    const { action, coordinate } = eventRuleStore.state;

    // FIXME: add action config properties to apisdk?
    switch (action?.type) {
      case EventRuleActionType.WEBHOOK:
        return (
          <StyledTextField
            label={'URL'}
            value={action.configuration.url}
            onChange={(e) => {
              eventRuleStore.setActionConfigValue('url', e.target.value as string);
            }}
          />
        );
      case EventRuleActionType.MQTT:
        return (
          <StyledTextField
            label={'Topic'}
            value={action.configuration.topic}
            onChange={(e) => {
              eventRuleStore.setActionConfigValue('topic', e.target.value as string);
            }}
          />
        );
      case EventRuleActionType.EKANBAN_TRIGGER:
        const selectorName = eventRuleStore.state.action?.configuration.compartmentSelectorName ?? '';
        const selectorIndex: CompartmentSelectorIndex | undefined =
          selectorName == ''
            ? undefined
            : {
                name: selectorName,
                coordinate: eventRuleStore.state.action!.configuration.compartmentSelectorCoordinate,
              };
        return (
          <>
            <div>
              <CompartmentSelectorPicker
                coordinate={coordinate ?? ''}
                onChange={(selector: CompartmentSelector | undefined) => {
                  if (selector !== undefined) {
                    eventRuleStore.setActionConfigValue('compartmentSelectorName', selector.name);
                    eventRuleStore.setActionConfigValue('compartmentSelectorCoordinate', selector.coordinate);
                  } else {
                    eventRuleStore.setActionConfigValue('compartmentSelectorName', '');
                    eventRuleStore.setActionConfigValue('compartmentSelectorCoordinate', '');
                  }
                }}
                selected={selectorIndex}
                onError={() => {
                  enqueueSnackbar<'error'>('Could not load compartment selectors.');
                  this.handleReset();
                }}
              />
            </div>
            <StyledTextField
              label={'Input JsonPath'}
              value={action.configuration.inputJsonPath}
              onChange={(e) => {
                eventRuleStore.setActionConfigValue('inputJsonPath', e.target.value as string);
              }}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  public render() {
    const { loading } = this.state;
    const { eventRuleStore } = this.stores;
    const { name, coordinate, trigger, action, allFilled } = eventRuleStore.state;
    const { closeHandler } = this.props;

    const isEditMode = eventRuleStore.editableEventRule !== undefined;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <div style={{ display: loading ? 'block' : 'none' }}>
          <Grid
            item
            xs={12}
            style={{
              height: 496,
              position: 'relative',
            }}
          >
            <div
              style={{
                top: '50%',
                marginTop: -48,
                position: 'absolute',
                width: '100%',
              }}
            >
              <CheckmarkSpinner complete={false} failure={false} />
            </div>
          </Grid>
        </div>

        {!loading && (
          <>
            <Grid container item xs={11} spacing={2}>
              <Grid item xs={4}>
                <Stack direction={'column'} spacing={1}>
                  <StyledFormHeader label={'Identifier'} />
                  <StyledTextField
                    type={'text'}
                    label={'Name'}
                    value={name}
                    disabled={isEditMode}
                    onChange={(e) => eventRuleStore.setState({ name: e.target.value })}
                  />
                  <CoordinateInput
                    value={coordinate}
                    disabled={isEditMode}
                    onChange={(coordinate) => eventRuleStore.setState({ coordinate })}
                    trailingDelimiter={false}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction={'column'} spacing={1}>
                  <StyledFormHeader label={'Trigger'} />
                  <div>
                    <StyledSelectField
                      native
                      onChange={(e) => {
                        const _type = e.target.value as EventRuleTriggerType;
                        const properties = {};
                        if (_type === EventRuleTriggerType.DEVICE_TRIGGER) {
                          properties['deviceTriggerType'] = 'button-pressed';
                        }
                        eventRuleStore.setState({
                          trigger: { type: _type, properties },
                        });
                      }}
                      value={trigger?.type}
                      label={'Type'}
                    >
                      {Object.keys(EventRuleTriggerType).map((triggerTypeKey: string, index: number) => (
                        <option key={index} value={EventRuleTriggerType[triggerTypeKey]}>
                          {EventRuleTriggerType[triggerTypeKey]}
                        </option>
                      ))}
                    </StyledSelectField>
                  </div>
                  {this.triggerForm()}
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction={'column'} spacing={1}>
                  <StyledFormHeader label={'Action'} />
                  <div>
                    <StyledSelectField
                      native
                      onChange={(e) =>
                        eventRuleStore.setState({
                          action: { type: e.target.value as EventRuleActionType, configuration: {} },
                        })
                      }
                      value={action?.type}
                      label={'Type'}
                    >
                      {Object.keys(EventRuleActionType).map((actionTypeKey: string, index: number) => (
                        <option key={index} value={EventRuleActionType[actionTypeKey]}>
                          {EventRuleActionType[actionTypeKey]}
                        </option>
                      ))}
                    </StyledSelectField>
                  </div>
                  {this.actionForm()}
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormPanelButtons
                cancelHandler={closeHandler}
                saveHandler={this.handleSave}
                resetHandler={this.handleReset}
                isDeleteHidden={true}
                isSaveDisabled={!allFilled}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(EventRulePanelComponent);

export const EventRulePanel = StyleWrapped;
