import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { UserManagementContent } from './Components/UserManagementContent';

export const UserManagement = ({ title }: TitleProps): React.JSX.Element => (
  <Page title={title} isSearchable={true}>
    <UserManagementContent />
  </Page>
);
