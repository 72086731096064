import { CompartmentSelectorIndex, Finder } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface FinderState {
  name: string;
  coordinate: string;
  templateNames: string[];
  compartmentSelector?: CompartmentSelectorIndex;
  compartmentInputInstructions?: string | null;
  showResultsDuration: number | null;
  allFilled: boolean;
}

export class FinderStore {
  @observable
  public editableFinder?: Finder;

  @observable
  public state: FinderState = {
    name: '',
    coordinate: '',
    templateNames: [],
    compartmentSelector: undefined,
    showResultsDuration: null,
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<FinderState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled = this.state.name !== '' && this.state.coordinate !== '';
      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState(
      {
        name: '',
        coordinate: '',
        templateNames: [],
        compartmentSelector: undefined,
        compartmentInputInstructions: undefined,
        showResultsDuration: null,
        allFilled: false,
      },
      false,
    );

    this.editableFinder = undefined;
  };

  @action setEditableFinder = (finder: Finder): void => {
    this.editableFinder = finder;

    if (finder) {
      this.setState(
        {
          name: finder.name,
          coordinate: finder.coordinate,
          templateNames: finder.templateNames,
          compartmentSelector: finder.compartmentSelector,
          compartmentInputInstructions: finder.compartmentInputInstructions,
          showResultsDuration: finder.showResultsDuration,
          allFilled: true,
        },
        false,
      );
    }
  };
}
