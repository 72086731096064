import { Permission, Role } from '@ekkogmbh/apisdk';
import { Fade, Grid, Hidden } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField/TextField';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, ClipboardEvent } from 'react';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { FormPanelButtons, PanelAction } from '../../Common/Components/FormPanelButtons';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { TaskCollectionProgressCallback } from '../../Common/Stores/TaskCollectionStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { RolePermissionSelect } from './RolePermissionSelect';

const styles = FormStyles;
const fadeTimeout = 2000;

interface RolePanelState {
  action: PanelAction;
  name: string;
  permissions: Permission[];
  changed: boolean;
  loading: boolean;
  deleteDialogOpen: boolean;
  allFilled: boolean;
}

interface RolePanelProps extends WithStyles<typeof styles> {
  role?: Role;
  allPermissions: Permission[];
  closeHandler: () => void;
  saveHandler: (role: Partial<Role>, progressCallback?: TaskCollectionProgressCallback) => Promise<void>;
  deleteHandler: (role: Role, progressCallback?: TaskCollectionProgressCallback) => Promise<void>;
}

class RolePanelComponent extends React.PureComponent<RolePanelProps> {
  public state: RolePanelState = {
    action: PanelAction.CREATE,
    name: '',
    permissions: [],
    changed: false,
    loading: false,
    deleteDialogOpen: false,
    allFilled: false,
  };

  constructor(props: RolePanelProps) {
    super(props);

    const { role } = this.props;

    if (role !== undefined) {
      const action = PanelAction.EDIT;
      const { name, permissions } = role;

      this.state = {
        ...this.state,
        action,
        name,
        permissions: [...permissions],
      };
    }
  }

  // @TODO deprecated
  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: Readonly<RolePanelProps>): void {
    const { role } = nextProps;
    const currentRole = this.props.role;

    const nextRole = JSON.stringify(role);
    const currRole = JSON.stringify(currentRole);

    if (nextRole !== currRole) {
      this.setState({
        name: '',
        permissions: [],
      });
      if (role) {
        this.setState({
          action: PanelAction.EDIT,
          name: role.name,
          permissions: [...role.permissions],
        });
      }
    }
  }

  public resetState = () => {
    const { role } = this.props;

    if (role) {
      this.setState({
        name: role.name,
        permissions: role.permissions,
        changed: false,
      });
    } else {
      this.setState({
        name: '',
        permissions: [],
        changed: false,
      });
    }
  };

  public updatePermissionsState = (_: Role, permissions: Permission[]) => {
    this.setState(
      {
        changed: true,
        permissions,
      },
      () => this.setState({ allFilled: this.isAllFilled() }),
    );
  };

  public onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.persist();
    // @TODO ¯\_(ツ)_/¯
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTimeout(() => this.onChange(event as any), 500);
  };

  public onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const state = { changed: true };

    state[name] = value;

    this.setState({ ...state }, () => this.setState({ allFilled: this.isAllFilled() }));
  };

  public isAllFilled = (): boolean => {
    const { name } = this.state;

    return !!name;
  };

  public onSave = async () => {
    const { role, closeHandler, saveHandler } = this.props;
    const { name, permissions } = this.state;

    const saveableRole: Partial<Role> = {
      name,
      permissions,
    };

    if (role) {
      saveableRole.id = role.id;
    }

    this.setState({ loading: true });

    try {
      await saveHandler(saveableRole);

      this.setState({ loading: false });
      closeHandler();
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  public onDelete = () => {
    this.setState({ deleteDialogOpen: true });
  };

  public onDeleteDismiss = () => {
    this.setState({ deleteDialogOpen: false });
  };

  public onDeleteOk = async () => {
    const { role, closeHandler, deleteHandler } = this.props;

    this.setState({
      loading: true,
      deleteDialogOpen: false,
    });

    if (!role) {
      this.setState({ loading: false }, () => closeHandler());

      return;
    }

    await deleteHandler(role);

    this.setState({ loading: false }, () => closeHandler());
  };

  public render() {
    const { allPermissions, classes, role } = this.props;

    const { action, name, permissions, changed, loading, deleteDialogOpen, allFilled } = this.state;

    const deleteDialogText = role ? `Delete Role ${role.name}?` : '';

    const roleForPermissionSelect = role
      ? {
          id: role.id,
          name: role.name,
          permissions,
        }
      : {
          permissions,
        };

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {action === PanelAction.EDIT && (
          <GenericDialog
            type="confirmation"
            open={deleteDialogOpen}
            title={'Delete Role'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}
        {loading && <LoadingMask />}

        <Grid item lg={4} md={6} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label={'Role-Name'}
              value={name}
              name={'name'}
              onChange={this.onChange}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  disabled: classes.disabled,
                },
              }}
            />
          </Fade>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <Grid item lg={4} md={6} xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <div>
              <RolePermissionSelect
                role={roleForPermissionSelect}
                allPermissions={allPermissions}
                saveHandler={this.updatePermissionsState}
                noDialog={true}
                classes={{ formControl: classes.formControlSelectAutoWidth }}
                label="Permissions"
              />
            </div>
          </Fade>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <FormPanelButtons
          cancelHandler={this.onCancel}
          resetHandler={this.resetState}
          saveHandler={this.onSave}
          deleteHandler={this.onDelete}
          isResetDisabled={!changed}
          isSaveDisabled={!changed || !allFilled}
          isDeleteDisabled={action !== PanelAction.EDIT || !role}
          isDeleteHidden={action !== PanelAction.EDIT || !role}
        />
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(RolePanelComponent);

export const RolePanel = StyleWrapped;
