import { action, observable } from 'mobx';

export class SearchBarStore {
  @observable
  public isRendered: boolean = false;

  @observable
  public isSearchableContent: boolean = false;

  @action
  public setRendered(): void {
    this.isRendered = true;
  }

  @action
  public setSearchable(searchable: boolean): void {
    this.isSearchableContent = searchable;
    if (!searchable) {
      this.isRendered = false;
    }
  }
}
