import { Finder } from '@ekkogmbh/apisdk';
import { Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  FinderManagementContentActionHandlers,
  FinderManagementContentPropsWithStores,
  FinderManagementContentState,
} from './FinderManagementContent';
import { Typography } from '@mui/material';

class FinderDataTableActions extends DataTableActionsComponent<Finder> {}

const Actions = (
  _: FinderManagementContentState,
  __: FinderManagementContentPropsWithStores,
  actions: FinderManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (finder: Finder): React.ReactNode => {
      const actionButtons: ActionButton<Finder>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return <FinderDataTableActions dataset={finder} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: Finder['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: Finder['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const NotificationStrategy = (): MUIDataTableColumnDef => ({
  label: 'Notification Strategy',
  name: 'entry.notificationStrategy',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (strategy: Finder['notificationStrategy']): React.ReactNode => (
      <Typography>{strategy.name}</Typography>
    ),
  },
});

const TemplateNames = (): MUIDataTableColumnDef => ({
  label: 'Template Names',
  name: 'entry.templateNames',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (templateNames: Finder['templateNames']): React.ReactNode => (
      <div>{templateNames && templateNames.length > 0 ? templateNames.join(', ') : '-'}</div>
    ),
  },
});

const CompartmentSelector = (): MUIDataTableColumnDef => ({
  label: 'Compartment Selector',
  name: 'entry.compartmentSelector',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (compartmentSelector: Finder['compartmentSelector']): React.ReactNode => (
      <div>{compartmentSelector ? compartmentSelector.name + '@' + compartmentSelector.coordinate : '-'}</div>
    ),
  },
});

const CompartmentInputColumn = (): MUIDataTableColumnDef => ({
  label: 'Compartment Input Instructions',
  name: 'entry.compartmentInputInstructions',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: Finder['compartmentInputInstructions']): React.ReactNode => <div>{value ?? '-'}</div>,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  FinderManagementContentPropsWithStores,
  FinderManagementContentState,
  FinderManagementContentActionHandlers
>> = [Name, Coordinate, NotificationStrategy, TemplateNames, CompartmentSelector, CompartmentInputColumn, Actions];
