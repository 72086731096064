import React from 'react';
import { Link } from 'react-router-dom';
import { CompartmentView } from '@ekkogmbh/apisdk';

interface Props {
  compartments: CompartmentView[];
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
  showResultsHandler: () => void;
}

class ResultComponent extends React.Component<Props, unknown> {
  public render(): React.JSX.Element {
    const { compartments, forwardedRef, showResultsHandler } = this.props;
    const linksCount = compartments.map((c) => c.links.length).reduce((prev, cur) => prev + cur, 0);

    return (
      <div ref={forwardedRef}>
        {`Found ${compartments.length} compartment${compartments.length === 1 ? '' : 's'} with ${linksCount} link${
          linksCount === 1 ? '' : 's'
        }. `}
        {compartments.length > 0 && (
          <Link to="#" onClick={() => showResultsHandler()}>
            Show them
          </Link>
        )}
      </div>
    );
  }
}

export const RunCompartmentSelectorResult = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ResultComponent {...props} forwardedRef={ref} />
));
