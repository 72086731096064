import { ButtonPressedTriggerPayload, TimeBasedTriggerPayload, TriggerCreate, TriggerType } from '@ekkogmbh/apisdk';
import { Fade, Grid, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import { inject, observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { OperationGroupStore } from '../Stores/OperationGroupStore';
import { DateTimePicker } from '@mui/x-date-pickers';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'operationGroupStore'];

interface TriggerFormStores {
  operationGroupStore: OperationGroupStore;
}

interface TriggerFormState {
  loading: boolean;
}

interface TriggerFormProps extends WithStyles<typeof styles> {}

@inject(...stores)
@observer
class TriggerFormComponent extends React.Component<TriggerFormProps, TriggerFormState> {
  public state: TriggerFormState = {
    loading: false,
  };

  get stores(): TriggerFormStores {
    return this.props as TriggerFormProps & TriggerFormStores;
  }

  public renderTrigger = (trigger: TriggerCreate, triggerIndex: number) => {
    let triggerPayloadForm = <div />;

    switch (trigger.type) {
      case TriggerType.BUTTON_PRESSED:
        triggerPayloadForm = this.renderButtonPressedTriggerPayloadForm(
          trigger.payload as ButtonPressedTriggerPayload,
          triggerIndex,
        );
        break;
      case TriggerType.TIME_BASED:
      default:
        triggerPayloadForm = this.renderTimeBasedTriggerPayloadForm(
          trigger.payload as TimeBasedTriggerPayload,
          triggerIndex,
        );
    }

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <StyledSelectField native label="Type" value={trigger.type} onChange={this.handleChangeType(triggerIndex)}>
              <option key={0} value={TriggerType.TIME_BASED}>
                Time-Based
              </option>
              <option key={1} value={TriggerType.BUTTON_PRESSED}>
                Button-Pressed
              </option>
            </StyledSelectField>
          </Fade>
        </Grid>

        {triggerPayloadForm}
      </Grid>
    );
  };

  public renderTimeBasedTriggerPayloadForm = (payload: TimeBasedTriggerPayload, triggerIndex: number) => {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Fade in={true} timeout={fadeTimeout}>
          <DateTimePicker
            className={classes.margin}
            label="Trigger-At"
            ampm={false}
            disablePast
            value={payload.datetime !== '' ? moment(payload.datetime) : moment()}
            onChange={this.handleChangeTimeBasedMoment(triggerIndex, 'datetime')}
          />
        </Fade>
      </Grid>
    );
  };

  public renderButtonPressedTriggerPayloadForm = (payload: ButtonPressedTriggerPayload, triggerIndex: number) => {
    const { classes } = this.props;

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    return (
      <Grid item container>
        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label="Label-Id"
              value={payload.labelId}
              onChange={this.handleChangeTextInput(triggerIndex, 'labelId')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Fade>
        </Grid>

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label="Technology"
              value={payload.technology}
              onChange={this.handleChangeTextInput(triggerIndex, 'technology')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Fade>
        </Grid>

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label="Button-Index"
              value={payload.buttonIndex}
              onChange={this.handleChangeTextInput(triggerIndex, 'buttonIndex')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Fade>
        </Grid>

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <DateTimePicker
              disabled={payload.validFrom === null}
              className={classes.margin}
              label="Valid-From"
              ampm={false}
              disablePast
              value={payload.validFrom && payload.validFrom !== '' ? moment(payload.validFrom) : moment()}
              onChange={this.handleChangeButtonPressedDuration(triggerIndex, 'validFrom')}
            />
          </Fade>
        </Grid>

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <DateTimePicker
              disabled={payload.validUntil === null}
              className={classes.margin}
              label="Valid-Until"
              ampm={false}
              disablePast
              value={payload.validUntil && payload.validUntil !== '' ? moment(payload.validUntil) : moment()}
              onChange={this.handleChangeButtonPressedDuration(triggerIndex, 'validUntil')}
            />
          </Fade>
        </Grid>
      </Grid>
    );
  };

  public handleChangeType = (triggerIndex: number) => ({ target: { value } }: SelectChangeEvent<unknown>) => {
    const { operationGroupStore } = this.stores;
    const {
      state: { triggers },
    } = operationGroupStore;

    const triggerType = value as TriggerType;

    triggers[triggerIndex].type = triggerType;

    switch (triggerType) {
      case TriggerType.BUTTON_PRESSED:
        triggers[triggerIndex].payload = {
          labelId: '',
          technology: 'solum',
          buttonIndex: '',
          validUntil: null,
          validFrom: null,
        } as ButtonPressedTriggerPayload;
        break;
      case TriggerType.TIME_BASED:
      default:
        triggers[triggerIndex].payload = {
          datetime: '',
        } as TimeBasedTriggerPayload;
    }

    operationGroupStore.setState({ triggers }, true);
  };

  public handleChangeTimeBasedMoment = (triggerIndex: number, payloadField: keyof TimeBasedTriggerPayload) => (
    date: Moment | null,
  ) => {
    const { operationGroupStore } = this.stores;
    const {
      state: { triggers },
    } = operationGroupStore;

    if (date == null || !date.isValid()) {
      enqueueSnackbar('Invalid date.', { variant: 'error' });

      operationGroupStore.setState(
        {
          allFilledTriggers: false,
        },
        false,
      );

      return;
    }

    if (date.isBefore(moment())) {
      date = moment();
    }

    (triggers[triggerIndex].payload as TimeBasedTriggerPayload)[payloadField] = date.set('seconds', 0).format();

    operationGroupStore.setState({ triggers });
  };

  public handleChangeButtonPressedDuration = (
    triggerIndex: number,
    payloadField: keyof ButtonPressedTriggerPayload,
  ) => (date: Moment | null) => {
    const { operationGroupStore } = this.stores;
    const {
      state: { triggers },
    } = operationGroupStore;
    const { payload } = triggers[triggerIndex] as { payload: ButtonPressedTriggerPayload };

    if (date == null || !date.isValid()) {
      enqueueSnackbar('Invalid date.', { variant: 'error' });

      return;
    }

    if (date.isBefore(moment())) {
      date = moment();
    }

    const validUntil = payloadField === 'validUntil' ? date : payload.validUntil ? moment(payload.validUntil) : null;
    const validFrom = payloadField === 'validFrom' ? date : payload.validFrom ? moment(payload.validFrom) : null;

    if (validFrom && validUntil && !validFrom.isBefore(validUntil)) {
      enqueueSnackbar('Valid-From date must be before Valid-Until date.', { variant: 'error' });

      return;
    }

    triggers[triggerIndex].payload[payloadField] = date.set('seconds', 0).format();

    operationGroupStore.setState({ triggers });
  };

  public handleCheckboxToggleButtonPressedDuration = (
    triggerIndex: number,
    payloadField: keyof ButtonPressedTriggerPayload,
  ) => (event: React.MouseEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>) => {
    // Stopping propagation because parent inputs of adornments will trigger onClick events otherwise
    event.stopPropagation();

    const { checked } = event.target;
    const { operationGroupStore } = this.stores;
    const {
      state: { triggers },
    } = operationGroupStore;
    const payload = triggers[triggerIndex].payload as ButtonPressedTriggerPayload;

    if (checked) {
      let defaultMoment = moment();

      // ensuring validUntil default is not before validFrom
      if (payloadField === 'validUntil' && payload.validFrom !== null) {
        defaultMoment = moment(payload.validFrom);
      }

      triggers[triggerIndex].payload[payloadField] = defaultMoment.set('seconds', 0).format();
    } else {
      triggers[triggerIndex].payload[payloadField] = null;
    }

    operationGroupStore.setState({ triggers });
  };

  public handleChangeTextInput = (triggerIndex: number, payloadField: string) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const { operationGroupStore } = this.stores;
    const {
      state: { triggers },
    } = operationGroupStore;

    triggers[triggerIndex].payload[payloadField] = value;

    operationGroupStore.setState({ triggers }, true);
  };

  public addTrigger = () => {
    const { operationGroupStore } = this.stores;
    const { triggers } = operationGroupStore.state;

    triggers.push({
      type: TriggerType.TIME_BASED,
      payload: {
        datetime: '',
      },
    });

    operationGroupStore.setState({ triggers });
  };

  public render() {
    const { operationGroupStore } = this.stores;
    const { loading } = this.state;
    const { triggers } = operationGroupStore.state;

    return (
      <Grid container spacing={10} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        {/*<Grid item xs={1}>*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    color="secondary"*/}
        {/*    onClick={this.addTrigger}*/}
        {/*    size={'small'}*/}
        {/*    style={{ margin: 8 }}*/}
        {/*    disabled*/}
        {/*  >*/}
        {/*    Add Trigger*/}
        {/*  </Button>*/}
        {/*</Grid>*/}

        {/*<Grid item xs={3}>*/}
        {/*  <Typography variant={'caption'} gutterBottom color={'secondary'}>*/}
        {/*    As soon as a trigger has been triggered,*/}
        {/*    the Operation-Group will be applied and all additional Triggers will be disabled.*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}

        {/*{spacer(8)}*/}

        {triggers.map((trigger: TriggerCreate, index: number) => (
          <Grid item xs={4} key={index}>
            {/*<Typography gutterBottom color={'secondary'}>*/}
            {/*  Trigger {index + 1}:*/}
            {/*</Typography>*/}
            {this.renderTrigger(trigger, index)}
          </Grid>
        ))}

        {spacer(8)}

        {spacer(12, { style: { height: 52 } })}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(TriggerFormComponent);

export const TriggerForm = StyleWrapped;
