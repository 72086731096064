import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../Helper/ThemeHelper';
import { AppBarStyles } from './AppBarStyles';
import { DrawerPaperStyles } from './DrawerPaperStyles';
import { SearchBarStyles } from './SearchBarStyles';

export const ContentMarginTop = -80;

export const NavigationStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => {
  const appBarStyles = AppBarStyles(theme);
  const drawerPaperStyles = DrawerPaperStyles(theme);
  const searchBarStyles = SearchBarStyles(theme);

  const styles = {
    ...appBarStyles,
    ...drawerPaperStyles,
    ...searchBarStyles,
  };

  return {
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24,
      height: 72,
    },
    navigationLinkIcon: {
      color: theme.palette.primary.contrastText,
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 12,
    },
    menuButtonHidden: {
      display: 'none',
    },
    subMenuHeader: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    title: {
      flexGrow: 1,
      animationDuration: '250ms',
      animationTimingFunction: 'ease',
      animationName: '$titleOpacity',
    },
    content: {
      flexGrow: 1,
      padding: spacing(theme) * 3,
      height: '100vh',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    contentGrid: {
      marginTop: ContentMarginTop,
      position: 'relative',
    },
    h5: {
      marginBottom: spacing(theme) * 2,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    paginationRoot: {
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
      border: 0,
    },
    paginationToolbar: {
      paddingLeft: 0,
      color: theme.palette.primary.main,
    },
    paginationSpacer: {},
    paginationCaption: {
      fontWeight: 700,
      marginLeft: spacing(theme),
      marginRight: 0,
      minWidth: 110,
      textAlign: 'center',
      lineHeight: '48px !important',
    },
    paginationSelectRoot: {
      marginLeft: spacing(theme),
      marginRight: spacing(theme),
    },
    paginationSelect: {
      marginTop: spacing(theme) / 4,
    },
    paginationSelectIcon: {
      color: theme.palette.primary.main,
      top: spacing(theme) / 2,
      right: -3,
    },
    paginationInput: {
      fontSize: '1rem',
      minWidth: 85,
      textAlign: 'center',
    },
    paginationMenuItem: {},
    paginationActions: {
      marginLeft: spacing(theme),
    },
    paginationRootSticky: {},
    paginationToolbarSticky: {
      color: theme.palette.primary.main,
      '& > $paginationCaptionSticky:nth-of-type(1)': {
        display: 'none',
      },
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 48,
      height: 48,
      minHeight: 48,
      boxShadow: theme.shadows[5],
      marginLeft: spacing(theme),
      paddingRight: 0,
    },
    paginationSpacerSticky: {},
    paginationCaptionSticky: {
      fontWeight: 700,
      marginLeft: spacing(theme),
      marginRight: 0,
      height: 48,
      lineHeight: '48px',
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 48,
      paddingLeft: spacing(theme),
      paddingRight: spacing(theme),
      color: theme.palette.primary.main,
    },
    paginationSelectRootSticky: {
      marginLeft: spacing(theme),
      marginRight: spacing(theme),
    },
    paginationSelectSticky: {
      marginTop: spacing(theme) / 4,
    },
    paginationSelectIconSticky: {
      color: theme.palette.primary.main,
      top: spacing(theme) / 2,
      right: -3,
    },
    paginationInputSticky: {
      fontSize: '1rem',
      fontWeight: 700,
      marginLeft: spacing(theme),
      marginRight: 0,
      height: 48,
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 48,
      paddingLeft: spacing(theme),
      paddingRight: spacing(theme),
      color: theme.palette.primary.main,
    },
    paginationMenuItemSticky: {},
    paginationActionsSticky: {
      marginLeft: spacing(theme) / 2,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    paginationButtonSticky: {
      marginLeft: spacing(theme),
      backgroundColor: theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '@media (max-width: 1100px)': {
      paginationToolbar: {
        '& > $paginationCaption:nth-of-type(1)': {
          display: 'none',
        },
      },
      paginationCaption: {
        minWidth: 82,
      },
      paginationInput: {
        minWidth: 70,
      },
    },
    '@keyframes titleOpacity': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    ...styles,
  };
};
