import {
  CompartmentSelectionStrategy,
  CompartmentSelector,
  CompartmentSelectorConfiguration,
  MinMaxType,
} from '@ekkogmbh/apisdk';
import { Grid, ListItemText, MenuItem, Stack } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { ListForm } from 'src/Common/Components/Forms/ListForm';
import { StyledCheckbox } from 'src/Common/Components/Forms/StyledCheckbox';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { CompartmentSelectorStore } from '../Stores/CompartmentSelectorStore';

const styles = FormStyles;

interface CompartmentSelectorPanelStores {
  api: ApiStore;
  compartmentSelectorStore: CompartmentSelectorStore;
}

interface CompartmentSelectorPanelState {
  loading: boolean;
}

export interface CompartmentSelectorPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (compartmentSelector: CompartmentSelector) => Promise<CompartmentSelector>;
}

@inject('api', 'compartmentSelectorStore')
@observer
class CompartmentSelectorPanelComponent extends React.Component<
  CompartmentSelectorPanelProps,
  CompartmentSelectorPanelState
> {
  public state: CompartmentSelectorPanelState = {
    loading: false,
  };

  get stores(): CompartmentSelectorPanelStores {
    return this.props as CompartmentSelectorPanelProps & CompartmentSelectorPanelStores;
  }

  public componentWillUnmount(): void {
    const { compartmentSelectorStore } = this.stores;
    compartmentSelectorStore.resetStore();
  }

  public handleReset = async () => {
    const { compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;

    if (editableCompartmentSelector !== undefined) {
      compartmentSelectorStore.setEditableCompartmentSelector(editableCompartmentSelector);
    } else {
      this.componentWillUnmount();
    }
  };

  public handleSave = async () => {
    const { saveHandler, closeHandler } = this.props;
    const { compartmentSelectorStore } = this.stores;

    const payload = compartmentSelectorStore.state as CompartmentSelector;

    this.setState({ loading: true }, async () => {
      await saveHandler(payload);
      this.handleReset();
      closeHandler();
    });
  };

  public configCheckbox = (
    label: string,
    configKey: string,
    isEnabled: boolean,
    onEnableValue: string | string[] | Record<string, string | number>,
    tooltip?: string,
    disabled?: boolean,
  ): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;

    return (
      <StyledCheckbox
        label={label}
        value={isEnabled}
        disabled={disabled === true}
        onChange={() => {
          if (isEnabled) {
            compartmentSelectorStore.removeConfigurationValue(configKey);
          } else {
            compartmentSelectorStore.setConfigurationValue(configKey, onEnableValue);
          }
        }}
        tooltip={tooltip}
      />
    );
  };

  public constantConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('constant');

    return (
      <Stack>
        {this.configCheckbox('Constant', 'constant', enabled, '', 'always uses the constant value as input')}
        {enabled && (
          <StyledTextField
            type={'text'}
            label={'Value'}
            value={configuration.constant as string}
            onChange={(e) => compartmentSelectorStore.setConfigurationValue('constant', e.target.value as string)}
          />
        )}
      </Stack>
    );
  };

  public prefixConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration, coordinate } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('prefix');

    return (
      <Stack>
        {this.configCheckbox('Prefix', 'prefix', enabled, coordinate, 'prefix inputs with a constant value')}
        {enabled && (
          <CoordinateInput
            label="Prefix"
            value={configuration.prefix}
            onChange={(p) => compartmentSelectorStore.setConfigurationValue('prefix', p)}
            trailingDelimiter="both"
          />
        )}
      </Stack>
    );
  };

  public suffixConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('suffix');

    return (
      <Stack>
        {this.configCheckbox('Suffix', 'suffix', enabled, '', 'add a constant value to the end of all inputs')}
        {enabled && (
          <StyledTextField
            type={'text'}
            label={'Value'}
            value={configuration.suffix as string}
            onChange={(e) => compartmentSelectorStore.setConfigurationValue('suffix', e.target.value as string)}
          />
        )}
      </Stack>
    );
  };

  public fieldsConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration, type } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('fields');
    const inapplicable = type === CompartmentSelectionStrategy.COORDINATE;

    return (
      <Stack>
        {this.configCheckbox(
          'Fields',
          'fields',
          enabled,
          [],
          'looks into given compartment fields for selection',
          inapplicable,
        )}
        {!inapplicable && enabled && (
          <ListForm
            items={configuration.fields as string[]}
            onChange={(items) => compartmentSelectorStore.setConfigurationValue('fields', items)}
          />
        )}
      </Stack>
    );
  };

  public splitConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('split');

    let form: React.JSX.Element = <></>;
    if (enabled) {
      const split = configuration.split;
      const { delimiter, position } = split!;

      form = (
        <>
          <StyledTextField
            type={'text'}
            label={'Delimiter'}
            value={delimiter}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('split', {
                ...split,
                delimiter: e.target.value as string,
              })
            }
          />
          <StyledTextField
            numeric
            type={'number'}
            label={'Position'}
            value={position}
            onChange={(e) => {
              const intValue = parseInt(e.target.value);
              const newValue = intValue >= 1 ? intValue : 1;

              compartmentSelectorStore.setConfigurationValue('split', {
                ...split,
                position: newValue,
              });
            }}
          />
        </>
      );
    }

    return (
      <Stack>
        {this.configCheckbox(
          'Split',
          'split',
          enabled,
          { delimiter: ',', position: 1 },
          'splits the selector input and uses only the value at the given position',
        )}
        {form}
      </Stack>
    );
  };

  public regexConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('regex');

    let form: React.JSX.Element = <></>;
    if (enabled) {
      const regex = configuration.regex;
      const { pattern, replacement } = regex!;

      form = (
        <>
          <StyledTextField
            type={'text'}
            label={'Pattern'}
            value={pattern}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('regex', { ...regex, pattern: e.target.value as string })
            }
          />
          <StyledTextField
            type={'text'}
            label={'Replacement'}
            value={replacement}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('regex', {
                ...regex,
                replacement: e.target.value as string,
              })
            }
          />
        </>
      );
    }

    return (
      <Stack>
        {this.configCheckbox(
          'Regex',
          'regex',
          enabled,
          { pattern: '', replacement: '' },
          'use a regex pattern/replacement to transform the input',
        )}
        {form}
      </Stack>
    );
  };

  public minmaxConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration, type } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('minmax');
    const inapplicable = type === CompartmentSelectionStrategy.COORDINATE;

    let form: React.JSX.Element = <></>;
    if (!inapplicable && enabled) {
      const minmax = configuration.minmax;
      const { type, field } = minmax!;

      form = (
        <>
          <StyledSelectField
            label={'Type'}
            value={type}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('minmax', {
                ...minmax,
                type: e.target.value as MinMaxType,
              })
            }
          >
            {Object.values(MinMaxType).map((m, i) => (
              <MenuItem key={`minmax-item-${i}`} value={m}>
                <ListItemText primary={m} />
              </MenuItem>
            ))}
          </StyledSelectField>
          <StyledTextField
            type={'text'}
            label={'Field'}
            value={field}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('minmax', { ...minmax, field: e.target.value as string })
            }
          />
        </>
      );
    }

    return (
      <Stack>
        {this.configCheckbox(
          'MinMax',
          'minmax',
          enabled,
          { type: 'minimize', field: '' },
          'selects the compartment where the given field value is the minimum/maximum',
          inapplicable,
        )}
        {form}
      </Stack>
    );
  };

  public inputValidationConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('inputValidation');

    let form: React.JSX.Element = <></>;
    if (enabled) {
      const validation = configuration.inputValidation as Record<string, string>;
      const { pattern, errorText } = validation;

      form = (
        <>
          <StyledTextField
            type={'text'}
            label={'Pattern'}
            value={pattern}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('inputValidation', {
                ...validation,
                pattern: e.target.value as string,
              })
            }
          />
          <StyledTextField
            type={'text'}
            label={'Error Text'}
            value={errorText}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('inputValidation', {
                ...validation,
                errorText: e.target.value as string,
              })
            }
          />
        </>
      );
    }

    return (
      <Stack>
        {this.configCheckbox(
          'Input Validation',
          'inputValidation',
          enabled,
          { pattern: '', errorText: '' },
          'limit inputs by matching against the given regex pattern',
        )}
        {form}
      </Stack>
    );
  };

  public batchConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const enabled = compartmentSelectorStore.hasConfiguration('batch');

    let form: React.JSX.Element = <></>;
    if (enabled) {
      const batch = configuration.batch as { delimiter: string };
      form = (
        <StyledTextField
          type={'text'}
          label={'delimiter'}
          value={batch.delimiter}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('batch', { delimiter: e.target.value as string })
          }
        />
      );
    }

    return (
      <Stack>
        {this.configCheckbox(
          'Batch',
          'batch',
          enabled,
          { delimiter: ';' },
          'split the input and use each part for selection',
        )}
        {form}
      </Stack>
    );
  };

  public assembleConfigurationForm(): React.JSX.Element {
    const configParts: React.JSX.Element[] = [
      this.fieldsConfig(),
      this.prefixConfig(),
      this.suffixConfig(),
      this.constantConfig(),
      this.splitConfig(),
      this.batchConfig(),
      this.regexConfig(),
      this.minmaxConfig(),
      this.inputValidationConfig(),
    ];

    return (
      <Grid container spacing={2}>
        {configParts.map((element, i) => (
          <Grid item xs={4} key={`config-grid-${i}`}>
            {element}
          </Grid>
        ))}
      </Grid>
    );
  }

  public render() {
    const { closeHandler } = this.props;
    const { loading } = this.state;
    const { compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;
    const { name, coordinate, configuration, type, allFilled } = compartmentSelectorStore.state;

    const typeOptions = Object.values(CompartmentSelectionStrategy).map((value, index) => (
      <option key={`selector-strategy-${index}`} value={value}>
        {value}
      </option>
    ));

    const isDisabled = editableCompartmentSelector !== undefined;

    const updateCoordinate = (newCoordinate: string) => {
      const previousCoordinate = coordinate;
      compartmentSelectorStore.setState({ coordinate: newCoordinate });

      // make the prefix behave like before EKKO-1307 and tie it to the coordinate (if the prefix isn't customized)
      const customizedPrefix =
        Object.keys(configuration).includes('prefix') && configuration.prefix !== previousCoordinate;
      if (type === 'select-by-coordinate' && !customizedPrefix) {
        compartmentSelectorStore.setState({ configuration: { ...configuration, prefix: newCoordinate } });
      }
    };

    const updateSelectorType = (newType: CompartmentSelectionStrategy) => {
      let newConfig: CompartmentSelectorConfiguration = { ...configuration };

      // make the prefix behave like before EKKO-1307 and tie it to the coordinate (if the prefix is unset)
      const noPrefix = !Object.keys(configuration).includes('prefix');
      if (newType === CompartmentSelectionStrategy.COORDINATE && noPrefix) {
        newConfig = { ...newConfig, prefix: coordinate };
      }

      switch (newType) {
        case CompartmentSelectionStrategy.COORDINATE:
          delete newConfig.fields;
          delete newConfig.minmax;
          break;
        case CompartmentSelectionStrategy.VALUE:
          break;
      }

      compartmentSelectorStore.setState({ type: newType, configuration: newConfig });
    };

    return (
      <Stack>
        <div style={{ display: loading ? 'block' : 'none' }}>
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid
              item
              xs={12}
              style={{
                height: 496,
                position: 'relative',
              }}
            >
              <div
                style={{
                  top: '50%',
                  marginTop: -48,
                  position: 'absolute',
                  width: '100%',
                }}
              >
                <CheckmarkSpinner complete={false} failure={false} />
              </div>
            </Grid>
          </Grid>
        </div>

        {!loading && (
          <>
            <Grid container spacing={1} alignContent={'stretch'}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <StyledFormHeader label={'Compartment Selector'} />
                  <StyledTextField
                    type={'text'}
                    label={'Name'}
                    value={name}
                    disabled={isDisabled}
                    onChange={(e) => compartmentSelectorStore.setState({ name: e.target.value })}
                  />
                  <CoordinateInput
                    value={coordinate}
                    disabled={isDisabled}
                    onChange={updateCoordinate}
                    trailingDelimiter={false}
                  />
                  <div>
                    <StyledSelectField
                      native
                      value={type}
                      onChange={(e) => updateSelectorType(e.target.value as CompartmentSelectionStrategy)}
                      label="Type"
                    >
                      {typeOptions}
                    </StyledSelectField>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <StyledFormHeader label={'Configuration'} />
                {this.assembleConfigurationForm()}
              </Grid>
            </Grid>
          </>
        )}
        <FormPanelButtons
          cancelHandler={closeHandler}
          saveHandler={this.handleSave}
          resetHandler={this.handleReset}
          isDeleteHidden={true}
          isSaveDisabled={!allFilled}
        />
      </Stack>
    );
  }
}

const StyleWrapped = withStyles(styles)(CompartmentSelectorPanelComponent);

export const CompartmentSelectorPanel = StyleWrapped;
