import { SortedSideSortingStrategy, Template } from '@ekkogmbh/apisdk';
import { Fade, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { AreaSelect } from '../../Common/Components/AreaSelect';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { ReactSelectMultiValue } from '../../Common/Components/ReactSelectMulti';
import { getOverrideRootNodesFromUserPermissions, NodeSeparator } from '../../Common/Helper/Nodes';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { PickingSideStore } from '../Stores/PickingSideStore';
import { SortedSideTemplatesSelect } from './SortedSideTemplatesSelect';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'pickingSideStore'];

interface SortedSidePanelFormStores {
  api: ApiStore;
  pickingSideStore: PickingSideStore;
}

interface SortedSidePanelFormState {
  loading: boolean;
}

interface SortedSidePanelFormProps extends WithStyles<typeof styles> {
  availableTemplates: Template[];
  hasAreasReadPermission: boolean;
  updateSortableItems: () => Promise<void>;
}

@inject(...stores)
@observer
class SortedSidePanelFormComponent extends React.Component<SortedSidePanelFormProps, SortedSidePanelFormState> {
  public state: SortedSidePanelFormState = {
    loading: false,
  };
  private sortableItemsTimeout?: number;

  get stores(): SortedSidePanelFormStores {
    return this.props as SortedSidePanelFormProps & SortedSidePanelFormStores;
  }

  public static isAllFilled = (
    key: string,
    templates: Template[],
    nodeSelectValue?: ReactSelectMultiValue[],
    nodeValue?: string,
  ) =>
    key !== '' &&
    templates.length > 0 &&
    (nodeValue === undefined || nodeValue !== '') &&
    (nodeSelectValue === undefined || nodeSelectValue.length > 0);

  public handleChange = (fieldName: string) => async ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | Record<string, string>>) => {
    const { pickingSideStore } = this.stores;
    const { hasAreasReadPermission } = this.props;
    const { editableSortedSide } = pickingSideStore;
    const { nodeSelectValue } = pickingSideStore.state;

    let { name, key, templates, nodeValue } = pickingSideStore.state;

    switch (fieldName) {
      case 'name':
        name = value;
        break;

      case 'area':
        nodeValue = value;

        this.handleSortableItemsUpdate();

        break;

      case 'key':
        key = value;

        this.handleSortableItemsUpdate();

        break;

      case 'templates':
        // Whole template struct is provided by the SortedSideTemplateSelect
        // Unfortunately the typing here is broken as hell, due to the fact, that material-ui
        // does not provide the right typing in the first place.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        templates = (value as unknown) as Template[];

        this.handleSortableItemsUpdate();

        break;

      case 'sortingStrategy':
        pickingSideStore.sortingStrategy = value as SortedSideSortingStrategy;
        pickingSideStore.changed = true;

        this.handleSortableItemsUpdate();

        return;
    }

    const allFilled = SortedSidePanelFormComponent.isAllFilled(
      key,
      templates,
      editableSortedSide === undefined && hasAreasReadPermission ? nodeSelectValue : undefined,
      !hasAreasReadPermission ? nodeValue : undefined,
    );

    pickingSideStore.changed = true;
    pickingSideStore.setState({
      name,
      key,
      templates,
      allFilled,
      nodeValue,
    });
  };

  public handleSortableItemsUpdate = (): void => {
    if (this.sortableItemsTimeout !== null) {
      window.clearTimeout(this.sortableItemsTimeout);
      this.sortableItemsTimeout = undefined;
    }

    this.sortableItemsTimeout = window.setTimeout(this.props.updateSortableItems, 500);
  };

  public handleChangeArea = async (nodeSelectValue: ReactSelectMultiValue[]) => {
    const { pickingSideStore } = this.stores;
    const { name, key, templates } = pickingSideStore.state;

    const allFilled = SortedSidePanelFormComponent.isAllFilled(key, templates, nodeSelectValue);

    pickingSideStore.changed = true;
    pickingSideStore.setState({
      allFilled,
      nodeSelectValue,
      name,
      key,
      templates,
    });

    this.handleSortableItemsUpdate();
  };

  public render() {
    const { api, pickingSideStore } = this.stores;
    const { editableSortedSide, sortingStrategy } = pickingSideStore;
    const { availableTemplates, classes, hasAreasReadPermission } = this.props;
    const { loading } = this.state;
    const { name, key, templates, nodeSelectValue, nodeValue } = pickingSideStore.state;

    const allPickingReadUserPermissions = api.getUserPermissionsByPermissionName(Permissions.PICKING_AREAS_READ);
    const overrideRootNodes = getOverrideRootNodesFromUserPermissions(allPickingReadUserPermissions);

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label={'Name (optional)'}
              value={name}
              name={'name'}
              onChange={this.handleChange('name')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Fade>
        </Grid>

        {editableSortedSide === undefined && hasAreasReadPermission && (
          <Grid item xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <FormControl variant="outlined" className={classes.margin}>
                <AreaSelect
                  handleValueChange={this.handleChangeArea}
                  value={nodeSelectValue}
                  overrideRootNodes={overrideRootNodes}
                />
              </FormControl>
            </Fade>
          </Grid>
        )}

        {(editableSortedSide !== undefined || !hasAreasReadPermission) && (
          <Grid item xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <TextField
                disabled={editableSortedSide !== undefined}
                label={'Area'}
                value={
                  editableSortedSide !== undefined
                    ? editableSortedSide.node.parts.join(' ' + NodeSeparator + ' ')
                    : nodeValue
                }
                name={'area'}
                onChange={this.handleChange('area')}
                variant="outlined"
                className={classes.margin}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
              />
            </Fade>
          </Grid>
        )}

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <TextField
              label={'Key'}
              value={key}
              name={'key'}
              onChange={this.handleChange('key')}
              variant="outlined"
              className={classes.margin}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Fade>
        </Grid>

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <div>
              <SortedSideTemplatesSelect
                value={templates.map((t) => t.id)}
                allTemplates={availableTemplates}
                changeHandler={this.handleChange('templates') as never}
                noDialog={true}
                classes={{ formControl: classes.formControlSelectAutoWidth }}
              />
            </div>
          </Fade>
        </Grid>

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <FormControl variant="standard" className={classes.margin}>
              <FormLabel>Sorting</FormLabel>
              <RadioGroup
                aria-label="Sorting-Strategy"
                name="sortingStrategy"
                className={classes.margin}
                value={sortingStrategy}
                onChange={this.handleChange('sortingStrategy')}
                row
              >
                <FormControlLabel value="alphanumeric-identifier" control={<Radio />} label="Alphanumeric-Identifier" />
                <FormControlLabel value="alphanumeric-value" control={<Radio />} label="Alphanumeric-Value" />
                <FormControlLabel value="manually" control={<Radio />} label="Manual" />
                <FormControlLabel value="per-order" control={<Radio />} label="Per-Order" />
              </RadioGroup>
            </FormControl>
          </Fade>
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(SortedSidePanelFormComponent);

export const SortedSidePanelForm = StyleWrapped;
