import { Theme } from '@mui/material';
import { Duration } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../Helper/ThemeHelper';

export const DrawerWidth = 280;

const toolbarIconLogoWidth = 56;
const toolbarIconLogoMarginLeft = 26;

const drawerPaperCloseDurationKey: keyof Duration = 'leavingScreen';
const iconLogoCloseDurationKey: keyof Duration = 'leavingScreen';

export const DrawerPaperStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  drawerButton: {
    position: 'fixed',
    bottom: spacing(theme) * -12,
    left: DrawerWidth - spacing(theme) * 8,
    marginLeft: 0,
    zIndex: 1250,
    transition: theme.transitions.create(['bottom', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[iconLogoCloseDurationKey],
      delay:
        theme.transitions.duration[drawerPaperCloseDurationKey] -
          theme.transitions.duration[iconLogoCloseDurationKey] || 0,
    }),
  },
  drawerButtonSticky: {
    bottom: spacing(theme) * 3,
  },
  drawer: {
    width: DrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    width: DrawerWidth,
  },
  drawerClose: {
    width: spacing(theme) * 9, // 7
  },
  drawerPaper: {
    // +++ hide scrollbar while still scrollable
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
      width: '0 !important',
    },
    // ---
    whiteSpace: 'nowrap',
    width: DrawerWidth,
    color: theme.palette.primary.contrastText,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: theme.shadows[7],
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[drawerPaperCloseDurationKey],
    }),
    width: spacing(theme) * 9, // 7
    [theme.breakpoints.up('sm')]: {
      width: spacing(theme) * 9,
    },
  },
  drawerButtonDrawerClosed: {
    left: spacing(theme) * 9 - spacing(theme) * 8 + spacing(theme) / 2,
  },
  toolbarIconLogo: {
    position: 'absolute',
    width: toolbarIconLogoWidth,
    height: 20,
    marginLeft: toolbarIconLogoMarginLeft,
    left: 0,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[iconLogoCloseDurationKey],
      delay:
        theme.transitions.duration[drawerPaperCloseDurationKey] -
          theme.transitions.duration[iconLogoCloseDurationKey] || 0,
    }),
  },
  toolbarIconLogoHidden: {
    left: (toolbarIconLogoWidth + toolbarIconLogoMarginLeft) * -1,
  },
  toolbarIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerTooltipTitle: {
    fontSize: 16,
    fontWeight: 700,
  },
});
