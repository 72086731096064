import { RoleMapping, Permission } from '@ekkogmbh/apisdk';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Apps, ExpandLess, ExpandMore, Update } from '@mui/icons-material';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { YourProfileStyles } from '../Styles/YourProfileStyles';

interface UserRoleCardState {
  extended: boolean;
}

interface UserRoleCardProps extends WithStyles<typeof YourProfileStyles> {
  nodeRoleMapping: RoleMapping;
}

class UserRoleCardComponent extends React.Component<UserRoleCardProps, UserRoleCardState> {
  public state: UserRoleCardState = {
    extended: false,
  };

  public toggleExtended = () => {
    this.setState({ extended: !this.state.extended });
  };

  public render() {
    const { extended } = this.state;
    const { nodeRoleMapping, classes } = this.props;
    const { role, coordinate, validUntil } = nodeRoleMapping;

    const isExpired = moment().isAfter(validUntil);
    const expirationText = moment(validUntil).fromNow();

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classNames(classes.cardTitle)}>{role.name}</Typography>
          <Typography className={classNames(classes.iconText)}>
            <Apps />
            {coordinate}
          </Typography>
          <Typography className={classNames(classes.iconText)}>
            <Update />
            {isExpired ? 'Expired' : 'Expires'} {expirationText}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={this.toggleExtended} startIcon={extended ? <ExpandLess /> : <ExpandMore />}>
            Permissions
          </Button>
        </CardActions>
        <Collapse in={extended} timeout={'auto'} unmountOnExit>
          <List>
            {role.permissions.map((permission: Permission, index: number) => (
              <ListItem key={index}>
                <ListItemText inset primary={permission} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Card>
    );
  }
}

export const UserRoleCard = withStyles(YourProfileStyles)(UserRoleCardComponent);
