import React from 'react';
import { ClearingUpdateStrategy, ManualUpdateStrategy, UpdaterProfile, UpdateStrategyType } from '@ekkogmbh/apisdk';
import { RegexScanUpdateStrategy } from '@ekkogmbh/apisdk/src/Sdk/ApiTypes';
import { Grid, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import { resolveStrategyName } from '../UpdaterProfileManagement';
import {
  UpdaterProfileManagementContentActionHandlers,
  UpdaterProfileManagementContentPropsWithStores,
  UpdaterProfileManagementContentState,
} from './UpdaterProfileManagementContent';

class UpdaterProfileDataTableActions extends DataTableActionsComponent<UpdaterProfile> {}

const UpdaterProfileActions = (
  _: UpdaterProfileManagementContentState,
  __: UpdaterProfileManagementContentPropsWithStores,
  actions: UpdaterProfileManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (updaterProfile: UpdaterProfile): React.ReactNode => {
      const actionButtons: ActionButton<UpdaterProfile>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <UpdaterProfileDataTableActions dataset={updaterProfile} isProcessing={false} actionButtons={actionButtons} />
      );
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: UpdaterProfile['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (coordinate: UpdaterProfile['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const TypeColumn = (): MUIDataTableColumnDef => ({
  label: 'Type',
  name: 'entry.updateStrategy.type',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (type: UpdaterProfile['updateStrategy']['type']) => (
      <Typography>{resolveStrategyName(type)}</Typography>
    ),
  },
});

const CompartmentSelectorCustomBody = (compartmentSelector: UpdaterProfile['compartmentSelector']) => {
  // @FIXME: this is the save type because UpdaterProfile does not yet use the CompartmentSelectorEntity
  const parameter = Object.values(compartmentSelector.configuration)[0];

  return (
    <Typography>
      {compartmentSelector.type} [ {parameter} ]
    </Typography>
  );
};

const CompartmentSelectorColumn = (): MUIDataTableColumnDef => ({
  label: 'Compartment Selector',
  name: 'entry.compartmentSelector',
  options: {
    sort: false,
    filter: false,
    customBodyRender: CompartmentSelectorCustomBody,
  },
});

const CompartmentInstructionsColumn = (): MUIDataTableColumnDef => ({
  label: 'Compartment Input Instructions',
  name: 'entry.compartmentInputInstructions',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: UpdaterProfile['compartmentInputInstructions']): React.ReactNode => (
      <Typography>{value ?? '-'}</Typography>
    ),
  },
});

const valueWithHeader = (key: string, value: string): React.JSX.Element => (
  <Grid
    key={key}
    style={{
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  >
    <Grid item>
      <Typography
        variant={'subtitle2'}
        color={'textSecondary'}
        style={{
          fontWeight: 700,
          wordBreak: 'keep-all',
        }}
      >
        {key}
      </Typography>
      <span>{value.length > 0 ? value : '-'}</span>
    </Grid>
  </Grid>
);

const ConfigCustomBody = (updateStrategy: UpdaterProfile['updateStrategy']) => {
  switch (updateStrategy.type) {
    case UpdateStrategyType.MANUAL:
      const manualStrategy = updateStrategy as ManualUpdateStrategy;
      const { preset, editMode, disabledFields } = manualStrategy.params;

      return (
        <>
          {valueWithHeader('Preset', preset)}
          {valueWithHeader('Edit Mode', editMode)}
          {valueWithHeader('Disabled Fields', disabledFields.join(', '))}
        </>
      );
    case UpdateStrategyType.REGEX_SCAN:
      const regexScanStrategy = updateStrategy as RegexScanUpdateStrategy;

      return valueWithHeader('Regex Fields', regexScanStrategy.params.fields.map((f) => f.key).join(', '));
    case UpdateStrategyType.CLEARING:
      const clearingStrategy = updateStrategy as ClearingUpdateStrategy;

      return (
        <>
          {valueWithHeader('Preset', clearingStrategy.params.preset)}
          {valueWithHeader('Keep Fields', clearingStrategy.params.disabledFields.join(', '))}
          {valueWithHeader('Clearing Text', clearingStrategy.params.clearingText)}
        </>
      );
  }
};

const ConfigColumn = (): MUIDataTableColumnDef => ({
  label: 'Configuration',
  name: 'entry.updateStrategy',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value) => (
      <Grid
        container
        style={{
          alignItems: 'center',
          columnGap: 32,
          display: 'grid',
          gridAutoColumns: '1fr',
          gridAutoFlow: 'column',
          justifyContent: 'space-between',
          rowGap: 8,
        }}
      >
        {ConfigCustomBody(value)}
      </Grid>
    ),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  UpdaterProfileManagementContentPropsWithStores,
  UpdaterProfileManagementContentState,
  UpdaterProfileManagementContentActionHandlers
>> = [
  Name,
  Coordinate,
  TypeColumn,
  CompartmentSelectorColumn,
  CompartmentInstructionsColumn,
  ConfigColumn,
  UpdaterProfileActions,
];
