import { Permission, Role } from '@ekkogmbh/apisdk';
import { Checkbox, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import ListItemText from '@mui/material/ListItemText';
import { SelectProps } from '@mui/material/Select';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { isArrayValue } from '../../Common/Helper/FormHelper';
import { RoleManagementStyles } from '../Styles/RoleManagementStyles';

interface RolePermissionSelectProps extends WithStyles<typeof RoleManagementStyles> {
  role: Role | Omit<Role, 'id' | 'name'>;
  allPermissions: Permission[];
  saveHandler: (role: Role, permissions: Permission[]) => void;
  noDialog?: boolean;
  label?: string;
}

class RolePermissionSelectComponent extends React.PureComponent<RolePermissionSelectProps> {
  public state: {
    permissions: Permission[];
    open: boolean;
    dialog: boolean;
  };
  private sortedAllPermissions: Permission[] = [];

  constructor(props: RolePermissionSelectProps) {
    super(props);

    const { permissions } = props.role;

    this.state = {
      permissions: permissions,
      open: false,
      dialog: false,
    };

    this.sortAllPermissions();
  }

  public sortAllPermissions = (allPermissions?: Permission[]) => {
    const permissions = allPermissions ? allPermissions : this.props.allPermissions;

    this.sortedAllPermissions = [...permissions].sort(this.allPermissionsSorter);
  };

  public onDialogDismiss = () => {
    const { permissions } = this.props.role;

    this.setState({
      permissions,
      dialog: false,
    });
  };

  public isPersistedRole = (role: Role | Omit<Role, 'id' | 'name'>): role is Role =>
    (role as Role).id !== undefined && (role as Role).name !== undefined;

  public onDialogConfirm = () => {
    const { role, saveHandler } = this.props;
    const { permissions } = this.state;

    if (this.isPersistedRole(role)) {
      saveHandler(role, permissions);
    }

    this.setState({ dialog: false });
  };

  public handleClose = () => {
    const { noDialog } = this.props;

    if (noDialog) {
      const { role, saveHandler } = this.props;
      const { permissions } = this.state;

      if (this.didPermissionsChange()) {
        saveHandler(role as Role, permissions);
      }

      this.setState({
        open: false,
      });
    } else {
      this.setState({
        open: false,
        dialog: this.didPermissionsChange(),
      });
    }
  };

  public handleOpen = () => {
    this.setState({ open: true });
  };

  public handleChange = (event: SelectChangeEvent<unknown>) => {
    const formValue = event.target.value as string | string[];

    this.setState({ permissions: formValue });
  };

  public didPermissionsChange = (): boolean =>
    this.props.allPermissions.filter((name: Permission) => this.didPermissionChangeByName(name)).length > 0;

  public didPermissionChangeByName = (name: string): boolean => {
    const previousPermissionNames = this.props.role.permissions;
    const currentPermissionNames = this.state.permissions;

    return (
      (previousPermissionNames.indexOf(name) === -1 && currentPermissionNames.indexOf(name) > -1) ||
      (previousPermissionNames.indexOf(name) > -1 && currentPermissionNames.indexOf(name) === -1)
    );
  };

  public renderPreviousPermissions = () => {
    const previousPermissionNames = this.props.role.permissions;

    return previousPermissionNames.map((name: string) => <div key={name}>{name}</div>);
  };

  public renderUpdatedPermissions = () => {
    const currentPermissionNames = this.state.permissions;

    return currentPermissionNames.map((name: string) => <div key={name}>{name}</div>);
  };

  public renderDialogText = () => {
    const privPermissions = this.renderPreviousPermissions();
    const currPermissions = this.renderUpdatedPermissions();

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="button" gutterBottom>
            Before
          </Typography>
          {privPermissions}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="button" gutterBottom>
            After
          </Typography>
          {currPermissions}
        </Grid>
      </Grid>
    );
  };

  public allPermissionsSorter = (permissionLeft: Permission, permissionRight: Permission) => {
    const selectedPermissionNames = this.state.permissions;

    const isSelectedPermissionLeft = selectedPermissionNames.indexOf(permissionLeft) > -1;
    const isSelectedPermissionRight = selectedPermissionNames.indexOf(permissionRight) > -1;

    if (
      (isSelectedPermissionLeft && isSelectedPermissionRight) ||
      (!isSelectedPermissionLeft && !isSelectedPermissionRight)
    ) {
      return permissionLeft.localeCompare(permissionRight);
    }

    return isSelectedPermissionLeft ? -1 : 1;
  };

  public render() {
    const { classes, role, label } = this.props;

    const { dialog, open, permissions } = this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        },
      },
    };

    const selectedRenderValue = (value: SelectProps['value']) =>
      isArrayValue(value as string[]) ? (value as string[]).join(', ') : (value as string);

    const allPermissionsMapper = (name: Permission) => (
      <MenuItem key={name} value={name}>
        <span className={classes.menuItemUpdated}>{this.didPermissionChangeByName(name) && '*'}</span>
        <Checkbox checked={permissions.indexOf(name) > -1} />
        <ListItemText primary={name} />
      </MenuItem>
    );

    return (
      <React.Fragment>
        <GenericDialog
          type="confirmation"
          open={dialog}
          title={
            <React.Fragment>
              {'Update Permissions of Role: '}
              <span className={classes.dialogTitleSpan}>{(role as Role).name}</span>
            </React.Fragment>
          }
          text={this.renderDialogText()}
          onClose={this.onDialogDismiss}
          onConfirm={this.onDialogConfirm}
        />
        <StyledSelectField
          variant="standard"
          disableUnderline={true}
          multiple
          style={{ marginTop: 0 }}
          parentStyle={{
            width: '100%',
            maxWidth: 800,
            margin: 8,
          }}
          value={permissions}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          open={open}
          renderValue={selectedRenderValue}
          MenuProps={MenuProps}
          label={label}
        >
          {this.sortedAllPermissions.map(allPermissionsMapper)}
        </StyledSelectField>
      </React.Fragment>
    );
  }
}

const StyleWrapped = withStyles(RoleManagementStyles)(RolePermissionSelectComponent);

export const RolePermissionSelect = StyleWrapped;
