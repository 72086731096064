// CAREFUL! this is customer-maintained code!
/*eslint-disable*/
// tslint:disable

// ###############################-Start of polyfills-##################################
// Custom Event >IE9 polyfill https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
(function() {
  if (typeof window.CustomEvent === 'function') {
    return false;
  }

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

// Object.assign() IE polyfill https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Polyfill
if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) {
      // .length of function is 2

      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) {
          // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}
// ###############################-End of polyfills-##################################

// ###############################-E2E authentication library-########################
var E2E = (function() {
  // Token data object
  var token = {
      access_token: '', // Access token used to access protected resources
      issued: '', // Date of issue
      expires_in: '', // Expiration in seconds
      token_type: 'Bearer', // Token type
      refresh_token: '', // Issued refresh token
      user_context: null, // Whole JSON of user context
      state: '', // Passed string from initialization
      scope: null, // WEBEAM: scopes
      id_token: '', // WEBEAM: encrypted id token
    },
    config = {
      service: 'APISEC', // <String> Which token authority want you to use. Can be either 'APISEC' or 'WEBEAM'
      APISEC: {
        // <Object> Parameteres of APISEC service
        useCookie: true, // <boolean> Get a token with SiteMinder's cookie if it's present
        forceSM: false, // <boolean> Force usage of SiteMinder even if cookies is't present -> Get redirected to SSO portal -> On successfull login get redirected back to the app with a token.
        sessionCookieName: 'SMSESSION', // <String> Name of a SiteMinder's cookie that will be passed to APISEC
        URL: '', // <String> Base URL of APISEC service. Must use https:// protocol  https://apisec-intranet.bmwgroup.net
        CLIENT_ID: '', // <String> Registred CLIENT_ID of your app on APISEC service
        CLIENT_SECRET: '', // <String> Registred CLIENT_SECRET of your app on APISEC service
        redirectUri: document.location, // <String> Registred redirect_uri of your app on APISEC service
        getUserContext: false, // <boolean> Fetch user context and store it in token.user_context<Object>
      },
      WEBEAM: {
        URL: '', // <String> Base URL of WEBEAM service. Must use https:// protocol https://auth.bmwgroup.net/auth
        realmPath: '', // <String> AM uses realms for separating different user groups and needs to be included in requests
        CLIENT_ID: '', // <String> Registred CLIENT_ID of your app on WEBEAM service
        CLIENT_SECRET: '', // <String> Registred CLIENT_SECRET of your app on WEBEAM service
        redirectUri: document.location, // <String> Registred redirect_uri of your app on WEBEAM service
        getUserContext: false, // <boolean> Fetch user context and store it in token.user_context<Object>
        scope: ['openid', 'profile'], // <Array[String]> Array of strings of scopes
        responseType: ['token', 'id_token'], // <Array[String]> Array of strings of response types
      },
      state: '', // <String> Data that get passed to token.state through redirects
      log: false, // <boolean> Print library's actions in console
    },
    serviceRef,
    initialized = false,
    iframe = top !== self ? true : false,
    status = {
      success: false,
      code: 0,
      message: '',
      has_user_context: false,
    },
    storedSettings = false,
    event = new CustomEvent('E2E');

  if (localStorage.getItem('E2Esettings') && window.opener != null) {
    if (window.stop != undefined) {
      window.stop();
    } else {
      document.execCommand('Stop');
    }
    if (document.body) {
      document.body.innerHTML = '';
    }
    storedSettings = true;
    config = JSON.parse(localStorage.getItem('E2Esettings'));
    serviceRef = config.service;
    if (config.service == 'WEBEAM') {
      fetchTokenByGet();
      if (token.access_token) {
        localStorage.setItem('E2Etoken', JSON.stringify(token));
        window.close();
      }
    }
  }

  // Authorization header for APISEC is base64 encoded string {CLIENT_ID:CLIENT_SECRET}
  function clientAuthorizationHeader() {
    var header = config[serviceRef].CLIENT_ID + ':' + config[serviceRef].CLIENT_SECRET;
    return 'Basic ' + btoa(header);
  }

  function appendOverlay() {
    var overlay = document.createElement('div');
    overlay.setAttribute('id', 'E2Eoverlay');
    overlay.style.cssText = 'position:fixed;left:0;top:0;bottom:0;right:0;background:#fff;z-index:99999999999999;';
    var overlayInner = document.createElement('div');
    overlayInner.style.cssText = 'position:absolute;top:40%;text-align:center;left:0;right:0;';
    var p = document.createElement('p');
    p.style.cssText = 'font-size:18px;line-height:1.75;color:#000;cursor:default;';
    p.innerHTML =
      'Your browser blocks the opening of a new tab that is needed for your authentication.<br> Please proceed by clicking this button:';
    var button = document.createElement('button');
    button.innerHTML = 'Authenticate';
    button.style.cssText = 'border:none;padding:1rem 2rem;background:#000;color:#FFF;font-weight:bold;cursor:pointer;';
    overlay.appendChild(overlayInner);
    overlayInner.appendChild(p);
    overlayInner.appendChild(button);

    var body = document.getElementsByTagName('body')[0];
    body.appendChild(overlay);
    return button;
  }
  function removeOverlay() {
    var element = document.getElementById('E2Eoverlay');
    if (element) {
      var body = document.getElementsByTagName('body')[0];
    }
    body.removeChild(element);
    return true;
  }
  // Main path of getting a token
  function fetchToken(credentials, callback) {
    // Check if access token already exists.
    if (token.access_token) {
      config.log ? console.log('E2E: Already had token stored. Invalidating it and fetching a new one...') : false;
      invalidateToken();
    }

    // WEBEAM iframe Flow
    if (config.service == 'WEBEAM' && iframe) {
      var newWin = window.open(document.location, '_blank');
      if (!newWin || newWin.closed) {
        //POPUP BLOCKED
        config.log ? console.log('E2E: Popup has been blocked. Opening via trusted event is required.') : false;
        // Create an overlay with an button. Clicking the button will dispatch trusted event bypassing popup blocker.
        appendOverlay().addEventListener('click', function() {
          window.open(document.location, '_blank');
          removeOverlay();
        });
      }
      updateStatus(
        false,
        6,
        'E2E: Library loaded within an iframe. Authentication is being done in newly opened window.',
      );
      // Look for cookie, if exists get token by cookie. Use
    } else if (
      ((config[serviceRef].forceSM ? true : getCookieByName(config[serviceRef].sessionCookieName)) &&
        config[serviceRef].useCookie) ||
      config.service == 'WEBEAM'
    ) {
      fetchTokenByGet(credentials);
    } else {
      // first prompt form and ask for the username and password in getUserCredentials promise. When we have them get the token by credentials
      if (credentials) {
        if (credentials.username && credentials.password) {
          fetchTokenByCredentials(credentials.username, credentials.password, credentials.grantType);
        }
      } else {
        config.log
          ? console.log(
              'E2E: SM cookie not found. Please call function E2E.fetchToken({q-number, q-password}); providing strings of user credentials.',
            )
          : false;
        updateStatus(
          false,
          2,
          'E2E: SM cookie not found. Please call function E2E.fetchToken({q-number, q-password}); providing user credentials.',
        );
      }
    }
    if (callback) {
      listen(callback, true);
    }
  }

  // Return value of a cookie of the name
  function getCookieByName(name) {
    var nameEQ = name + '=',
      ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  // Use APISEC's Implicit/SiteMinder & WEBEAM way of getting a new access token. That is redirecting the application's frontend to the service
  function fetchTokenByGet(credentials) {
    // Read URL and get content after last # sign
    var locationStr = location.hash,
      urlParams = locationStr.slice(locationStr.lastIndexOf('#') + 1);

    // Check if we have returned parameters already
    if (urlParams.length > 0 && urlParams.charAt(0) != '/' && !token.access_token) {
      // Parse everything in URL after last # sign
      var data = JSON.parse('{"' + urlParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function(key, value) {
        return key === '' ? value : decodeURIComponent(value);
      });
      if (data.access_token || data.error) {
        // Change words separators '+' to spaces ' '
        if (data.error_description) {
          data.error_description = data.error_description.replace('+', ' ');
        }
        // If cookie is invalid turn off cookie flow
        if (data.error) {
          config[serviceRef].useCookie = false;
          // if credentias were passed, try to get the token
          if (credentials) {
            fetchToken(credentials);
          }
        }
        // Handle data we got in URL
        handleAPISECResponse(data);
      } else {
        // Fallback when there are data after last # sign, but no token or error object
        config[serviceRef].useCookie = false;
        fetchToken(credentials);
      }
    } else {
      // If already have token, invalide it
      if (token.access_token) {
        invalidateToken();
      }
      //Build url query for APISEC or WEBEAM endpoint and redirect app to it
      var clientID = config[serviceRef].CLIENT_ID ? '&client_id=' + config[serviceRef].CLIENT_ID : '',
        redirectUri = config[serviceRef].redirectUri ? '&redirect_uri=' + config[serviceRef].redirectUri : '',
        state = config.state ? '&state=' + config.state : '',
        responseType = config[serviceRef].responseType
          ? '?response_type=' + config[serviceRef].responseType.join(' ')
          : '?response_type=token',
        paramsString = responseType + clientID + redirectUri + state,
        url;
      if (serviceRef == 'WEBEAM') {
        var scope = config[serviceRef].scope ? '&scope=' + config[serviceRef].scope.join(' ') : '',
          nonce = '&nonce=' + genNonce();
        paramsString = paramsString + scope + nonce;
        url = config[serviceRef].URL + '/oauth2' + config[serviceRef].realmPath + '/authorize' + paramsString;
      } else {
        url = config[serviceRef].URL + '/oauth/authorize' + paramsString;
      }
      // Redirect application to auth endpoint
      location.replace(encodeURI(url));
    }
  }
  // grantType can be either password or secureID used as password (SecurID of the user: comprised of PIN followed by the ID created by the token.)
  function fetchTokenByCredentials(username, password, grantType) {
    // APISEC endpoint
    var url = config[serviceRef].URL + '/oauth/token',
      data = 'grant_type=' + (grantType ? grantType : 'password') + '&username=' + username + '&password=' + password,
      xhr = new XMLHttpRequest(); // Perform request to acquire the oauth token with the previously created params

    xhr.withCredentials = true;
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('Authorization', clientAuthorizationHeader());
    xhr.send(data);
    xhr.onerror = function(e) {
      var statusMsg = 'Error: Server not responding or refused...';
      config.log ? console.log(statusMsg) : false;
      updateStatus(false, 4, statusMsg);
    };
    xhr.onload = function(e) {
      handleAPISECResponse(JSON.parse(this.responseText), this.status);
    };
  }
  // Store available token data into E2E.token object. Raise update status and dispatch event for both success and error.
  function handleAPISECResponse(data, status) {
    // Valid request - got access token
    if (data.access_token) {
      token.access_token = data.access_token;

      // additional data that could be useful
      token.expires_in = data.expires_in ? data.expires_in : '';
      token.issued = Date.now();
      token.refresh_token = data.refresh_token ? data.refresh_token : '';
      token.state = data.state ? data.state : '';
      token.scope = data.scope ? data.scope.split(' ') : null;
      token.id_token = data.id_token ? data.id_token : '';
      token.nonce = data.nonce ? data.nonce : '';

      var statusMsg = 'Token acquired successfully and stored in E2E.token.access_token';

      config.log ? console.log(statusMsg) : false;
      config.log ? console.dir(token) : false;

      updateStatus(true, 1, statusMsg);

      if (config[serviceRef].getUserContext) {
        getUserContext();
      }

      // Invalid request
    } else {
      config.log
        ? console.log(
            'Status: ' + status + ', Error: ' + data.error + ',  Error description: ' + data.error_description,
          )
        : false;
      var msg = {
        status: status,
        error: data.error,
        error_description: data.error_description,
      };
      updateStatus(false, 3, msg);
    }
  }

  // Get user context data from the token from APISEC endpoint
  function getUserContext() {
    var xhr = new XMLHttpRequest(),
      url;
    if (serviceRef == 'WEBEAM') {
      url = config[serviceRef].URL + '/oauth2' + config[serviceRef].realmPath + '/userinfo';
      var data = 'access_token=' + token.access_token;
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send(data);
    } else {
      url = config[serviceRef].URL + '/apisec/info/oauth/' + token.access_token;
      xhr.open('GET', url);
      xhr.send();
    }

    xhr.onload = function(e) {
      if (xhr.status == 200) {
        token.user_context = JSON.parse(this.responseText);
      }
      updateStatus(status.success, status.code, status.message, true);
    };
  }

  // Invalidate stored token, keeping the cookie
  function invalidateToken() {
    var url, data;
    if (serviceRef == 'WEBEAM') {
      url = config[serviceRef].URL + '/oauth2' + config[serviceRef].realmPath + '/token/revoke';
      data = '&token=' + token.access_token;
    } else {
      // APISEC endpoint
      url = config[serviceRef].URL + '/oauth/revoke';
      data = 'token_type_hint=access_token&token=' + token.access_token;
    }
    // Build XHR request
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('Authorization', clientAuthorizationHeader());

    xhr.send(data);
    // Resolve response
    xhr.onload = function(e) {
      flushToken();
      var statusMsg = 'E2E: Token successfully invalidated';
      config.log ? console.log(statusMsg) : false;
      updateStatus(true, 5, statusMsg, false);
    };
    return true;
  }

  // Get rid of local token data
  function flushToken() {
    token.access_token = '';
    token.issued = '';
    token.expires_in = '';
    token.refresh_token = '';
    token.user_context = null;
    token.state = '';
    token.scope = null;
    token.id_token = '';
  }

  // Set properties to status object and dispatch event
  function updateStatus(success, code, message, has_user_context) {
    status.success = success;
    status.code = code;
    status.message = message;
    status.has_user_context = has_user_context ? has_user_context : false;
    window.dispatchEvent(event);
  }

  function webeamStorageHandler() {
    if (localStorage.getItem('E2Etoken')) {
      var tokenData = JSON.parse(localStorage.getItem('E2Etoken'));
      Object.assign(token, tokenData);
      localStorage.removeItem('E2Etoken');
      localStorage.removeItem('E2Esettings');
      var statusMsg = 'Token acquired successfully and stored in E2E.token.access_token';
      config.log ? console.log(statusMsg) : false;
      config.log ? console.dir(token) : false;
      updateStatus(true, 1, statusMsg);
    }
  }

  function initCall(appSettings, callback) {
    if (initialized) {
      updateStatus(false, 0, 'E2E Authorization Library has been already initialized.');
      return;
    }
    if (!appSettings) {
      updateStatus(false, 0, 'Please pass configuration object as a parameter.');
    } else {
      serviceRef = appSettings.service ? appSettings.service : config.service;
      if (appSettings[serviceRef]) {
        appSettings[serviceRef] = Object.assign(config[serviceRef], appSettings[serviceRef]);
        Object.assign(config, appSettings);
        if (serviceRef == 'WEBEAM' && iframe) {
          localStorage.setItem('E2Esettings', JSON.stringify(config));
        }
        window.addEventListener('storage', webeamStorageHandler);
      } else {
        updateStatus(false, 0, 'Please pass service endpoint configuration in init settings.');
      }
    }
    if (callback) {
      listen(callback);
    }
    if (!initialized && appSettings) {
      initialized = true;
      fetchToken();
    }
  }

  // Combine user-provided config with defaults. Wrapper for delayed/insta initialization
  function init(appSettings, callback, instant) {
    if (!storedSettings) {
      if (!instant) {
        document.addEventListener('DOMContentLoaded', initCall(appSettings, callback));
      } else {
        initCall(appSettings, callback);
      }
    }
  }
  // Setup event listener and pass status object in a callback
  function listen(callback, once) {
    if (status.code == 1) {
      callback(status);
    }
    if (status.code == 1 && once) {
      return;
    }
    function handler(e) {
      if (once) {
        e.currentTarget.removeEventListener(e.type, handler);
      }
      callback(status);
    }
    window.addEventListener('E2E', handler);
    function destroy() {
      window.removeEventListener('E2E', handler);
    }
    return {
      destroy: destroy,
    };
  }

  // Unique string generator
  function genNonce() {
    var text = '',
      possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 15; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  // Methods and properties publicly available
  return {
    init: init,
    token: token,
    fetchToken: fetchToken,
    invalidateToken: invalidateToken,
    listen: listen,
    state: config.state,
  };
})();

export const e2e = E2E;
