import { IconButton } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { NavigationStyles } from '../Styles/NavigationStyles';

interface NavigationButtonProps extends WithStyles<typeof NavigationStyles> {
  sticky: boolean;
  icon: React.ComponentType<SvgIconProps>;
  tooltipText: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
  buttonRefFunc?: (node: React.ReactNode) => void;
}

class NavigationButtonComponent extends React.Component<NavigationButtonProps> {
  public render() {
    const { classes, sticky, icon, tooltipText, onClick, style, buttonRefFunc } = this.props;
    const Icon = icon;

    return (
      <Tooltip title={tooltipText} placement="bottom" enterDelay={500} leaveDelay={200}>
        <IconButton
          color="primary"
          onClick={onClick}
          style={style}
          ref={buttonRefFunc!}
          className={classNames(classes.appBarButton, sticky && classes.appBarButtonSticky)}
          size="large"
        >
          <Icon />
        </IconButton>
      </Tooltip>
    );
  }
}

export const NavigationButton = withStyles(NavigationStyles)(NavigationButtonComponent);
