import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { InsertDriveFile } from '@mui/icons-material';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import React from 'react';
import { CsvDropzoneStore } from '../../Stores/CsvDropzoneStore';
import { CSVImportStepperStyles } from '../../Styles/CSVImportStepperStyles';
import { LoadingMask } from '../LoadingMask';

interface UploadStepProps extends WithStyles<typeof CSVImportStepperStyles> {
  loading: boolean;
  csvDropzoneStore?: CsvDropzoneStore;
}

@inject('csvDropzoneStore')
class UploadStepComponent extends React.Component<UploadStepProps> {
  public render() {
    const { classes, csvDropzoneStore, loading } = this.props;

    return (
      <div className={classNames(classes.previewContainer)}>
        <div className={classes.preview}>
          <div className={classes.previewInner}>
            {loading && <LoadingMask />}
            <InsertDriveFile className={classes.fileIcon} />
            <br />
            <Typography variant={'caption'} style={{ fontSize: '1.1rem' }}>
              {csvDropzoneStore!.file!.name}
            </Typography>
            <br />
            <Typography variant={'caption'} style={{ fontSize: '0.8rem' }}>
              {Number(csvDropzoneStore!.file!.size / 1024).toFixed(2)} kB
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export const UploadStep = withStyles(CSVImportStepperStyles)(UploadStepComponent);
