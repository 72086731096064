import { CompartmentSelectorIndex } from '@ekkogmbh/apisdk';
import {
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { spacer } from 'src/Common/Components/Forms/Spacer';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CompartmentGeneratorForm } from 'src/CompartmentManagement/Components/CompartmentGeneratorForm';
import { CompartmentGeneratorStore } from 'src/CompartmentManagement/Stores/CompartmentGeneratorStore';
import { LabelLinkStore } from 'src/LabelManagement/Stores/LabelLinkStore';
import { LinkProfileStore } from '../Stores/LinkProfileStore';
import { CompartmentSelectorPicker } from 'src/CompartmentSelectorManagement/CompartmentSelectorPicker';

const styles = FormStyles;

interface LinkProfileConfigurationFormState {
  loading: boolean;
}

interface LinkProfileConfigurationFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface LinkProfileConfigurationFormStores {
  api: ApiStore;
  linkProfileStore: LinkProfileStore;
  labelLinkStore: LabelLinkStore;
  compartmentGeneratorStore: CompartmentGeneratorStore;
}

type LinkProfileConfigurationFormPropsWithStores = LinkProfileConfigurationFormProps &
  LinkProfileConfigurationFormStores;

@inject('api', 'linkProfileStore', 'labelLinkStore', 'compartmentGeneratorStore')
@observer
class LinkProfileConfigurationFromComponent extends Component<
  LinkProfileConfigurationFormProps,
  LinkProfileConfigurationFormState
> {
  private fetchPromises: CancelableFetchPromises = {};

  public state: LinkProfileConfigurationFormState = {
    loading: true,
  };

  get stores(): LinkProfileConfigurationFormPropsWithStores {
    return this.props as LinkProfileConfigurationFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: false });
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public onAutoTemplateGroupSelect = (event: SelectChangeEvent<unknown>) => {
    const { labelLinkStore, linkProfileStore } = this.stores;
    const groupNames = labelLinkStore.getGroupNames();

    const value = event.target.value as number;
    const autoTemplateGroup = value >= 0 ? groupNames[value] : undefined;

    linkProfileStore.setState({ autoTemplateGroup });
  };

  public render(): React.JSX.Element {
    const { errorCallback } = this.props;
    const { linkProfileStore, labelLinkStore, compartmentGeneratorStore } = this.stores;
    const {
      coordinate,
      autoTemplateGroup,
      compartmentSelector,
      multipleCompartmentSelection,
      removeObsoleteCompartments,
    } = linkProfileStore.state;

    const templateGroups = labelLinkStore.getGroupNames();
    const templateGroupOptions = templateGroups.map((groupName: string, index: number) => {
      const groupKey = `auto-template-option-${index}`;
      return (
        <MenuItem key={groupKey} value={index}>
          <ListItemText primary={groupName} />
        </MenuItem>
      );
    });

    return (
      <Fade in={true} timeout={1000}>
        <Grid container alignItems={'stretch'}>
          <Grid container item xs={12} spacing={1} alignItems={'stretch'} alignContent={'stretch'}>
            <Grid item xs={6}>
              <StyledSelectField
                label={'Automatic Template Group'}
                value={templateGroups.indexOf(autoTemplateGroup ?? '-')}
                onChange={this.onAutoTemplateGroupSelect}
              >
                <MenuItem value={-1}>
                  <ListItemText primary={'-'} />
                </MenuItem>
                {templateGroupOptions}
              </StyledSelectField>
            </Grid>
            {spacer(6)}
            <Grid item xs={6}>
              <CompartmentSelectorPicker
                coordinate={coordinate}
                selected={compartmentSelector as CompartmentSelectorIndex}
                onChange={(selector) => linkProfileStore.setState({ compartmentSelector: selector })}
                onError={() => {
                  enqueueSnackbar('No CompartmentSelectors found.');
                  errorCallback();
                }}
              />
            </Grid>
            {spacer(6)}
            <Grid item xs={6}>
              <Tooltip title="Allow selection when matching multiple compartments">
                <FormControlLabel
                  label="Multiple Compartment Selection"
                  control={
                    <Checkbox
                      checked={multipleCompartmentSelection}
                      onChange={(event) => {
                        linkProfileStore.setState({ multipleCompartmentSelection: event.target.checked });
                      }}
                    />
                  }
                  style={{ margin: 12 }}
                />
              </Tooltip>
            </Grid>
            {spacer(6)}
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Label Input Instructions'}
                tooltip={'Instruction text displayed when applying the profile.'}
                value={linkProfileStore.state.labelInputInstructions ?? ''}
                onChange={(e) => linkProfileStore.setState({ labelInputInstructions: e.target.value })}
              />
            </Grid>
            {spacer(6)}
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Compartment Input Instructions'}
                tooltip={'Instruction text displayed when applying the profile.'}
                value={linkProfileStore.state.compartmentInputInstructions ?? ''}
                onChange={(e) => linkProfileStore.setState({ compartmentInputInstructions: e.target.value })}
              />
            </Grid>
            {spacer(6)}
            <Grid item xs={6}>
              <Tooltip title={'Remove compartments left without linked labels'}>
                <FormControlLabel
                  label={'Remove Obsolete Compartments'}
                  control={
                    <Checkbox
                      checked={removeObsoleteCompartments}
                      onChange={() =>
                        linkProfileStore.setState({ removeObsoleteCompartments: !removeObsoleteCompartments }, false)
                      }
                    />
                  }
                  style={{ margin: 12 }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={'Create a compartment if the selector can not find one'}>
                <FormControlLabel
                  label={'Compartment Creation'}
                  control={
                    <Checkbox
                      checked={compartmentGeneratorStore.isEnabled}
                      onChange={() => compartmentGeneratorStore.toggleEnabled()}
                    />
                  }
                  style={{ margin: 12 }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {compartmentGeneratorStore.isEnabled && (
            <Grid item xs={11}>
              <CompartmentGeneratorForm />
            </Grid>
          )}
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(LinkProfileConfigurationFromComponent);

export const LinkProfileConfigurationForm = StyleWrapped;
