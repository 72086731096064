import { OptionsObject } from 'notistack';

export type EnqueueSnackbarFn = (message: string, options?: OptionsObject) => void;

export interface SuccessHandlerStatusMessages {
  [key: number]: string;
}

export const handleError = (enqueueSnackbar: EnqueueSnackbarFn, statusCodes?: number[], callback?: () => void) => (
  status: number,
  response: Response,
  json: { message: string },
): void => {
  if (statusCodes !== undefined) {
    if (statusCodes.indexOf(status) !== -1) {
      enqueueSnackbar(response.statusText + ': ' + json.message, { variant: 'error' });
    }
  } else {
    if (status >= 400) {
      enqueueSnackbar(response.statusText + ': ' + json.message, { variant: 'error' });
    } else if (status === undefined) {
      enqueueSnackbar('No response from server.', { variant: 'error' });
    }
  }

  if (callback !== undefined) {
    callback();
  }
};

export const handleSuccess = (
  enqueueSnackbar: EnqueueSnackbarFn,
  statusMessages?: SuccessHandlerStatusMessages,
  callback?: () => void,
) => ({ status }: Response): void => {
  if (statusMessages !== undefined && statusMessages[status] !== undefined) {
    enqueueSnackbar(statusMessages[status]);
  }

  if (callback !== undefined) {
    callback();
  }
};
