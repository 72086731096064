import { Grid, Hidden } from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { GridProps } from '@mui/material/Grid/Grid';
import { HiddenProps } from '@mui/material/Hidden';
import React from 'react';

export const spacer = (xs?: GridSize, gridProps?: GridProps): React.JSX.Element => (
  <Hidden lgDown>
    <Grid item xs={xs} {...gridProps}>
      {}
    </Grid>
  </Hidden>
);

export const spacerCustom = (hiddenProps: HiddenProps, gridProps: { [key: string]: GridSize }): React.JSX.Element => (
  <Hidden {...hiddenProps}>
    <Grid item {...gridProps}>
      {}
    </Grid>
  </Hidden>
);
