import {
  Action,
  ActionName,
  EkanbanTrigger,
  Finder,
  LinearStateMachineDefinition,
  ReplenishmentPlan,
  TriggerName,
} from '@ekkogmbh/apisdk';
import { Add, Delete, Edit, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { StyledFormHeader } from '../../Common/Components/Forms/StyledFormHeader';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { copy } from '../../Common/Helper/FormHelper';
import { ReplenishmentPlanStore } from '../Stores/ReplenishmentPlanStore';
import { FinderPicker } from 'src/FinderManagement/FinderPicker';
import { enqueueSnackbar } from 'notistack';

const styles = FormStyles;

interface ReplenishmentPlanPanelStores {
  api: ApiStore;
  replenishmentPlanStore: ReplenishmentPlanStore;
}

interface ReplenishmentPlanPanelState {
  loading: boolean;
  form: {
    index: number | null;
    state: string;
    trigger: EkanbanTrigger;
    action: Action;
    allFilled: boolean;
  };
}

export interface ReplenishmentPlanPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (replenishmentPlan: ReplenishmentPlan) => Promise<ReplenishmentPlan>;
}

const defaultFormState = {
  index: null,
  state: '',
  trigger: {
    name: TriggerName.BUTTON_PRESS,
    configuration: {},
  },
  action: {
    name: ActionName.NOOP,
  },
  allFilled: false,
};

@inject('api', 'replenishmentPlanStore')
@observer
class ReplenishmentPlanPanelComponent extends React.Component<
  ReplenishmentPlanPanelProps,
  ReplenishmentPlanPanelState
> {
  public state: ReplenishmentPlanPanelState = {
    loading: false,
    form: copy(defaultFormState),
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): ReplenishmentPlanPanelStores {
    return this.props as ReplenishmentPlanPanelProps & ReplenishmentPlanPanelStores;
  }

  public componentDidMount(): void {
    const { replenishmentPlanStore } = this.stores;
    const { editableReplenishmentPlan } = replenishmentPlanStore;

    if (editableReplenishmentPlan !== undefined) {
      replenishmentPlanStore.setEditableReplenishmentPlan(editableReplenishmentPlan);

      this.setState({
        form: copy(defaultFormState),
      });
    }
  }

  public componentWillUnmount(): void {
    const { replenishmentPlanStore } = this.stores;
    replenishmentPlanStore.resetStore();
    cancelFetchPromises(this.fetchPromises);
  }

  public handleReset = async () => {
    const { replenishmentPlanStore } = this.stores;
    const { editableReplenishmentPlan } = replenishmentPlanStore;

    if (editableReplenishmentPlan !== undefined) {
      replenishmentPlanStore.setEditableReplenishmentPlan(editableReplenishmentPlan);

      this.setState({
        form: copy(defaultFormState),
      });
    } else {
      this.setState(
        {
          form: copy(defaultFormState),
        },
        this.componentWillUnmount,
      );
    }
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { replenishmentPlanStore } = this.stores;

    const { name, coordinate, linearStateMachineDefinition } = replenishmentPlanStore.state;

    const payload = {
      name,
      coordinate,
      linearStateMachineDefinition,
    };

    this.setState({ loading: true }, async () => {
      await saveHandler(payload);
      this.handleReset();
      closeHandler();
    });
  };

  public validateForm = () => {
    const { form } = this.state;

    let allFilled = form.state !== '';

    switch (form.trigger.name) {
      case TriggerName.BUTTON_PRESS:
        allFilled =
          allFilled &&
          form.trigger.configuration.buttonIndex !== undefined &&
          form.trigger.configuration.buttonIndex !== '';
        break;
      case TriggerName.GEOFENCE:
        allFilled = allFilled && form.trigger.configuration.type !== undefined;
        break;
      default:
        break;
    }

    this.setState({
      form: {
        ...form,
        allFilled,
      },
    });
  };

  public onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form.state = e.target.value;
    this.setState({ form }, this.validateForm);
  };

  public onChangeTrigger = (e: SelectChangeEvent<unknown>) => {
    const { form } = this.state;
    form.trigger.name = e.target.value as TriggerName;
    form.trigger.configuration = {};

    switch (form.trigger.name) {
      case TriggerName.GEOFENCE:
        form.trigger.configuration.type = 'entry';
        break;
      default:
        break;
    }

    this.setState({ form }, this.validateForm);
  };

  public onChangeAction = (e: SelectChangeEvent<unknown>) => {
    const { form } = this.state;
    form.action.name = e.target.value as ActionName;
    this.setState({ form }, this.validateForm);
  };

  public onSaveState = () => {
    const { replenishmentPlanStore } = this.stores;
    const { form } = this.state;
    const { linearStateMachineDefinition } = replenishmentPlanStore.state;

    if (!form.allFilled) {
      return; // error message, toast, whatever?
    }

    if (form.index === null && !linearStateMachineDefinition.states.includes(form.state)) {
      linearStateMachineDefinition.states.push(form.state);
    } else if (form.index === null && linearStateMachineDefinition.states.includes(form.state)) {
      return; // dont save since state already exists and user wasnt editing this state? maybe show error?
    } else if (form.index !== null) {
      if (form.state !== linearStateMachineDefinition.states[form.index]) {
        const oldStateName = linearStateMachineDefinition.states[form.index];
        delete linearStateMachineDefinition.triggers[oldStateName];
        delete linearStateMachineDefinition.onEnterActions[oldStateName];
      }

      linearStateMachineDefinition.states[form.index] = form.state;
    }

    linearStateMachineDefinition.triggers[form.state] = {
      name: form.trigger.name,
      configuration: form.trigger.configuration,
    };
    linearStateMachineDefinition.onEnterActions[form.state] = { name: form.action.name };
    replenishmentPlanStore.setState({ linearStateMachineDefinition });

    this.setState({
      form: copy(defaultFormState),
    });
  };

  public onClickEditState = (index: number) => () => {
    const { replenishmentPlanStore } = this.stores;
    const { linearStateMachineDefinition } = replenishmentPlanStore.state;

    const state = linearStateMachineDefinition.states[index];
    const trigger = copy(linearStateMachineDefinition.triggers[state]);
    const onEnterAction = copy(linearStateMachineDefinition.onEnterActions[state]);

    this.setState({
      form: {
        index,
        state,
        trigger,
        action: onEnterAction,
        allFilled: true,
      },
    });
  };

  public onClickDeleteState = (index: number) => () => {
    const { replenishmentPlanStore } = this.stores;
    const { linearStateMachineDefinition } = replenishmentPlanStore.state;

    const state = linearStateMachineDefinition.states[index];

    linearStateMachineDefinition.states.splice(index, 1);

    delete linearStateMachineDefinition.triggers[state];
    delete linearStateMachineDefinition.onEnterActions[state];

    replenishmentPlanStore.setState({ linearStateMachineDefinition });
  };

  public prettifyString = (str: string): string =>
    str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  public renderState = (index: number, state: string, trigger: EkanbanTrigger, onEnterAction: Action) => {
    const { onClickEditState, onClickDeleteState } = this;

    return (
      <Box key={'lsmd-state-' + index} p={1}>
        <Card variant={'outlined'}>
          <CardHeader
            title={
              <Typography variant="h5" component="h2">
                {state}
              </Typography>
            }
            action={
              <>
                <IconButton aria-label="edit" onClick={onClickEditState(index)}>
                  <Settings />
                </IconButton>
                <IconButton aria-label="delete" onClick={onClickDeleteState(index)}>
                  <Delete />
                </IconButton>
              </>
            }
          />
          <CardContent>
            <Typography color="textSecondary" variant={'overline'}>
              Trigger
            </Typography>
            <Typography fontWeight={700}>{trigger.name}</Typography>
            <Box pl={4}>
              {Object.keys(trigger.configuration).map((key) => (
                <Typography key={key} color="textSecondary">
                  {key}: {trigger.configuration[key]}
                </Typography>
              ))}
            </Box>
            <Typography color="textSecondary" variant={'overline'}>
              Action
            </Typography>
            <Typography fontWeight={700}>{onEnterAction.name}</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  };

  public renderStates = (linearStateMachineDefinition: LinearStateMachineDefinition) => (
    <Stack spacing={2}>
      {linearStateMachineDefinition.states.map((state, index) =>
        this.renderState(
          index,
          state,
          linearStateMachineDefinition.triggers[state],
          linearStateMachineDefinition.onEnterActions[state],
        ),
      )}
    </Stack>
  );

  public renderFormTriggerConfiguration = (triggerName: TriggerName) => {
    const { replenishmentPlanStore } = this.stores;
    const { coordinate } = replenishmentPlanStore.state;
    const { form } = this.state;
    switch (triggerName) {
      case TriggerName.BUTTON_PRESS:
        const buttonFormStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          form.trigger.configuration.buttonIndex = e.target.value;
          this.setState({ form }, this.validateForm);
        };

        return (
          <div>
            <StyledTextField
              type="text"
              label="Button Index"
              value={form.trigger.configuration.buttonIndex ?? ''}
              onChange={buttonFormStateChange}
            />
          </div>
        );
      case TriggerName.HTTP:
        const httpFormStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          form.trigger.configuration = { key: e.target.value };
          this.setState({ form }, this.validateForm);
        };

        return (
          <div>
            <StyledTextField
              type="text"
              label="Key"
              value={form.trigger.configuration.key ?? ''}
              onChange={httpFormStateChange}
              tooltip={'Optional. Must be provided when triggering if set.'}
            />
          </div>
        );
      case TriggerName.FINDER:
        const { finderName, finderCoordinate } = form.trigger.configuration;
        const finderId =
          finderName && finderCoordinate
            ? {
                name: finderName,
                coordinate: finderCoordinate,
              }
            : undefined;
        return (
          <div>
            <FinderPicker
              coordinate={coordinate}
              selected={finderId}
              onChange={(finder: Finder | undefined) => {
                form.trigger.configuration = { name: finder!.name, coordinate: finder!.coordinate };
                this.setState({ form }, this.validateForm);
              }}
              onError={() => {
                enqueueSnackbar<'error'>('No finders available at given coordinate.');
                this.handleReset();
              }}
            />
          </div>
        );
      case TriggerName.GEOFENCE:
        const { fenceName, type } = form.trigger.configuration;

        return (
          <div>
            <StyledTextField
              type="text"
              label="Fence Name"
              value={fenceName ?? ''}
              onChange={(e) => {
                form.trigger.configuration.fenceName = e.target.value as string;
                this.setState({ form }, this.validateForm);
              }}
            />
            <StyledSelectField
              native
              label="Event Type"
              value={type}
              onChange={(e) => {
                form.trigger.configuration.type = e.target.value as string;
                this.setState({ form }, this.validateForm);
              }}
            >
              {['entry', 'exit'].map((eventType: string, index: number) => (
                <option value={eventType} key={index}>
                  {this.prettifyString(eventType)}
                </option>
              ))}
            </StyledSelectField>
          </div>
        );
      default:
        return null;
    }
  };

  public renderFormState = () => {
    const { form } = this.state;
    const { prettifyString, onChangeState, onChangeTrigger, onChangeAction, renderFormTriggerConfiguration } = this;

    return (
      <Stack spacing={2}>
        <StyledTextField type="text" label="Name" value={form.state} onChange={onChangeState} autoFocus={true} />
        <div>
          <StyledSelectField native value={form.trigger.name} onChange={onChangeTrigger} label="Trigger">
            {Object.keys(TriggerName).map((trigger) => (
              <option key={trigger} value={TriggerName[trigger]}>
                {prettifyString(TriggerName[trigger])}
              </option>
            ))}
          </StyledSelectField>
        </div>
        {renderFormTriggerConfiguration(form.trigger.name)}
        <div>
          <StyledSelectField native value={form.action} onChange={onChangeAction} label="Action">
            {Object.keys(ActionName).map((action) => (
              <option key={action} value={ActionName[action]}>
                {prettifyString(ActionName[action])}
              </option>
            ))}
          </StyledSelectField>
        </div>
      </Stack>
    );
  };

  public render() {
    const { closeHandler } = this.props;
    const { loading, form } = this.state;
    const { replenishmentPlanStore } = this.stores;
    const { coordinate, name, linearStateMachineDefinition, allFilled } = replenishmentPlanStore.state;
    const { editableReplenishmentPlan } = replenishmentPlanStore;

    const isEditState = form.index !== null;
    const formStateExists = linearStateMachineDefinition.states.includes(form.state);

    const coordinateSelectDisabled =
      editableReplenishmentPlan !== undefined || form.trigger.name === TriggerName.FINDER;
    const stateButtonDisabled = !form.allFilled || (formStateExists && !isEditState);

    const { onSaveState, renderStates, renderFormState } = this;

    const states = renderStates(linearStateMachineDefinition);

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} container spacing={2} alignItems={'stretch'} style={{ display: loading ? 'block' : 'none' }}>
          <Grid
            item
            xs={12}
            style={{
              height: 496,
              position: 'relative',
            }}
          >
            <div
              style={{
                top: '50%',
                marginTop: -48,
                position: 'absolute',
                width: '100%',
              }}
            >
              <CheckmarkSpinner complete={false} failure={false} />
            </div>
          </Grid>
        </Grid>

        {!loading && (
          <Grid item container mt={0} spacing={1} alignContent={'stretch'}>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <StyledFormHeader label={'Identifier'} />
                <StyledTextField
                  type="text"
                  label="Name"
                  value={name}
                  disabled={editableReplenishmentPlan !== undefined}
                  onChange={(e) => replenishmentPlanStore.setState({ name: e.target.value })}
                />
                <CoordinateInput
                  value={coordinate}
                  disabled={coordinateSelectDisabled}
                  onChange={(coordinate) => replenishmentPlanStore.setState({ coordinate })}
                  trailingDelimiter={false}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <StyledFormHeader label={(isEditState ? 'Edit' : 'Add') + ' State'} />
                {renderFormState()}
                <Grid container mt={0} spacing={0} alignContent={'stretch'} alignItems={'end'} alignSelf={'stretch'}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={onSaveState}
                      disabled={stateButtonDisabled}
                      style={{ margin: 8 }}
                    >
                      {isEditState ? <Edit /> : <Add />} {isEditState ? 'Edit' : 'Add'} State
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <StyledFormHeader label={'States'} />
                {linearStateMachineDefinition.states.length > 0 && states}
              </Stack>
            </Grid>
          </Grid>
        )}
        <FormPanelButtons
          cancelHandler={closeHandler}
          saveHandler={this.handleSave}
          resetHandler={this.handleReset}
          isDeleteHidden={true}
          isSaveDisabled={!allFilled}
          finished={loading}
        />
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(ReplenishmentPlanPanelComponent);

export const ReplenishmentPlanPanel = StyleWrapped;
