import { EslManagerPrivateRoute, HttpMethod, Pagination, PaginationResponse, SortedSide } from '@ekkogmbh/apisdk';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';
import { ReactSelectAsync, ReactSelectAsyncOption } from '../../Common/Components/ReactSelectAsync';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';

const stores = ['api'];

interface SortedSideSelectStores {
  api: ApiStore;
}

interface SortedSideSelectState {
  loading: boolean;
  value: ReactSelectAsyncOption<SortedSide> | null;
}

interface SortedSideSelectProps {
  label: string;
  handleValueChange: (value: ReactSelectAsyncOption<SortedSide>) => void;
  value: ReactSelectAsyncOption<SortedSide> | null;
  isDisabled?: boolean;
}

@inject(...stores)
class SortedSideSelectComponent extends Component<SortedSideSelectProps, SortedSideSelectState> {
  public state: SortedSideSelectState = {
    loading: false,
    value: null,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): SortedSideSelectStores {
    return this.props as SortedSideSelectProps & SortedSideSelectStores;
  }

  public static getDerivedStateFromProps(
    props: Readonly<SortedSideSelectProps>,
    state: SortedSideSelectState,
  ): Partial<SortedSideSelectState> | null {
    if (JSON.stringify(props.value) !== JSON.stringify(state.value)) {
      const value = props.value;
      return {
        value,
      };
    }

    return null;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchSuggestionSortedSides = async (
    nameFilter: string,
  ): Promise<Array<ReactSelectAsyncOption<SortedSide>>> => {
    const { api } = this.stores;

    const sortedSideMapper = (sortedSide: SortedSide): ReactSelectAsyncOption<SortedSide> => ({
      value: sortedSide,
      label: sortedSide.name,
    });

    const pagination: Pagination = {
      page: 1,
      limit: 500,
    };

    if (nameFilter) {
      pagination.filter = {};
      pagination.filter.name = nameFilter;
    }

    const promise = await request<PaginationResponse<SortedSide>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSortedSides(pagination),
      EslManagerPrivateRoute.SORTED_SIDES,
      HttpMethod.GET,
    );

    const data = await promise;

    return data.items!.map(sortedSideMapper);
  };

  public handleChangeSortedSide = async (
    selected: ValueType<OptionTypeBase>,
    _?: ActionMeta<OptionTypeBase>,
  ): Promise<void> => {
    const { handleValueChange } = this.props;

    this.setState(
      {
        loading: false,
      },
      () => handleValueChange(selected as ReactSelectAsyncOption<SortedSide>),
    );
  };

  public render(): React.JSX.Element {
    const { value } = this.state;

    const { label, isDisabled } = this.props;

    return (
      <ReactSelectAsync
        fetchSuggestions={this.fetchSuggestionSortedSides}
        label={label}
        handleChange={this.handleChangeSortedSide}
        value={value}
        placeholder={'Select Picking-Side'}
        isDisabled={isDisabled}
      />
    );
  }
}

export const SortedSideSelect = SortedSideSelectComponent;
