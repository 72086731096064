import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from 'src/Common/Helper/ThemeHelper';
import { ModulesBaseStyles } from '../../Common/Styles/ModulesBaseStyles';

export const YourProfileStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  ...ModulesBaseStyles(theme),
  root: {
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: spacing(theme) * 2,
    marginBottom: spacing(theme) * 4,
    paddingBottom: spacing(theme) * 4,
    position: 'relative',
  } as CSSProperties,
  profileText: {
    fontSize: 16,
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 18,
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    lineHeight: 'normal',
  },
  card: {
    maxWidth: 400,
    minWidth: 300,
  },
});
