import { OperationGroup } from '@ekkogmbh/apisdk';
import { Delete, Description } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { nodeSeparator } from '../../AreaManagement/Components/AreaManagementContent';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { Permissions } from '../../Common/Stores/ApiStore';
import { DataTableRecord } from '../../Common/Stores/DataTableStore';
import {
  OperationGroupsContentActionHandlers,
  OperationGroupsContentPropsWithStores,
  OperationGroupsContentState,
} from './OperationGroupsContent';

class OperationGroupDataTableActions extends DataTableActionsComponent<DataTableRecord<OperationGroup>> {}

const OperationGroupName = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (name: OperationGroup['name']): React.ReactNode => <div>{name}</div>,
  },
});

const OperationGroupNode = (): MUIDataTableColumnDef => ({
  label: 'Area',
  name: 'entry.nodeParts',
  options: {
    sort: false,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup['nodeParts']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{value.join(nodeSeparator)}</div>
    ),
  },
});

const OperationGroupDescription = (): MUIDataTableColumnDef => ({
  label: 'Description',
  name: 'entry.description',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup['description']): React.ReactNode => <div>{value}</div>,
  },
});

const OperationGroupState = (): MUIDataTableColumnDef => ({
  label: 'State',
  name: 'entry.state',
  options: {
    sort: false,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (state: OperationGroup['state']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{state}</div>
    ),
  },
});

const OperationGroupCreatedAt = (): MUIDataTableColumnDef => ({
  label: 'Created-At',
  name: 'entry.createdAt',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: OperationGroup['createdAt']): React.ReactNode => {
      const dateString = new Date(value).toLocaleDateString();
      const timeString = new Date(value).toLocaleTimeString();
      return (
        <div>
          {dateString} - {timeString}
        </div>
      );
    },
  },
});

const OperationGroupActions = (
  _: OperationGroupsContentState,
  propsWithStores: OperationGroupsContentPropsWithStores,
  actions: OperationGroupsContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (operationGroup: OperationGroup): React.ReactNode => {
      const hasWritePermission = propsWithStores.api.userHasPermissionOnAnyNode(Permissions.OPERATION_GROUPS_WRITE);

      const actionButtons: ActionButton<OperationGroup>[] = [
        {
          title: 'Details',
          onClick: actions.details,
          icon: Description,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <OperationGroupDataTableActions dataset={operationGroup} isProcessing={false} actionButtons={actionButtons} />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  OperationGroupsContentPropsWithStores,
  OperationGroupsContentState,
  OperationGroupsContentActionHandlers
>> = [
  OperationGroupName,
  OperationGroupNode,
  OperationGroupDescription,
  OperationGroupState,
  OperationGroupCreatedAt,
  OperationGroupActions,
];
