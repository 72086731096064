import { FieldInputTypeConfig, FieldInputTypeName } from '@ekkogmbh/apisdk';
import { ListItemText, MenuItem, Popover, PopoverProps, Stack } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import React from 'react';
import { ListForm } from 'src/Common/Components/Forms/ListForm';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface FieldInputTypePopoverState {
  currentConfig?: FieldInputTypeConfig;
  textInput: string;
}

interface FieldInputTypePopoverProps extends WithStyles<typeof styles> {
  initialConfig?: FieldInputTypeConfig;
  onChangeCallback: (typeConfig: FieldInputTypeConfig) => void;
  popoverProps: PopoverProps;
}

class FieldInputTypePopoverComponent extends React.Component<FieldInputTypePopoverProps, FieldInputTypePopoverState> {
  public state: FieldInputTypePopoverState = {
    textInput: '',
  };

  public async componentDidMount(): Promise<void> {
    const { initialConfig } = this.props;

    this.setState({ currentConfig: initialConfig ?? { name: FieldInputTypeName.ANY } });
  }

  public onChangeType(typeName: FieldInputTypeName) {
    const { onChangeCallback } = this.props;

    const newConfig = { name: typeName };

    switch (newConfig.name) {
      case FieldInputTypeName.DROPDOWN:
        newConfig['values'] = [];
        break;
      case FieldInputTypeName.VALIDATED:
        newConfig['regex'] = '/^.*$/';
        break;
    }

    this.setState({ textInput: '', currentConfig: newConfig }, () => onChangeCallback(newConfig));
  }

  public onChangeConfig(typeConfig: FieldInputTypeConfig) {
    const { onChangeCallback } = this.props;

    this.setState({ currentConfig: typeConfig }, () => onChangeCallback(typeConfig));
  }

  public renderTypeUI(): React.JSX.Element {
    const { currentConfig } = this.state;

    if (!currentConfig) {
      return <></>;
    }

    switch (currentConfig.name) {
      case FieldInputTypeName.VALIDATED:
        const regex = currentConfig.regex as string;

        return (
          <>
            <StyledFormHeader label={'Regex Configuration'} />
            <StyledTextField
              type={'text'}
              value={regex}
              label={'Pattern'}
              onChange={(e) => this.onChangeConfig({ ...currentConfig, regex: e.target.value as string })}
            />
          </>
        );

      case FieldInputTypeName.DROPDOWN:
        const values = currentConfig.values as string[];

        return (
          <>
            <StyledFormHeader label={'Selectable Values'} />
            <ListForm
              items={values}
              onChange={(items: string[]) => this.onChangeConfig({ ...currentConfig, values: items })}
            />
          </>
        );
      case FieldInputTypeName.ANY:
      default:
        return <></>;
    }
  }

  public render() {
    const { currentConfig } = this.state;
    const { popoverProps } = this.props;

    return (
      <Popover {...popoverProps}>
        <Stack
          spacing={1}
          marginLeft={2}
          marginRight={2}
          marginTop={0}
          marginBottom={1}
          justifyContent={'center'}
          alignItems={'stretch'}
        >
          <StyledFormHeader label={'Field Type Configuration'} />
          <StyledSelectField
            label={'Type'}
            value={currentConfig ? currentConfig.name : FieldInputTypeName.ANY}
            onChange={(e) => this.onChangeType(e.target.value as FieldInputTypeName)}
          >
            {Object.keys(FieldInputTypeName).map((typeName, idx) => (
              <MenuItem key={`fieldType-${idx}`} value={FieldInputTypeName[typeName]}>
                <ListItemText primary={typeName} />
              </MenuItem>
            ))}
          </StyledSelectField>
          {this.renderTypeUI()}
        </Stack>
      </Popover>
    );
  }
}

const StyleWrapped = withStyles(styles)(FieldInputTypePopoverComponent);

export const FieldInputTypePopover = StyleWrapped;
