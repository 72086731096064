import {
  DevicePool,
  DevicePoolIdentifier,
  EslManagerPublicRouteV2,
  HttpMethod,
  PaginationResponse,
} from '@ekkogmbh/apisdk';
import { ListItemText, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface DevicePoolPickerStores {
  api: ApiStore;
}

interface DevicePoolPickerProps extends WithStyles<typeof styles> {
  coordinate: string;
  selected?: DevicePoolIdentifier;
  optional?: boolean;
  onChange: (devicePool: DevicePoolIdentifier | undefined) => void;
  onError?: () => void;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
  label?: string;
}

interface DevicePoolPickerState {
  loading: boolean;
  failure: boolean;
  devicePools: (DevicePool | undefined)[];
}

@inject('api')
class DevicePoolPickerComponent extends Component<DevicePoolPickerProps, DevicePoolPickerState> {
  public state: DevicePoolPickerState = {
    loading: true,
    failure: false,
    devicePools: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): DevicePoolPickerStores {
    return this.props as DevicePoolPickerProps & DevicePoolPickerStores;
  }

  public componentDidMount(): void {
    this.setState({ loading: true }, this.fetchDevicePools);
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchDevicePools = async (): Promise<void> => {
    const { api } = this.stores;
    const { onError, onChange, optional, selected } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, devicePools: [] }, onError);
    };

    let devicePools: (DevicePool | undefined)[] = [];
    try {
      const devicePoolPagination = await request<PaginationResponse<DevicePool>>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getDevicePools({ page: 1, limit: 9999 }),
        EslManagerPublicRouteV2.DEVICE_POOLS,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );

      devicePools = devicePoolPagination.items ?? [];
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    if (optional) {
      devicePools.unshift(undefined);
    }

    if (devicePools.length > 0) {
      this.setState({ loading: false, devicePools });

      if (!selected && !optional) {
        onChange(devicePools[0]);
      }
    } else {
      onErrorCallback();
    }
  };

  private getListIndex = (devicePool?: DevicePoolIdentifier): number => {
    const { devicePools } = this.state;

    if (devicePool === undefined || (devicePool.name === '' && devicePool.coordinate === '')) {
      return 0;
    }

    return devicePools.findIndex(
      (pool: DevicePool | undefined) =>
        pool && pool.name === devicePool.name && pool.coordinate === devicePool.coordinate,
    );
  };

  private onChangePool = (event: SelectChangeEvent<unknown>): void => {
    const { onChange } = this.props;
    const { devicePools } = this.state;

    const value = event.target.value as number;

    onChange(devicePools[value]);
  };

  private createMenuItem = (index: number, devicePool?: DevicePool): React.JSX.Element => {
    const name = devicePool ? devicePool.name : '-';
    const coordinate = devicePool ? devicePool.coordinate : '';

    return (
      <MenuItem key={`device-pool-picker-item-${name}-${coordinate}`} value={index}>
        <ListItemText primary={name} secondary={coordinate} />
      </MenuItem>
    );
  };

  public render() {
    const { label, disabled, selected } = this.props;
    const { devicePools, loading, failure } = this.state;

    const entries = devicePools.map((devicePool: DevicePool | undefined, index: number) =>
      this.createMenuItem(index, devicePool),
    );

    const loadingItem = (
      <MenuItem key={`loading`} value={0}>
        <ListItemText primary={'Loading ...'} />
      </MenuItem>
    );

    const value = loading ? 0 : selected ? this.getListIndex(selected) : 0;

    return (
      <StyledSelectField
        value={value}
        label={label ?? 'Device Pool'}
        onChange={this.onChangePool}
        disabled={failure || loading || disabled}
      >
        {loading ? loadingItem : entries}
      </StyledSelectField>
    );
  }
}

const StyleWrapped = withStyles(styles)(DevicePoolPickerComponent);

export const DevicePoolPicker = StyleWrapped;
