import { Checkbox, Fade, FormControlLabel, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { spacer } from 'src/Common/Components/Forms/Spacer';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { UpdaterProfileStore } from '../Stores/UpdaterProfileStore';
import { CompartmentSelectorPicker } from 'src/CompartmentSelectorManagement/CompartmentSelectorPicker';
import { CompartmentSelectorIndex } from '@ekkogmbh/apisdk';

const styles = FormStyles;

interface UpdaterProfileCompartmentSelectionFormState {
  loading: boolean;
}

interface UpdaterProfileCompartmentSelectionFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface UpdaterProfileCompartmentSelectionFormStores {
  api: ApiStore;
  updaterProfileStore: UpdaterProfileStore;
}

type UpdaterProfileCompartmentSelectionFormPropsWithStores = UpdaterProfileCompartmentSelectionFormProps &
  UpdaterProfileCompartmentSelectionFormStores;

@inject('api', 'updaterProfileStore')
@observer
class UpdaterProfileCompartmentSelectionFromComponent extends Component<
  UpdaterProfileCompartmentSelectionFormProps,
  UpdaterProfileCompartmentSelectionFormState
> {
  private fetchPromises: CancelableFetchPromises = {};

  public state: UpdaterProfileCompartmentSelectionFormState = {
    loading: true,
  };

  get stores(): UpdaterProfileCompartmentSelectionFormPropsWithStores {
    return this.props as UpdaterProfileCompartmentSelectionFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: false });
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public render(): React.JSX.Element {
    const { errorCallback } = this.props;
    const { updaterProfileStore } = this.stores;
    const { compartmentSelector, multipleCompartmentSelection, coordinate } = updaterProfileStore.state;

    return (
      <Fade in={true} timeout={1000}>
        <Grid container alignItems={'stretch'}>
          <Grid container item xs={12} spacing={1} alignItems={'stretch'} alignContent={'stretch'}>
            <Grid item xs={12}>
              <CompartmentSelectorPicker
                coordinate={coordinate}
                selected={compartmentSelector as CompartmentSelectorIndex}
                onChange={(selector) => updaterProfileStore.setState({ compartmentSelector: selector })}
                onError={() => {
                  enqueueSnackbar('No Compartment Selectors found.');
                  errorCallback();
                }}
              />
            </Grid>
            {spacer(12)}
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Compartment Input Instructions'}
                tooltip={'Instruction text displayed when applying the profile.'}
                value={updaterProfileStore.state.compartmentInputInstructions ?? ''}
                onChange={(e) => updaterProfileStore.setState({ compartmentInputInstructions: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <Tooltip title="Allow selection when matching multiple compartments">
                <FormControlLabel
                  label="Multiple Compartment Selection"
                  control={
                    <Checkbox
                      checked={multipleCompartmentSelection}
                      onChange={(event) => {
                        updaterProfileStore.setState({ multipleCompartmentSelection: event.target.checked });
                      }}
                    />
                  }
                  style={{ margin: 12 }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(UpdaterProfileCompartmentSelectionFromComponent);

export const UpdaterProfileCompartmentSelectionForm = StyleWrapped;
