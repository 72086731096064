import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { ModulesBaseStyles } from '../../Common/Styles/ModulesBaseStyles';

export const LabelManagementStyles: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => ({
  ...ModulesBaseStyles(theme),
  // module specific css here
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionExpanded: {},
  identifier: {
    lineHeight: 'normal',
    fontWeight: 700,
    userSelect: 'text',
  },
});
