import { EventEmitter } from 'eventemitter3';
import { action, observable } from 'mobx';
import React from 'react';
import { Routes, MenuEntry, SubMenu, RouteShortDefinition } from 'src/Routes';
import { scrollTop } from '../Helper/ScrollTop';

export interface SubMenuItem {
  title: string;
  entries: RouteShortDefinition[];
}

export type NavigationMenuEntry = MenuEntry['link'];

export type NavigationSortedMenu = {
  title: SubMenu;
  entries: NavigationMenuEntry[];
};

export class NavigationStore extends EventEmitter {
  @observable
  public openSubMenues: string[] = [SubMenu.LABELING];

  @observable
  public open: boolean = false;

  @observable
  public clientHeight: number = 0;

  @observable
  private scrollRef: React.RefObject<HTMLDivElement> | null = null;

  @observable
  public isRestoring: boolean = true;

  @observable
  public menuSorting: NavigationSortedMenu[] = [];

  @observable
  public sortedMenuEntries: RouteShortDefinition[] = [];

  @observable
  public sortedSubMenus: SubMenuItem[] = [];

  private storageKeyMenu: string = 'ESLMANAGER_NAVIGATION_MENU';

  public constructor() {
    super();

    this.restoreSortedMenuSorting();
    this.initMenuSorting();
    this.storeSortedMenuSorting();
  }

  public getScrollComponent(): React.RefObject<HTMLDivElement> | null {
    return this.scrollRef;
  }

  @action
  public toggleSubMenu(menuName: string): void {
    const subMenuIndex = this.openSubMenues.indexOf(menuName);
    if (subMenuIndex >= 0) {
      this.openSubMenues.splice(subMenuIndex, 1);
    } else {
      this.openSubMenues.push(menuName);
    }
  }

  @action
  public drawerOpen(): void {
    this.open = true;
    this.emit('drawer-state-change');
  }

  @action
  public drawerClose(): void {
    this.open = false;
    this.emit('drawer-state-change');
  }

  public setScrollComponent(ref: React.RefObject<HTMLDivElement>): void {
    this.scrollRef = ref;
    this.emit('scroll-component-set');
  }

  public unsetScrollComponent(): void {
    this.scrollRef = null;
    this.emit('scroll-component-unset');
  }

  @action
  public scrollTop(duration: number = 1000, easing: string = 'easeOutQuart', callback?: () => void): void {
    if (!this.scrollRef) {
      return;
    }

    const element = this.scrollRef.current;

    if (element !== null) {
      scrollTop(element, duration, easing, callback);
    }

    return;
  }

  @action
  public restoreSortedMenuSorting = (): void => {
    const storedMenuSorting = localStorage.getItem(this.storageKeyMenu);

    if (storedMenuSorting) {
      this.menuSorting = JSON.parse(storedMenuSorting);
    }
  };

  @action
  public initMenuSorting = (): void => {
    const { menuSorting } = this;
    // fill menuSorting with all missing menus from Routes
    // fill entries with all missing entries from Routes
    Routes.forEach((route) => {
      if (route.menu) {
        const menuIndex = menuSorting.findIndex((menu) => menu.title === route.menu?.subMenu);
        if (menuIndex < 0) {
          menuSorting.push({
            title: route.menu.subMenu,
            entries: [route.menu.link],
          });
        } else {
          const entryIndex = menuSorting[menuIndex].entries.indexOf(route.menu.link);
          if (entryIndex < 0) {
            menuSorting[menuIndex].entries.push(route.menu.link);
          }
        }
      }
    });
    // remove all entries from menuSorting that are not in Routes
    menuSorting.forEach((menu) => {
      menu.entries = menu.entries.filter((entry) => {
        const routeIndex = Routes.findIndex((route) => route.menu?.link === entry);
        return routeIndex >= 0;
      });
    });
  };

  @action
  public applyPermissionsToMenuSorting = (linkPermissions: string[]): void => {
    // remove all entries from menuSorting that's link is not in linkPermissions
    const { menuSorting } = this;
    menuSorting.forEach((menu) => {
      menu.entries = menu.entries.filter((entry) => {
        const linkPermissionIndex = linkPermissions.indexOf(entry);
        return linkPermissionIndex >= 0;
      });
    });
    this.storeSortedMenuSorting();
  };

  @action
  public storeSortedMenuSorting = (): void => {
    localStorage.setItem(this.storageKeyMenu, JSON.stringify(this.menuSorting));
  };

  @action
  public applySortingToMenuEntries = (menuEntries: RouteShortDefinition[]): void => {
    // use menuSorting to sort menuEntries ignoring subMenus
    const { menuSorting } = this;
    const sortedMenuEntries: RouteShortDefinition[] = [];
    menuSorting.forEach((menu) => {
      menu.entries.forEach((entry) => {
        const entryIndex = menuEntries.findIndex((menuEntry) => menuEntry.menu?.link === entry);
        if (entryIndex >= 0) {
          sortedMenuEntries.push(menuEntries[entryIndex]);
        }
      });
    });
    this.sortedMenuEntries = sortedMenuEntries;
  };

  @action
  public applySortingToSubMenus = (subMenuItems: SubMenuItem[]): void => {
    // use menuSorting to sort subMenuItems and their entries
    const { menuSorting } = this;
    const sortedSubMenus: SubMenuItem[] = [];
    menuSorting.forEach((menu) => {
      const subMenuIndex = subMenuItems.findIndex((subMenu) => subMenu.title === menu.title);

      if (subMenuIndex >= 0) {
        const entries: RouteShortDefinition[] = [];
        menu.entries.forEach((entry) => {
          const entryIndex = subMenuItems[subMenuIndex].entries.findIndex(
            (subMenuEntry) => subMenuEntry.menu?.link === entry,
          );
          if (entryIndex >= 0) {
            entries.push(subMenuItems[subMenuIndex].entries[entryIndex]);
          }
        });
        sortedSubMenus.push({
          title: menu.title,
          entries,
        });
      }
    });

    this.sortedSubMenus = sortedSubMenus;
  };

  @action
  public moveSubMenuEntry = (subMenuTitle: string, from: number, to: number): void => {
    // use the subMenu title to move an entry
    const { menuSorting } = this;
    const menuIndex = menuSorting.findIndex((menu) => menu.title === subMenuTitle);
    if (menuIndex >= 0) {
      const [moved] = menuSorting[menuIndex].entries.splice(from, 1);
      menuSorting[menuIndex].entries.splice(to, 0, moved);
    }

    this.storeSortedMenuSorting();
    this.applySortingToMenuEntries(this.sortedMenuEntries);
    this.applySortingToSubMenus(this.sortedSubMenus);
  };
}
