import { LinearProgress } from '@mui/material';
import React from 'react';

export interface LoadingWrapperProps {
  loading: boolean;
  children?: React.ReactNode;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}
class LoadingWrapperComponent extends React.Component<LoadingWrapperProps> {
  public render(): React.JSX.Element {
    const { children, loading, forwardedRef, ...otherProps } = this.props;

    return (
      <div {...otherProps} ref={forwardedRef}>
        {loading && (
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: '100%',
            }}
          />
        )}
        {children}
      </div>
    );
  }
}

export default LoadingWrapperComponent;
