const easings = {
  linear(t: number) {
    return t;
  },
  easeInQuad(t: number) {
    return t * t;
  },
  easeOutQuad(t: number) {
    return t * (2 - t);
  },
  easeInOutQuad(t: number) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  easeInCubic(t: number) {
    return t * t * t;
  },
  easeOutCubic(t: number) {
    return --t * t * t + 1;
  },
  easeInOutCubic(t: number) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  easeInQuart(t: number) {
    return t * t * t * t;
  },
  easeOutQuart(t: number) {
    return 1 - --t * t * t * t;
  },
  easeInOutQuart(t: number) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  easeInQuint(t: number) {
    return t * t * t * t * t;
  },
  easeOutQuint(t: number) {
    return 1 + --t * t * t * t * t;
  },
  easeInOutQuint(t: number) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  },
};

export const scrollTop = (
  element: HTMLElement,
  duration: number = 1000,
  easing: string = 'easeOutQuart',
  callback?: () => void,
): void => {
  const start = element.scrollTop;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
  const destinationOffset = 0;

  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement!.clientHeight,
    document.documentElement!.scrollHeight,
    document.documentElement!.offsetHeight,
  );

  const windowHeight =
    window.innerHeight ||
    document.documentElement!.clientHeight ||
    document.getElementsByTagName('body')[0].clientHeight;

  const destinationOffsetToScroll = Math.round(
    documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset,
  );

  if (!('requestAnimationFrame' in window)) {
    element.scroll(element.scrollLeft, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, (now - startTime) / duration);
    const timeFunction = easings[easing](time);
    const nextPosition = Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start);

    element.scroll(element.scrollLeft, nextPosition);

    if (element.scrollTop === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
};
