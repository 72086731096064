import { PickingColor } from '@ekkogmbh/apisdk';
import { green, orange, red } from '@mui/material/colors';

const saturation = 400;

export const PickingColorsCSS: Record<PickingColor, string> = {
  GREEN: green[saturation],
  RED: red[saturation],
  YELLOW: orange[saturation],
};

export const PickingColors: PickingColor[] = Object.values(PickingColor);
