import { LinkSavePayload, LinkView, Template } from '@ekkogmbh/apisdk';
import { Fade, Grid, Hidden } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField/TextField';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, ClipboardEvent, Component } from 'react';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { CancelableFetchPromises, cancelFetchPromises, noop } from '../../Common/Helper/PromiseHelper';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { TemplatePicker } from 'src/TemplateManagement/TemplatePicker';
import { LinkStore } from 'src/CompartmentManagement/Stores/LinkStore';
import { TechnologyPicker } from 'src/Common/Components/Forms/TechnologyPicker';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['linkStore'];

type LinkPanelPropsWithStores = LinkPanelProps & LinkPanelStores;

interface LinkPanelStores {
  linkStore: LinkStore;
}

interface LinkPanelState {
  loading: boolean;
  renderInputElements: boolean;
}

export interface LinkPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (link: LinkSavePayload) => Promise<void>;
  deleteHandler: (link: LinkView) => Promise<void>;
}

@inject(...stores)
@observer
class LinkPanelComponent extends Component<LinkPanelProps, LinkPanelState> {
  public state: LinkPanelState = {
    loading: false,
    renderInputElements: true,
  };
  private progressCompletedCallback: () => void;
  private fetchPromises: CancelableFetchPromises = {};

  constructor(props: LinkPanelProps) {
    super(props);

    const { closeHandler } = this.props;

    this.progressCompletedCallback = () => {
      this.setState({ loading: false });
      closeHandler();
    };
  }

  get stores(): LinkPanelStores {
    return this.props as LinkPanelPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    this.setState(
      {
        loading: true,
      },
      async () => {
        this.setState({
          loading: false,
        });
      },
    );
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
    this.progressCompletedCallback = noop;
  }

  public resetState = () => {
    this.componentDidMount();
  };

  public onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.persist();
    const target = event.target as HTMLInputElement;
    setTimeout(() => this.handleChange(target.name, target.value), 500);
  };

  public onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    this.handleChange(name, value);
  };

  public handleChange = (name: string, value: string) => {
    const { linkStore } = this.stores;

    linkStore.setState({
      [name]: value,
    });
  };

  public onSave = async () => {
    const { linkStore } = this.stores;
    const { saveHandler } = this.props;

    this.setState({ loading: true });

    try {
      await saveHandler(linkStore.getSavableLink());
      this.progressCompletedCallback();
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  public onDelete = () => {
    const { linkStore } = this.stores;
    const { deleteHandler } = this.props;

    //FIXME: might need to pass progress callback
    deleteHandler(linkStore.editableLink!);
  };

  public render() {
    const { classes, closeHandler } = this.props;
    const { loading, renderInputElements } = this.state;
    const { linkStore } = this.stores;
    const { editableLink, isAllFilled } = linkStore;
    const { coordinate, labelId, templateName, pageNumber, technology } = linkStore.state;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item lg={4} md={6} xs={12}>
          {renderInputElements && (
            <React.Fragment>
              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'Coordinate'}
                  value={coordinate}
                  name={'identifier'}
                  disabled={true}
                  onChange={this.onChange}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                    },
                  }}
                />
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <TechnologyPicker
                  selected={technology}
                  onChange={(selection) => this.handleChange('technology', selection as string)}
                  disabled={!!editableLink}
                />
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'LabelId'}
                  value={labelId}
                  name={'labelId'}
                  disabled={!!editableLink}
                  onChange={this.onChange}
                  onPaste={this.onPaste}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                    },
                  }}
                />
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <TextField
                  label={'Page'}
                  value={pageNumber}
                  name={'pageNumber'}
                  disabled={!!editableLink}
                  type={'number'}
                  onChange={this.onChange}
                  onPaste={this.onPaste}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                    },
                    inputProps: {
                      min: 1,
                      max: 9,
                    },
                  }}
                />
              </Fade>

              <Fade in={true} timeout={fadeTimeout}>
                <TemplatePicker
                  linkableOnly
                  coordinate={coordinate}
                  selected={templateName}
                  onChangeCallback={(selection) => this.handleChange('templateName', (selection as Template).name)}
                  onErrorCallback={() => {
                    enqueueSnackbar('No linkable templates found.', { variant: 'error' });
                    closeHandler();
                  }}
                />
              </Fade>
            </React.Fragment>
          )}
        </Grid>

        <Hidden mdDown>
          <Grid item xs={6}>
            {}
          </Grid>
        </Hidden>

        <FormPanelButtons
          cancelHandler={this.onCancel}
          resetHandler={this.resetState}
          saveHandler={this.onSave}
          deleteHandler={this.onDelete}
          isSaveDisabled={!isAllFilled}
          isDeleteDisabled={!editableLink}
        />
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(LinkPanelComponent);

export const LinkPanel = StyleWrapped;
