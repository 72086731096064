import { action, observable } from 'mobx';

export interface LabelPageMapping {
  groupName: string;
  page: string;
}

export class LabelLinkStore {
  // starting point variable for resetting and change detection.
  public initialTemplateGroups: Record<string, Record<string, string>> = {};

  @observable
  public isAllFilled: boolean = false;

  @observable
  public templateGroups: Record<string, Record<string, string>> = {};

  @action
  public setTemplateGroup = (name: string, templatePageMapping: Record<string, string> = {}): void => {
    this.templateGroups = {
      [name]: templatePageMapping,
      ...this.templateGroups,
    };

    this.setAllFilled();
  };

  @action
  public deleteTemplateGroup = (name: string): void => {
    if (this.isExistingGroup(name)) {
      delete this.templateGroups[name];
      this.setAllFilled();
    }
  };

  @action
  resetTemplateGroups = (): void => {
    this.templateGroups = this.initialTemplateGroups;

    this.setAllFilled();
  };

  @action
  public setAllFilled = (): void => {
    const groupNames = this.getGroupNames();

    for (const groupName in this.getGroupNames()) {
      if (this.getPageIndices(groupName).length < 1) {
        this.isAllFilled = false;
        return;
      }
    }

    this.isAllFilled = groupNames.length > 0;
  };

  public resetStore = (): void => {
    this.initialTemplateGroups = {};
    this.resetTemplateGroups();
  };

  public setPage = (groupName: string, page: string, templateName: string): void => {
    if (!this.isExistingGroup(groupName)) {
      this.setTemplateGroup(groupName, { [page]: templateName });
    } else {
      const group = this.templateGroups[groupName];

      group[page] = templateName;

      this.setTemplateGroup(groupName, group);
    }

    this.setAllFilled();
  };

  public deletePage = (groupName: string, page: string): void => {
    if (this.isExistingGroup(groupName)) {
      const group = this.templateGroups[groupName];
      delete group[page];
      this.setTemplateGroup(groupName, group);
    }
  };

  public getPageIndices = (groupName: string): string[] => {
    if (this.isExistingGroup(groupName)) {
      return Object.keys(this.templateGroups[groupName]);
    }

    return [];
  };

  public getGroupNames = (): string[] => {
    const names = Object.keys(this.templateGroups);
    names.sort((a: string, b: string) => a.localeCompare(b));

    return names;
  };

  public getRemovedMappings = (): LabelPageMapping[] => {
    const initialGroups = Object.keys(this.initialTemplateGroups);

    const mappings: LabelPageMapping[] = [];

    initialGroups.forEach((groupName: string) => {
      const pages = Object.keys(this.initialTemplateGroups[groupName]);

      pages.forEach((page: string) => {
        try {
          const isDeleted = this.templateGroups[groupName][page] === undefined;
          if (isDeleted) {
            mappings.push({ groupName, page } as LabelPageMapping);
          }
        } catch {
          // assume its deleted if unable to access
          mappings.push({ groupName, page } as LabelPageMapping);
        }
      });
    });

    return mappings;
  };

  public getAddedMappings = (): LabelPageMapping[] => {
    const templateGroups = this.getGroupNames();

    const mappings: LabelPageMapping[] = [];

    templateGroups.forEach((groupName: string) => {
      const pages = this.getPageIndices(groupName);

      pages.forEach((page: string) => {
        try {
          const initialTemplate = this.initialTemplateGroups[groupName][page];
          const currentTemplate = this.templateGroups[groupName][page];

          const isNew = currentTemplate !== undefined && currentTemplate !== initialTemplate;

          if (isNew) {
            mappings.push({
              groupName,
              page,
            } as LabelPageMapping);
          }
        } catch {
          // if this mapping is not found in the initial set, its new.
          mappings.push({
            groupName,
            page,
          } as LabelPageMapping);
        }
      });
    });

    return mappings;
  };

  public hasChanged = (): boolean => JSON.stringify(this.initialTemplateGroups) !== JSON.stringify(this.templateGroups);

  private isExistingGroup = (groupName: string): boolean => this.getGroupNames().indexOf(groupName) !== -1;
}
