import { action, observable } from 'mobx';

export class NotificationStore {
  @observable
  public notifications: string[] = [];
  @observable
  public newNotifications: number = 0;

  @action
  public resetCounter(): void {
    this.newNotifications = 0;
  }

  @action
  public add(notification: string): void {
    this.notifications.push(notification);
    this.newNotifications++;
  }

  @action
  public remove(notification: string): void {
    const index = this.notifications.indexOf(notification);
    if (index >= 0) {
      this.notifications.splice(index, 1);
    }
  }
}
