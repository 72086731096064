import {
  EslManagerPrivateRoute,
  HttpMethod,
  Pagination,
  PaginationResponse,
  PickingArea,
  PickingAreaCreatePayload,
  PickingAreaUpdatePayload,
} from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from '../../Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { PickingAreaStore } from '../Stores/PickingAreaStore';
import { PickingStyles } from '../Styles/PickingStyles';
import { materialDatatableColumnDefinitions } from './PickingAreaDatatableColumnDefinitions';
import { PickingAreaPanel, PickingAreaPanelProps } from './PickingAreaPanel';
import React from 'react';

const styles = PickingStyles;

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore', 'pickingAreaStore'];

export interface PickingAreasContentActionHandlers {
  edit: (pickingArea: PickingArea) => void;
  delete: (pickingArea: PickingArea) => void;
}

export type PickingAreasContentPropsWithStores = PickingAreasContentProps & PickingAreasContentStores;

export interface PickingAreasContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  pickingAreaStore: PickingAreaStore;
}

export interface PickingAreasContentActions {
  updatePickingArea?: (pickingArea: PickingArea) => void;
}

export interface PickingAreasContentState {
  editablePickingArea?: PickingArea;
  deleteDialogOpen: boolean;
}

export interface PickingAreasContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

@inject(...stores)
class PickingAreasContentComponent extends Component<PickingAreasContentProps, PickingAreasContentState> {
  public state: PickingAreasContentState = {
    deleteDialogOpen: false,
  };
  private readonly actions: PickingAreasContentActions = {};
  private readonly filterFields: DataTableFilterFields<PickingArea> = ['id', 'type', 'requiredSide', 'optionalSide'];
  private readonly sortFieldMap: DataTableSortFieldMap<PickingArea> = {
    id: 'PA.externalIdentifier',
    type: 'PA.type',
    requiredSide: 'RS.name',
    optionalSide: 'OS.name',
  };
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Picking-Area updated.',
    201: 'Picking-Area created.',
    204: 'Picking-Area deleted.',
  };

  get stores(): PickingAreasContentStores {
    return this.props as PickingAreasContentPropsWithStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public addPickingArea = async (pickingArea: PickingAreaCreatePayload): Promise<PickingArea> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<PickingArea>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addPickingArea(pickingArea),
      EslManagerPrivateRoute.PICKING_AREAS,
      HttpMethod.POST,
      this.successStatusCodes,
      successCallback,
    );
  };

  public updatePickingArea = async (pickingArea: PickingAreaUpdatePayload): Promise<PickingArea> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<PickingArea>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.updatePickingArea(pickingArea),
      EslManagerPrivateRoute.PICKING_AREA,
      HttpMethod.POST,
      this.successStatusCodes,
      successCallback,
    );
  };

  public deletePickingArea = async (pickingArea: PickingArea): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deletePickingArea(pickingArea),
      EslManagerPrivateRoute.PICKING_AREA,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public fetchAreas = async (pagination: Pagination): Promise<PaginationResponse<PickingArea>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<PickingArea>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPickingAreas(pagination),
      EslManagerPrivateRoute.PICKING_AREAS,
      HttpMethod.GET,
    );
  };

  public createContentPanels = (): ContentPanelDefinition[] => {
    const { pickingAreaStore } = this.stores;
    const { editablePickingArea } = this.state;
    const { addPickingArea, updatePickingArea } = this;

    const closeCallback = () => {
      this.setState({ editablePickingArea: undefined });
      pickingAreaStore.resetStore();
    };

    const addPickingAreaPanelDefinition: ContentPanelDefinition<Omit<PickingAreaPanelProps, 'classes'>> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: PickingAreaPanel,
      panelProps: {
        saveHandler: addPickingArea,
        updateHandler: updatePickingArea,
        closeHandler: closeCallback,
        closeCallback,
        pickingArea: editablePickingArea,
      },
      permission: Permissions.PICKING_AREAS_WRITE,
      toggleOffCallback: closeCallback,
      expandHandler: (expandCallback: () => void) => {
        const { actions } = this;

        if (actions.updatePickingArea === undefined) {
          actions.updatePickingArea = (pickingArea: PickingArea) => {
            this.setState({ editablePickingArea: pickingArea });
            expandCallback();
          };
        }
      },
    };

    return [addPickingAreaPanelDefinition];
  };

  public openEditPanel = async (pickingArea: PickingArea): Promise<void> => {
    const { updatePickingArea } = this.actions;

    if (updatePickingArea) {
      updatePickingArea(pickingArea);
    }
  };

  public openDeleteDialog = async (pickingArea: PickingArea): Promise<void> => {
    this.setState({
      editablePickingArea: pickingArea,
      deleteDialogOpen: true,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      editablePickingArea: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { editablePickingArea } = this.state;

    if (!editablePickingArea || !editablePickingArea.id) {
      return;
    }

    await this.deletePickingArea(editablePickingArea);

    this.setState({
      editablePickingArea: undefined,
      deleteDialogOpen: false,
    });
  };

  public render() {
    const { deleteDialogOpen, editablePickingArea } = this.state;
    const { classes } = this.props;
    const { fetchAreas } = this;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PickingAreasContentPropsWithStores, {
        delete: this.openDeleteDialog,
        edit: this.openEditPanel,
      }),
    );

    const contentPanels = this.createContentPanels();

    const deleteDialogText =
      deleteDialogOpen && editablePickingArea ? (
        <React.Fragment>
          <div>
            Delete Picking-Area: <span className={classes.boldFont}>{editablePickingArea.id}</span>
          </div>
          <div>
            with Required Side: <span className={classes.boldFont}>{editablePickingArea.requiredSide.name}</span>?
          </div>
        </React.Fragment>
      ) : (
        ''
      );

    return (
      <Grid item xs={12}>
        {deleteDialogOpen && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={deleteDialogOpen}
            title={'Delete Picking-Area'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        <ContentPanels panels={contentPanels} />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={fetchAreas}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<PickingAreasContentProps, typeof PickingAreasContentComponent>(
  PickingAreasContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PickingAreasContent = StyleWrapped;
