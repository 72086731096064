import { Authentication, AuthMechanism, Credentials } from '@ekkogmbh/apisdk';

export class OAuth2AuthMechanism implements AuthMechanism {
  public authentication: Authentication = { isAuthenticated: false };
  private e2eInitialized: boolean = false;
  private callback?: () => Promise<void>;

  constructor(private e2e: E2E, private e2eSettings: E2ESettings) {}

  public async authenticate(_?: Credentials, callback?: () => Promise<void>): Promise<void> {
    this.callback = callback;

    if (!this.e2eInitialized) {
      this.e2e.init(this.e2eSettings, this.e2eInitCallback);
    } else {
      this.e2e.fetchToken({}, undefined);
    }
  }

  public async invalidateAuthentication(): Promise<void> {
    this.authentication.authToken = undefined;
    this.authentication.user = undefined;
    this.authentication.isAuthenticated = false;

    this.e2e.invalidateToken();
  }

  protected e2eInitCallback = async (status: { code: number }): Promise<void> => {
    this.e2eInitialized = true;

    switch (status.code) {
      case 1:
        // Token successfully acquired and stored in E2E.token.access_token | its details in E2E.token
        this.authentication.authToken = { token: this.e2e.token.access_token, refresh_token: '' };
        this.authentication.isAuthenticated = true;
        break;

      case 2:
      // Couldn't acquire token via cookie and credentials weren't passed. Use E2E.fetchToken({username:'username', password:'password'});
      /*eslint no-fallthrough: 0*/
      case 3:
        // Server Error: Either bad endpoint configuration or wrong username and password provided - ask again and E2E.fetchToken({username:'username', password:'password'});
        // More details in status.message
        this.e2e.fetchToken({}, undefined);
        break;

      case 4:
        // Connection Error: Server not responding or refused connection...
        break;

      default:
    }

    if (this.callback !== undefined) {
      await this.callback();
    }
  };
}
