import { FormControl, InputLabel, MenuProps, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React, { Component, ReactNode } from 'react';
import { FormStyles } from '../../Styles/FormStyles';

const styles = FormStyles;

interface StyledSelectFieldProps extends WithStyles<typeof styles> {
  label?: string;
  value: SelectProps['value'];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  onClose?: (event: React.SyntheticEvent) => void;
  onOpen?: (event: React.SyntheticEvent) => void;
  open?: boolean;
  native?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
  endAdornment?: SelectProps['endAdornment'];
  children?: ReactNode;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
  MenuProps?: Partial<MenuProps>;
  style?: React.CSSProperties;
  parentStyle?: React.CSSProperties;
  variant?: SelectProps['variant'];
  disableUnderline?: boolean;
}

class StyledSelectFieldComponent extends Component<StyledSelectFieldProps> {
  public render() {
    const {
      classes,
      label,
      value,
      disabled,
      multiple,
      onChange,
      onClose,
      onOpen,
      open,
      native,
      children,
      renderValue,
      endAdornment,
      forwardedRef,
      MenuProps,
      style,
      parentStyle,
      variant,
      disableUnderline,
    } = this.props;

    const dynamicProps: Partial<SelectProps> = {};
    if (!variant || variant === 'outlined') {
      dynamicProps.input = <OutlinedInput label={label} />;
    }

    if (disableUnderline) {
      dynamicProps.disableUnderline = true;
    }

    return (
      <FormControl variant="outlined" className={classes.margin} style={parentStyle} ref={forwardedRef}>
        {!!label && <InputLabel id={'outlined-' + label}>{label}</InputLabel>}
        <Select
          variant="standard"
          labelId={!!label ? 'outlined-' + label : undefined}
          value={value}
          onChange={onChange}
          onClose={onClose}
          onOpen={onOpen}
          open={open}
          native={native}
          multiple={multiple}
          disabled={disabled}
          renderValue={renderValue}
          endAdornment={endAdornment}
          MenuProps={MenuProps}
          style={style}
          {...dynamicProps}
        >
          {children}
        </Select>
      </FormControl>
    );
  }
}

const StyleWrapped = withStyles(styles)(StyledSelectFieldComponent);

// eslint-disable-next-line react/display-name
export const StyledSelectField = React.forwardRef<HTMLDivElement, Omit<StyledSelectFieldProps, 'classes'>>(
  (props, ref) => <StyleWrapped {...props} forwardedRef={ref} />,
);
