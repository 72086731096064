import { Typography } from '@mui/material';
import React, { Component } from 'react';

interface StyleFormHeaderProps {
  label: string;
}

export class StyledFormHeader extends Component<StyleFormHeaderProps> {
  public render(): React.JSX.Element {
    const { label } = this.props;

    return (
      <Typography
        variant="overline"
        color="primary"
        style={{ display: 'inline-block', fontWeight: 700, textAlign: 'center', width: '100%' }}
      >
        {label}
      </Typography>
    );
  }
}
