import {
  CompartmentSelector,
  CompartmentView,
  EslManagerPublicRouteV1,
  HttpMethod,
  Pagination,
  PaginationResponse,
} from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { CompartmentSelectorStore } from '../Stores/CompartmentSelectorStore';
import { CompartmentSelectorManagementStyles } from '../Styles/CompartmentSelectorManagementStyles';
import { materialDatatableColumnDefinitions } from './CompartmentSelectorDatatableColumnDefinitions';
import { CompartmentSelectorPanel, CompartmentSelectorPanelProps } from './CompartmentSelectorPanel';
import React from 'react';
import { RunCompartmentSelectorResult } from './RunComartmentSelectorResult';

const styles = CompartmentSelectorManagementStyles;

const stores = ['api', 'compartmentSelectorStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

interface CompartmentSelectorManagementContentActions {
  updateCompartmentSelector?: (compartmentSelector: CompartmentSelector) => void;
}

export interface CompartmentSelectorManagementContentActionHandlers {
  edit: (compartmentSelector: CompartmentSelector) => void;
  delete: (compartmentSelector: CompartmentSelector) => void;
  run: (compartmentSelector: CompartmentSelector) => void;
}

export interface CompartmentSelectorManagementContentStores {
  api: ApiStore;
  compartmentSelectorStore: CompartmentSelectorStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface CompartmentSelectorManagementContentState {
  deletableCompartmentSelector?: CompartmentSelector;
  runnableCompartmentSelector?: {
    selector: CompartmentSelector;
    resultText?: React.ReactNode;
    spinner?: boolean;
  };
  matchedCompartments?: CompartmentView[];
}

export interface CompartmentSelectorManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type CompartmentSelectorManagementContentPropsWithStores = CompartmentSelectorManagementContentProps &
  CompartmentSelectorManagementContentStores;

@inject(...stores)
class CompartmentSelectorManagementContentComponent extends Component<
  CompartmentSelectorManagementContentProps,
  CompartmentSelectorManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<CompartmentSelector> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<CompartmentSelector> = { name: 'CS.name' };
  private readonly actions: CompartmentSelectorManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): CompartmentSelectorManagementContentStores {
    return this.props as CompartmentSelectorManagementContentPropsWithStores;
  }

  public state: CompartmentSelectorManagementContentState = {};

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchCompartmentSelectors = async (
    pagination: Pagination,
  ): Promise<PaginationResponse<CompartmentSelector>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<CompartmentSelector>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getCompartmentSelectors(pagination),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTORS,
      HttpMethod.GET,
    );
  };

  public onDelete = async (compartmentSelector: CompartmentSelector): Promise<void> => {
    this.setState((state, _) => ({ ...state, deletableCompartmentSelector: compartmentSelector }));
  };

  public onDeleteDismiss = async () => {
    this.setState((state, _) => ({ ...state, deletableCompartmentSelector: undefined }));
  };

  public onDeleteConfirm = async () => {
    const { deletableCompartmentSelector } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableCompartmentSelector) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteCompartmentSelector(deletableCompartmentSelector.coordinate, deletableCompartmentSelector.name),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
      HttpMethod.DELETE,
      { 200: 'Compartment Selector deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public renderCompartments = (compartments: CompartmentView[]): React.JSX.Element => (
    <MUIDataTable
      title={''}
      data={compartments.map((c) => ({
        coordinate: c.coordinate,
        links: c.links.map((l) => l.labelId).join(', '),
      }))}
      columns={[
        { label: `Coordinate`, name: 'coordinate' },
        { label: 'Linked Label Ids', name: 'links' },
      ]}
      options={{ responsive: 'standard', selectableRows: 'none' }}
    />
  );

  public onRunSubmit = async (value: string) => {
    const { api } = this.stores;
    const selector = this.state.runnableCompartmentSelector?.selector;
    if (selector) {
      this.setState((state) => ({ ...state, runnableCompartmentSelector: { selector, spinner: true } }));
      await request<CompartmentView[]>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.runCompartmentSelector(selector.coordinate, selector.name, value),
        EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
        HttpMethod.POST,
        { 200: 'Run compartment selector.' },
      ).then((compartments: CompartmentView[]) => {
        const resultText = (
          <RunCompartmentSelectorResult
            compartments={compartments}
            showResultsHandler={() =>
              this.setState((state) => ({
                ...state,
                matchedCompartments: compartments,
              }))
            }
          />
        );

        this.setState((state) => ({
          ...state,
          runnableCompartmentSelector: { selector, resultText, spinner: false },
        }));
      });
    }
  };

  public onEdit = async (compartmentSelector: CompartmentSelector): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateCompartmentSelector } = this.actions;

    if (updateCompartmentSelector) {
      updateCompartmentSelector(compartmentSelector);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (compartmentSelector: CompartmentSelector): Promise<CompartmentSelector> => {
    const { api, navigationStore, searchContentStore, compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;

    const doOverwrite = editableCompartmentSelector !== undefined;

    const responseSelector = await request<CompartmentSelector>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveCompartmentSelector(compartmentSelector, doOverwrite),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
      HttpMethod.PUT,
      { 200: 'Compartment Selector saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return responseSelector;
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { compartmentSelectorStore } = this.stores;

    const addPanelDefinition: ContentPanelDefinition<CompartmentSelectorPanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: CompartmentSelectorPanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.COMPARTMENT_SELECTORS_WRITE,
      toggleOffCallback: () => compartmentSelectorStore.resetStore(),
      expandHandler: (expandCallback: () => void) => {
        const { updateCompartmentSelector } = this.actions;
        if (updateCompartmentSelector === undefined) {
          this.actions.updateCompartmentSelector = async (compartmentSelector: CompartmentSelector) => {
            expandCallback();
            compartmentSelectorStore.setEditableCompartmentSelector(compartmentSelector);
          };
        }
      },
    };

    return [addPanelDefinition];
  };

  public render() {
    const { deletableCompartmentSelector, runnableCompartmentSelector, matchedCompartments } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as CompartmentSelectorManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
        run: (selector) => this.setState((state, _) => ({ ...state, runnableCompartmentSelector: { selector } })),
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />

        {deletableCompartmentSelector && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableCompartmentSelector}
            title={'Delete Compartment Selector'}
            text={deletableCompartmentSelector.name}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteConfirm}
          />
        )}

        {runnableCompartmentSelector && (
          <GenericDialog
            type="input"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!runnableCompartmentSelector}
            title={'Test Compartment Selector'}
            text={runnableCompartmentSelector.resultText ?? runnableCompartmentSelector.selector.name}
            onClose={() => this.setState((state, _) => ({ ...state, runnableCompartmentSelector: undefined }))}
            onSubmit={this.onRunSubmit}
            buttonText={{ cancel: 'Close', ok: 'Search' }}
            checkmarkSpinner={runnableCompartmentSelector.spinner}
          />
        )}

        {matchedCompartments && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!matchedCompartments}
            title={'Found compartments'}
            text={this.renderCompartments(matchedCompartments)}
            onClose={() => this.setState((state) => ({ ...state, matchedCompartments: undefined }))}
            onConfirm={() => this.setState((state) => ({ ...state, matchedCompartments: undefined }))}
            buttonText={{ cancel: 'Close', ok: 'OK' }}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchCompartmentSelectors}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'asc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<
  CompartmentSelectorManagementContentProps,
  typeof CompartmentSelectorManagementContentComponent
>(CompartmentSelectorManagementContentComponent);

const StyleWrapped = withStyles(styles)(RouterWrapped);

export const CompartmentSelectorManagementContent = StyleWrapped;
