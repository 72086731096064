import { ReceivingArea, SaveReceivingAreaPayload } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export type ReceivingAreaState = Partial<ReceivingArea> & {
  allFilled: boolean;
};

export class ReceivingAreaStore {
  @observable
  public editableReceivingArea?: ReceivingArea;

  @observable
  public state: ReceivingAreaState = {
    name: '',
    coordinate: '',
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<ReceivingAreaState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.state.allFilled =
      this.state.coordinate !== '' && this.state.name !== '' && this.state.replenishmentPlan !== undefined;
  };

  @action
  public resetStore = (receivingArea?: ReceivingArea): void => {
    if (receivingArea !== undefined) {
      this.setState(receivingArea);
    } else {
      this.setState({
        name: '',
        coordinate: '',
        replenishmentPlan: undefined,
      });
    }

    this.editableReceivingArea = receivingArea;
  };

  public getPayload = (): SaveReceivingAreaPayload => ({
    replenishmentPlan: this.state.replenishmentPlan!,
  });
}
