import {
  AutocompleteRenderInputParams,
  BaseTextFieldProps,
  StandardTextFieldProps,
  StyledComponentProps,
  TextField,
  Tooltip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React, { ChangeEvent, Component } from 'react';
import { FormStyles } from '../../Styles/FormStyles';

const styles = FormStyles;

interface StyledTextFieldProps extends WithStyles<typeof styles> {
  label: string;
  numeric?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  tooltip?: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
}

class StyledTextFieldComponent extends Component<
  BaseTextFieldProps & StyledTextFieldProps & Partial<AutocompleteRenderInputParams>
> {
  public textField = (): React.JSX.Element => {
    const {
      classes,
      type,
      label,
      value,
      disabled,
      onChange,
      numeric,
      error,
      helperText,
      inputProps,
      endAdornment,
      startAdornment,
      ...remainingProps
    } = this.props;

    // don't pass unknown props to MUI TextField
    delete remainingProps.tooltip;

    // make sure not to override passed InputProps, since it breaks rendering of Autocomplete options
    const InputProps: Omit<AutocompleteRenderInputParams['InputProps'], 'ref' | 'className'> &
      StyledComponentProps = Object.keys(remainingProps).includes('InputProps')
      ? (remainingProps as { InputProps: AutocompleteRenderInputParams['InputProps'] }).InputProps
      : {
          classes: {
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled,
          },
          endAdornment,
          startAdornment,
        };

    // enforce digit-only input
    const numericInputProps: StandardTextFieldProps['inputProps'] = numeric
      ? { inputMode: 'numeric', pattern: '[0-9]*' }
      : undefined;

    return (
      <TextField
        {...remainingProps}
        type={type}
        label={label}
        value={value}
        disabled={disabled}
        onChange={onChange}
        variant="outlined"
        className={classes.margin}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.focused,
          },
        }}
        InputProps={InputProps}
        inputProps={inputProps ?? numericInputProps}
        error={error}
        helperText={helperText}
      />
    );
  };

  public render() {
    const { tooltip } = this.props;

    return (
      <div>
        {tooltip && tooltip !== '' && (
          <Tooltip title={tooltip} arrow={true}>
            {this.textField()}
          </Tooltip>
        )}
        {!tooltip && this.textField()}
      </div>
    );
  }
}

const StyleWrapped = withStyles(styles)(StyledTextFieldComponent);

export const StyledTextField = StyleWrapped;
