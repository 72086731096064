import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { CsvDropzoneStore } from '../Common/Stores/CsvDropzoneStore';
import { TitleProps } from '../Routes';
import { OperationGroupsContent } from './Components/OperationGroupsContent';
import { OperationGroupStore } from './Stores/OperationGroupStore';

const csvDropzoneStore = new CsvDropzoneStore();
const operationGroupStore = new OperationGroupStore();

const stores = {
  csvDropzoneStore,
  operationGroupStore,
};

export const OperationGroups = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <OperationGroupsContent />
    </Page>
  </Provider>
);
