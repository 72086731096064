export interface Config {
  api: {
    /**
     * env.AUTH_MECHANISM
     */
    authMechanism: string;
    /**
     * env.BACKEND_URL
     */
    url: string;
    autoRefresh: boolean;
  };
  e2eSettings: E2ESettings;
  /**
   * env.FAQ_URL
   */
  faqUrl?: string;
  /**
   * env.IMPORT_DIFF_MODAL
   * IMPORT_DIFF_MODAL > 0: show modal and n seconds until auto execute
   * IMPORT_DIFF_MODAL = -1: show modal no auto execute
   * IMPORT_DIFF_MODAL = 0 or empty/not existent: no modal
   */
  importDiffModal: number | boolean;
  availableTemplates: string[];
  coordinateFieldName: string;
  labelIdFieldName: string;
  acceptedTemplateFileTypes: string[];
  exportFormatTypes: string[];
  ackByButtonTopics: string[];
  buttonConfirmEventTopics: string[];
}

const fetchConfig = async (): Promise<Config> => {
  const headers = new Headers();
  headers.append('pragma', 'no-cache');
  headers.append('cache-control', 'no-cache');

  const response = await fetch('/env.json', {
    method: 'GET',
    headers,
  });

  const env = await response.json();

  const environment = env.BUILD_ENVIRONMENT || 'development';
  const apiUrl = (env.BACKEND_URL as string) || 'http://service-php-eslmanager.default.minikube.dev.ekko.io';
  const authAutoRefresh = env.AUTH_AUTO_REFRESH === 'true';
  const authMechanism = (env.AUTH_MECHANISM as string) || 'jwt';
  const e2eClientId = env.OAUTH_CLIENT_ID as string;
  const e2eClientSecret = env.OAUTH_CLIENT_SECRET as string;
  const e2eUrl = env.OAUTH_URL as string;
  const e2eRealmPath = env.OAUTH_REALM_PATH as string;
  const e2eRedirectUri = env.OAUTH_REDIRECT_URI as string;
  const labelTemplates = env.LABEL_TEMPLATES as string;
  const faqUrl = env.FAQ_URL;
  const importDiffModal = env.IMPORT_DIFF_MODAL ? parseInt(env.IMPORT_DIFF_MODAL, 10) : false;

  const availableTemplates = labelTemplates.split(',');
  const coordinateFieldName = env.APPLIANCE_COORDINATE_FIELD_NAME ?? 'Koordinate';
  const labelIdFieldName = env.APPLIANCE_LABELID_FIELD_NAME ?? 'labelid';
  let ackByButtonTopics = [];
  if (env.ACK_BY_BUTTON_TOPICS) {
    ackByButtonTopics = env.ACK_BY_BUTTON_TOPICS.split(',');
  }
  let buttonConfirmEventTopics = [];
  if (env.BUTTON_CONFIRM_EVENT_TOPICS) {
    buttonConfirmEventTopics = env.BUTTON_CONFIRM_EVENT_TOPICS.split(',');
  }

  const exportFormats = env.EXPORT_FORMATS ?? 'csv';
  const exportFormatTypes = exportFormats.split(',');

  const e2eWEBEAM = {
    URL: e2eUrl,
    CLIENT_ID: e2eClientId,
    CLIENT_SECRET: e2eClientSecret,
    redirectUri: e2eRedirectUri,
    realmPath: e2eRealmPath,
  };
  let acceptedTemplateFileTypes: string[] = [];
  if (env.ACCEPTED_TEMPLATE_FILE_TYPES !== undefined && typeof env.ACCEPTED_TEMPLATE_FILE_TYPES === 'string') {
    acceptedTemplateFileTypes = env.ACCEPTED_TEMPLATE_FILE_TYPES.split(/,\s*/);
  }

  const dev = {
    api: {
      url: apiUrl,
      authMechanism,
      autoRefresh: authAutoRefresh,
    },
    e2eSettings: {
      service: 'WEBEAM',
      log: true,
      WEBEAM: {
        ...e2eWEBEAM,
      },
    },
    availableTemplates,
    faqUrl,
    importDiffModal,
    coordinateFieldName,
    labelIdFieldName,
    acceptedTemplateFileTypes,
    exportFormatTypes,
    ackByButtonTopics,
    buttonConfirmEventTopics,
  };
  const testing = {
    api: {
      url: 'http://localhost:10000/backend',
      authMechanism,
      autoRefresh: authAutoRefresh,
    },
    e2eSettings: {
      service: 'WEBEAM',
      log: true,
      WEBEAM: {
        ...e2eWEBEAM,
      },
    },
    availableTemplates,
    faqUrl,
    importDiffModal,
    coordinateFieldName,
    labelIdFieldName,
    acceptedTemplateFileTypes,
    exportFormatTypes,
    ackByButtonTopics,
    buttonConfirmEventTopics,
  };
  const prod = {
    api: {
      url: apiUrl,
      authMechanism,
      autoRefresh: authAutoRefresh,
    },
    e2eSettings: {
      service: 'WEBEAM',
      log: false,
      WEBEAM: {
        ...e2eWEBEAM,
      },
    },
    availableTemplates,
    faqUrl,
    importDiffModal,
    coordinateFieldName,
    labelIdFieldName,
    acceptedTemplateFileTypes,
    exportFormatTypes,
    ackByButtonTopics,
    buttonConfirmEventTopics,
  };

  return environment === 'testing' ? testing : environment === 'development' ? dev : prod;
};

export default fetchConfig;
