import { PickingArea, PickingAreaSortedSide, PickingColor, PickingType, SortedSide } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';
import { ReactSelectAsyncOption } from '../../Common/Components/ReactSelectAsync';

export interface PickingAreaState {
  colors: PickingColor[];
  externalIdentifier: string;
  defaultBlinkDuration: number;
  type: PickingType;
  requiredSide?: PickingAreaSortedSide;
  requiredSideAck: boolean;
  optionalSide?: PickingAreaSortedSide | null;
  optionalSideAck?: boolean | null;
  symmetricalAck?: boolean | null;
  simultaneousBlinkCount: number;
  simultaneousJobCount: number;
  needRefresh: boolean;
  allFilled: boolean;
  requiredSideSelected: ReactSelectAsyncOption<SortedSide> | null;
  optionalSideSelected: ReactSelectAsyncOption<SortedSide> | null;
  ackByButtonTopic: string | null;
  automaticJobFinish: boolean;
}

export class PickingAreaStore {
  @observable
  public editablePickingArea?: PickingArea;

  @observable
  public state: PickingAreaState = {
    colors: [PickingColor.GREEN],
    externalIdentifier: '',
    type: PickingType.PICK_BY,
    requiredSideAck: false,
    optionalSideAck: null,
    symmetricalAck: null,
    defaultBlinkDuration: 120,
    simultaneousBlinkCount: 10,
    simultaneousJobCount: 1,
    needRefresh: false,
    allFilled: false,
    requiredSideSelected: null,
    optionalSideSelected: null,
    ackByButtonTopic: null,
    automaticJobFinish: false,
  };

  @observable
  public loading: boolean = false;

  @observable
  public changed: boolean = false;

  @action
  public setState = (newState: Partial<PickingAreaState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };
  };

  @action
  public resetState = (): void => {
    const { editablePickingArea } = this;

    const {
      editablePickingArea: {
        id: externalIdentifier = '',
        type = PickingType.PICK_BY,
        requiredSideAck = false,
        optionalSideAck = null,
        symmetricalAck = null,
        defaultBlinkDuration = 120,
        colors = [PickingColor.GREEN],
        simultaneousBlinkCount = 10,
        maximumSimultaneouslyRunningJobs: simultaneousJobCount = 1,
        ackByButtonTopic = null,
        requiredSide = null,
        optionalSide = null,
        automaticJobFinish = false,
      } = {},
    } = this;

    const requiredSideSelected = requiredSide
      ? {
          value: requiredSide as SortedSide,
          label: requiredSide.name,
        }
      : null;

    const optionalSideSelected = optionalSide
      ? {
          value: optionalSide as SortedSide,
          label: optionalSide.name,
        }
      : null;

    this.state = {
      allFilled: editablePickingArea !== undefined,
      needRefresh: false,
      externalIdentifier,
      type,
      requiredSideAck,
      optionalSideAck,
      symmetricalAck,
      defaultBlinkDuration,
      colors,
      simultaneousBlinkCount,
      simultaneousJobCount,
      requiredSideSelected,
      optionalSideSelected,
      ackByButtonTopic,
      automaticJobFinish,
    };

    this.changed = false;
    this.loading = false;
  };

  @action
  public resetStore = (): void => {
    this.editablePickingArea = undefined;
    this.loading = false;
    this.changed = false;
    this.resetState();
  };
}
