import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from 'src/Common/Helper/ThemeHelper';

export const LoginStyles = (theme: Theme): Record<string, CSSProperties> => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: spacing(theme) * 3,
    marginRight: spacing(theme) * 3,
    [theme.breakpoints.up(400 + spacing(theme) * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: spacing(theme) * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${spacing(theme) * 2}px ${spacing(theme) * 3}px ${spacing(theme) * 3}px`,
  } as CSSProperties,
  avatar: {
    margin: spacing(theme),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: spacing(theme),
  },
  submit: {
    marginTop: spacing(theme) * 3,
  },
  close: {
    padding: spacing(theme) / 2,
  },
});
