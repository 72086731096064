import { UpdateStrategyType } from '@ekkogmbh/apisdk';
import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from 'src/Common/Components/Page';
import { TitleProps } from 'src/Routes';
import { CompartmentGeneratorStore } from '../CompartmentManagement/Stores/CompartmentGeneratorStore';
import { PresetStore } from '../PresetManagement/Stores/PresetStore';
import { UpdaterProfileManagementContent } from './Components/UpdaterProfileManagementContent';
import { UpdaterProfileStore } from './Stores/UpdaterProfileStore';

export const resolveStrategyName = (type: UpdateStrategyType): string => {
  switch (type) {
    case UpdateStrategyType.MANUAL:
      return 'Manual';
    case UpdateStrategyType.REGEX_SCAN:
      return 'Regex Scan';
    case UpdateStrategyType.CLEARING:
      return 'Clearing';
  }
};

const stores = {
  compartmentGeneratorStore: new CompartmentGeneratorStore(),
  presetStore: new PresetStore(),
  updaterProfileStore: new UpdaterProfileStore(),
};

export const UpdaterProfileManagement = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <UpdaterProfileManagementContent />
    </Page>
  </Provider>
);
