import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { CompartmentDetailsContent } from './Components/CompartmentDetailsContent';

export const CompartmentDetails = ({ title }: TitleProps): React.JSX.Element => (
  <Provider>
    <Page title={title} isSearchable={true}>
      <CompartmentDetailsContent />
    </Page>
  </Provider>
);
