import { PickingArea } from '@ekkogmbh/apisdk';
import { CSSProperties } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import { AssignmentLate, AssignmentReturn, AssignmentTurnedIn, Check, Clear, Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { BlinkColorItem } from 'src/Common/Components/BlinkColorItem';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  PickingAreasContentActionHandlers,
  PickingAreasContentPropsWithStores,
  PickingAreasContentState,
} from './PickingAreasContent';

class PickingAreaDataTableActions extends DataTableActionsComponent<PickingArea> {}

const textStyle: CSSProperties = {
  verticalAlign: 'middle',
};

const iconStyle: CSSProperties = {
  height: 16,
  width: 16,
  marginLeft: 8,
  color: 'rgba(153, 153, 153, 1)',
  ...textStyle,
};

const AreaExternalIdentifier = (): MUIDataTableColumnDef => ({
  label: 'External-Identifier',
  name: 'entry.id',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (id: PickingArea['id']): React.ReactNode => <div style={{ fontWeight: 700 }}>{id}</div>,
  },
});

const AreaPickingType = (): MUIDataTableColumnDef => ({
  label: 'Picking-Type',
  name: 'entry.type',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (type: PickingArea['type']): React.ReactNode => <div style={{ fontWeight: 700 }}>{type}</div>,
  },
});

const AreaRequiredSide = (): MUIDataTableColumnDef => ({
  label: 'Required-Side',
  name: 'entry',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: PickingArea): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>
        <span style={{ ...textStyle } as React.CSSProperties}>{value.requiredSide.name}</span>
        <span>
          <Tooltip title={value.requiredSideAck ? 'ACK' : 'NO-ACK'} placement="top" enterDelay={500} leaveDelay={200}>
            {value.requiredSideAck ? (
              <AssignmentTurnedIn style={{ ...iconStyle } as React.CSSProperties} />
            ) : (
              <AssignmentLate style={{ ...iconStyle } as React.CSSProperties} />
            )}
          </Tooltip>
        </span>
      </div>
    ),
  },
});

const AreaOptionalSide = (): MUIDataTableColumnDef => ({
  label: 'Optional-Side',
  name: 'entry',
  options: {
    sort: true,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: PickingArea): React.ReactNode => (
      <div>
        {value.optionalSide !== undefined && value.optionalSide !== null && (
          <React.Fragment>
            <span style={{ ...textStyle } as React.CSSProperties}>{value.optionalSide.name}</span>
            <span>
              <Tooltip
                title={value.optionalSideAck ? (value.symmetricalAck ? 'SYMMETRIC-ACK' : 'ACK') : 'NO-ACK'}
                placement="top"
                enterDelay={500}
                leaveDelay={200}
              >
                {value.optionalSideAck ? (
                  value.symmetricalAck ? (
                    <AssignmentReturn style={{ ...iconStyle } as React.CSSProperties} />
                  ) : (
                    <AssignmentTurnedIn style={{ ...iconStyle } as React.CSSProperties} />
                  )
                ) : (
                  <AssignmentLate style={{ ...iconStyle } as React.CSSProperties} />
                )}
              </Tooltip>
            </span>
          </React.Fragment>
        )}
        {!value.optionalSide && '-'}
      </div>
    ),
  },
});

// // @ts-ignore
// const AreaRequiredSideAck = (): MUIDataTableColumnDef => ({
//   name: 'Required-Side ACK',
//   field: 'entry',
//   options: {
//     sort: false,
//     filter: false,
//     headerNoWrap: true,
//     customBodyRender: (value: PickingArea): React.ReactNode => value.requiredSideAck ? 'yes' : 'no',
//   },
// });
//
// // @ts-ignore
// const AreaOptionalSideAck = (): MUIDataTableColumnDef => ({
//   name: 'Optional-Side ACK',
//   field: 'entry',
//   options: {
//     sort: false,
//     filter: false,
//     headerNoWrap: true,
//     customBodyRender: (value: PickingArea): React.ReactNode => value.optionalSideAck ? 'yes' : 'no',
//   },
// });

const AreaColors = (): MUIDataTableColumnDef => ({
  label: 'Blink-Colors',
  name: 'entry.colors',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (colors: PickingArea['colors']): React.ReactNode =>
      colors.map((color: string, index: number) => <BlinkColorItem key={index} color={color} />),
  },
});

const AreaNextColor = (): MUIDataTableColumnDef => ({
  label: 'Next-Color',
  name: 'entry.nextColor',
  options: {
    sort: false,
    filter: false,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (nextColor: PickingArea['nextColor']): React.ReactNode => <BlinkColorItem color={nextColor} />,
  },
});

const AreaSimultaneousBlinkCount = (): MUIDataTableColumnDef => ({
  label: 'Blink-Count',
  name: 'entry.simultaneousBlinkCount',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: PickingArea['simultaneousBlinkCount']): React.ReactNode => value,
  },
});

const AreaDefaultBlinkDuration = (): MUIDataTableColumnDef => ({
  label: 'Blink-Duration',
  name: 'entry.defaultBlinkDuration',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: PickingArea['defaultBlinkDuration']): React.ReactNode => value,
  },
});

const AreaMaximumSimultaneouslyRunningJobs = (): MUIDataTableColumnDef => ({
  label: 'Concurrent-Jobs',
  name: 'entry.maximumSimultaneouslyRunningJobs',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: PickingArea['maximumSimultaneouslyRunningJobs']): React.ReactNode => value,
  },
});

const AreaAckByButtonTopic = (): MUIDataTableColumnDef => ({
  label: 'Acknowledge-With-Button',
  name: 'entry.ackByButtonTopic',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: PickingArea['ackByButtonTopic']): React.ReactNode => {
      if (!value) {
        return (
          <div>
            <Clear />
          </div>
        );
      }

      return (
        <div>
          <Tooltip
            title={
              <span>
                topic: <b>{value}</b>
              </span>
            }
            placement="top"
            enterDelay={500}
            leaveDelay={200}
          >
            <Check />
          </Tooltip>
        </div>
      );
    },
  },
});

const AutomaticJobFinish = (): MUIDataTableColumnDef => ({
  label: 'Automatic-Job-Finish',
  name: 'entry.automaticJobFinish',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (value: PickingArea['automaticJobFinish']): React.ReactNode => (value ? <Check /> : <Clear />),
  },
});

const AreaActions = (
  _: PickingAreasContentState,
  propsWithStores: PickingAreasContentPropsWithStores,
  actions: PickingAreasContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (pickingArea: PickingArea): React.ReactNode => {
      const hasWritePermission = propsWithStores.api.userHasPermissionOnAnyNode(Permissions.PICKING_AREAS_WRITE);

      const actionButtons: ActionButton<PickingArea>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return <PickingAreaDataTableActions dataset={pickingArea} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  PickingAreasContentPropsWithStores,
  PickingAreasContentState,
  PickingAreasContentActionHandlers
>> = [
  AreaExternalIdentifier,
  // AreaRequiredSideAck,
  AreaPickingType,
  AreaRequiredSide,
  AreaOptionalSide,
  // AreaOptionalSideAck,
  AreaColors,
  AreaNextColor,
  AreaSimultaneousBlinkCount,
  AreaDefaultBlinkDuration,
  AreaMaximumSimultaneouslyRunningJobs,
  AutomaticJobFinish,
  AreaAckByButtonTopic,
  AreaActions,
];
