import { EslManagerPrivateRoute, HttpMethod, Technology } from '@ekkogmbh/apisdk';
import { Checkbox, ListItemText, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface TechnologyPickerStores {
  api: ApiStore;
}

interface TechnologyPickerProps extends WithStyles<typeof styles> {
  selected?: string | string[];
  optional?: boolean;
  multiple?: boolean;
  onChange: (selection: string | string[] | undefined) => void;
  onError?: () => void;
  disabled?: boolean;
  label?: string;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}

interface TechnologyPickerState {
  loading: boolean;
  failure: boolean;
  technologyNames: string[];
}

@inject('api')
class TechnologyPickerComponent extends Component<TechnologyPickerProps, TechnologyPickerState> {
  public state: TechnologyPickerState = {
    loading: true,
    failure: false,
    technologyNames: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): TechnologyPickerStores {
    return this.props as TechnologyPickerProps & TechnologyPickerStores;
  }

  public async componentDidMount(): Promise<void> {
    await this.fetchTechnologies();
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchTechnologies = async (): Promise<void> => {
    const { api } = this.stores;
    const { onError, onChange, optional, selected, multiple } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, technologyNames: [] }, onError);
    };

    let technologies: Technology[] = [];
    try {
      technologies = await request<Technology[]>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getTechnologies(),
        EslManagerPrivateRoute.TECHNOLOGIES,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    const technologyNames = technologies.map((technology) => technology.name).sort();

    if (technologyNames.length > 0) {
      this.setState({ loading: false, technologyNames });

      if (!(multiple || optional || selected)) {
        onChange(technologyNames[0]);
      }
    } else {
      onErrorCallback();
    }
  };

  private getSelectedIndices = (selection?: string | string[]): number | number[] | '' => {
    const { technologyNames } = this.state;

    if (selection instanceof Array) {
      return selection.map((name: string) => technologyNames.indexOf(name));
    }

    return selection ? technologyNames.indexOf(selection) : '';
  };

  private onChange = (event: SelectChangeEvent<unknown>) => {
    const { onChange } = this.props;
    const { technologyNames } = this.state;

    const selection = event.target.value;

    if (selection instanceof Array) {
      const selectedNames = selection.map((value: number) => technologyNames[value]);
      onChange(selectedNames);
    } else {
      const selectedIndex = selection as number;
      onChange(selectedIndex < 0 ? undefined : technologyNames[selection as number]);
    }
  };

  public render() {
    const { disabled, multiple, selected, label, optional, forwardedRef } = this.props;
    const { technologyNames, loading, failure } = this.state;

    const entries: React.JSX.Element[] = [];

    if (optional && !multiple) {
      entries.push(
        <MenuItem key={'technology-option-none'} value={-1}>
          <ListItemText primary={'-'} />
        </MenuItem>,
      );
    }

    technologyNames.forEach((name: string, index: number) => {
      entries.push(
        <MenuItem key={`technology-option-${index}-${name}`} value={index}>
          {multiple && <Checkbox checked={(selected as string[]).includes(name)} />}
          <ListItemText primary={name} />
        </MenuItem>,
      );
    });

    const renderValue = (value: SelectProps['value']) => {
      const selectedIndices = value as number[];
      const combinedNames = selectedIndices.map((value) => technologyNames[value]).join(', ');
      return <ListItemText primary={combinedNames} />;
    };

    return (
      <>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
            ref={forwardedRef}
          >
            <CheckmarkSpinner complete={false} failure={failure} />
          </div>
        )}
        {!loading && (
          <StyledSelectField
            disabled={disabled}
            multiple={multiple}
            onChange={this.onChange}
            value={this.getSelectedIndices(selected)}
            label={label ?? multiple ? 'Technologies' : 'Technology'}
            renderValue={multiple ? renderValue : undefined}
          >
            {entries}
          </StyledSelectField>
        )}
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(TechnologyPickerComponent);

// eslint-disable-next-line react/display-name
export const TechnologyPicker = React.forwardRef<HTMLDivElement, Omit<TechnologyPickerProps, 'classes'>>(
  (props, ref) => <StyleWrapped {...props} forwardedRef={ref} />,
);
