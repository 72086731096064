import { TemplateType } from '@ekkogmbh/apisdk';
import { Fade, Grid, SelectChangeEvent, TextField } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { enumKeys } from 'src/Common/Helper/Enum';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { TemplateStore } from '../Stores/TemplateStore';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'templateStore', 'configStore'];

interface TemplateFormStores {
  templateStore: TemplateStore;
  configStore: ConfigStore;
}

interface TemplateFormState {
  loading: boolean;
}

interface TemplateFormProps extends WithStyles<typeof styles> {}

@inject(...stores)
@observer
class TemplateFormComponent extends React.Component<TemplateFormProps, TemplateFormState> {
  public state: TemplateFormState = {
    loading: false,
  };

  get stores(): TemplateFormStores {
    return this.props as TemplateFormProps & TemplateFormStores;
  }

  public handleChange = (fieldName: string) => async ({
    target: { value },
  }:
    | SelectChangeEvent<unknown>
    | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    | { target: { value: string } }) => {
    const { templateStore } = this.stores;

    switch (fieldName) {
      case 'name':
        templateStore.setState({ name: (value as string).trim() }, true);
        break;
      case 'type':
        templateStore.setState({ type: value as TemplateType }, true);
        break;
      case 'coordinate':
        templateStore.setState({ coordinate: (value as string).trim() }, true);
    }

    templateStore.changed = true;
  };

  public render() {
    const { loading } = this.state;
    const { classes } = this.props;
    const { templateStore } = this.stores;
    const { name, coordinate } = templateStore.state;
    const { editableTemplate } = templateStore;

    const templateType = templateStore.state.type;

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid item xs={12}>
                <TextField
                  label={'Name'}
                  value={name}
                  name={'name'}
                  onChange={this.handleChange('name')}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  disabled={!!editableTemplate}
                />
              </Grid>

              <Grid item xs={6}>
                <CoordinateInput
                  value={coordinate}
                  onChange={(value) => this.handleChange('coordinate')({ target: { value } })}
                  disabled={!!editableTemplate}
                  trailingDelimiter={false}
                />
              </Grid>

              <Grid item xs={6}>
                <StyledSelectField
                  native
                  disabled
                  value={templateType}
                  onChange={this.handleChange('type')}
                  label="Type"
                >
                  {enumKeys(TemplateType).map((possibleTemplateType) => (
                    <option key={possibleTemplateType} value={TemplateType[possibleTemplateType]}>
                      {possibleTemplateType}
                    </option>
                  ))}
                </StyledSelectField>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(TemplateFormComponent);

export const TemplateForm = StyleWrapped;
